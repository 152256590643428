import { useToasts } from 'components/Toasts/Toasts';
import { Api } from 'Definitions/Api';
import { useMutation, useQuery } from 'react-query';
import { invalidateAllDocuments } from 'services/documents';
import client, { queryClient } from 'utils/client';

export function useDocumentsHook() {
  const { showRequestError } = useToasts();

  const baseUrl = 'api/v1/documents';

  const useGetDocuments = () => useQuery(baseUrl, { useErrorBoundary: true });
  const useGetDocument = (id: string) =>
    useQuery(`${baseUrl}/${id}`, { useErrorBoundary: true });

  const patchDocument = (props: { id: string; patch: Api['DocumentPatch'] }) => {
    return client(`${baseUrl}/${props.id}`, {
      method: 'PATCH',
      body: props.patch,
    }).catch((error) => showRequestError(error));
  };

  // @ts-ignore
  const patchDocumentMutation = useMutation(patchDocument, {
    onError: (error) => {
      showRequestError(error);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(baseUrl);
      queryClient.invalidateQueries(`${baseUrl}/${variables.id}`);
      invalidateAllDocuments();
    },
  });

  const patchDocumentsSample = (patch: Api['SampleDocumentsPatchIn']) => {
    return client(`${baseUrl}/sample`, {
      method: 'PATCH',
      body: patch,
    }).catch((error) => showRequestError(error));
  };

  // @ts-ignore
  const patchDocumentsSampleMutation = useMutation(patchDocumentsSample, {
    onError: () => {
      showRequestError();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(baseUrl);
      invalidateAllDocuments();
    },
  });

  return {
    useGetDocuments: useGetDocuments,
    useGetDocument: useGetDocument,
    patchDocumentMutation: patchDocumentMutation,
    patchDocumentsSampleMutation: patchDocumentsSampleMutation,
  };
}
