import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiPanel,
  EuiSelect,
  EuiTitle,
} from '@elastic/eui';
import { useAtom } from 'jotai';
import React from 'react';
import {
  AnnotationsPreferences,
  showElementsEnum,
} from '../../Definitions/AnnotationsPreferences';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { annotationPreferencesAtom } from './AnnotationState';

export default function AnnotationsPreferencesFlyout(props: {
  isOpen: boolean;
  onFlyoutClose: () => void;
}) {
  const { isOpen, onFlyoutClose } = props;
  const intl = useTranslate();
  const [
    annotationPreferences,
    setAnnotationPreferences,
  ] = useAtom<AnnotationsPreferences>(annotationPreferencesAtom);

  const showElementsOptions = [
    {
      value: showElementsEnum.ONLY_ANNOTATED,
      text: intl.formatMessage({
        id: 'annotationPreferencesFlyout.showAnnotatedSchemaElements',
        defaultMessage: 'Show only annotated schema elements',
      }),
    },
    {
      value: showElementsEnum.ALL,
      text: intl.formatMessage({
        id: 'annotationPreferencesFlyout.showAllSchemaElements',
        defaultMessage: 'Show all schema elements',
      }),
    },
  ];

  let flyout;

  function onShowElementsSelectChange(
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    // @ts-ignore
    setAnnotationPreferences({
      ...annotationPreferences,
      showElements: e.target.value,
    });
  }

  if (isOpen) {
    flyout = (
      <EuiFlyout
        ownFocus
        size="s"
        onClose={onFlyoutClose}
        aria-labelledby="annotationFlyoutTitle"
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id="annotationFlyoutTitle">
              <Translate
                id="annotationPreferencesFlyout.annotationPreferences"
                defaultMessage="Annotation preferences"
              />
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiTitle size="xs">
            <h3>
              <Translate
                id="annotationPreferencesFlyout.annotationElementsList"
                defaultMessage="Annotation elements list"
              />
            </h3>
          </EuiTitle>

          <EuiPanel color="subdued" borderRadius="none" hasShadow={false}>
            <EuiSelect
              id="showElementsSelect"
              options={showElementsOptions}
              value={annotationPreferences.showElements}
              onChange={(e) => onShowElementsSelectChange(e)}
              aria-label="Annotation elements list: show always all elements from schema"
            />
          </EuiPanel>
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }

  return <>{flyout}</>;
}
