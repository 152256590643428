import { useToasts } from 'components/Toasts/Toasts';
import { Api } from 'Definitions/Api';
import { useAtom } from 'jotai';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getFolderEndpoint, getFoldersEndpoint } from 'services/endpoints';
import client, { queryClient } from 'utils/client';
import { userSettingsAtom } from '../Header/States/UserState';
import { useTranslate } from '../Internationalisation/useTranslate';

export type FolderInfo = { id: string; name: string };

export function useFolders() {
  const { addToast, showRequestError } = useToasts();
  const intl = useTranslate();
  const navigate = useNavigate();
  const [userSettings] = useAtom(userSettingsAtom);

  const foldersUrl = 'api/v1/folders';

  const useGetFolders = (permission?: Api['PermissionApi']) => {
    let url = foldersUrl;
    const queryParams = new URLSearchParams();
    if (permission) {
      queryParams.append('permission', permission);
      url += `?${queryParams.toString()}`;
    }
    return useQuery(
      [
        'folders',
        'all',
        {
          permission: permission,
        },
      ],
      // @ts-ignore
      (): Promise<Api['FoldersGetOut']> => client(url),
      {
        useErrorBoundary: true,
      }
    );
  };

  const useGetFolder = (folderId: string | undefined, enabled = true) =>
    useQuery(getFolderEndpoint(folderId), { useErrorBoundary: true, enabled });

  const deleteFolder = (folderId: string) => {
    return client(getFolderEndpoint(folderId), {
      method: 'DELETE',
    });
  };

  // @ts-ignore
  const deleteFolderMutation = useMutation(deleteFolder, {
    onError: () => {
      addToast({
        title: intl.formatMessage({
          id: 'useFolders.message.folderNotDeleted',
          defaultMessage: 'Folder not deleted. Only empty folders can be deleted',
        }),
        color: 'danger',
      });
    },
    onSuccess: () => {
      navigate(`/folders/${userSettings.oldestAccessibleFolderId}/documents`);
      queryClient.invalidateQueries(['folders']).then(() =>
        addToast({
          title: intl.formatMessage({
            id: 'useFolders.message.folderDeleted',
            defaultMessage: 'Folder deleted',
          }),
          color: 'success',
        })
      );
    },
  });

  const createFolder = (folder: { parentFolderId: string; folderName: string }) => {
    return client(getFoldersEndpoint(), {
      method: 'POST',
      body: {
        parent_id: folder.parentFolderId,
        name: folder.folderName,
      },
    });
  };

  // @ts-ignore
  const createFolderMutation = useMutation(createFolder, {
    onError: () => {
      addToast({
        title: intl.formatMessage({
          id: 'useFolders.message.folderNotCreated',
          defaultMessage: 'Folder not created',
        }),
        color: 'danger',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['folders']).then(() =>
        addToast({
          title: intl.formatMessage({
            id: 'useFolders.message.folderCreated',
            defaultMessage: 'Folder created',
          }),
          color: 'success',
        })
      );
    },
  });

  const patchFolder = (props: { folderId: string; patch: Api['FolderPatchIn'] }) => {
    return client(`${foldersUrl}/${props.folderId}`, {
      method: 'PATCH',
      body: props.patch,
    });
  };

  // @ts-ignore
  const patchFolderMutation = useMutation(patchFolder, {
    onError: (error) => {
      showRequestError(error);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['folders']);
      queryClient.invalidateQueries(getFolderEndpoint(variables.folderId));
    },
  });

  const useGetFolderStatistics = (folderId: string, start: string, end: string) => {
    return useQuery(
      ['folders', folderId, 'statistics', { start: start, end: end }],
      (): Promise<Api['FolderStatisticsGetOut']> =>
        client(
          `${foldersUrl}/${folderId}/statistics?imported_at_after=${start}&imported_at_before=${end}`
        ),
      {
        useErrorBoundary: true,
      }
    );
  };

  return {
    useGetFolders: useGetFolders,
    useGetFolder: useGetFolder,
    useGetFolderStatistics: useGetFolderStatistics,
    deleteFolderMutation: deleteFolderMutation,
    createFolderMutation: createFolderMutation,
    patchFolderMutation: patchFolderMutation,
  };
}
