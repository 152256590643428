import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiNotificationBadge,
  EuiPopover,
} from '@elastic/eui';
import useHover from 'react-use-hover';
import { IDP_USER_ID, THEME } from 'settings/constants';
import { SchemaElementKind } from '../../../Definitions/SchemaElementKind';

export function AnnotationItemMessagesIcon({
  confidence,
  updatedBy,
  messages,
  showOnlyNonempty = false,
}) {
  const [isMessagesIconHovered, messagesIconHoverProps] = useHover({
    mouseEnterDelayMS: 0,
    mouseLeaveDelayMS: 0,
  });

  const userMessages = (messages || []).filter((message) =>
    ['approved', 'info', 'warning', 'error'].includes(message.type)
  );
  const hasConfidenceMessage = userMessages?.some((m) =>
    m.source.startsWith('validation/confidence')
  );
  const showExtraConfidenceMessage =
    !hasConfidenceMessage &&
    ((updatedBy && updatedBy !== IDP_USER_ID) || !!confidence || confidence === 0);

  if (!userMessages?.length && !showExtraConfidenceMessage) {
    return showOnlyNonempty ? null : (
      <EuiNotificationBadge
        size="m"
        style={{
          marginRight: '0.5em',
          backgroundColor: THEME.euiColorSuccess,
        }}
      >
        <EuiIcon size="s" type="check" />
      </EuiNotificationBadge>
    );
  }

  const messageTypeToBadgeColor = {
    approved: 'success',
    info: 'primary',
    warning: 'warning',
    error: 'danger',
  };
  const messageTypeToIconColor = {
    approved: THEME.euiColorSuccess,
    info: THEME.euiColorSuccess,
    warning: THEME.euiColorWarning,
    error: THEME.euiColorDanger,
  };
  const messageTypeToLevel = { info: 0, warning: 1, error: 2 };
  userMessages.sort((a, b) => {
    return messageTypeToLevel[b.type] - messageTypeToLevel[a.type];
  });
  const highestLevelColor = messageTypeToIconColor[userMessages[0]?.type || 'info'];
  return !userMessages.length && showOnlyNonempty ? null : (
    <EuiPopover
      anchorPosition="upCenter"
      ownFocus={false}
      button={
        <EuiNotificationBadge
          size="m"
          {...messagesIconHoverProps}
          style={{
            marginRight: '0.5em',
            backgroundColor: highestLevelColor,
          }}
        >
          {userMessages.length || <EuiIcon size="s" type="check" />}
        </EuiNotificationBadge>
      }
      isOpen={isMessagesIconHovered}
      closePopover={() => {}}
    >
      <EuiFlexGroup
        direction="column"
        alignItems="flexStart"
        gutterSize="s"
        style={{ maxWidth: '30em' }}
      >
        {userMessages.map((message, index) => (
          <EuiFlexItem key={index}>
            <EuiBadge key={message.id} color={messageTypeToBadgeColor[message.type]}>
              <div className="messages" style={{ padding: '0.3em 0' }}>
                {message.content}
              </div>
            </EuiBadge>
          </EuiFlexItem>
        ))}
        {showExtraConfidenceMessage && (
          <EuiFlexItem>
            <EuiBadge color="hollow">
              <div className="messages" style={{ padding: '0.3em 0' }}>
                {updatedBy && updatedBy !== IDP_USER_ID
                  ? 'Approved by user.'
                  : 'Confidence ' + Number(confidence).toFixed(2) + '.'}
              </div>
            </EuiBadge>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiPopover>
  );
}

export function AnnotationMessagesIcon({ messages }) {
  const [isMessagesIconHovered, messagesIconHoverProps] = useHover({
    mouseEnterDelayMS: 0,
    mouseLeaveDelayMS: 0,
  });

  const userMessages = messages?.filter((message) =>
    ['warning', 'error'].includes(message.type)
  );
  if (!userMessages?.length) {
    return null;
  }
  const messageTypeToBadgeColor = {
    warning: 'warning',
    error: 'danger',
  };
  const messageTypeToIconColor = {
    warning: THEME.euiColorWarning,
    error: THEME.euiColorDanger,
  };
  const messageTypeToLevel = { warning: 1, error: 2 };
  userMessages.sort((a, b) => {
    return messageTypeToLevel[b.type] - messageTypeToLevel[a.type];
  });
  const highestLevelColor = messageTypeToIconColor[userMessages[0].type];
  return (
    <EuiPopover
      anchorPosition="upCenter"
      ownFocus={false}
      button={
        <EuiNotificationBadge
          size="m"
          {...messagesIconHoverProps}
          style={{
            marginLeft: '0.5em',
            marginBottom: '1px',
            backgroundColor: highestLevelColor,
          }}
        >
          {userMessages.length}
        </EuiNotificationBadge>
      }
      isOpen={isMessagesIconHovered}
      closePopover={() => {}}
    >
      <EuiFlexGroup
        direction="column"
        alignItems="flexStart"
        gutterSize="s"
        style={{ maxWidth: '30em' }}
      >
        {userMessages.map((message, index) => (
          <EuiFlexItem key={index}>
            <EuiBadge key={message.id} color={messageTypeToBadgeColor[message.type]}>
              <div className="messages" style={{ padding: '0.3em 0' }}>
                {message.content} ({message.schema_element_id})
              </div>
            </EuiBadge>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </EuiPopover>
  );
}

export const getMessages = (items = [], messages = []) => {
  if (!items || !_.isArray(items)) {
    return messages;
  }
  for (const item of items) {
    if (item.kind !== SchemaElementKind.Datapoint && item.elements.length) {
      getMessages(item.elements, messages);
    } else if (item.kind === SchemaElementKind.Datapoint && item?.messages?.length) {
      messages.push(...item.messages);
    }
  }
  return messages;
};
