import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiTextArea,
} from '@elastic/eui';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { createNewSchema } from 'services/schemas';
import { SchemasPostIn } from '../../../Definitions/ApiTypes';
import { SchemaAsNewModalWindowProps } from '../../../Definitions/SchemaAsNewModalWindowProps';
import { Translate } from '../../Internationalisation/translate';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useToasts } from '../../Toasts/Toasts';

export function SchemaSaveAsNewModalWindow(props: SchemaAsNewModalWindowProps) {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [schemaName, setSchemaName] = useState<string>(props.originalSchema?.name);
  const [schemaDescription, setSchemaDescription] = useState<string | undefined>(
    props.originalSchema?.description
  );
  const { folderId: selectedFolderId } = useParams();
  const intl = useTranslate();

  const { mutate, isLoading } = useMutation(createNewSchema, {
    onError: (error: { message?: string }) => {
      addToast({
        title: intl.formatMessage({
          id: 'schemaSaveAsNewModal.skillNotBeSaved',
          defaultMessage: 'Skill could not be saved',
        }),
        text: <p>{error.message}</p>,
        color: 'danger',
      });
    },
    onSuccess: (data) => {
      addToast({ title: 'Skill created', color: 'success' });
      queryClient.invalidateQueries('api/v1/schemas');
      navigate(`/skills/${data.schema_id}`);
    },
    onSettled: () => handleCloseModal(),
  });

  function handleCloseModal() {
    props.onClose();
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSchemaName(event.target.value);
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setSchemaDescription(event.target.value);
  }

  function handleSubmitForm() {
    const payload: SchemasPostIn = {
      folder_id: selectedFolderId,
      name: schemaName as string,
      description: schemaDescription,
      content: props.newContent,
    };
    mutate(payload);
  }

  let modal;

  const schemaForm = (
    <EuiForm id="schemaAsNewModalForm" component="form">
      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaSaveAsNewModal.schemaName',
          defaultMessage: 'Schema name',
        })}
      >
        <EuiFieldText
          required
          fullWidth
          name="schema-name"
          value={schemaName}
          onChange={handleNameChange}
        />
      </EuiFormRow>
      <EuiFormRow
        label={intl.formatMessage({
          id: 'schemaSaveAsNewModal.schemaDescription',
          defaultMessage: 'Schema description',
        })}
      >
        <EuiTextArea
          fullWidth
          name="schema-description"
          value={schemaDescription}
          onChange={handleDescriptionChange}
        />
      </EuiFormRow>
    </EuiForm>
  );

  if (props.open) {
    modal = (
      <EuiModal onClose={handleCloseModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>
              <Translate
                id="schemaSaveAsNewModal.schemaCannotBeUpdated"
                defaultMessage="Schema cannot be updated"
              />
            </h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <Translate
            id="schemaSaveAsNewModal.schemaCannotBeUpdated.description"
            defaultMessage={`
          Annotations already exist for one of the modified schema elements. In this case it is not possible to change the element's ID, kind, or parent schema element. However, the updated schema can be saved as a new schema version.
          `}
          />
          <EuiSpacer />
          {schemaForm}
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={handleCloseModal}>Cancel</EuiButtonEmpty>

          <EuiButton
            type="submit"
            form="schemaModalForm"
            onClick={handleSubmitForm}
            isDisabled={!schemaName?.length}
            isLoading={isLoading}
            fill
          >
            <Translate
              id="schemaSaveAsNewModal.saveAsNewVersion"
              defaultMessage="Save as new version"
            />
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return <div>{modal}</div>;
}
