import { EuiFieldSearch } from '@elastic/eui';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentView } from '../../Definitions/DocumentView';
import {
  currentFilterValues,
  documentViews,
  pageIndexAtom,
} from '../Header/States/UserState';
import { useTranslate } from '../Internationalisation/useTranslate';

export default function Search() {
  const [searchInputRef, setSearchInputRef] = useState<
    HTMLInputElement | HTMLElement | null
  >(null);
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [filterValues, setFilterValues] = useAtom(currentFilterValues);
  const [dViews, setDocumentViews] = useAtom<DocumentView[]>(documentViews);
  const intl = useTranslate();
  const [, setPageIndex] = useAtom(pageIndexAtom);

  useEffect(() => {
    if (!filterValues?.datapointValue && searchInputRef) {
      searchInputRef.value = null;
    }
  }, [filterValues]);

  useHotkeys('alt+s', (event) => {
    event.preventDefault();
    if (searchInputRef) {
      searchInputRef.focus();
    } else {
      handleSearchInputRef(null, true);
    }
  });

  function handleSearch(searchText: string) {
    const selectedDocumentView = dViews.find((view) => view.selected) as DocumentView;
    const filteredDocumentViews = dViews.filter(
      (view) => view.id !== selectedDocumentView.id
    );
    const savedFilterValues = selectedDocumentView.savedFilterValues;

    let currentViewFilteredValues = selectedDocumentView.filterValues;

    if (!searchText.length) {
      const datapointValue = savedFilterValues['datapointValue'];

      currentViewFilteredValues = datapointValue
        ? { ...currentViewFilteredValues, datapointValue }
        : _.omit(filterValues, 'datapointValue');
    } else {
      navigate(`/folders/${folderId}/documents`);
      currentViewFilteredValues = {
        ...currentViewFilteredValues,
        datapointValue: searchText,
      };
    }
    setPageIndex(0);
    setFilterValues(currentViewFilteredValues);
    setDocumentViews({
      documentViews: [
        ...filteredDocumentViews,
        { ...selectedDocumentView, filterValues: currentViewFilteredValues },
      ],
    });
  }

  function handleSearchInputRef(
    ref?: HTMLInputElement | HTMLElement | null,
    forceFocus = false
  ) {
    if (ref) {
      setSearchInputRef(ref);
    } else {
      const searchInput = document.getElementById('search-all-documents-field');
      setSearchInputRef(searchInput);
      if (searchInput && forceFocus) {
        searchInput.focus();
      }
    }
  }

  return (
    <div style={{ margin: '0 4px' }}>
      <EuiFieldSearch
        id="search-all-documents-field"
        isClearable
        fullWidth
        // append="⌘s"
        placeholder={intl.formatMessage({
          id: 'search.searchByFileName',
          defaultMessage: 'Search by filename or value…',
        })}
        aria-label="Search in documents by filename or datapoint value"
        style={{
          justifyContent: 'center',
          height: '36px',
          marginTop: '2px',
          minWidth: 320,
        }}
        onSearch={handleSearch}
        inputRef={handleSearchInputRef}
      />

      {/* <EuiSelectableTemplateSitewide
        id="search-all-documents-field"
        popoverButtonBreakpoints={['xs', 's']}
        popoverProps={{ isOpen: false }}
        popoverButton={<EuiIcon type="search" />}
        options={[]}
        aria-label="Search in documents by filename or datapoint value"
        style={{
          justifyContent: 'center',
          height: '36px',
          marginTop: '2px',
        }}
        searchProps={{
          // Search on enter seems not working with disabled incremental search
          incremental: false,
          isClearable: false,
          onSearch: handleSearch,
          placeholder: 'Search by filename or value…',
        }}
      /> */}
    </div>
  );
}
