import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiRange,
} from '@elastic/eui';
import { useBottomBarButtonStyle } from 'components/BottomBar/BottomBar';
import { atom, useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import LazyLoad from 'react-lazyload';
import { useSearchParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { VISIBILITY } from 'settings/constants';
import { Can, PERMISSION, useCan } from 'utils/permissions';
import { useIsMounted } from '../../../utils/useIsMounted';
import { InLineLoader } from '../../InlineLoader/InlineLoader';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useAnnotationStore } from '../Annotation';
import { useActivateDatapoint } from '../AnnotationPanel/useActivateDatapoint';
import AnnotationsPreferencesFlyout from '../AnnotationsPreferencesFlyout';
import {
  documentSplit,
  focusTableAtom,
  hoverAnnotationBoxIdAtom,
  isBoxAutoFitEnabledAtom,
  isExtendedAnnotationEnabledAtom,
  isTestModeEnabledAtom,
  showBoundingBoxesAtom,
  showSearchInDocumentBoxAtom,
} from '../AnnotationState';
import { documentSearchResultsAtom, SearchInDocumentBox } from '../SearchInDocumentBox';
import { useSplitOperations } from '../useSplitOperations';
import { ImageAnnotation } from './ImageAnnotation/ImageAnnotation';
import { useDocumentClasses } from './ImageAnnotation/useDocumentClasses';
import { useDocumentClassification } from './ImageAnnotation/useDocumentClassification';
import { pageScaleAtom } from './ImageBrowser';

const pageReferencesAtom = atom([]);

export function usePageScrolling() {
  const [references, setReferences] = useAtom(pageReferencesAtom);
  const annotation = useAnnotationStore((state) => state.annotation);
  const setCurrentPageIndex = useAnnotationStore((state) => state.setCurrentPageIndex);
  const focusedPageIndex = useAnnotationStore((state) => state.focusedPageIndex);
  const focusPage = useAnnotationStore((state) => state.focusPage);
  const currentPageIndex = useAnnotationStore((state) => state.currentPageIndex);
  const pageUrls = annotation?.pages;
  const { setActiveDatapoint } = useActivateDatapoint();

  const isMounted = useIsMounted();

  function updateSelectedPageIndex(pageIndex, scroll = true) {
    if (currentPageIndex !== pageIndex) {
      setCurrentPageIndex(pageIndex);
      scroll && references[pageUrls[pageIndex]].current.scrollIntoView();
    }
  }

  useEffect(() => {
    if (!annotation) return;
    const refs = annotation.pages.reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
    }, {});
    setReferences(refs);
  }, [annotation, setReferences]);

  useHotkeys(
    's,down',
    () => {
      const nextPage = Math.min(annotation.pages.length - 1, currentPageIndex + 1);
      focusPage(nextPage);
    },
    [annotation, currentPageIndex]
  );
  useHotkeys(
    'w,up',
    () => {
      const previousPage = Math.max(0, currentPageIndex - 1);
      focusPage(previousPage);
    },
    [currentPageIndex]
  );
  // TODO: Close popup on first Esc press. Now it at first loses focus from insert field.
  useHotkeys(
    'esc,alt+e',
    (event) => {
      setActiveDatapoint(undefined);
      event.preventDefault();
    },
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] }
  );

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    // TODO: Fix pageIndex updates when the images are small.
    //  Check all waypoints in viewport and choose the closest one to the center.
    if (currentPageIndex !== focusedPageIndex && isMounted) {
      setCurrentPageIndex(focusedPageIndex);
      pageUrls &&
        references[pageUrls[focusedPageIndex]] &&
        references[pageUrls[focusedPageIndex]].current.scrollIntoView();
    }
  }, [isMounted.current, focusedPageIndex, currentPageIndex]);

  return {
    pageReferences: references,
    setPageReferences: setReferences,
    updateSelectedPageIndex: updateSelectedPageIndex,
  };
}

export function ImageAnnotationList() {
  const annotation = useAnnotationStore((state) => state.annotation);
  // TODO: Use only height as the only size parameter
  const getPageWidth = (scale) => 800 * scale;
  const [scale] = useAtom(pageScaleAtom);
  const pageHeight = 1130 * scale;
  const [documentSearchResults] = useAtom(documentSearchResultsAtom);
  const [, setHoverAnnotationBoxId] = useAtom(hoverAnnotationBoxIdAtom);
  const scrolling = usePageScrolling();
  const pageWidth = getPageWidth(scale);
  const { activeDatapoint, setActiveDatapoint } = useActivateDatapoint();

  if (annotation === null) return <InLineLoader />;
  if (annotation.id === undefined) return <InLineLoader />;
  const pageUrls = annotation.pages;
  const pageNumber = annotation.pages.length;

  const pages = (
    <EuiFlexGroup
      gutterSize="none"
      direction="column"
      // When user mouse leaves the document area we want to clear any pinned field
      // so it does not intefere with document reading.
      onMouseLeave={() => {
        if (activeDatapoint) {
          setActiveDatapoint(undefined);
        }
        setHoverAnnotationBoxId(undefined);
      }}
    >
      {pageUrls.map((url, pageIndex) => (
        <div
          // style={{ background: '#' + Math.random().toString(16).substr(-6) }}
          key={url}
          ref={scrolling.pageReferences[url]}
        >
          <Waypoint
            fireOnRapidScroll={false}
            topOffset="30%"
            bottomOffset="30%"
            onEnter={(props) => {
              props.previousPosition === Waypoint.below &&
                props.currentPosition === Waypoint.inside &&
                scrolling.updateSelectedPageIndex(pageIndex, (scroll = false));
            }}
            onLeave={(props) => {
              props.previousPosition === Waypoint.inside &&
                props.currentPosition === Waypoint.below &&
                scrolling.updateSelectedPageIndex(pageIndex - 1, (scroll = false));
            }}
          />
          <EuiFlexItem>
            <LazyLoad
              height={pageHeight}
              // Load page forward in advance
              offset={pageHeight}
              debounce={100}
              once
              overflow
              resize
              style={{ paddingBottom: '1em' }}
            >
              <ImageAnnotation
                key={url}
                annotationId={annotation.id}
                schemaId={annotation.schema_id}
                pageIndex={pageIndex}
                pageUrl={url}
                pageWidth={pageWidth}
                pageHeight={pageHeight}
                searchResults={documentSearchResults}
                pageNumber={pageNumber}
              />
            </LazyLoad>
          </EuiFlexItem>
        </div>
      ))}
    </EuiFlexGroup>
  );

  return pages;
}

export function AnnotationPagesMenu() {
  const annotation = useAnnotationStore((state) => state.annotation);
  const scrolling = usePageScrolling();
  const pageCount = annotation?.pages.length;
  const [scale, setScale] = useAtom(pageScaleAtom);
  const focusedPageIndex = useAnnotationStore((state) => state.focusedPageIndex);
  const [showSearchInDocumentBox, setShowSearchInDocumentBox] = useAtom(
    showSearchInDocumentBoxAtom
  );
  const iconSize = 'm';
  const size = 's';
  const minZoomScale = 0.5;
  const maxZoomScale = 2;

  const getIconColor = (isActive: boolean) => (isActive ? 'primary' : 'text');
  const getIconDisplay = (isActive: boolean) => (isActive ? 'empty' : 'empty');
  const intl = useTranslate();

  function roundTo(number, precision = 10) {
    return Math.round(number * precision) / precision;
  }

  const searchButton = (
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        style={{ height: '30px' }}
        color={getIconColor(false)}
        display={getIconDisplay(false)}
        iconType="search"
        isSelected={showSearchInDocumentBox}
        iconSize={iconSize}
        size={size}
        aria-label="Toggle search box"
        title={
          showSearchInDocumentBox
            ? intl.formatMessage({
                id: 'imageAnnotationList.hideSearchBox',
                defaultMessage: 'Hide search box',
              })
            : intl.formatMessage({
                id: 'imageAnnotationList.showSearchBox',
                defaultMessage: 'Show search box',
              })
        }
        onClick={() => {
          setShowSearchInDocumentBox(!showSearchInDocumentBox);
        }}
      />
    </EuiFlexItem>
  );

  const search = (
    <EuiPopover
      button={searchButton}
      panelPaddingSize="none"
      anchorPosition="upLeft"
      hasArrow={false}
      offset={8}
      closePopover={() => {}}
      isOpen={showSearchInDocumentBox}
      zIndex={VISIBILITY.POPOVER}
      // closePopover={() => setShowSearchInDocumentBox(false)}
    >
      <SearchInDocumentBox />
    </EuiPopover>
  );

  const zoomIn = (
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        color={getIconColor(false)}
        display={getIconDisplay(false)}
        iconSize={iconSize}
        size={size}
        aria-label="Zoom in"
        title={intl.formatMessage({
          id: 'imageAnnotationList.zoomIn',
          defaultMessage: 'Zoom in',
        })}
        iconType="plus"
        onClick={() => setScale(roundTo(Math.min(scale + 0.1, maxZoomScale)))}
      />
    </EuiFlexItem>
  );

  const zoomOut = (
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        color={getIconColor(false)}
        display={getIconDisplay(false)}
        iconSize={iconSize}
        size={size}
        iconType="minus"
        aria-label="Zoom out"
        title={intl.formatMessage({
          id: 'imageAnnotationList.zoomOut',
          defaultMessage: 'Zoom out',
        })}
        onClick={() => setScale(roundTo(Math.max(scale - 0.1, minZoomScale)))}
      />
    </EuiFlexItem>
  );

  const zoomButtons = (
    <>
      {zoomOut}
      {zoomIn}
    </>
  );

  const pageValue = Math.min(Math.max(1, focusedPageIndex + 1), pageCount || 1);

  return (
    <EuiFlexGroup
      direction="row"
      gutterSize="m"
      alignItems="center"
      justifyContent="flexEnd"
      responsive={false}
    >
      <EuiFlexItem grow={false}>
        <EuiRange
          id="page-slider"
          value={pageValue}
          prepend={search}
          append={pageCount && `of ${pageCount}`}
          step={1}
          autoSize={true}
          compressed
          min={1}
          max={pageCount}
          onChange={(event) =>
            !!event.currentTarget.value &&
            scrolling.updateSelectedPageIndex(
              Math.min(Math.max(1, event.currentTarget.value), pageCount) - 1
            )
          }
          showInput="inputWithPopover"
          showLabels
          aria-label="Page"
        />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiRange
          id="zoom-slider"
          value={roundTo(scale * 100)}
          prepend={zoomButtons}
          append="%"
          autoSize={true}
          compressed
          min={minZoomScale * 100}
          max={maxZoomScale * 100}
          onChange={(event) => setScale(event.currentTarget.value / 100)}
          showInput="inputWithPopover"
          showLabels
          aria-label="Page zoom"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export function AnnotationSettingsMenu() {
  const [isExtendedAnnotationEnabled, setIsExtendedAnnotationEnabled] = useAtom(
    isExtendedAnnotationEnabledAtom
  );
  const [isBoxAutoFitEnabled, setIsBoxAutoFitEnabled] = useAtom(
    isBoxAutoFitEnabledAtom
  );
  const [showBoundingBoxes, setShowBoundingBoxes] = useAtom(showBoundingBoxesAtom);
  const [isTestModeEnabled, setIsTestModeEnabled] = useAtom(isTestModeEnabledAtom);
  const [
    isAnnotationPreferencesFlyoutVisible,
    setIsAnnotationPreferencesFlyoutVisible,
  ] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const bottomBarButtonStyle = useBottomBarButtonStyle();
  const iconSize = 'm';
  const size = 's';

  const getIconColor = (isActive: boolean) => (isActive ? 'primary' : 'text');
  const getIconDisplay = (isActive: boolean) => (isActive ? 'empty' : 'empty');
  const intl = useTranslate();
  const [isDocumentSplit, setDocumentSplit] = useAtom(documentSplit);
  const splitDocuments = useDocumentClassification();
  const annotation = useAnnotationStore((state) => state.annotation);
  const can = useCan();
  const documentClasses = useDocumentClasses(annotation.schema_id);
  const { getAddRowOperations, createBatchOperations } = useSplitOperations();
  const pageLength = annotation.pages.length;

  const [, setFocusedTable] = useAtom(focusTableAtom);

  useEffect(() => {
    if (splitDocuments && splitDocuments.rows.length > 1) {
      setDocumentSplit(true);
    }
    return () => {
      setDocumentSplit(false);
    };
  }, []);

  useEffect(() => {
    if (
      isDocumentSplit &&
      splitDocuments &&
      documentClasses &&
      documentClasses.length === 1 &&
      (!splitDocuments.rows || splitDocuments.rows.length === 0)
    ) {
      const batchActions = getAddRowOperations(
        documentClasses[0].value,
        0,
        pageLength - 1,
        splitDocuments.tableId,
        0
      );

      createBatchOperations(annotation?.id, batchActions);
    }
  }, [isDocumentSplit]);

  const annotationPreferencesButton = (
    <EuiButtonIcon
      style={bottomBarButtonStyle}
      iconType="gear"
      color={getIconColor(false)}
      display={getIconDisplay(false)}
      onClick={() =>
        setIsAnnotationPreferencesFlyoutVisible(!isAnnotationPreferencesFlyoutVisible)
      }
      size={size}
      title={intl.formatMessage({
        id: 'imageAnnotationList.annotationPreferences',
        defaultMessage: 'Annotation preferences',
      })}
      aria-label="Annotation preferences"
    />
  );

  const annotationPreferencesFlyout = isAnnotationPreferencesFlyoutVisible && (
    <AnnotationsPreferencesFlyout
      isOpen={isAnnotationPreferencesFlyoutVisible}
      onFlyoutClose={() => setIsAnnotationPreferencesFlyoutVisible(false)}
    />
  );

  const annotationButtons = (
    <EuiFlexGroup gutterSize="xs">
      {splitDocuments && (
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            style={bottomBarButtonStyle}
            color={getIconColor(isDocumentSplit)}
            display={getIconDisplay(isDocumentSplit)}
            iconType="cut"
            isSelected={isDocumentSplit}
            iconSize={iconSize}
            size={size}
            aria-label="Toggle document split"
            disabled={!can.can(PERMISSION.DOCUMENTS_SPLIT)}
            title={
              isDocumentSplit
                ? intl.formatMessage({
                    id: 'imageAnnotationList.disableDocumentSplit',
                    defaultMessage: 'Disable document split mode',
                  })
                : intl.formatMessage({
                    id: 'imageAnnotationList.enableDocumentSplit',
                    defaultMessage: 'Enable document split mode',
                  })
            }
            onClick={() => {
              setFocusedTable(undefined);
              setDocumentSplit(!isDocumentSplit);
            }}
          />
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          style={bottomBarButtonStyle}
          color={getIconColor(isExtendedAnnotationEnabled)}
          display={getIconDisplay(isExtendedAnnotationEnabled)}
          iconType="pencil"
          isSelected={isExtendedAnnotationEnabled}
          iconSize={iconSize}
          size={size}
          aria-label="Toggle extended annotation"
          title={
            isExtendedAnnotationEnabled
              ? intl.formatMessage({
                  id: 'imageAnnotationList.disableExtendedAnnotationMode',
                  defaultMessage: 'Disable extended annotation mode',
                })
              : intl.formatMessage({
                  id: 'imageAnnotationList.enableExtendedAnnotationMode',
                  defaultMessage: 'Enable extended annotation mode',
                })
          }
          onClick={() => {
            setIsExtendedAnnotationEnabled(!isExtendedAnnotationEnabled);
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          style={bottomBarButtonStyle}
          color={getIconColor(showBoundingBoxes)}
          display={getIconDisplay(showBoundingBoxes)}
          iconType="visText"
          isSelected={showBoundingBoxes}
          iconSize={iconSize}
          size={size}
          aria-label="Toggle bounding boxes"
          title={
            showBoundingBoxes
              ? intl.formatMessage({
                  id: 'imageAnnotationList.hideBoundingBoxes',
                  defaultMessage: 'Hide bounding boxes',
                })
              : intl.formatMessage({
                  id: 'imageAnnotationList.showBoundingBoxes',
                  defaultMessage: 'Show bounding boxes',
                })
          }
          onClick={() => {
            setShowBoundingBoxes(!showBoundingBoxes);
          }}
        />
      </EuiFlexItem>
      <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            style={bottomBarButtonStyle}
            color={getIconColor(isBoxAutoFitEnabled)}
            display={getIconDisplay(isBoxAutoFitEnabled)}
            isSelected={isBoxAutoFitEnabled}
            title={
              isBoxAutoFitEnabled
                ? intl.formatMessage({
                    id: 'imageAnnotationList.disableAutomaticBoxResizing',
                    defaultMessage: 'Disable automatic box resizing to selected text',
                  })
                : intl.formatMessage({
                    id: 'imageAnnotationList.enableAutomaticBoxResizing',
                    defaultMessage: 'Enable automatic box resizing to selected text',
                  })
            }
            iconSize={iconSize}
            size={size}
            aria-label="Fit box to text"
            iconType="fold"
            onClick={() => {
              setIsBoxAutoFitEnabled(!isBoxAutoFitEnabled);
            }}
          />
        </EuiFlexItem>
      </Can>
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          style={bottomBarButtonStyle}
          color={getIconColor(isTestModeEnabled)}
          display={getIconDisplay(isTestModeEnabled)}
          isSelected={isTestModeEnabled}
          title={
            isTestModeEnabled
              ? intl.formatMessage({
                  id: 'imageAnnotationList.disableTestMode',
                  defaultMessage: 'Disable test mode',
                })
              : intl.formatMessage({
                  id: 'imageAnnotationList.enableTestMode',
                  defaultMessage: 'Enable test mode',
                })
          }
          iconSize={iconSize}
          size={size}
          aria-label="Enable test mode"
          iconType="string"
          onClick={() => {
            setIsTestModeEnabled(!isTestModeEnabled);
            if (isTestModeEnabled) {
              searchParams.delete('annotationIdGroundTruth');
              searchParams.delete('annotationId');
              setSearchParams(searchParams, { replace: true });
            }
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>{annotationPreferencesButton}</EuiFlexItem>
    </EuiFlexGroup>
  );
  return (
    <>
      {annotationPreferencesFlyout}
      {annotationButtons}
    </>
  );
}
