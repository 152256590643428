import { EuiResizableContainer } from '@elastic/eui';
import { BOTTOM_BAR_HEIGHT } from 'components/BottomBar/BottomBar';
import { useAtom } from 'jotai';
import React from 'react';
import { THEME } from '../../settings/constants';
import { documentPanelResizeAtom } from '../Annotation/DocumentPanel/ResizeState';

export function VerticallySplitContainer({
  leftPanel,
  rightPanel,
  leftPanelSize = 37,
  paddingSize = 'm',
}) {
  const [documentPanelResized, setDocumentPanelResized] = useAtom(
    documentPanelResizeAtom
  );

  return (
    <EuiResizableContainer
      onPanelWidthChange={() => setDocumentPanelResized(documentPanelResized + 1)}
      style={{
        height: `calc(100vh - ${THEME.euiHeaderHeightCompensation})`,
        paddingBottom: BOTTOM_BAR_HEIGHT,
      }}
    >
      {(EuiResizablePanel, EuiResizableButton) => {
        return (
          <>
            <EuiResizablePanel
              id="leftPanel"
              initialSize={leftPanelSize}
              mode="collapsible"
              paddingSize={paddingSize}
              style={{ marginRight: '1em' }}
            >
              {leftPanel}
            </EuiResizablePanel>
            <EuiResizableButton />
            <EuiResizablePanel
              id="rightPanel"
              initialSize={100 - leftPanelSize}
              mode="main"
              paddingSize={paddingSize}
            >
              {rightPanel}
            </EuiResizablePanel>
          </>
        );
      }}
    </EuiResizableContainer>
  );
}
