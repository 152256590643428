import { Api } from 'Definitions/Api';
import { useEffect } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import client from 'utils/client';
import { useAnnotationStore } from './Annotation';

export function useAnnotation() {
  const queryClient = useQueryClient();

  const useGetAnnotation = (annotationId: string) => {
    return useQuery(
      ['annotations', annotationId],
      () => client(`api/v1/annotations/${annotationId}`),
      {
        enabled: !!annotationId,
        useErrorBoundary: true,
      }
    );
  };

  const useGetAnnotationContent = (
    annotationId: string,
    ignoreUserUpdates?: boolean,
    options?: UseQueryOptions
  ) => {
    const queryOptions = options || {
      enabled: !!annotationId,
    };
    let url = `api/v1/annotations/${annotationId}/content`;
    if (ignoreUserUpdates) {
      url = url + '?ignore_user_updates=true';
    }
    return useQuery(
      [
        'annotations',
        annotationId,
        'content',
        { ignoreUserUpdates: ignoreUserUpdates },
      ],
      (): Promise<Api['AnnotationContentGetOut']> => client(url),
      {
        ...queryOptions,
        useErrorBoundary: true,
      }
    );
  };

  const invalidateAnnotations = () => {
    queryClient.invalidateQueries(['get-document-annotations']);
    return queryClient.invalidateQueries(['annotations']);
  };

  return {
    useGetAnnotation: useGetAnnotation,
    useGetAnnotationContent: useGetAnnotationContent,
    invalidateAnnotations: invalidateAnnotations,
  };
}

export function useSelectedAnnotationStoreRefresh(annotationId: string) {
  // Temporary hook before we migrate from store to hooks with atoms
  const setAnnotation = useAnnotationStore((state) => state.setAnnotation);
  const setAnnotationContent = useAnnotationStore(
    (state) => state.setAnnotationContent
  );
  const { useGetAnnotation, useGetAnnotationContent } = useAnnotation();
  const { data: annotation } = useGetAnnotation(annotationId);
  const { data: annotationContent } = useGetAnnotationContent(annotationId, false);

  useEffect(() => {
    if (annotation && annotationContent) {
      setAnnotationContent(annotationContent.content);
      setAnnotation(annotation);
    }
  }, [annotation, annotationContent, setAnnotation, setAnnotationContent]);
}
