import {
  EuiButton,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiSwitch,
  EuiTitle,
} from '@elastic/eui';
import { RelatedDocumentsTable } from 'components/Annotation/AnnotationPanel/RelatedDocumentsTable';
import { useAnnotation } from 'components/Annotation/useAnnotation';
import { useDocumentsHook } from 'components/Document/useDocuments';
import { useFolders } from 'components/Folders/useFolders';
import { QueryLoader } from 'components/InlineLoader/QueryLoader';
import { SchemaSelector } from 'components/Manage/Schema/SchemaSelector';
import { SkillVersionSelector } from 'components/Manage/Schema/SkillVersionSelector';
import { saveAs } from 'file-saver';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { PERMISSION, useCan } from 'utils/permissions';
import {
  getDocumentContentUrl,
  processDocument,
  useAnnotations,
  useDocuments,
} from '../../../services/documents';
import { getThemeJson } from '../../../themes/ThemeJson';
import { useAnnotationsPeriodicRefresh } from '../../../utils/useAnnotationsPeriodicRefresh';
import { ErrorComponent } from '../../Errors/ErrorComponent';
import { InLineLoader } from '../../InlineLoader/InlineLoader';
import { Translate } from '../../Internationalisation/translate';
import { asDateTime, useTranslate } from '../../Internationalisation/useTranslate';
import { useToasts } from '../../Toasts/Toasts';
import { documentIsProcessing, isTestModeEnabledAtom } from '../AnnotationState';

export function DocumentProperties({ documentId }) {
  const documentsHook = useDocumentsHook();
  const { data: documents, isLoading: isLoadingDocuments } = useDocuments({
    id: documentId,
    enabled: !!documentId,
    isTrashed: null,
  });
  const intl = useTranslate();
  const can = useCan();
  const { showRequestError } = useToasts();

  const documentContentMutation = useMutation(getDocumentContentUrl, {
    onSuccess: (documentContent) => {
      const objectURL = URL.createObjectURL(documentContent);
      saveAs(objectURL, `${document.original_file_name}`);
    },
    onError: (err) => {
      showRequestError(err);
    },
  });

  const {
    mutate: getDocumentContent,
    isLoading: isLoadingDocumentContent,
  } = documentContentMutation;

  if (isLoadingDocuments) return <InLineLoader />;
  if (documents.data.length == 0) {
    const error = new Error(
      intl.formatMessage({
        id: 'documentTab.error.noDocumentFound',
        defaultMessage: 'No document found',
      })
    );
    return <ErrorComponent error={error} />;
  }
  const document = documents.data[0];

  const documentInfo = [
    {
      title: intl.formatMessage({
        id: 'documentTab.filename',
        defaultMessage: 'Filename',
      }),
      description: document.original_file_name,
    },
    {
      title: intl.formatMessage({
        id: 'documentTab.pages',
        defaultMessage: 'Pages',
      }),
      description: document.page_count,
    },
    {
      title: intl.formatMessage({
        id: 'documentTab.importedBy',
        defaultMessage: 'Imported by',
      }),
      description: document.imported_by,
    },
    {
      title: intl.formatMessage({
        id: 'documentTab.importedAt',
        defaultMessage: 'Imported at',
      }),
      description: asDateTime(document.imported_at),
    },
    {
      title: intl.formatMessage({
        id: 'documentTab.fileModifiedAt',
        defaultMessage: 'File modified at',
      }),
      description: asDateTime(document.modified_at),
    },
    {
      title: intl.formatMessage({
        id: 'documentTab.useForTraining',
        defaultMessage: 'For training',
      }),
      description: (
        <EuiSwitch
          id="useForTraining"
          label=""
          checked={document.use_for_training}
          disabled={!can.can(PERMISSION.DOCUMENTS_UPDATE)}
          onChange={(e) =>
            documentsHook.patchDocumentMutation.mutate({
              id: document.id,
              patch: { use_for_training: e.target.checked },
            })
          }
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'documentTab.useForTest',
        defaultMessage: 'For test',
      }),
      description: (
        <EuiSwitch
          id="useForTest"
          label=""
          checked={document.use_for_test}
          disabled={!can.can(PERMISSION.DOCUMENTS_UPDATE)}
          onChange={(e) =>
            documentsHook.patchDocumentMutation.mutate({
              id: document.id,
              patch: { use_for_test: e.target.checked },
            })
          }
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'documentTab.metadata',
        defaultMessage: 'Metadata',
      }),
      description: (
        <ReactJson
          src={document.metadata}
          theme={getThemeJson()}
          name={false}
          collapsed={true}
          displayDataTypes={false}
          displayObjectSize={false}
          quotesOnKeys={false}
        />
      ),
    },
    {
      title: intl.formatMessage({
        id: 'documentTab.relatedDocuments',
        defaultMessage: 'Related documents',
      }),
      description: <RelatedDocumentsTable document={document} />,
    },
  ];

  return (
    <EuiFlexGroup direction="column" className="eui-textBreakWord">
      <EuiFlexItem>
        <EuiDescriptionList type="row" listItems={documentInfo} />
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiFlexGroup direction="row" wrap={true}>
          <EuiFlexItem>
            <EuiButton
              size="s"
              isLoading={isLoadingDocumentContent}
              fullWidth={false}
              aria-label="Download"
              title={intl.formatMessage({
                id: 'documentTab.downloadDocument',
                defaultMessage: 'Download document',
              })}
              onClick={() => getDocumentContent(documentId)}
            >
              <Translate id="button.download" defaultMessage="Download" />
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton
              size="s"
              fullWidth={false}
              aria-label="Share"
              title={intl.formatMessage({
                id: 'documentTab.button.shareDocument',
                defaultMessage: 'Share document',
              })}
              onClick={() => {
                window.open(
                  `mailto:?subject=Sharing document in IDP` +
                    `&body=Open document in IDP: ` +
                    `<a href=${window.location.protocol}//${window.location.hostname}/documents/${document.id}>
              ${document.original_file_name}
            </a>`
                );
              }}
            >
              <Translate id="documentTab.button.share" defaultMessage="Share" />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export function DocumentPropertiesFlyout({ documentId, setIsFlyoutVisible }) {
  const flyout = (
    <EuiFlyout
      ownFocus
      onClose={() => setIsFlyoutVisible(false)}
      aria-labelledby="documentProperties"
      size="m"
      style={{ maxWidth: '500px' }}
    >
      <EuiFlyoutHeader>
        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>
                <Translate
                  id="documentPropertiesFlyout.title"
                  defaultMessage="Document"
                />
              </h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <DocumentProperties documentId={documentId} />
      </EuiFlyoutBody>
    </EuiFlyout>
  );

  return flyout;
}

function DocumentProcessing({ documentId, documentSchemaId, onProcessCallback }) {
  const { folderId: selectedFolderId } = useParams();
  const foldersHook = useFolders();
  const getFolderHook = foldersHook.useGetFolder(selectedFolderId);
  const [selectedSchemaId, setSelectedSchemaId] = useState();
  const [testSkillVersionId, setTestSkillVersionId] = useState();
  const [isTestModeEnabled] = useAtom(isTestModeEnabledAtom);
  const intl = useTranslate();

  useEffect(() => {
    setSelectedSchemaId(getFolderHook.data?.skill_id ?? documentSchemaId);
  }, [documentSchemaId, getFolderHook.data]);

  if (!getFolderHook.isSuccess) {
    return <QueryLoader queries={[getFolderHook]} />;
  }

  return (
    <>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiFlexGroup direction="column" gutterSize="xs">
            <EuiFlexItem>
              <EuiTitle size="xxs">
                <span>{intl.message.skill}</span>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              <SchemaSelector
                defaultSchemaId={selectedSchemaId}
                onChange={setSelectedSchemaId}
                includeParentFolders={true}
                hasLink={true}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {isTestModeEnabled && selectedSchemaId && (
          <EuiFlexItem>
            <EuiForm>
              <EuiFormRow fullWidth label="Skill version to test">
                <SkillVersionSelector
                  schemaId={selectedSchemaId}
                  onChange={setTestSkillVersionId}
                />
              </EuiFormRow>
            </EuiForm>
          </EuiFlexItem>
        )}
        <EuiFlexItem>
          <Translate
            id="documentProcessing.processSkillDescription"
            defaultMessage={`Process document with selected skill to create new annotation with extracted
               datapoints according to skill schema.
              `}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <ProcessDocumentButton
            documentId={documentId}
            schemaId={selectedSchemaId}
            testSkillVersionId={testSkillVersionId}
            onProcessCallback={onProcessCallback}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}

function useLastDocumentProcessingStatus(documentId) {
  const { data, isLoading } = useAnnotations(documentId);
  const annotations = data?.data;

  if (isLoading) return undefined;
  if (annotations.length === 0) return null;
  const status = annotations[0].status;
  return status;
}

function ProcessDocumentButton({
  documentId,
  schemaId,
  testSkillVersionId,
  onProcessCallback,
}) {
  const [isRequestInProcess, setIsRequestInProcess] = useState(false);
  const [documentsInProcessing, setDocumentIsProcessing] = useAtom(
    documentIsProcessing
  );
  const status = useLastDocumentProcessingStatus(documentId);
  const { addToast } = useToasts();
  const { invalidateAnnotations } = useAnnotation();
  const intl = useTranslate();
  const can = useCan();
  const { showRequestError } = useToasts();

  useAnnotationsPeriodicRefresh(documentId);

  const onClick = () => {
    setIsRequestInProcess(true);
    processDocument(documentId, schemaId, testSkillVersionId)
      .then(() => {
        setIsRequestInProcess(false);
        addToast({
          title: intl.formatMessage({
            id: 'documentTab.documentSentForProcessing',
            defaultMessage: 'Document sent for processing',
          }),
          color: 'success',
        });
        onProcessCallback();
        return invalidateAnnotations();
      })
      .then(() => {
        setDocumentIsProcessing({ ...documentsInProcessing, [documentId]: true });
      })
      .catch((response) => {
        setIsRequestInProcess(false);
        if (response.message == 'Locked') {
          addToast({
            title: intl.formatMessage({
              id: 'documentTab.documentIsAlreadyProcessing',
              defaultMessage: 'Document is already in processing',
            }),
            color: 'warning',
          });
        } else {
          showRequestError();
        }
      });
  };

  return status === undefined ? (
    <EuiButton
      title={intl.formatMessage({
        id: 'processDocumentButton.button.loadProcessingStatus',
        defaultMessage: 'Loading processing status',
      })}
      isLoading={true}
    >
      <Translate
        id="processDocumentButton.button.loadProcessingStatus"
        defaultMessage="Loading processing status"
      />
    </EuiButton>
  ) : status === 'processing' ? (
    <EuiButton
      title={intl.formatMessage({
        id: 'processDocumentButton.button.documentInProcessing',
        defaultMessage: 'Document in processing',
      })}
      isLoading={true}
    >
      <Translate
        id="processDocumentButton.button.documentInProcessing"
        defaultMessage="Document in processing"
      />
    </EuiButton>
  ) : (
    <EuiButton
      fill
      color="primary"
      iconType="play"
      aria-label="Process document"
      title={intl.formatMessage({
        id: 'processDocumentButton.button.processDocument',
        defaultMessage: 'Process document',
      })}
      isLoading={isRequestInProcess}
      onClick={onClick}
      isDisabled={!can.can(PERMISSION.DOCUMENTS_PROCESS)}
    >
      <Translate
        id="processDocumentButton.button.processDocument"
        defaultMessage="Process document"
      />
    </EuiButton>
  );
}

export function DocumentProcessingFlyout({
  documentId,
  documentSchemaId,
  setIsFlyoutVisible,
}) {
  const flyout = (
    <EuiFlyout
      ownFocus
      onClose={() => setIsFlyoutVisible(false)}
      aria-labelledby="documentProcessing"
      size="s"
      style={{ maxWidth: '500px' }}
    >
      <EuiFlyoutHeader>
        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>
                <Translate
                  id="documentProcessingFlyout.title"
                  defaultMessage="Process document"
                />
              </h2>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <DocumentProcessing
          documentId={documentId}
          documentSchemaId={documentSchemaId}
          onProcessCallback={() => setIsFlyoutVisible(false)}
        />
      </EuiFlyoutBody>
    </EuiFlyout>
  );

  return flyout;
}
