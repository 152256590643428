import {
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiPopover,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SchemaElementKind } from '../../../Definitions/SchemaElementKind';
import { Translate } from '../../Internationalisation/translate';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useAnnotationStore } from '../Annotation';
import { openedKeywordAnnotationExpandedRow } from '../AnnotationState';
import { useActivateDatapoint } from './useActivateDatapoint';

export function KeywordAnnotationMenu({ foundElement, parentId, toggleRow, rowId }) {
  const [isPopoverOpen, setPopover] = useState(false);
  const [expandedRows] = useAtom(openedKeywordAnnotationExpandedRow);
  const smallContextMenuPopoverId = useGeneratedHtmlId({
    prefix: 'smallContextMenuPopover',
  });
  const annotationStore = useAnnotationStore();
  const { setActiveDatapoint } = useActivateDatapoint();
  const intl = useTranslate();

  const onOpenMenu = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const createKeyword = () => {
    const newKeyWord = {
      schema_element_kind: SchemaElementKind.Datapoint,
      schema_element_id: foundElement.schema_element_id,
      parent_id: parentId,
      annotation_class: 'keyword',
      id: uuidv4(),
    };
    annotationStore.createAnnotationItem(newKeyWord).then(() => {
      if (!expandedRows.has(rowId)) {
        toggleRow(rowId);
      }
      setActiveDatapoint(undefined);
      closePopover();
    });
  };

  const createHeader = () => {
    const newKeyWord = {
      schema_element_kind: SchemaElementKind.Datapoint,
      schema_element_id: foundElement.schema_element_id,
      parent_id: parentId,
      annotation_class: 'column_header',
      id: uuidv4(),
    };
    annotationStore.createAnnotationItem(newKeyWord).then(() => {
      if (!expandedRows.has(rowId)) {
        toggleRow(rowId);
      }
      setActiveDatapoint(undefined);
      closePopover();
    });
  };

  const items = [
    <EuiContextMenuItem key="copy" icon="tokenKey" onClick={createKeyword}>
      <Translate
        id="keywordAnnotationMenu.keywordMenu.addKeyword"
        defaultMessage=" Add keyword"
      />
    </EuiContextMenuItem>,
    <EuiContextMenuItem key="edit" icon="editorTable" onClick={createHeader}>
      <Translate
        id="keywordAnnotationMenu.keywordMenu.addHeader"
        defaultMessage=" Add header"
      />
    </EuiContextMenuItem>,
  ];

  const button = (
    <EuiButtonIcon
      size="xs"
      iconType="layers"
      aria-label="Add keyword or header"
      onClick={onOpenMenu}
      title={intl.formatMessage({
        id: 'keywordAnnotationMenu.keywordMenu.add',
        defaultMessage: 'Add keyword or header',
      })}
    />
  );

  return (
    <EuiPopover
      id={smallContextMenuPopoverId}
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft"
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <EuiContextMenuPanel size="s" items={items} />
    </EuiPopover>
  );
}
