import React from 'react';
import { FieldAnnotationItem } from './FieldAnnotationItem';
import { TableAnnotationItem } from './TableAnnotationItem';

export function AnnotationElementInSection({
  elementSchema,
  elementAnnotation,
  isExtendedAnnotationEnabled,
}: {
  elementSchema: any;
  elementAnnotation: any;
  isExtendedAnnotationEnabled: boolean;
}) {
  if (!elementAnnotation) {
    return null;
  }

  let pageIndex = 0;
  switch (elementSchema.kind) {
    case 'datapoint':
      if (
        !isExtendedAnnotationEnabled &&
        elementAnnotation.annotation_class != 'value'
      ) {
        return null;
      }
      return (
        <div key={`field-annotation-item-${elementAnnotation.normalized_value}`}>
          <FieldAnnotationItem
            elementAnnotation={elementAnnotation}
            schemaElement={elementSchema}
          />
        </div>
      );
    case 'list':
      pageIndex =
        elementAnnotation?.elements?.length > 0
          ? elementAnnotation.elements[0].page_index
          : 0;
      return (
        <TableAnnotationItem
          name={elementSchema.label}
          schemaElement={elementSchema}
          elementAnnotation={elementAnnotation}
          key={elementSchema.id}
          pageIndex={pageIndex}
          value={`${elementAnnotation?.elements?.length} item${
            elementAnnotation?.elements?.length === 1 ? '' : 's'
          }`}
          columns={[
            {
              field: elementSchema.elements.id,
              name: elementSchema.elements.label,
              isHidden: elementSchema.elements.is_hidden,
            },
          ]}
          items={elementAnnotation.elements}
          metadata={
            elementAnnotation.hasOwnProperty('metadata') ? elementSchema.metadata : {}
          }
        />
      );
    case 'table':
      pageIndex =
        elementAnnotation.elements?.length > 0 &&
        elementAnnotation.elements[0].elements.length > 0
          ? elementAnnotation.elements[0].elements[0].page_index
          : 0;
      return (
        <TableAnnotationItem
          name={elementSchema.label}
          schemaElement={elementSchema.elements}
          elementAnnotation={elementAnnotation}
          key={elementSchema.id}
          pageIndex={pageIndex}
          value={`${elementAnnotation.elements?.length || 0} row${
            elementAnnotation.elements?.length === 1 ? '' : 's'
          }`}
          columns={elementSchema.elements?.elements?.map(
            (datapoint: { id: any; label: any; is_hidden: boolean }) => {
              return {
                field: datapoint.id,
                name: datapoint.label,
                isHidden: datapoint.is_hidden,
              };
            }
          )}
          items={elementAnnotation.elements}
          metadata={
            elementAnnotation.hasOwnProperty('metadata') ? elementSchema.metadata : {}
          }
        />
      );
    default:
      return null;
  }
}
