import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiText } from '@elastic/eui';
import {
  AnnotationItemMessagesIcon,
  getMessages,
} from 'components/Annotation/AnnotationPanel/Messages';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { THEME, VISIBILITY } from '../../../settings/constants';
import { useAnnotationStore } from '../Annotation';
import { focusTableAtom, openedKeywordAnnotationExpandedRow } from '../AnnotationState';
import { showTableGridAnnotationAtom } from '../DocumentPanel/ImageAnnotation/ImageAnnotation';
import { TableAnnotation } from './TableAnnotation';
import { useActivateDatapoint } from './useActivateDatapoint';

export function TableAnnotationItem({
  schemaElement,
  name,
  elementAnnotation,
  value,
  columns,
  items,
  metadata,
  pageIndex,
}) {
  const focusPage = useAnnotationStore((state) => state.focusPage);
  const [, setItemIdToExpandedRowMap] = useAtom(openedKeywordAnnotationExpandedRow);
  // TODO: Not working after schema update. Make work for all sections child elements.
  const [focusedTable, setFocusedTable] = useAtom(focusTableAtom);
  const { setActiveDatapoint } = useActivateDatapoint();
  const [, setShowTableGridAnnotationAtom] = useAtom(showTableGridAnnotationAtom);

  useEffect(() => {
    return () => {
      setFocusedTable(undefined);
      setShowTableGridAnnotationAtom(false);
    };
  }, []);

  useEffect(() => {
    if (focusedTable.id === elementAnnotation.id) {
      focusTable();
    }
  }, [items]);

  useEffect(() => {
    if (focusedTable.id === elementAnnotation.id && !focusedTable.tableComponent) {
      focusTable();
    }
  }, [focusedTable.tableComponent]);

  const focusTable = () => {
    if (!!items) {
      setFocusedTable({
        id: elementAnnotation.id,
        kind: elementAnnotation.kind,
        tableComponent: (
          <TableAnnotation
            id={elementAnnotation.id}
            items={items}
            metadata={metadata}
            elementAnnotation={elementAnnotation}
            columns={columns}
            schemaElement={schemaElement}
            styles={{ zIndex: VISIBILITY.POPOVER }}
          />
        ),
      });
      focusPage(pageIndex);
    }
  };

  const onLinkClick = () => {
    setActiveDatapoint(undefined, false);
    focusTable();
    setItemIdToExpandedRowMap(new Set());
  };

  const messages = items.length ? getMessages(items) : [];

  const fieldRow = (
    <EuiLink
      onClick={onLinkClick}
      color="primary"
      style={{ color: THEME.euiTextColor, width: '100%' }}
    >
      <div style={{ padding: '0.2em 0em 0.2em 0.1em', display: 'flex', width: '100%' }}>
        <div
          style={{
            flex: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '40%',
          }}
        >
          <AnnotationItemMessagesIcon
            messages={messages}
            style={{
              display: 'inline',
            }}
          />
          <EuiText
            style={{
              display: 'inline',
            }}
            size="s"
            className="eui-textBreakAll"
            title={name}
          >
            {name}
          </EuiText>
        </div>
        <div
          style={{
            display: 'inline',
            maxWidth: '20%',
            minWidth: '10%',
          }}
        >
          <EuiText
            size="s"
            className="eui-textBreakAll"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <strong>{value}</strong>
          </EuiText>
        </div>
      </div>
    </EuiLink>
  );

  return (
    <EuiFlexGroup direction="row" gutterSize="none" style={{ width: '100%' }}>
      <EuiFlexItem style={{ width: '100%' }}>{fieldRow}</EuiFlexItem>
    </EuiFlexGroup>
  );
}
