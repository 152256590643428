import { useAnnotation } from 'components/Annotation/useAnnotation';
import { useCallback, useEffect, useState } from 'react';
import { invalidateDocument, useAnnotations } from '../services/documents';
import { useInterval } from './useInterval';

export function useAnnotationsPeriodicRefresh(documentId: string): void {
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [lastAnnotationStatus, setLastAnnotationStatus] = useState<string>();
  const { data } = useAnnotations(documentId);
  const { invalidateAnnotations } = useAnnotation();

  useEffect(() => {
    if (data) {
      const annotations = data as { data: Array<{ status: string }> };
      setLastAnnotationStatus(annotations.data[0]?.status || 'processing');
    }
  }, [data]);

  const refreshAnnotationsInFirstMinute = useCallback(() => {
    if (lastAnnotationStatus?.endsWith('processing') && refreshCounter < 12) {
      invalidateAnnotations();
      invalidateDocument(documentId);
      setRefreshCounter(refreshCounter + 1);
    }
  }, [documentId, lastAnnotationStatus, refreshCounter]);

  const refreshAnnotationsTillFiveMinutes = useCallback(() => {
    if (
      lastAnnotationStatus?.endsWith('processing') &&
      refreshCounter > 11 &&
      refreshCounter < 27
    ) {
      invalidateAnnotations();
      invalidateDocument(documentId);
      setRefreshCounter(refreshCounter + 1);
    }
  }, [documentId, lastAnnotationStatus, refreshCounter]);

  const refreshAnnotationsAfterFiveMinutes = useCallback(() => {
    if (lastAnnotationStatus?.endsWith('processing') && refreshCounter > 26) {
      invalidateAnnotations();
      invalidateDocument(documentId);
      setRefreshCounter(refreshCounter + 1);
    }
  }, [documentId, lastAnnotationStatus, refreshCounter]);

  useInterval(refreshAnnotationsInFirstMinute, 5000);
  useInterval(refreshAnnotationsTillFiveMinutes, 20000);
  useInterval(refreshAnnotationsAfterFiveMinutes, 120000);
}
