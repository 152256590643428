import { useMemo } from 'react';
import { useAnnotationStore } from '../../Annotation';

export type DocumentClassificationSchemaId =
  | '_document_class'
  | '_document_class_first_page_index'
  | '_document_class_last_page_index'
  | '_document_classification_item';

export const documentClassificationSchemaIds = [
  '_document_class',
  '_document_class_first_page_index',
  '_document_class_first_page_index',
  '_document_classification_item',
];
export interface DocumentClassificationDatapoint {
  id: string;
  schema_element_id: DocumentClassificationSchemaId;
  value: string | number;
}

export interface DocumentClassificationRow {
  id: string;
  startPageIndex: number;
  datapoints: DocumentClassificationDatapoint[];
}

export interface DocumentClassificationContent {
  tableId: string;
  rows: DocumentClassificationRow[];
}

export function useDocumentClassification(): DocumentClassificationContent | undefined {
  const annotationContent = useAnnotationStore((state) => state.annotationContent);

  return useMemo((): DocumentClassificationContent | undefined => {
    // @ts-ignore
    const annotationMetaContent = annotationContent.elements.find(
      (content) => content['schema_element_id'] === '_metacontent'
    );

    if (annotationMetaContent && Array.isArray(annotationMetaContent.elements)) {
      // @ts-ignore
      const documentClassification = annotationMetaContent.elements.find(
        (element) => element['schema_element_id'] === '_document_classification'
      );

      if (!documentClassification) {
        return;
      }

      const tableId: string = documentClassification.id;
      // @ts-ignore
      const rows: DocumentClassificationRow[] = documentClassification.elements
        .map(
          (row): DocumentClassificationRow => {
            return {
              id: row.id,
              // @ts-ignore
              startPageIndex: parseInt(
                row.elements.find(
                  (element) =>
                    element['schema_element_id'] === '_document_class_first_page_index'
                )?.value
              ),
              datapoints: row.elements.map(
                ({
                  id,
                  schema_element_id,
                  value,
                }: {
                  id: string;
                  schema_element_id: string;
                  value: string;
                }) => {
                  if (schema_element_id === '_document_class') {
                    return {
                      id,
                      schema_element_id,
                      value,
                    };
                  }
                  return {
                    id,
                    schema_element_id,
                    value: parseInt(value),
                  };
                }
              ),
            };
          }
        )
        .sort(
          (currentRow: DocumentClassificationRow, nextRow: DocumentClassificationRow) =>
            currentRow.startPageIndex - nextRow.startPageIndex
        );

      return { tableId, rows: rows || [] };
    }
    return;
  }, [annotationContent]);
}
