import {
  EuiButtonIcon,
  EuiComboBox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiText,
  EuiTextArea,
} from '@elastic/eui';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Can, PERMISSION, useCan } from 'utils/permissions';
import { DatapointType } from '../../Definitions/DatapointType';
import {
  AnnotationControlVariant,
  DatapointValueControlVariant,
} from '../../Definitions/DatapointValueControlVariant';
import {
  createAnnotationItemMutation,
  patchAnnotationItemMutate,
} from '../../services/annotations';
import { invalidateAnnotationContent } from '../../services/documents';
import { ReferenceDataSearchButton } from '../Manage/ReferenceData/components/ReferenceDataSearchButton';
import { useGetReferenceDataParams } from '../Manage/ReferenceData/hooks/useGetReferenceDataParams';
import { useAnnotationStore } from './Annotation';
import { useActivateDatapoint } from './AnnotationPanel/useActivateDatapoint';
import { isTestModeEnabledAtom, wasNewListRowAddedAtom } from './AnnotationState';

export const DatapointValueControl = ({
  datapointId,
  datapointType,
  elementKind,
  datapointEnumChoices,
  parentId,
  id,
  value,
  variant,
  box,
  messages,
  isKnownDatapoint,
  annotationClass,
  hooks,
  pageIndex,
  tableId,
  originalValue,
}: {
  parentId?: string;
  value?: string;
  id?: string;
  datapointType?: any;
  datapointId?: string;
  elementKind?: any;
  datapointEnumChoices?: any[];
  variant: DatapointValueControlVariant;
  box?: any;
  messages?: any;
  isKnownDatapoint?: boolean;
  annotationClass?: 'value' | 'column_header' | 'keyword';
  canChangeAnnotationClass?: boolean;
  hooks: Array<string>;
  pageIndex?: number;
  tableId?: string;
  originalValue?: string;
}) => {
  const referenceData = useGetReferenceDataParams(hooks);

  let saveAnnotationTimer: NodeJS.Timeout;
  const can = useCan();
  const fieldRef = useRef(null);
  const [currentValue, setCurrentValue] = useState(value || undefined);
  // We switch to text area from one line input field when input value is over limit
  // and we need to remember and keep current cursor position during this switch.
  const [currentValueCursorPosition, setCurrentValueCursorPosition] = useState(
    undefined
  );
  const [isPatchLoaderVisible, setIsPatchLoaderVisible] = useState<boolean>(false);
  const [enumSelectOptions, setEnumSelectOptions] = useState<any[]>();

  const annotationStore = useAnnotationStore();
  const [isDatapointActive, setIsDatapointActive] = useState<boolean>(false);
  const { pinnedDatapoint, setActiveDatapoint } = useActivateDatapoint();
  const [isTestModeEnabled] = useAtom(isTestModeEnabledAtom);
  const intl = useIntl();
  const [newListRowAdded, setWasNewListRowAdded] = useAtom(wasNewListRowAddedAtom);

  useEffect(() => {
    setCurrentValue(value);
  }, [originalValue, value]);

  useEffect(() => {
    if (newListRowAdded && newListRowAdded === id) {
      handleElementActivation();
      setWasNewListRowAdded(undefined);
    }
  }, [newListRowAdded]);

  // @ts-ignore
  const patchAnnotationMutation = useMutation(patchAnnotationItemMutate, {
    onMutate: () => {
      saveAnnotationTimer = setTimeout(() => {
        setIsPatchLoaderVisible(true);
      }, 2000);
    },
    onSuccess: (data, variables) => {
      clearTimeout(saveAnnotationTimer);
      setIsPatchLoaderVisible(false);
      invalidateAnnotationContent(variables.annotationId);
    },
    onError: () => {
      clearTimeout(saveAnnotationTimer);
      setIsPatchLoaderVisible(false);
    },
    onSettled: () => {
      setIsDatapointActive(false);
    },
  });

  // @ts-ignore
  const createAnnotationMutation = useMutation(createAnnotationItemMutation, {
    onMutate: () => {
      saveAnnotationTimer = setTimeout(() => {
        setIsPatchLoaderVisible(true);
      }, 2000);
    },
    onSuccess: (data, variables) => {
      clearTimeout(saveAnnotationTimer);
      setIsPatchLoaderVisible(false);
      invalidateAnnotationContent(variables.annotationId);
    },
    onError: () => {
      clearTimeout(saveAnnotationTimer);
      setIsPatchLoaderVisible(false);
    },
    onSettled: () => {
      setIsDatapointActive(false);
    },
  });

  useEffect(() => {
    // Choices from schema can be overwritten by choices from annotation item message.
    const enumChoicesMessage = messages?.find(
      (message: any) => message.type === 'enum_choices'
    );

    if (enumChoicesMessage) {
      const enumChoicesContent = JSON.parse(enumChoicesMessage.content);
      setEnumSelectOptions(
        enumChoicesContent.map((choice: any) => ({
          ...choice,
        }))
      );
    } else if (datapointEnumChoices) {
      setEnumSelectOptions(
        datapointEnumChoices.map((choice) => ({
          ...choice,
        }))
      );
    }
  }, [datapointEnumChoices, messages]);

  useEffect(() => {
    if (isDatapointActive && fieldRef.current) {
      // @ts-ignore
      fieldRef.current.focus();
      const position =
        currentValueCursorPosition !== undefined
          ? currentValueCursorPosition
          : // @ts-ignore
            fieldRef.current.value.length;
      // @ts-ignore
      fieldRef.current.setSelectionRange(position, position);
    }
  }, [isDatapointActive, currentValue]);

  const handleAnnotationValueSave = (newValue: any) => {
    // @ts-ignore
    const annotationId = annotationStore.annotation.id as string;
    setCurrentValue(newValue);
    if (id && isKnownDatapoint) {
      const patchProps = {
        annotationId: annotationId,
        annotationItemId: id,
        patch: {
          value: datapointType !== DatapointType.Enum ? newValue : undefined,
          normalized_value: newValue,
        },
      };
      patchAnnotationMutation.mutate(patchProps);
    } else {
      const postProps = {
        annotationId,
        payload: {
          id,
          schema_element_kind: elementKind,
          schema_element_id: datapointId,
          parent_id: parentId,
          annotation_class: 'value',
          value: newValue,
          normalized_value: newValue,
        },
      };
      createAnnotationMutation.mutate(postProps);
    }
  };

  const handleEnumValueChange = (newValue: any) => {
    handleAnnotationValueSave(newValue);
    setIsDatapointActive(false);
  };

  const handleOnBlurValueField = () => {
    if (variant !== AnnotationControlVariant.TableCell) {
      handleAnnotationValueSave(currentValue);
    }
    setIsDatapointActive(false);
  };

  const filterInputKeys = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (variant === AnnotationControlVariant.TableCell) {
        setIsDatapointActive(false);
        handleAnnotationValueSave(currentValue);
        setActiveDatapoint(undefined, false);
      } else {
        handleOnBlurValueField();
        setActiveDatapoint(undefined, false);
      }
    }
  };

  const handleChangeTextValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
    setCurrentValueCursorPosition(fieldRef.current?.selectionStart);
  };

  const getFormattedValue = (inputString: string) => {
    if (!inputString) {
      return '';
    }

    return inputString.length <= 60 ? inputString : `${inputString.slice(0, 60)}…`;
  };

  useEffect(() => {
    if (pinnedDatapoint && pinnedDatapoint.id === id) {
      setIsDatapointActive(true);
    }
  }, []);

  const handleElementActivation = () => {
    if (!can.can(PERMISSION.ANNOTATIONS_UPDATE) || isTestModeEnabled) {
      return;
    }

    if (!isKnownDatapoint) {
      setIsDatapointActive(false);
      handleAnnotationValueSave(currentValue);
    }

    setIsDatapointActive(true);
    if (variant === AnnotationControlVariant.TableCell) {
      setActiveDatapoint(
        {
          id,
          box,
          kind: 'datapoint',
          value: currentValue,
          normalized_value: currentValue,
          annotation_class: annotationClass,
          schema_element_id: datapointId,
          type: datapointType,
          page_index: pageIndex,
          isTableDatapoint: true,
        },
        true
      );
    } else {
      setActiveDatapoint(undefined, false);
    }
  };

  const renderEnumValue = () => {
    let visibleValue;
    const enumChoicesMessage = messages?.find(
      (message: any) => message.type === 'enum_choices'
    );
    // Enum options in message have precedence over schema enum options
    const enumChoices = enumChoicesMessage
      ? JSON.parse(enumChoicesMessage.content)
      : datapointEnumChoices;
    const enumOption = enumChoices?.find((option) => option.value === currentValue);

    if (!enumOption) {
      visibleValue = value;
    } else {
      visibleValue = enumOption.label
        ? enumOption.label.length <= 60
          ? enumOption.label
          : `${enumOption.label.slice(0, 60)}...`
        : '';
    }

    return (
      <EuiText
        size="s"
        className={
          variant === AnnotationControlVariant.TableCell
            ? 'annotation-table-enum-value'
            : 'annotation-enum-value'
        }
      >
        {visibleValue}
      </EuiText>
    );
  };

  const renderValue = () => {
    return (
      <>
        {currentValue && (
          <div
            className={clsx({
              'annotation-datapoint-value':
                variant === AnnotationControlVariant.ListItem,
              'annotation-table-datapoint-value':
                variant === AnnotationControlVariant.TableCell,
            })}
            onClick={handleElementActivation}
          >
            {datapointType !== DatapointType.Enum && (
              <EuiText
                size="s"
                title={currentValue}
                className={
                  variant === AnnotationControlVariant.TableCell
                    ? 'annotation-table-text-value'
                    : 'annotation-text-value'
                }
              >
                {getFormattedValue(currentValue)}
              </EuiText>
            )}
            {datapointType === DatapointType.Enum && renderEnumValue()}
            {isPatchLoaderVisible && (
              <EuiLoadingSpinner size="s" style={{ marginLeft: 8 }} />
            )}
          </div>
        )}
        {!isTestModeEnabled && (
          <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
            {!currentValue && (
              <div
                className={clsx({
                  'annotation-datapoint-add-button': true,
                  'table-cell-add-button':
                    variant === AnnotationControlVariant.TableCell,
                })}
              >
                <EuiButtonIcon
                  iconType="plus"
                  title={intl.formatMessage({
                    id: 'datapointValueControl.addValue',
                    defaultMessage: 'Add value',
                  })}
                  aria-label="Add value"
                  onClick={handleElementActivation}
                />
              </div>
            )}
          </Can>
        )}
      </>
    );
  };

  const renderTextFieldForm = () => {
    const TextComponent =
      currentValue && currentValue.length > 50 ? EuiTextArea : EuiFieldText;
    return (
      <div className="annotation-datapoint-form">
        <TextComponent
          value={currentValue}
          onChange={handleChangeTextValue}
          aria-label="Enter value of datapoint"
          className={
            variant === AnnotationControlVariant.TableCell
              ? 'annotation-table-value-field'
              : 'annotation-value-field'
          }
          onBlur={handleOnBlurValueField}
          onKeyDown={filterInputKeys}
          inputRef={fieldRef}
          fullWidth
        />
      </div>
    );
  };

  const selectedEnumOption = enumSelectOptions?.find(
    (option) => option.value === currentValue
  );
  const selectedEnumOptions = selectedEnumOption ? [selectedEnumOption] : [];
  const renderEnumForm = () => {
    // @ts-ignore
    return (
      <div className="annotation-datapoint-form">
        <EuiComboBox
          aria-label="Enum selector"
          className="annotation-value-enum"
          options={enumSelectOptions || []}
          selectedOptions={selectedEnumOptions}
          onChange={(options) => handleEnumValueChange(options[0]?.value)}
          onBlur={() => {
            setIsDatapointActive(false);
          }}
          singleSelection={{ asPlainText: true }}
          isClearable={false}
          autoFocus={true}
          compressed
          fullWidth
        />
      </div>
    );
  };

  const referenceDataElement = useMemo(() => {
    if (referenceData) {
      return (
        <ReferenceDataSearchButton
          // @ts-ignore
          referenceData={referenceData}
          datapointValue={value}
          id={id as string}
          tableId={tableId as string}
        ></ReferenceDataSearchButton>
      );
    }
    return;
  }, [referenceData]);

  const renderForm = () => {
    return (
      <>
        {datapointType === DatapointType.Enum && renderEnumForm()}
        {datapointType !== DatapointType.Enum && renderTextFieldForm()}
      </>
    );
  };

  return (
    <>
      <EuiFlexGroup
        justifyContent="spaceBetween"
        gutterSize="xs"
        style={{ width: '100%' }}
      >
        <EuiFlexItem>
          <div
            className={clsx({
              'datapoint-control': true,
              active: id && id === pinnedDatapoint?.id,
            })}
            style={{ textAlign: 'right', paddingRight: '0' }}
          >
            {!isDatapointActive && renderValue()}
            {isDatapointActive && renderForm()}
          </div>
        </EuiFlexItem>
        {referenceDataElement && (
          <EuiFlexItem grow={false}>{referenceDataElement}</EuiFlexItem>
        )}
      </EuiFlexGroup>
    </>
  );
};
