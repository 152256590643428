import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiText,
  EuiToken,
} from '@elastic/eui';
import React from 'react';
import { Can, PERMISSION } from 'utils/permissions';
import { DatapointType } from '../../../Definitions/DatapointType';
import { AnnotationControlVariant } from '../../../Definitions/DatapointValueControlVariant';
import { SchemaElementKind } from '../../../Definitions/SchemaElementKind';
import { Translate } from '../../Internationalisation/translate';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useAnnotationStore } from '../Annotation';
import { DatapointValueControl } from '../DatapointValueControl';
import { useActivateDatapoint } from './useActivateDatapoint';

export function KeywordRowCard({
  datapoints,
  columnId,
  elementAnnotation,
  schemaElement,
  rowId,
}) {
  const deleteAnnotationItem = useAnnotationStore(
    (state) => state.deleteAnnotationItem
  );
  const { setActiveDatapoint } = useActivateDatapoint();
  const intl = useTranslate();

  const deleteItem = (id) => {
    deleteAnnotationItem(id);
    setActiveDatapoint(undefined, false);
  };

  const getCellElementSchema = (schemaElementId) => {
    let cellElementSchema;

    if (schemaElement.kind === SchemaElementKind.List) {
      cellElementSchema = schemaElement.elements;
    } else {
      cellElementSchema = schemaElement.elements?.find(
        (item) => item.id === schemaElementId
      );
    }

    return cellElementSchema;
  };

  const getParentId = () => {
    let parentId = rowId;

    if (schemaElement.kind === SchemaElementKind.List) {
      parentId = elementAnnotation.id;
    }

    return parentId;
  };

  return (
    <EuiFlexGroup gutterSize="xs" direction="column">
      <EuiFlexItem grow={false}>
        <EuiHorizontalRule margin="none" size="half" />
      </EuiFlexItem>
      <EuiFlexItem grow={true}>
        {datapoints.length === 0 && (
          <EuiText
            color="subdued"
            size="xs"
            style={{ marginLeft: '8px', justifyContent: 'center', display: 'flex' }}
          >
            <Translate
              id="keywordRowCards.keywordExtensionRow.noItems"
              defaultMessage="No items."
            />
          </EuiText>
        )}
        {datapoints.map((item, index) => {
          const cellElementSchema = getCellElementSchema(columnId);
          const parentId = getParentId();
          return (
            <div key={index}>
              <EuiFlexGroup gutterSize="s" alignItems="center">
                <EuiFlexItem grow={false} style={{ paddingLeft: '8px' }}>
                  {item.annotation_class === 'column_header' && (
                    <EuiToken
                      iconType="editorTable"
                      fill="dark"
                      title={intl.formatMessage({
                        id: 'keywordRowCards.columnHeader',
                        defaultMessage: 'Column header',
                      })}
                      shape="square"
                      color="euiColorVis5"
                    />
                  )}
                  {item.annotation_class === 'keyword' && (
                    <EuiToken
                      iconType="tokenKey"
                      fill="dark"
                      title={intl.formatMessage({
                        id: 'keywordRowCards.keyword',
                        defaultMessage: 'Keyword',
                      })}
                      shape="square"
                      color="euiColorVis5"
                    />
                  )}
                </EuiFlexItem>
                <EuiFlexItem grow={true}>
                  <DatapointValueControl
                    parentId={parentId}
                    datapointId={cellElementSchema.id}
                    datapointType={cellElementSchema.type}
                    elementKind={SchemaElementKind.Datapoint}
                    datapointEnumChoices={
                      cellElementSchema.type === DatapointType.Enum
                        ? cellElementSchema.choices
                        : undefined
                    }
                    variant={AnnotationControlVariant.TableCell}
                    value={item.value}
                    messages={item.messages}
                    id={item.id}
                    box={item.box}
                    annotationClass={item.annotation_class}
                    pageIndex={item.pageIndex}
                    isKnownDatapoint={true}
                    hooks={cellElementSchema.hooks || []}
                  />
                </EuiFlexItem>
                <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
                  <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                      size="xs"
                      iconType="trash"
                      color="text"
                      title="Delete item"
                      aria-label={intl.formatMessage({
                        id: 'keywordRowCards.keywordMenu.delete',
                        defaultMessage: 'Delete item',
                      })}
                      onClick={() => deleteItem(item.id)}
                    />
                  </EuiFlexItem>
                </Can>
              </EuiFlexGroup>
            </div>
          );
        })}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
