import { atom } from 'jotai';
import { ReactComponentElement } from 'react';
import { atomWithLocalStorage } from 'utils/persistence';
import { showElementsEnum } from '../../Definitions/AnnotationsPreferences';
import { activeDatapointAtom } from './DocumentPanel/DatapointAnnotationPopups/AnnotationPopupState';

export const isExtendedAnnotationEnabledAtom = atomWithLocalStorage(
  'isExtendedAnnotationEnabled',
  false
);

export const isBoxAutoFitEnabledAtom = atomWithLocalStorage(
  'isBoxAutoFitEnabled',
  true
);

export const showBoundingBoxesAtom = atomWithLocalStorage('showBoundingBoxes', true);
export const isTestModeEnabledAtom = atomWithLocalStorage('isTestModeEnabled', false);

export const showSearchInDocumentBoxAtom = atomWithLocalStorage(
  'showSearchInDocumentBox',
  false
);

// Width of floating table annotation
export const tablePreferences = atomWithLocalStorage('tablePreferences', {});
export const referenceDataTablePreferences = atomWithLocalStorage(
  'referenceDataTablePreferences',
  {}
);

export const annotationPreferencesAtom = atomWithLocalStorage('annotationPreferences', {
  showElements: showElementsEnum.ONLY_ANNOTATED,
});
// Has bounding box of selected datapoint changed so we should request text from the box?
export const hasBoxChangedAtom = atom(false);
// Counter that is increased when we need to rerender annotation popup when box component
// has changed or vice versa. The annotation package does not allow passing state setter
// so we use this to force rerender on state change.
export const annotationRenderCountAtom = atom(0);
export const wasNewRowAddedAtom = atom(false);
export const wasNewListRowAddedAtom = atom<string | undefined>(undefined);
export const hoverAnnotationBoxIdAtom = atom<string | undefined>(undefined);
export const reviewDocumentsNumberAtom = atom<number | undefined>(undefined);
export const reviewDocumentsQueryStringAtom = atom<string | undefined>(undefined);
export const annotationItemAtom = atom(undefined);
export const annotationItemIdAtom = atom(undefined);
export const notExtractedElementsAtom = atom([]);
export const elementsWithEmptyValueAtom = atom([]);
export const currentReviewAnnotation = atom<
  { documentId: string; annotationId: string } | undefined
>(undefined);
export const openedKeywordAnnotationAccordion = atomWithLocalStorage(
  'openedKeywordAnnotationAccordion',
  undefined
);

export const documentSplit = atom(false);
export const documentIsProcessing = atom<Record<string, boolean> | undefined>(
  undefined
);
export const splitInProgress = atom(false);

export const openedKeywordAnnotationExpandedRow = atomWithLocalStorage(
  'openedKeywordAnnotationExpandedRow',
  new Set()
);

const focusTableIdAtom = atom<string | undefined>(undefined);
const focusTableComponentAtom = atom<ReactComponentElement<any> | undefined>(undefined);

export const focusTableAtom = atom(
  (get) => {
    const id = get(focusTableIdAtom);
    const tableComponent = get(focusTableComponentAtom);

    return {
      id,
      tableComponent,
    };
  },

  (
    get,
    set,
    focusTableObject:
      | { id: string; tableComponent: ReactComponentElement<any> | undefined }
      | undefined
  ) => {
    if (!focusTableObject) {
      set(focusTableIdAtom, undefined);
      set(focusTableComponentAtom, undefined);
      set(activeDatapointAtom, undefined);
    }

    if (focusTableObject) {
      set(focusTableIdAtom, focusTableObject.id);

      if (!focusTableObject.tableComponent) {
        set(focusTableComponentAtom, undefined);
        set(activeDatapointAtom, undefined);
      } else {
        set(focusTableComponentAtom, focusTableObject.tableComponent);
      }
    }
  }
);
export const hideSplitBarAtom = atom(false);
export const splitDrawModeActiveAtom = atom(false);
export const annotationHasErrorMessagesAtom = atom(false);
