import { SchemaElementKind } from '../../Definitions/SchemaElementKind';
import { getSchemaDataElement } from '../../utils/schemaUtils';
import { useAnnotationStore } from './Annotation';
import { getCurrentTableDatapoints } from './utils';

export function useGetFilteredDatapoints() {
  const annotationContent = useAnnotationStore((state) => state.annotationContent);
  const schema = useAnnotationStore((state) => state.schema);

  const getSectionElementById = (sectionElementId: string): string | undefined => {
    if (!schema) {
      return;
    }
    const annotationElements = annotationContent?.elements || [];

    let foundElement;

    // @ts-ignore
    for (const annotationItem of annotationElements) {
      if (annotationItem.kind === SchemaElementKind.Section) {
        for (const sectionElement of annotationItem.elements) {
          if (sectionElement.id === sectionElementId) {
            foundElement = sectionElement;
          }
        }
      }
    }

    return foundElement;
  };

  const getNormalDatapoints = () => {
    if (!schema) {
      return;
    }
    const annotationElements = annotationContent?.elements || [];
    const normalDatapoints: any = [];

    // @ts-ignore
    for (const annotationItem of annotationElements) {
      if (annotationItem.kind === SchemaElementKind.Section) {
        for (const sectionElement of annotationItem.elements) {
          if (sectionElement.kind === SchemaElementKind.Datapoint) {
            const schemaElement = getSchemaDataElement(
              schema,
              sectionElement.schema_element_id
            );

            normalDatapoints.push({
              ...sectionElement,
              type: schemaElement?.type,
              choices: schemaElement?.choices,
            });
          }
        }
      }
    }

    return normalDatapoints;
  };

  const getSectionElementDatapoints = (sectionId: string) => {
    if (!schema) {
      return;
    }
    const sectionElement = getAnnotationSectionElement(sectionId);

    if (sectionElement.kind === SchemaElementKind.Table) {
      return getCurrentTableDatapoints(sectionElement, schema);
    } else if (sectionElement.kind === SchemaElementKind.List) {
      return sectionElement.elements.map((element) => {
        const schemaElement = getSchemaDataElement(schema, element.schema_element_id);
        return {
          ...element,
          type: schemaElement?.type,
          choices: schemaElement?.choices,
        };
      });
    }
    return [];
  };

  // Helpers

  const getAnnotationSectionElement = (sectionId: string) => {
    const annotationElements = annotationContent?.elements || [];

    return annotationElements.reduce((section, annotationItem) => {
      if (section) {
        return section;
      }
      if (annotationItem.kind === SchemaElementKind.Section) {
        const sectionDatapoints = annotationItem.elements.find(
          (sectionElement) => sectionElement.id === sectionId
        );

        if (sectionDatapoints) {
          section = sectionDatapoints;
          return section;
        }
      }
    }, undefined);
  };

  return {
    getNormalDatapoints: getNormalDatapoints,
    getSectionElementDatapoints: getSectionElementDatapoints,
    getSectionElementById: getSectionElementById,
  };
}
