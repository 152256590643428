import {
  EuiButton,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from '@elastic/eui';
import { useAtom } from 'jotai';
import React, { useMemo, useState } from 'react';
import { DatapointType } from '../../../../Definitions/DatapointType';
import { createAnnotationBatchOperations } from '../../../../services/annotations';
import { invalidateAnnotationContent } from '../../../../services/documents';
import { useAnnotationStore } from '../../../Annotation/Annotation';
import { focusTableAtom } from '../../../Annotation/AnnotationState';
import { Translate } from '../../../Internationalisation/translate';
import { useToasts } from '../../../Toasts/Toasts';
import { ReferenceDataTable } from './ReferenceDataTable';

export const DatapointReferenceDataTable = ({
  fields,
  defaultData,
  matchedElements,
  datapointValue,
  id,
  referenceDataId,
  tableId,
}: {
  fields: Array<string>;
  defaultData: Array<Record<string, string>>;
  matchedElements: Array<any>;
  datapointValue: string | undefined;
  id: string;
  referenceDataId: string;
  tableId: string;
}) => {
  const availableColumns = matchedElements.map((element) => element.column);
  const [data, setData] = useState(defaultData);
  const [selectedRowIndex, updateSelectedRowIndex] = useState<number | undefined>(
    undefined
  );
  const [usedColumns, setUsedColumns] = useState<Set<string>>(
    new Set(availableColumns)
  );

  const annotationContent = useAnnotationStore((state) => state.annotationContent);
  const annotationStore = useAnnotationStore();

  const [, setFocusedTable] = useAtom(focusTableAtom);
  const { showRequestError } = useToasts();

  const columnTitle = (field: string) => (
    <EuiFlexGroup alignItems={'center'} gutterSize={'xs'}>
      <EuiFlexItem style={{ width: '20%' }}>
        <EuiCheckbox
          id={field}
          disabled={!availableColumns.includes(field)}
          onChange={() => toggleColumn(field)}
          checked={usedColumns.has(field)}
        />
      </EuiFlexItem>
      <EuiFlexItem style={{ width: '80%' }}>
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={field}
        >
          {field}
        </span>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const findAllDatapoints = (elements: Array<any>) => {
    const datapoints: any[] = [];

    const findDatapoints = (array: Array<any>) => {
      array.forEach((item) => {
        if (item.kind === 'datapoint' && item.annotation_class === 'value') {
          const { elements, ...rest } = item;
          datapoints.push(rest);
        }
        if (item.elements) {
          findDatapoints(item.elements);
        }
      });
    };

    findDatapoints(elements);

    return datapoints;
  };

  const findDatapoints = (elements: Array<any>) => {
    const findParentAnnotation = (array: Array<any>, id: string, parent?: any): any => {
      return array.reduce((ac, cu) => {
        if (cu.id === id) {
          ac = parent;
        }

        if (!ac && cu.elements) {
          ac = findParentAnnotation(cu.elements, id, cu);
        }

        return ac;
      }, undefined);
    };

    const parentAnnotation = findParentAnnotation(elements, id);

    if (parentAnnotation.kind === 'section') {
      return findAllDatapoints(elements);
    }

    return parentAnnotation.elements;
  };

  const handleAnnotationValueSave = async (formattedValues: Record<string, string>) => {
    if (annotationContent) {
      // @ts-ignore
      const datapoints = findDatapoints(annotationContent.elements);
      // @ts-ignore
      const annotationId = annotationStore.annotation.id as string;

      const updateValueObject = Object.entries(formattedValues).map(([key, value]) => {
        const schemaElementId = matchedElements.find(
          (element) => element.column === key
        );
        const datapoint = datapoints.find(
          (datapoint: any) =>
            datapoint.schema_element_id === schemaElementId.schema_element_id
        );
        return {
          columnName: key,
          value: value,
          datapoint,
        };
      });

      const commands: any = [];

      updateValueObject.forEach(({ value, datapoint }) => {
        if (!datapoint || datapoint.value === value) {
          return;
        }
        const batchOperation = {
          kind: 'update',
          payload: {
            id: datapoint.id,
            value: datapoint.type !== DatapointType.Enum ? value : undefined,
            normalized_value: value,
            box_x_min: null,
            box_x_max: null,
            box_y_min: null,
            box_y_max: null,
          },
        };
        commands.push(batchOperation);
      });

      try {
        await createAnnotationBatchOperations(annotationId, {
          commands,
        });
      } catch (error) {
        showRequestError(error);
      } finally {
        await invalidateAnnotationContent(annotationId);
      }
    }
  };

  const toggleColumn = (field: string) => {
    if (usedColumns.has(field)) {
      usedColumns.delete(field);
    } else {
      usedColumns.add(field);
    }
    setUsedColumns(usedColumns);
  };

  const rowClasses = useMemo(() => {
    let rowClasses = {};
    if (selectedRowIndex !== undefined) {
      rowClasses = { [selectedRowIndex]: 'euiDataGridRow--rowClassesDemoSelected' };
    }

    return rowClasses;
  }, [selectedRowIndex]);

  const closeReferenceTable = () =>
    setFocusedTable({ id: tableId, tableComponent: undefined });

  const updateAnnotationValue = () => {
    const foundRow = data.find((value, index) => index === selectedRowIndex);

    let formattedValue = {};
    Array.from(usedColumns).forEach((column) => {
      if (foundRow[column]) {
        formattedValue = { ...formattedValue, [column]: foundRow[column] };
      }
    });

    handleAnnotationValueSave(formattedValue);
    closeReferenceTable();
  };

  const SelectionRowCell = ({ rowIndex }: { rowIndex: number }) => {
    const isChecked = selectedRowIndex === rowIndex;

    return (
      <div>
        <EuiCheckbox
          id={`${rowIndex}`}
          aria-label={`Select row ${rowIndex}`}
          checked={isChecked}
          onChange={(e) => {
            if (e.target.checked) {
              updateSelectedRowIndex(rowIndex);
            } else {
              updateSelectedRowIndex(undefined);
            }
          }}
        />
      </div>
    );
  };

  const leadingControlColumns = [
    {
      id: 'selection',
      width: 32,
      headerCellRender: () => null,
      rowCellRender: SelectionRowCell,
    },
  ];

  const title = (
    <EuiFlexItem>
      <EuiTitle size="s">
        <h2>
          <Translate
            id="referenceData.modal.title"
            defaultMessage="Search reference data"
          />
        </h2>
      </EuiTitle>
    </EuiFlexItem>
  );

  return (
    <EuiFlexGroup direction="column" gutterSize="s">
      <ReferenceDataTable
        fields={fields}
        defaultData={defaultData}
        data={data}
        setData={setData}
        title={title}
        datapointValue={datapointValue}
        referenceDataId={referenceDataId}
        leadingControlColumns={leadingControlColumns}
        rowClasses={rowClasses}
        getColumnTitle={columnTitle}
        shouldUpdateTablePreferences={true}
        usedColumns={usedColumns}
      />
      <EuiFlexItem grow={false}>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton
              disabled={selectedRowIndex === undefined || !usedColumns.size}
              onClick={updateAnnotationValue}
              size="s"
              fill
            >
              <Translate id="button.update" defaultMessage="Update" />
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={closeReferenceTable} size="s">
              <Translate id="button.close" defaultMessage="Close" />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
