import { EuiButtonIcon, EuiComboBox, EuiPopover, useEuiTheme } from '@elastic/eui';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { ScaledTableGridContext } from './TableGridContainer';
import { sortTableGridLines } from './utils';

export function ColumnConfigToggle({ onEnter, onLeave, line, columnSchemasOptions }) {
  const schemaOptions = columnSchemasOptions.map((schema) => ({
    label: schema.label,
    value: schema.id,
  }));

  const { euiTheme } = useEuiTheme();
  const [backgroundColor, setBackgroundColor] = useState(euiTheme.colors.primary);
  const [selectedOptions, setSelectedOption] = useState([]);
  const [, setShowTitle] = useState(false);
  const [isPopoverOpen, setPopover] = useState(false);
  const intl = useTranslate();

  const { setScaledGrid } = useContext(ScaledTableGridContext);

  useEffect(() => {
    const findOption = schemaOptions.filter(
      (option) => option.value === line.schemaElementId
    );
    if (findOption.length) {
      setSelectedOption(findOption);
    }
  }, []);

  const onMouseEnter = () => {
    onEnter(line);
    setShowTitle(true);
    setBackgroundColor(euiTheme.colors.secondary);
  };

  const onMouseLeave = () => {
    onLeave(line);
    setShowTitle(false);
    setBackgroundColor(euiTheme.colors.primary);
  };

  const onColumnSchemaChange = (selectedSchema) => {
    setSelectedOption(selectedSchema);
    setScaledGrid((previousScaleGrid) => {
      const updatedLine = { ...line, schemaElementId: selectedSchema[0]?.value };
      const filteredGridLines = previousScaleGrid.lines.filter(
        (line) => line.id !== updatedLine.id
      );
      return {
        ...previousScaleGrid,
        lines: sortTableGridLines([...filteredGridLines, updatedLine]),
      };
    });
  };

  const togglePopover = () => {
    setShowTitle(false);
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const button = (
    <EuiButtonIcon
      onClick={togglePopover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        color: 'white',
        backgroundColor: `${backgroundColor}`,
        borderRadius: '5px 5px 0px 0px',
      }}
      iconType="pencil"
      color="text"
      title={intl.formatMessage({
        id: 'columnConfigToggle.button.toggleSchemaPopOver',
        defaultMessage: 'Select column',
      })}
      aria-label="Toggle schema pop over"
      size="xs"
    />
  );

  return (
    <>
      <EuiPopover
        style={{
          position: 'absolute',
          cursor: 'pointer',
          top: '-25px',
          left: `${line.from.x}px`,
          padding: '2px',
        }}
        anchorPosition="upCenter"
        button={button}
        isOpen={isPopoverOpen}
        closePopover={closePopover}
      >
        <div style={{ width: '300px', zIndex: '99' }}>
          <EuiComboBox
            style={{ width: '300px', zIndex: '99' }}
            singleSelection={{ asPlainText: true }}
            options={schemaOptions}
            selectedOptions={selectedOptions}
            onChange={onColumnSchemaChange}
          />
        </div>
      </EuiPopover>
      {selectedOptions[0] && (
        <div
          style={{
            position: 'absolute',
            top: '-80px',
            width: '70px',
            height: '37px',
            minHeight: '30px',
            display: 'flex',
            padding: '3px',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            fontSize: '12px',
            transform: 'rotateZ(90deg)',
            left: `${line.from.x - 21}px`,
            color: `${euiTheme.colors.darkestShade}`,
            backgroundColor: `${euiTheme.colors.lightestShade}`,
          }}
        >
          <span>{selectedOptions[0].label}</span>
        </div>
      )}
    </>
  );
}
