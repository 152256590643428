import {
  EuiAvatar,
  EuiContextMenu,
  EuiHeaderSectionItemButton,
  EuiPopover,
  useEuiTheme,
} from '@elastic/eui';
import { useChangelog } from 'components/Changelog/Changelog';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from 'services/auth';
import { useUsers } from 'services/users';
import { THEME_MODE, VISIBILITY } from 'settings/constants';
import { ThemeMode } from 'settings/ThemeMode';
import { invertThemeMode } from 'themes/ThemeSelector';
import { useTranslate } from '../Internationalisation/useTranslate';
import { ChangePasswordModal } from './ChangePasswordModal';
import { UserSettingsModal } from './UserSettingsModal';

export const HeaderUserMenu = () => {
  // const { keycloak } = useKeycloak();
  const { useGetCurrentUser } = useUsers();
  const { data: currentUser } = useGetCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const { euiTheme } = useEuiTheme();
  const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const { isThereUnseenVersion } = useChangelog();
  const intl = useTranslate();
  const navigate = useNavigate();

  const color =
    THEME_MODE === ThemeMode.dark ? euiTheme.colors.text : euiTheme.colors.primary;

  const handleLogout = () => {
    // if (isKeycloakUsed()) {
    //   keycloak.logout();
    // } else {
    logout();
    // }
  };

  const handleChangePassword = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleUserSettings = () => {
    setIsUserSettingsModalOpen(true);
  };

  const handleResetLocalUserSettings = () => {
    localStorage.clear();
    setIsOpen(false);
    navigate('.');
  };

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleLanguageConfigModal = () => {
    setIsUserSettingsModalOpen(false);
    closeMenu();
  };

  const handleChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
    closeMenu();
  };

 

  let panelItems = [
    {
      name: intl.formatMessage({ id: 'headerUserMenu.help', defaultMessage: 'Help' }),
      toolTipContent: intl.formatMessage({ id: 'headerUserMenu.help.toolTipContent', defaultMessage: 'Coming Soon' }),
      disabled: true,
      //href: 'https://idp.pta.de/help',
      //target: '_blank',
    },
    // {
    //   name: intl.formatMessage({
    //     id: 'headerUserMenu.changelog',
    //     defaultMessage: 'Changelog',
    //   }),
    //   href: RoutePath.CHANGELOG,
    //   icon: isThereUnseenVersion ? (
    //     <EuiIcon type="dot" size="m" color="accent" />
    //   ) : undefined,
    // },
    // {
    //   name: intl.formatMessage({
    //     id: 'headerUserMenu.support',
    //     defaultMessage: 'Support',
    //   }),
    //   isHidden: !currentUser?.settings?.service_desk_url,
    //   href: currentUser?.settings?.service_desk_url,
    //   target: '_blank',
    // },
    {
      name: intl.formatMessage({
        id: 'foldersSidebar.users',
        defaultMessage: 'Users',
      }),
      isHidden: !currentUser?.settings?.user_management_url,
      href: currentUser?.settings?.user_management_url,
      target: '_blank',
    },
    {
      isSeparator: true,
    },
    {
      name: intl.formatMessage({
        id: 'headerUserMenu.changePassword',
        defaultMessage: 'Change password',
      }),
      onClick: handleChangePassword,
    },
    {
      name: intl.formatMessage({
        id: 'headerUserMenu.userSettings',
        defaultMessage: 'User settings',
      }),
      onClick: handleUserSettings,
    },
    {
      name: intl.formatMessage({
        id: 'headerUserMenu.resetLocalUserSettings',
        defaultMessage: 'Reset local user settings',
      }),
      onClick: handleResetLocalUserSettings,
    },
    {
      name: intl.formatMessage({
        id: 'headerUserMenu.invertColorTheme',
        defaultMessage: 'Invert color theme',
      }),
      onClick: invertThemeMode,
    },
    {
      name: intl.formatMessage({
        id: 'headerUserMenu.logout',
        defaultMessage: 'Log out',
      }),
      onClick: handleLogout,
    },
  ];
  panelItems = panelItems.filter((item) => !item.isHidden);
  // Remove our extra attribute, otherwise warnings are emitted.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  panelItems = panelItems.map(({ isHidden, ...keep }) => keep);

  const panels = [
    {
      id: 0,
      title:
        currentUser?.name ||
        intl.formatMessage({ id: 'headerUserMenu.user', defaultMessage: 'User' }),
      items: panelItems,
    },
  ];

  const button = (
    <EuiHeaderSectionItemButton
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="User menu"
      onClick={onMenuButtonClick}
      notification={isThereUnseenVersion}
    >
      <EuiAvatar
        name={
          currentUser?.name ||
          intl.formatMessage({ id: 'headerUserMenu.user', defaultMessage: 'User' })
        }
        iconSize="s"
        size="s"
        type="space"
        initialsLength={1}
        iconColor="primary"
        color={color}
      />
    </EuiHeaderSectionItemButton>
  );

  return (
    <>
      <EuiPopover
        button={button}
        isOpen={isOpen}
        anchorPosition="downRight"
        closePopover={closeMenu}
        panelPaddingSize="none"
        zIndex={VISIBILITY.POPOVER}
      >
        <EuiContextMenu initialPanelId={0} panels={panels} />
      </EuiPopover>
      {isUserSettingsModalOpen && (
        <UserSettingsModal setIsModalVisible={handleLanguageConfigModal} />
      )}
      {isChangePasswordModalOpen && (
        <ChangePasswordModal setIsModalVisible={handleChangePasswordModal} />
      )}
    </>
  );
};
