import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import client from '../../utils/client';
import { useTranslate } from '../Internationalisation/useTranslate';
import { useToasts } from '../Toasts/Toasts';

export function RedirectToNewPaths() {
  const { documentId, schemaId, versionId } = useParams();
  const { addToast } = useToasts();
  const intl = useTranslate();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (documentId) {
      client(`api/v1/documents/${documentId}`)
        .then((data) => {
          navigate(`/folders/${data.folder_id}${pathname}${search}`, {
            replace: true,
          });
        })
        .catch((error) => {
          if (error.status === 403) {
            addToast({
              title: intl.formatMessage({
                id: 'redirect.errorResourcePermission',
                defaultMessage: 'Not sufficient permissions to view this resource.',
              }),
              color: 'danger',
            });
          } else {
            addToast({
              title: intl.formatMessage({
                id: 'redirect.errorResource',
                defaultMessage: 'Resource not found.',
              }),
              color: 'danger',
            });
          }
        });
    }
  }, [documentId]);

  useEffect(() => {
    if (schemaId) {
      client(`api/v1/schemas/${schemaId}`)
        .then((data) => {
          if (versionId) {
            navigate(
              `/folders/${data.folder_id}/skills/${schemaId}/versions/${versionId}`,
              { replace: true }
            );
          } else if (pathname.includes('monitoring')) {
            navigate(`/folders/${data.folder_id}/skills/${schemaId}/monitoring`, {
              replace: true,
            });
          } else {
            navigate(`/folders/${data.folder_id}/manage/skills/${schemaId}`, {
              replace: true,
            });
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            addToast({
              title: intl.formatMessage({
                id: 'redirect.errorResourcePermission',
                defaultMessage: 'Not sufficient permissions to view this resource.',
              }),
              color: 'danger',
            });
          } else {
            addToast({
              title: intl.formatMessage({
                id: 'redirect.errorResource',
                defaultMessage: 'Resource not found.',
              }),
              color: 'danger',
            });
          }
        });
    }
  }, [schemaId]);

  return <Navigate replace to="/" />;
}
