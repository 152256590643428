import { EuiButtonGroup } from '@elastic/eui';
import React from 'react';
import { useTranslate } from '../../../Internationalisation/useTranslate';

export function AnnotationClassSelector({
  defaultAnnotationClass,
  onAnnotationClassChange,
  isDisabled = false,
  isValueDisabled = false,
  isSpreadsheetDatapoint = false,
}) {
  const intl = useTranslate();

  const annotationClassOptions = [
    {
      id: 'value',
      isDisabled: isDisabled || isValueDisabled,
      label: intl.formatMessage({
        id: 'datapointAnnotationEditPopup.value',
        defaultMessage: 'Value',
      }),
    },
    {
      id: 'keyword',
      isDisabled: isSpreadsheetDatapoint,
      label: intl.formatMessage({
        id: 'datapointAnnotationEditPopup.keyword',
        defaultMessage: 'Keyword',
      }),
    },
    {
      id: 'column_header',
      isDisabled: isSpreadsheetDatapoint,
      label: intl.formatMessage({
        id: 'datapointAnnotationEditPopup.header',
        defaultMessage: 'Header',
      }),
    },
  ];

  function onChange(annotationClass) {
    onAnnotationClassChange(annotationClass);
  }

  return (
    <EuiButtonGroup
      name="annotationClasses"
      type="single"
      isDisabled={isDisabled}
      color="text"
      legend={intl.formatMessage({
        id: 'datapointAnnotationEditPopup.annotationClass',
        defaultMessage: 'Annotation class',
      })}
      options={annotationClassOptions}
      idSelected={defaultAnnotationClass}
      onChange={(annotationClass) => onChange(annotationClass)}
      buttonSize="compressed"
      isFullWidth
    />
  );
}
