import { EuiFieldSearch } from '@elastic/eui';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { searchedSidenavFoldersAtom, useFoldersTree } from '../Folders/Folders';
import { useFolders } from '../Folders/useFolders';
import { InLineLoader } from '../InlineLoader/InlineLoader';
import { useTranslate } from '../Internationalisation/useTranslate';

export function SideBarFolderSearch() {
  const { useGetFolders } = useFolders();
  const { data: folders, isLoading } = useGetFolders();
  const [query, setQuery] = useState('');
  const [, setSearchedSidenavFolders] = useAtom(searchedSidenavFoldersAtom);
  const intl = useTranslate();
  const { getFolderParentsDisplayString, getFolderInfoWithParents } = useFoldersTree();
  const location = useLocation();

  const clearSearch = () => {
    setSearchedSidenavFolders(undefined);
  };

  useEffect(() => {
    setQuery('');
  }, [location]);

  useEffect(() => {
    if (!query) {
      clearSearch();
      return;
    }
    const timer = setTimeout(() => {
      filterFolders(folders.data, query.toLowerCase());
    }, 500);

    return () => clearTimeout(timer);
  }, [folders, query]);

  const filterFolders = (folders, query) => {
    let queryWordList = query.split(/\s+/);

    if (queryWordList.length === 2 && queryWordList[1] === '') {
      queryWordList = [queryWordList[0]];
    }
    const filteredFolders = folders.filter((folder) => {
      const folderString = getFolderParentsDisplayString(folder.id);
      const folderName = folder.name.toLowerCase();
      let isMatch = true;

      const isMatchingFolder = (queryWord) => {
        return folderName.includes(queryWord);
      };

      queryWordList.forEach((word, index) => {
        if (!isMatch) {
          return;
        }

        const lastIndex = queryWordList.length - 1;

        if (!folderString.toLowerCase().includes(word.toLowerCase())) {
          isMatch = false;
        }

        if (lastIndex === index && isMatchingFolder(word)) {
          isMatch = true;
        }
      });

      if (isMatchingFolder(query)) {
        isMatch = true;
      }

      return isMatch;
    });

    const allRelatedFolders: Record<string, any> = {};

    filteredFolders.forEach((folder: any) => {
      allRelatedFolders[folder.id] = folder;
      const relatedFolders = getFolderInfoWithParents(folder.id);
      relatedFolders.forEach((relatedFolder) => {
        if (!allRelatedFolders[relatedFolder.id]) {
          allRelatedFolders[relatedFolder.id] = relatedFolder;
        }
      });
    });

    setSearchedSidenavFolders(allRelatedFolders);
  };

  if (isLoading) return <InLineLoader />;

  const onChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <EuiFieldSearch
        placeholder={intl.formatMessage({
          id: 'documentTable.batchActions.moveTo.searchFolder',
          defaultMessage: 'Search folder',
        })}
        value={query}
        incremental={true}
        onChange={(e) => onChange(e)}
        isClearable={true}
        aria-label="Search folder"
      />
    </>
  );
}
