import { EuiComboBox } from '@elastic/eui';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useSchema } from '../../../../services/documents';
import { InLineLoader } from '../../../InlineLoader/InlineLoader';
import { useTranslate } from '../../../Internationalisation/useTranslate';
import {
  elementsWithEmptyValueAtom,
  isExtendedAnnotationEnabledAtom,
  notExtractedElementsAtom,
} from '../../AnnotationState';
import { getDatapointSchemas } from '../../utils';

export function SchemaElementSelector({
  defaultSchemaElementId,
  schemaId,
  onSchemaElementChange,
  isInvalid,
  annotationClass,
}) {
  const { data: schema, isLoading } = useSchema(schemaId);
  const [selectedSchemaElementId, setSelectedSchemaElementId] = useState(
    defaultSchemaElementId
  );
  const [notExtractedElements] = useAtom(notExtractedElementsAtom);
  const [elementsWithEmptyValue] = useAtom(elementsWithEmptyValueAtom);
  const [isExtendedAnnotationEnabled] = useAtom(isExtendedAnnotationEnabledAtom);
  const [emptyElementsOptions, setEmptyElementsOptions] = useState([]);
  const intl = useTranslate();

  useEffect(() => {
    if (defaultSchemaElementId) {
      onSchemaElementChange(defaultSchemaElementId);
    }
  }, [defaultSchemaElementId]);

  useEffect(() => {
    let notExtractedElementsOptions = [];
    let elementsWithEmptyValueOptions = [];

    if (notExtractedElements.length) {
      notExtractedElementsOptions = notExtractedElements
        .filter((element) => !element.is_hidden)
        .map((element) => ({
          label: element.label,
          value: element.value,
        }));
    }

    if (elementsWithEmptyValue.length) {
      elementsWithEmptyValueOptions = elementsWithEmptyValue
        .filter((element) => !element.is_hidden)
        .map((element) => ({
          label: element.label,
          value: element.value,
        }));
    }

    setEmptyElementsOptions(
      notExtractedElementsOptions.concat(elementsWithEmptyValueOptions)
    );
  }, [notExtractedElements, elementsWithEmptyValue]);

  if (isLoading) return <InLineLoader />;

  const datapointSchemas = getDatapointSchemas(schema.content, ['datapoint']);

  const options = datapointSchemas
    .filter((element) => !element.is_hidden)
    .map((datapointSchema) => {
      return { label: datapointSchema.label, value: datapointSchema.id };
    });

  let selectedOptions = options.filter(
    (option) => option.value === selectedSchemaElementId
  );

  const onChange = (selectedOptions) => {
    selectedOptions[0] && setSelectedSchemaElementId(selectedOptions[0].value);
    selectedOptions[0] && onSchemaElementChange(selectedOptions[0].value);
  };

  const offerAllSchemaElements = () => {
    return annotationClass !== 'value' && isExtendedAnnotationEnabled;
  };

  return (
    <EuiComboBox
      fullWidth
      placeholder={intl.formatMessage({
        id: 'datapointEditPopup.selectField',
        defaultMessage: 'Select data point',
      })}
      singleSelection={{ asPlainText: true }}
      sortMatchesBy="startsWith"
      options={offerAllSchemaElements() ? options : emptyElementsOptions}
      compressed={true}
      isClearable={false}
      selectedOptions={selectedOptions}
      onChange={onChange}
      isInvalid={isInvalid}
    />
  );
}
