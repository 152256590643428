import { useAtom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { SchemaElementKind } from '../../Definitions/SchemaElementKind';
import { createAnnotationBatchOperations } from '../../services/annotations';
import { invalidateAnnotationContent } from '../../services/documents';
import { splitInProgress } from './AnnotationState';
import { DocumentClassificationSchemaId } from './DocumentPanel/ImageAnnotation/useDocumentClassification';

export function useSplitOperations() {
  const [, setSplitInProgress] = useAtom(splitInProgress);

  const getUpdateOperation = (id: string, value: string | number) => {
    return {
      kind: 'update',
      payload: {
        id,
        value,
        normalized_value: value,
      },
    };
  };

  const createOperation = (
    id: string,
    kind: 'create' | 'update' | 'delete',
    schemaElementId: DocumentClassificationSchemaId,
    value: string | number | undefined,
    parentId: string,
    pageIndex: number,
    schemaElementKind: SchemaElementKind = SchemaElementKind.Datapoint
  ) => {
    return {
      kind,
      payload: {
        id,
        schema_element_kind: schemaElementKind,
        schema_element_id: schemaElementId,
        parent_id: parentId,
        annotation_class: 'value',
        value,
        normalized_value: value,
        page_index: pageIndex,
      },
    };
  };

  const getAddRowOperations = (
    documentClass: DocumentClassificationSchemaId,
    documentClassFirstPageIndex: number,
    documentClassLastPageIndex: number,
    tableId: string,
    pageIndex: number
  ) => {
    const rowId = uuidv4();

    const rowOperation = createOperation(
      rowId,
      'create',
      '_document_classification_item',
      undefined,
      tableId,
      pageIndex,
      SchemaElementKind.Tuple
    );
    const documentClassOperation = createOperation(
      uuidv4(),
      'create',
      '_document_class',
      documentClass,
      rowId,
      pageIndex
    );
    const documentFirstPageIndexOperation = createOperation(
      uuidv4(),
      'create',
      '_document_class_first_page_index',
      documentClassFirstPageIndex,
      rowId,
      pageIndex
    );
    const documentLastPageIndexOperation = createOperation(
      uuidv4(),
      'create',
      '_document_class_last_page_index',
      documentClassLastPageIndex,
      rowId,
      pageIndex
    );

    return [
      rowOperation,
      documentClassOperation,
      documentFirstPageIndexOperation,
      documentLastPageIndexOperation,
    ];
  };

  const createBatchOperations = (
    annotationId: string,
    batchActions: any,
    runOnSuccess?: () => void,
    runOnError?: () => void
  ) => {
    setSplitInProgress(true);
    // @ts-ignore
    createAnnotationBatchOperations(annotationId, {
      commands: batchActions,
    })
      .then(() => {
        return invalidateAnnotationContent(annotationId);
      })
      .then(() => {
        setSplitInProgress(false);
        runOnSuccess && runOnSuccess();
      })
      .catch(() => {
        setSplitInProgress(false);
        runOnError && runOnError();
      });
  };

  return {
    getAddRowOperations: getAddRowOperations,
    getUpdateOperation: getUpdateOperation,
    createBatchOperations: createBatchOperations,
  };
}
