import { localLogout, refreshAccessToken } from '../services/auth';
import { LOGIN_URL } from '../settings/constants';
import { useKeycloakStore } from '../utils/useGlobalSettings';
export function checkIfAccessTokenValidAndTryRefresh() {
  // Return if token valid. Try refresh the token at first.
  if (isKeycloakUsed()) {
    useKeycloakStore
      .getState()
      .keycloak.updateToken(5)
      .then((refreshed) => {
        refreshed && console.log(`token refreshed`);
      })
      .catch(() => {
        console.log('could not refresh token');
      });
    return true;
  }
  try {
    const tokenExpiration = localStorage.getItem('access_token_expires_at');
    const refreshTokenExpiration = localStorage.getItem('refresh_token_expires_at');
    const now_seconds = Date.now() / 1000;
    // We refresh token if it expires in refreshOffset seconds
    const refreshOffset = 15;
    const tokenExpired = now_seconds > tokenExpiration - refreshOffset;
    const refreshTokenExpired = now_seconds > refreshTokenExpiration;
    if (refreshTokenExpired) {
      console.log('refresh token expired');
      localLogout();
      return false;
    } else if (tokenExpired) {
      refreshAccessToken()
        .catch(() => {
          console.log('token expired and could not be refreshed');
          localLogout();
          return false;
        })
        .then(console.log('token refreshed'));
    }
  } catch (error) {
    console.log('error in token validation ', error);
    localLogout();
    return false;
  }
  return true;
}

export function redirectToLogin() {
  console.log('redirecting to login');
  window.location.assign(LOGIN_URL);
}

export function isKeycloakUsed() {
  let isUsed = useKeycloakStore.getState().isKeycloakUsed;
  if (isUsed === undefined) {
    const parsed = JSON.parse(localStorage.getItem('globalSettings') ?? '{}');
    isUsed = parsed?.auth_type === 'keycloak';
  }
  return isUsed;
}

export function useIsAuthenticated() {
  // const { keycloak } = useKeycloak();
  // const setToken = useKeycloakStore((state) => state.setToken);
  // const token = useKeycloakStore((state) => state.token);

  // We set token via onTokens in ReactKeycloakProvider but that does not set it synchronously.
  // We need to check here to be sure the token is set before returning user is authenticated.
  // It should not be needed after we migrate services and client to hooks so we can
  // set token via useKeycloak directly in client.
  // if (isKeycloakUsed() && keycloak.token != token) {
  //   setToken(keycloak.token);
  // }

  const isAuthenticated = isKeycloakUsed()
    ? keycloak.authenticated
    : checkIfAccessTokenValidAndTryRefresh();
  return { isAuthenticated: isAuthenticated };
}
