import { useAtom } from 'jotai';
import { useMutation, useQuery } from 'react-query';
import {
  defaultDocumentViews,
  documentViews,
  userSettingsAtom,
} from '../components/Header/States/UserState';
import { useTranslate } from '../components/Internationalisation/useTranslate';
import { useToasts } from '../components/Toasts/Toasts';
import { useIsAuthenticated } from '../utils/auth';
import client from '../utils/client';
import {
  getCurrentUserEndpoint,
  getCurrentUserPermissionsEndpoint,
  getCurrentUserSettingsEndpoint,
  getUsersEndpoint,
} from './endpoints';

export function useUsers() {
  const { isAuthenticated } = useIsAuthenticated();
  const [, setDocumentViews] = useAtom(documentViews);
  const [, setUserSettings] = useAtom(userSettingsAtom);
  const { addToast } = useToasts();
  const [defaultViews] = useAtom(defaultDocumentViews);
  const intl = useTranslate();

  const useGetCurrentUser = () =>
    useQuery(getCurrentUserEndpoint(), {
      enabled: isAuthenticated,
      useErrorBoundary: true,
      onSuccess: async (data) => {
        const { folder_id: oldestAccessibleFolderId } = await getUserPermissions();
        setUserSettings((prevState) => {
          let state = { ...prevState, oldestAccessibleFolderId };
          if (data.settings.language) {
            state = { ...state, language: data.settings.language };
          }
          return state;
        });
        const views = data.settings.document_views
          ? JSON.parse(data.settings.document_views)
          : [];
        let documentViews = views.map((view) => {
          if (view.savedFilterValues) {
            return {
              ...view,
              filterValues: { ...view.savedFilterValues, ...view.filterValues },
            };
          }
          return {
            ...view,
            savedFilterValues: {},
          };
        });

        if (documentViews.length) {
          documentViews = documentViews.map((userView) => {
            if (userView.isDefault && typeof userView.name === 'string') {
              const foundDefaultView = defaultViews.find(
                (defaultView) => defaultView.name.defaultMessage === userView.name
              );
              if (foundDefaultView) {
                return { ...userView, name: foundDefaultView.name };
              }
              return userView;
            }
            return userView;
          });
        }
        setDocumentViews({ documentViews });
      },
      onError: () => {
        addToast({
          title: intl.formatMessage({
            id: 'user.cantFetchUser',
            defaultMessage: "Can't fetch users",
          }),
          color: 'danger',
        });
      },
    });

  const useGetUserPermissions = ({ folderId = null }, treatError = true) => {
    const { isAuthenticated } = useIsAuthenticated();

    let url = getCurrentUserPermissionsEndpoint();
    if (folderId !== null) {
      url = `${url}?folder_id=${folderId}`;
    }
    return useQuery(url, {
      useErrorBoundary: treatError,
      enabled: isAuthenticated,
    });
  };

  const getUserPermissions = (folderId = null) => {
    let url = getCurrentUserPermissionsEndpoint();
    if (folderId !== null) {
      url = `${url}?folder_id=${folderId}`;
    }

    return client(url, {
      method: 'GET',
    });
  };

  const useGetUsers = (state = null) => {
    let url = getUsersEndpoint();
    if (state !== null) {
      url = `${url}?state=${state}`;
    }
    return useQuery(url, { useErrorBoundary: true });
  };

  const updateUserDocumentViewsMutation = useMutation(
    (documentViews) => {
      const stringifyDocumentViews = JSON.stringify(documentViews);
      return client(getCurrentUserSettingsEndpoint(), {
        body: { document_views: stringifyDocumentViews },
        method: 'PATCH',
      });
    },
    {
      onError: () => {
        addToast({
          title: intl.formatMessage({
            id: 'user.userSettingsNotUpdated',
            defaultMessage: 'User settings not updated',
          }),
          color: 'danger',
        });
      },
    }
  );

  const updateUserLanguage = useMutation(
    (language) => {
      return client(getCurrentUserSettingsEndpoint(), {
        body: { language },
        method: 'PATCH',
      });
    },
    {
      onError: () => {
        addToast({
          title: intl.formatMessage({
            id: 'user.userSettingsNotUpdated',
            defaultMessage: 'User settings not updated',
          }),
          color: 'danger',
        });
      },
    }
  );

  return {
    useGetCurrentUser,
    useGetUserPermissions,
    useGetUsers,
    updateUserDocumentViewsMutation,
    updateUserLanguage,
    getUserPermissions,
  };
}
