import { EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody } from '@elastic/eui';
import React from 'react';

const PageLayout = ({ children, restrictWidth = false }) => {
  return (
    <>
      <EuiPage restrictWidth={restrictWidth} style={{ height: '100%' }}>
        <EuiPageBody component="div" style={{ height: '100%' }}>
          <EuiPageContent paddingSize="xs" style={{ height: '100%' }}>
            <EuiPageContentBody style={{ height: '100%' }}>
              {children}
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </>
  );
};

export default PageLayout;
