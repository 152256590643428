import { useMemo } from 'react';
import { useSchema } from '../../../../services/documents';

export function useDocumentClasses(schemaId: string) {
  const { data: schema } = useSchema(schemaId);

  return useMemo(() => {
    if (schema) {
      // @ts-ignore
      const classChoices = schema.content.elements.find(
        (content) => content.id === '_metacontent'
      );
      if (classChoices && Array.isArray(classChoices.elements)) {
        const documentClassifications =
          classChoices.elements.find(
            (element) => element.id === '_document_classification'
          ) || [];

        return documentClassifications?.elements?.elements[0]?.choices || [];
      }
      return [];
    }
  }, [schema]);
}
