import { useAtom } from 'jotai';
import React from 'react';
import { InLineLoader } from '../../InlineLoader/InlineLoader';
import { useAnnotationStore } from '../Annotation';
import { focusTableAtom } from '../AnnotationState';
import { DocumentViewer } from './DocumentViewer';

export function DocumentPanel({ documentId }) {
  const [focusTable] = useAtom(focusTableAtom);
  const isDocumentProcessed = useAnnotationStore((state) => state.isDocumentProcessed);

  if (isDocumentProcessed === undefined) return <InLineLoader />;
  return (
    <DocumentViewer
      focusedTableComponent={focusTable.tableComponent}
      isDocumentProcessed={isDocumentProcessed}
      documentId={documentId}
    />
  );
}
