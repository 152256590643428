import { useTranslate } from '../../Internationalisation/useTranslate';

export function useAnnotationStatusList() {
  const intl = useTranslate();

  const testingStatuses = {
    test_processing: {
      color: 'hollow',
      label: intl.formatMessage({
        id: 'annotationStatus.testProcessing',
        defaultMessage: 'Test processing',
      }),
    },
    test_processing_failed: {
      color: 'hollow',
      label: intl.formatMessage({
        id: 'annotationStatus.testProcessingFailed',
        defaultMessage: 'Test processing failed',
      }),
    },
    test_to_review: {
      color: 'hollow',
      label: intl.formatMessage({
        id: 'annotationStatus.testToReview',
        defaultMessage: 'Test to review',
      }),
    },
  };

  const annotationStatusList: Record<string, { color: string; label: string }> = {
    processing: {
      color: 'primary',
      label: intl.formatMessage({
        id: 'annotationStatus.processing',
        defaultMessage: 'Processing',
      }),
    },
    processing_failed: {
      color: 'danger',
      label: intl.formatMessage({
        id: 'annotationStatus.processingFailed',
        defaultMessage: 'Processing failed',
      }),
    },
    processing_canceled: {
      color: 'danger',
      label: intl.formatMessage({
        id: 'annotationStatus.processingCanceled',
        defaultMessage: 'Processing canceled',
      }),
    },
    to_classify: {
      color: 'warning',
      label: intl.formatMessage({
        id: 'annotationStatus.toClassify',
        defaultMessage: 'To classify',
      }),
    },
    classified: {
      color: 'warning',
      label: intl.formatMessage({
        id: 'annotationStatus.classified',
        defaultMessage: 'Classified',
      }),
    },
    to_review: {
      color: 'warning',
      label: intl.formatMessage({
        id: 'annotationStatus.toReview',
        defaultMessage: 'To review',
      }),
    },
    in_review: {
      color: 'accent',
      label: intl.formatMessage({
        id: 'annotationStatus.inReview',
        defaultMessage: 'In review',
      }),
    },
    approved: {
      color: 'success',
      label: intl.formatMessage({
        id: 'annotationStatus.approved',
        defaultMessage: 'Approved',
      }),
    },
    split: {
      color: 'success',
      label: intl.formatMessage({
        id: 'annotationStatus.split',
        defaultMessage: 'Split',
      }),
    },
    postponed: {
      color: 'hollow',
      label: intl.formatMessage({
        id: 'annotationStatus.postponed',
        defaultMessage: 'Postponed',
      }),
    },
    rejected: {
      color: 'danger',
      label: intl.formatMessage({
        id: 'annotationStatus.rejected',
        defaultMessage: 'Rejected',
      }),
    },
    to_export: {
      color: 'default',
      label: intl.formatMessage({
        id: 'annotationStatus.toExport',
        defaultMessage: 'To export',
      }),
    },
    exporting: {
      color: 'hollow',
      label: intl.formatMessage({
        id: 'annotationStatus.exporting',
        defaultMessage: 'Exporting',
      }),
    },
    exported: {
      color: 'success',
      label: intl.formatMessage({
        id: 'annotationStatus.exported',
        defaultMessage: 'Exported',
      }),
    },
    export_failed: {
      color: 'danger',
      label: intl.formatMessage({
        id: 'annotationStatus.exportFailed',
        defaultMessage: 'Export failed',
      }),
    },
    deleted: {
      color: 'danger',
      label: intl.formatMessage({
        id: 'annotationStatus.deleted',
        defaultMessage: 'Deleted',
      }),
    },
    reference: {
      color: 'success',
      label: intl.formatMessage({
        id: 'annotationStatus.reference',
        defaultMessage: 'Reference',
      }),
    },
  };

  const getMapAnnotationStatusList = (
    includeTestStatuses = false,
    availableOptions?: string[]
  ): Record<string, { color: string; label: string }> => {
    let options = {};

    if (availableOptions) {
      availableOptions.forEach(
        (option) => (options = { ...options, [option]: annotationStatusList[option] })
      );
    } else {
      options = { ...annotationStatusList };
    }

    if (includeTestStatuses) {
      options = { ...options, ...testingStatuses };
    }

    return options;
  };

  const getArrayAnnotationStatusList = (
    includeTestStatuses = false,
    availableOptions?: string[]
  ) => {
    return Object.entries(
      getMapAnnotationStatusList(includeTestStatuses, availableOptions)
    ).map(([statusId, statusDetails]) => ({ ...statusDetails, value: statusId }));
  };

  return {
    getArrayAnnotationStatusList: getArrayAnnotationStatusList,
    getMapAnnotationStatusList: getMapAnnotationStatusList,
  };
}
