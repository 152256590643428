import { EuiCallOut, EuiPanel, EuiResizableContainer, EuiText } from '@elastic/eui';
import { BottomBar } from 'components/BottomBar/BottomBar';
import { useAtom } from 'jotai';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDocument } from '../../../services/documents';
import { Translate } from '../../Internationalisation/translate';
import { ImageAnnotationList } from './ImageAnnotationList';
import { ImageBrowser } from './ImageBrowser';
import { PdfBrowser } from './PdfBrowser';
import { documentPanelResizeAtom } from './ResizeState';
import { SpreadsheetAnnotations } from './SpreadsheetAnnotation/SpreadsheetAnnotations';
import { SpreadsheetBrowser } from './SpreadsheetAnnotation/SpreadsheetBrowser';

export function DocumentViewer({
  focusedTableComponent,
  isDocumentProcessed,
  documentId,
}) {
  const { data: document, isLoading: documentIsLoading } = useDocument(documentId);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const collapseFn = useRef(null);
  const [documentPanelResized, setDocumentPanelResized] = useAtom(
    documentPanelResizeAtom
  );

  const [collapsedIdPanels, setCollapsedIdPanels] = useState({});

  const collapseTablePanel = useCallback(
    (value) => {
      onCollapse('tableAnnotationPanel', value);
      collapseFn.current();
    },
    [collapseFn]
  );

  useEffect(() => {
    if (document && collapseFn?.current) {
      collapseTablePanel(true);
    }
  }, [collapseFn, document]);
  const onCollapse = (optionId, value) => {
    const newToggleIdToSelectedMap = {
      ...collapsedIdPanels,
      [optionId]: value,
    };
    setCollapsedIdPanels(newToggleIdToSelectedMap);
  };

  useLayoutEffect(() => {
    if (!collapseFn?.current) {
      return;
    }

    if (focusedTableComponent && collapsedIdPanels.tableAnnotationPanel === true) {
      collapseTablePanel(false);
    } else if (
      !focusedTableComponent &&
      collapsedIdPanels.tableAnnotationPanel === false
    ) {
      collapseTablePanel(true);
    }
  }, [focusedTableComponent, collapseFn]);

  if (documentIsLoading) return null;
  let documentViewer;
  let documentViewerType;
  let getTransformedPdfContent = false;

  // TODO: change to real media types once changed in API
  switch (document.media_type) {
    // case 'application/pdf':
    case 'pdf':
      documentViewerType = 'pdf';
      break;
    case 'doc':
    case 'docx':
      documentViewerType = 'pdf';
      getTransformedPdfContent = true;
      break;
    case 'xlsx':
    case 'xls':
    case 'xlsm':
    case 'ods':
    case 'csv':
      // case 'application/vnd.ms-excel':
      // case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      // case 'text/csv':
      // case 'application/vnd.oasis.opendocument.spreadsheet':
      // case 'application/vnd.ms-excel.sheet.macroEnabled.12':
      documentViewerType = 'spreadsheet';
      break;
    case 'jpeg':
    case 'png':
      // case 'image/jpeg':
      // case 'image/png':
      documentViewerType = 'image';
      break;
    default:
      break;
  }

  if (isDocumentProcessed) {
    switch (documentViewerType) {
      case 'spreadsheet':
        documentViewer = (
          <SpreadsheetAnnotations style={{ height: '100%' }} documentId={documentId} />
        );
        break;
      default:
        documentViewer = <ImageAnnotationList />;
        break;
    }
  } else {
    switch (documentViewerType) {
      case 'pdf':
        documentViewer = (
          <PdfBrowser
            documentId={documentId}
            getTransformedPdfContent={getTransformedPdfContent}
          />
        );
        break;
      case 'spreadsheet':
        documentViewer = (
          <SpreadsheetBrowser style={{ height: '100%' }} documentId={documentId} />
        );
        break;
      case 'image':
        documentViewer = <ImageBrowser documentId={documentId} />;
        break;
      default:
        break;
    }
  }

  const unsupportedFormatError = (
    <EuiCallOut color="danger">
      <EuiText textAlign="center">
        <Translate
          id="error.unsupportedFileFormat"
          defaultMessage="Preview for files in this format is not supported."
        />
      </EuiText>
    </EuiCallOut>
  );

  return (
    <div
      style={{
        padding: '1em 1em 1em 1em',
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <EuiResizableContainer
        onToggleCollapsed={onCollapse}
        onPanelWidthChange={() => setDocumentPanelResized(documentPanelResized + 1)}
        style={{ height: '100%' }}
        direction="vertical"
        className="tableResizableContainer"
      >
        {(EuiResizablePanel, EuiResizableButton, { togglePanel }) => {
          collapseFn.current = () => {
            return togglePanel('tableAnnotationPanel', { direction: 'bottom' });
          };
          return (
            <>
              <EuiResizablePanel id="documentPanel" initialSize={55} minSize="30%">
                {documentViewer ? documentViewer : unsupportedFormatError}
              </EuiResizablePanel>
              <EuiResizableButton />
              <EuiResizablePanel
                id="tableAnnotationPanel"
                initialSize={45}
                paddingSize="s"
                minSize="0px"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                }}
              >
                <EuiPanel
                  hasShadow={false}
                  hasBorder={false}
                  paddingSize="s"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                  }}
                >
                  {focusedTableComponent}
                </EuiPanel>
              </EuiResizablePanel>
            </>
          );
        }}
      </EuiResizableContainer>
      <BottomBar documentViewerType={documentViewerType} document={document} />
    </div>
  );
}
