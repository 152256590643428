import { EuiTitle, useEuiTheme } from '@elastic/eui';
import React from 'react';
import { Translate } from '../../Internationalisation/translate';

export function OverlayTableGrid({ width, height, showDrawOverlay }) {
  const { euiTheme } = useEuiTheme();
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          margin: '1px',
          left: 0,
          border: `4px dashed ${euiTheme.colors.primary}`,
          width: `calc(${width}px - 2px)`,
          height: `calc(${height}px - 2px)`,
        }}
      />
      {showDrawOverlay && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            cursor: 'crosshair',
            backgroundColor: 'rgba(56,52,52,0.5)',
            width,
            height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EuiTitle style={{ color: 'rgb(223, 229, 239)' }} size="l">
            <h1>
              <Translate
                id="tableAnnotationConfig.overlay.drawAnnotationTable"
                defaultMessage="Draw table grid"
              />
            </h1>
          </EuiTitle>
        </div>
      )}
    </>
  );
}
