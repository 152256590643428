import {
  DropResult,
  EuiAccordion,
  EuiButtonIcon,
  EuiComboBox,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHighlight,
  EuiIcon,
  EuiPanel,
  EuiSelectableOption,
  EuiSpacer,
  EuiToolTip,
} from '@elastic/eui';
import Form from '@rjsf/core';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { getDatapointSchemas } from 'components/Annotation/utils';
import { userSettingsAtom } from 'components/Header/States/UserState';
import { QueryLoader } from 'components/InlineLoader/QueryLoader';
import { useTranslate } from 'components/Internationalisation/useTranslate';
import { Api } from 'Definitions/Api';
import { useAtom } from 'jotai';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AceEditor from 'react-ace';
import Frame from 'react-frame-component';
import { THEME_MODE } from 'settings/constants';
import { ThemeMode } from 'settings/ThemeMode';
import { ulid } from 'ulid';
import { Can, PERMISSION } from 'utils/permissions';
import { useSchemas } from './useSchemas';

require('ace-builds/src-noconflict/mode-json');
require('ace-builds/src-noconflict/theme-monokai');

export function SchemaHooks(props: {
  schemaContent: Api['SchemaContent'];
  hooksSchemaElementId: string;
  hookUris: string[];
  onChange: (hooks: string[]) => void;
}) {
  const schemaHook = useSchemas();
  const [userSettings] = useAtom(userSettingsAtom);
  const getHooksHook = schemaHook.useGetSchemaHooks(userSettings.language || 'en');
  const intl = useTranslate();
  const [hookUris, setHookUris] = useState(props.hookUris || []);

  useEffect(() => {
    props.onChange(hookUris);
  }, [hookUris]);

  const onHookDragEnd = (result: DropResult) => {
    result.destination &&
      setHookUris([
        ...euiDragDropReorder(hookUris, result.source.index, result.destination.index),
      ]);
  };

  const onDeleteHook = (index: number) => {
    setHookUris([...hookUris.slice(0, index), ...hookUris.slice(index + 1)]);
  };

  const onUpdateHook = useCallback(
    (index: number, hookUri: string) => {
      setHookUris((hooks) => {
        return [...hooks.slice(0, index), hookUri, ...hooks.slice(index + 1)];
      });
    },
    [setHookUris]
  );

  const datapointSchemas = useMemo(() => {
    if (!props.schemaContent) {
      return [];
    }
    return getDatapointSchemas(props.schemaContent);
  }, [props.schemaContent]);

  const hooksConfigs = getHooksHook.data?.hooks;
  const hooksOptions = useMemo(() => {
    if (!hooksConfigs) {
      return null;
    }
    return hooksConfigs.map((config) => {
      const hookSchema: RJSFSchema = JSON.parse(config.parameters_json_schema);
      return {
        id: config.id,
        label: hookSchema.title,
        value: { description: hookSchema.description },
      };
    });
  }, [hooksConfigs]);

  if (!getHooksHook.isSuccess) {
    return <QueryLoader queries={[getHooksHook]} />;
  }

  const renderOption = (option, searchValue, contentClassName) => {
    const { label, value } = option;
    return (
      <span className={contentClassName} title={value.description}>
        <EuiHighlight search={searchValue}>{label}</EuiHighlight>
      </span>
    );
  };

  const onAddHook = (options: EuiSelectableOption[]) => {
    const hookId = options[0].id;
    setHookUris((hookUris) => [...hookUris, hookId]);
  };

  const onAddCustomHook = (value: string) => {
    if (!value || !value.startsWith('https://')) {
      return;
    }
    setHookUris((hookUris) => [...hookUris, value]);
  };

  return (
    <EuiFlexGroup direction="column" gutterSize="m">
      <EuiFlexItem>
        <EuiDragDropContext onDragEnd={onHookDragEnd}>
          <EuiDroppable
            droppableId="HOOKS_DROP"
            spacing="l"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <>
              {hookUris.map((hookUri: string, index: number) => {
                const hookId = hookUri.split('?')[0];
                return (
                  <EuiDraggable
                    spacing="s"
                    key={`draggable-hook-${props.hooksSchemaElementId}-${index}-${hookId}`}
                    index={index}
                    draggableId={`draggable-hook-${index}-${hookId}`}
                    customDragHandle={true}
                  >
                    {(provided) => (
                      <EuiPanel paddingSize="m">
                        <EuiFlexGroup>
                          <EuiFlexItem grow={true}>
                            <EuiAccordion
                              id="hookConfig"
                              buttonContent={
                                <EuiFlexItem grow={false}>
                                  <span className="eui-textBreakWord">
                                    {hooksOptions.find((option) => option.id === hookId)
                                      ?.label || hookId}
                                  </span>
                                </EuiFlexItem>
                              }
                              paddingSize="s"
                              initialIsOpen={false}
                              extraAction={
                                <>
                                  <EuiFlexGroup>
                                    <Can I={PERMISSION.SCHEMAS_UPDATE}>
                                      <EuiFlexItem grow={false}>
                                        <EuiButtonIcon
                                          aria-label={intl.message.delete}
                                          iconType="trash"
                                          title={intl.message.delete}
                                          iconSize="m"
                                          color="text"
                                          onClick={() => onDeleteHook(index)}
                                        />
                                      </EuiFlexItem>
                                    </Can>
                                    <EuiFlexItem>
                                      <div
                                        {...provided.dragHandleProps}
                                        aria-label="Drag handle"
                                      >
                                        <EuiIcon type="grab" />
                                      </div>
                                    </EuiFlexItem>
                                  </EuiFlexGroup>
                                </>
                              }
                            >
                              <SchemaHook
                                hookUri={hookUri}
                                hookConfig={hooksConfigs.find((v) =>
                                  hookUri.startsWith(v.id)
                                )}
                                datapointSchemas={datapointSchemas}
                                hookSchemaElementId={props.hooksSchemaElementId}
                                index={index}
                                onChange={onUpdateHook}
                              />
                            </EuiAccordion>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPanel>
                    )}
                  </EuiDraggable>
                );
              })}
            </>
          </EuiDroppable>
        </EuiDragDropContext>
      </EuiFlexItem>
      <Can I={PERMISSION.SCHEMAS_UPDATE}>
        <EuiFlexItem grow>
          <EuiComboBox
            fullWidth={true}
            aria-label={intl.formatMessage({
              id: 'schemaHooks.add',
              defaultMessage: 'Add hook',
            })}
            placeholder={intl.formatMessage({
              id: 'schemaHooks.add',
              defaultMessage: 'Add hook',
            })}
            options={hooksOptions}
            onChange={onAddHook}
            isClearable={false}
            singleSelection
            renderOption={renderOption}
            data-test-subj="addHookComboBox"
            onCreateOption={onAddCustomHook}
            customOptionText={intl.formatMessage({
              id: 'schemaHooks.addExternalHook',
              defaultMessage: 'Add as external hook (use form `https://...`)',
            })}
          />
        </EuiFlexItem>
      </Can>
      <EuiSpacer />
    </EuiFlexGroup>
  );
}

type HookParametersType = Record<string, any>;

interface IHook {
  hookId: string;
  events: string[];
  messageSourceId: string;
  parameters: HookParametersType;
}

// Schema hook events are in form `annotation_content.<schema element ID>.<created|updated>`.
// `annotation_content` is optional. If present, remove it to normalize it for selector options.
// Also schema element ID is optional if it should be the same as ID of element
// where the hook is defined. Remove it to normalize it to `This datapoint ...` event option.
function preprocessEvents(events: string[], hookSchemaElementId: string) {
  return events.map((event) => {
    return event
      .replace('annotation_content.', '')
      .replace(`${hookSchemaElementId}.`, '');
  });
}

// Parse hook URI to hook object. For URI format see SchemaHookUri class in IDP core.
function parseHookUri(props: {
  hookUri: string;
  hookSchemaElementId: string;
  defaultEvents: string[];
}): IHook {
  const hookUriParts = props.hookUri.split('?', 2);
  let hookId = hookUriParts[0];
  const hookUriParams = hookUriParts[1];
  let parameters = {};
  let events: string[] = props.defaultEvents || [];
  let messageSourceId = null;

  if (!!hookUriParams) {
    const hookSearchParams = new URLSearchParams(hookUriParams);
    const parametersString = hookSearchParams.get('parameters');
    messageSourceId = hookSearchParams.get('source_id');
    const eventsParam = hookSearchParams.get('events');
    if (eventsParam) {
      events = eventsParam.split(',');
    }

    if (!parametersString) {
      parameters = {};
    } else {
      parameters = JSON.parse(parametersString);
    }

    // Parameter attributes can be also top level attributes in the URI query.
    // Add them all to parameters object.
    for (const [key, value] of hookSearchParams.entries()) {
      if (!['events', 'source_id', 'parameters'].includes(key)) {
        parameters[key] = value;
      }
    }
  }
  // Normalize all dummy hook IDs used in the past to real dummy hook ID.
  if (['', 'dummy_hook'].includes(hookId)) {
    hookId = 'dummy';
  }
  events = preprocessEvents(events, props.hookSchemaElementId);
  // Add unique source_id to each hook if not present.
  // Otherwise hook ID is used as message source and two hooks of the same type
  // would overwrite each other messages.
  if (!messageSourceId) {
    messageSourceId = `${hookId}#${ulid()}`;
  }

  return {
    hookId: hookId,
    events: events,
    messageSourceId: messageSourceId,
    parameters: parameters,
  };
}

// Transform hook back to its URI.
function hookToHookUri(hook: IHook): string {
  const queryParams = new URLSearchParams();
  const eventsParam = hook.events.join(',');
  !!hook.messageSourceId && queryParams.set('source_id', hook.messageSourceId);
  !!hook.events && queryParams.set('events', eventsParam);

  if (!!hook.parameters && !_.isEmpty(hook.parameters)) {
    const parametersJson = JSON.stringify(hook.parameters);
    queryParams.set('parameters', parametersJson);
  }

  const queryParamsString = queryParams.toString();
  let hookUri;
  if (!queryParamsString) {
    hookUri = hook.hookId;
  } else {
    hookUri = `${hook.hookId}?${queryParamsString}`;
  }
  return hookUri;
}

export function SchemaHook(props: {
  hookUri: string;
  hookConfig?: Api['SchemaHookConfig'];
  datapointSchemas: Api['SchemaDatapoint'][];
  hookSchemaElementId: string;
  onChange: (index: number, hookUri: string) => void;
  index: number;
}) {
  const [hook, setHook] = useState<IHook>();
  const hookUri = hook && hookToHookUri(hook);
  const defaultEvents = props.hookConfig?.default_events || [];
  const intl = useTranslate();

  useEffect(() => {
    const parsedHookUri = parseHookUri({
      hookUri: props.hookUri,
      defaultEvents: defaultEvents,
    });
    setHook(parsedHookUri);
  }, [props.hookUri, JSON.stringify(defaultEvents)]);

  useEffect(() => {
    if (hookUri) {
      props.onChange(props.index, hookUri);
    }
  }, [hookUri]);

  const onEventsChange = useCallback(
    (events) => {
      setHook((hook) => {
        return { ...hook, events: events };
      });
    },
    [setHook]
  );

  const onParametersFormChange = useCallback(
    (e) => {
      setHook((hook) => {
        return { ...hook, parameters: e.formData };
      });
    },
    [setHook]
  );

  const onJsonParametersChange = useCallback(
    (value) => {
      setHook((hook) => {
        return { ...hook, parameters: value };
      });
    },
    [setHook]
  );

  const onParametersFormSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  const uiSchema: UiSchema = {
    'ui:title': ' ',
    'ui:submitButtonOptions': { norender: true },
  };

  // https://rjsf-team.github.io/react-jsonschema-form/
  // https://github.com/rjsf-team/react-jsonschema-form/blob/114a0300def4a779a77b9dab6d226b08a62788f6/packages/playground/src/index.jsx#L24
  const formCssLink =
    THEME_MODE === ThemeMode.dark
      ? '//cdnjs.cloudflare.com/ajax/libs/bootswatch/3.3.6/darkly/bootstrap.min.css'
      : '//cdnjs.cloudflare.com/ajax/libs/bootswatch/3.3.6/flatly/bootstrap.min.css';

  if (!hook) {
    return null;
  }

  return (
    <EuiForm>
      <EuiFormRow
        fullWidth
        label={
          <EuiToolTip
            content={intl.formatMessage({
              id: 'schemaHooks.eventsTooltip',
              defaultMessage: 'Events when the hook should run.',
            })}
          >
            <span>
              {intl.formatMessage({
                id: 'schemaHooks.events',
                defaultMessage: 'Events',
              })}
              <EuiIcon type="questionInCircle" color="subdued" />
            </span>
          </EuiToolTip>
        }
      >
        <Events
          datapointSchemas={props.datapointSchemas}
          defaultEvents={hook.events}
          hookSchemaElementId={props.hookSchemaElementId}
          onChange={onEventsChange}
        />
      </EuiFormRow>
      <EuiFormRow
        fullWidth
        label={intl.formatMessage({
          id: 'schemaHooks.parameters',
          defaultMessage: 'Parameters',
        })}
      >
        <>
          {!props.hookConfig ? (
            <ParametersJsonEditor
              defaultHookParameters={hook.parameters}
              onValidParametersChange={onJsonParametersChange}
            />
          ) : (
            <Frame
              frameBorder="0"
              head={
                <>
                  <link rel="stylesheet" id="theme" href={formCssLink} />
                  <style>{`body {background-color: transparent; margin-right: 8px}`}</style>
                </>
              }
              // TODO: Style scrollbar with EUI or autofit iframe height so there is not scrollbar.
              // css={css`${useEuiScrollBar()}`}
              style={{
                width: '100%',
                height: '500px',
                border: 0,
              }}
            >
              <Form
                schema={JSON.parse(props.hookConfig.parameters_json_schema)}
                validator={validator}
                onChange={onParametersFormChange}
                formData={hook.parameters}
                onSubmit={onParametersFormSubmit}
                uiSchema={uiSchema}
              />
            </Frame>
          )}
        </>
      </EuiFormRow>
    </EuiForm>
  );
}

// Json editor for parameters of custom external hooks (where JSON schema is not available).
export function ParametersJsonEditor(props: {
  defaultHookParameters: HookParametersType;
  onValidParametersChange: (parameters: HookParametersType) => void;
  readOnly?: boolean;
}) {
  const defaultParametersJson = JSON.stringify(
    props.defaultHookParameters || {},
    null,
    2
  );
  const [parametersJson, setParametersJson] = useState(defaultParametersJson);
  const [isJsonValid, setIsJsonValid] = useState(true);

  const onParametersChange = (parametersJson: string) => {
    setParametersJson(parametersJson);
    try {
      const parameters = JSON.parse(parametersJson);
      setIsJsonValid(true);
      props.onValidParametersChange(parameters);
    } catch {
      setIsJsonValid(false);
    }
  };

  return (
    <AceEditor
      // https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
      mode="json"
      theme="monokai"
      // TODO: Tune styles to be in line with the rest and support light mode.
      style={{ backgroundColor: '#1D1E24' }}
      name="hook-parameters"
      width="100%"
      height="150px"
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      readOnly={props.readOnly}
      annotations={
        isJsonValid
          ? undefined
          : [{ row: 0, column: 0, type: 'error', text: 'Invalid JSON.' }]
      }
      value={parametersJson}
      onChange={onParametersChange}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  );
}

function Events(props: {
  datapointSchemas: Api['SchemaDatapoint'][];
  defaultEvents: string[];
  hookSchemaElementId: string;
  onChange: (events: string[]) => void;
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const intl = useTranslate();

  const options = useMemo(() => {
    const datapointIdsWithLabels = [
      {
        id: 'created',
        label: intl.formatMessage({
          id: 'schemaHooks.thisDatapointCreated',
          defaultMessage: 'This datapoint created',
        }),
      },
      {
        id: 'updated',

        label: intl.formatMessage({
          id: 'schemaHooks.thisDatapointUpdated',
          defaultMessage: 'This datapoint updated',
        }),
      },
    ];
    props.datapointSchemas.forEach((schema) => {
      if (schema.id === props.hookSchemaElementId) {
        return;
      }
      datapointIdsWithLabels.push({
        id: `${schema.id}.created`,
        label: `${schema.label} ${intl.formatMessage({
          id: 'schemaHooks.created',
          defaultMessage: 'created',
        })}`,
      });
      datapointIdsWithLabels.push({
        id: `${schema.id}.updated`,
        label: `${schema.label} ${intl.formatMessage({
          id: 'schemaHooks.updated',
          defaultMessage: 'updated',
        })}`,
      });
    });

    const options = datapointIdsWithLabels.map((datapoint) => {
      return { id: datapoint.id, label: datapoint.label };
    });

    return options;
  }, [props.datapointSchemas, props.hookSchemaElementId]);

  useEffect(() => {
    const defaultEvents = preprocessEvents(
      props.defaultEvents,
      props.hookSchemaElementId
    );
    let defaultEventsOptions = [];
    defaultEvents.forEach((event) => {
      const knownOption = options.find((option) => option.id === event);
      if (knownOption) {
        defaultEventsOptions.push(knownOption);
      } else {
        defaultEventsOptions.push({
          id: event,
          label: `${event} (${intl.formatMessage({
            id: 'schemaHooks.unknownEvent',
            defaultMessage: 'unknown event',
          })})`,
        });
      }
    });
    setSelectedOptions(defaultEventsOptions);
  }, [JSON.stringify(props.defaultEvents)]);

  const onChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const eventIds = selectedOptions.map((option) => option.id);
    props.onChange(eventIds);
  };

  return (
    <>
      <EuiComboBox
        fullWidth
        aria-label={intl.formatMessage({
          id: 'schemaHooks.selectEvents',
          defaultMessage: 'Select events',
        })}
        placeholder={intl.formatMessage({
          id: 'schemaHooks.selectEvents',
          defaultMessage: 'Select events',
        })}
        options={options}
        selectedOptions={selectedOptions}
        onChange={onChange}
        isClearable={false}
        data-test-subj="eventsComboBox"
      />
    </>
  );
}
