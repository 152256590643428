import { Ability, AbilityBuilder } from '@casl/ability';
import { useAbility } from '@casl/react';
import React, { createContext, useEffect, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { RoutePath } from '../routes';
import { useUsers } from '../services/users';

export const AbilityContext = createContext();

/**
 * Similar to `Can = createContextualCan(AbilityContext.Consumer);` but with `fallback`.
 * See: https://github.com/stalniy/casl/tree/master/packages/casl-react#bind-can-to-a-particular-ability-instance
 */
export function Can({ children, I, fallback = null }) {
  const ability = useAbility(AbilityContext);
  return <>{ability.can(I) ? children : fallback}</>;
}

/** See: https://github.com/stalniy/casl/tree/master/packages/casl-react#imperative-access-to-ability-instance */
export function useCan() {
  return useAbility(AbilityContext);
}

function useParamOutsideRouter() {
  const routes = [
    { path: `${RoutePath.FOLDER}` },
    { path: `${RoutePath.FOLDER}/${RoutePath.DOCUMENTS}` },
    { path: `${RoutePath.FOLDER}/${RoutePath.DASHBOARD}` },
    { path: `${RoutePath.FOLDER}/${RoutePath.ANNOTATIONS}` },
    { path: `${RoutePath.FOLDER}/${RoutePath.SKILL_MONITORING}` },
    { path: `${RoutePath.FOLDER}/${RoutePath.SCHEMAS}` },
    { path: `${RoutePath.FOLDER}/${RoutePath.SCHEMAS_BASE}` },
    {
      path: `${RoutePath.FOLDER}/${RoutePath.SCHEMAS_BASE}/${RoutePath.REFERENCE_DATA_BASE}`,
    },
    {
      path: `${RoutePath.FOLDER}/${RoutePath.SCHEMAS_BASE}/${RoutePath.REFERENCE_DATA}`,
    },
  ];

  const useCurrentParams = () => {
    const location = useLocation();
    const foundRoutes = matchRoutes(routes, location);

    if (foundRoutes) {
      return foundRoutes[0].params;
    }
    return undefined;
  };

  return useCurrentParams();
}

/** Get global ability instance configured from user permissions. */
export function useConfiguredAbility() {
  const { useGetCurrentUser, useGetUserPermissions } = useUsers();

  const params = useParamOutsideRouter();
  const selectedFolderId = params ? params?.folderId : undefined;

  const { data: permissions, isLoading: isLoadingPermissions } = useGetUserPermissions({
    // When no folder selected yet (after the first login), we get permissions
    // for the oldest accessible folder.
    folderId: !selectedFolderId ? null : selectedFolderId,
  });
  // Preload current user with settings so it's available in child components.
  const { isLoading: isLoadingUser } = useGetCurrentUser();
  const [ability, setAbility] = useState();

  useEffect(() => {
    const { can, rules } = new AbilityBuilder(Ability);
    permissions?.permissions?.forEach((permission) => {
      can(permission);
    });
    const new_ability = new Ability(rules);
    setAbility(new_ability);
  }, [permissions]);

  // Ability rules are updated asynchronously, so we have to check they are already loaded.
  const isLoading = !(ability?.$.length > 0) || isLoadingPermissions || isLoadingUser;

  // Without selected folder return empty ability. Not authorized page with info message
  // to select folder or ask for permissions will be shown.
  if (!selectedFolderId) return { ability: ability, isLoading: isLoadingUser };

  return { ability: ability, isLoading: isLoading };
}

/** Values are permission IDs defined in API and has to be in sync with API. */
export const PERMISSION = {
  DOCUMENTS_READ: 'documents/read',
  DOCUMENTS_UPDATE: 'documents/update',
  DOCUMENTS_IMPORT: 'documents/import',
  DOCUMENTS_PROCESS: 'documents/process',
  DOCUMENTS_TRASH: 'documents/trash',
  DOCUMENTS_SPLIT: 'documents/split',
  ANNOTATIONS_READ: 'annotations/read',
  ANNOTATIONS_UPDATE: 'annotations/update',
  SCHEMAS_CREATE: 'schemas/create',
  SCHEMAS_READ: 'schemas/read',
  SCHEMAS_UPDATE: 'schemas/update',
  SCHEMAS_DELETE: 'schemas/delete',
  SKILL_VERSIONS_READ: 'skill_versions/read',
  SKILL_VERSIONS_CREATE: 'skill_versions/create',
  SKILL_VERSIONS_UPDATE: 'skill_versions/update',
  SKILL_VERSIONS_DELETE: 'skill_versions/delete',
  REFERENCE_DATA_READ: 'reference_data/read',
  REFERENCE_DATA_CREATE: 'reference_data/create',
  REFERENCE_DATA_UPDATE: 'reference_data/update',
  REFERENCE_DATA_DELETE: 'reference_data/delete',
  EXTRACTORS_CREATE: 'extractors/create',
  FOLDERS_CREATE: 'folders/create',
  FOLDERS_DELETE: 'folders/delete',
  FOLDERS_UPDATE: 'folders/update',
  HOOKS_READ: 'hooks/read',
  HOOKS_CREATE: 'hooks/create',
  HOOKS_DELETE: 'hooks/delete',
  HOOKS_UPDATE: 'hooks/update',
  HOOK_DELIVERIES_READ: 'hook_deliveries/read',
  PERMISSIONS_READ: 'permissions/read',
  PERMISSIONS_CREATE: 'permissions/create',
  PERMISSIONS_DELETE: 'permissions/delete',
  PERMISSIONS_UPDATE: 'permissions/update',
};
