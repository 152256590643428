import { EuiHeaderBreadcrumbs } from '@elastic/eui';
import { useFoldersTree } from 'components/Folders/Folders';
import React from 'react';
import { Link } from 'react-router-dom';

export function HeaderFoldersNavigation() {
  const { selectedFolderWithParents } = useFoldersTree();

  if (!selectedFolderWithParents) return null;

  const breadcrumbs = selectedFolderWithParents.map((folder) => {
    return {
      text: (
        <Link className="breadcrumb-link" to={`/folders/${folder.id}/documents`}>
          {folder.name}
        </Link>
      ),
    };
  });

  return (
    <EuiHeaderBreadcrumbs
      aria-label="Folders navigation"
      truncate={true}
      responsive={{ xs: 1, s: 1, m: 1, l: 2, xl: 3 }}
      breadcrumbs={breadcrumbs}
      style={{ marginLeft: '8px' }}
    />
  );
}
