import { redirectToLogin } from '../utils/auth';
import client from '../utils/client';
import { deleteCookie } from '../utils/state';
import { getIdpAuthEndpoint } from './endpoints';

export function refreshAccessToken() {
  console.log('refreshing token');
  return client(`${getIdpAuthEndpoint()}/refresh_token`, {
    refreshToken: true,
    method: 'POST',
  }).then((data) => {
    localStorage.setItem('access_token_expires_at', data['access_token_expires_at']);
  });
}
export function login(username, password) {
  console.log('logging in');
  let formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  return client(`${getIdpAuthEndpoint()}/login`, {
    body: formData,
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    onlyAuthorized: false,
  });
}

export function localLogout() {
  console.log('deleting cookies with CSRF tokens');
  // HTTP only cookies with tokens can be deleted only by server. If server is not
  // accessible (e.g. offline or when it is down), these cookies with tokens cannot be
  // removed. But API requires also CSRF tokens for all endpoints (also GET methods)
  // so removing at least CSRF tokens in non HTTP only cookies prevents user from
  // authenticating.
  deleteCookie('csrf_access_token');
  deleteCookie('csrf_refresh_token');
  localStorage.removeItem('access_token_expires_at');
  localStorage.removeItem('refresh_token_expires_at');
  redirectToLogin();
}

export function logout() {
  console.log('logging out');
  client(`${getIdpAuthEndpoint()}/logout`, { method: 'POST' }).finally(() => {
    localLogout();
  });
}
