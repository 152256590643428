import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPage,
  EuiPanel,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getSystemLogs } from '../../services/endpoints';
import client from '../../utils/client';
import { Translate } from '../Internationalisation/translate';

export function ErrorComponent({
  error,
}: {
  error: Error;
  resetErrorBoundary: () => {};
}) {
  const navigate = useNavigate();

  const isRequestError =
    error?.response?.status >= 400 && error?.response?.status < 500;
  const isServerError = error?.response?.status >= 500;
  const isRuntimeError = !isRequestError && !isServerError;
  const resetNavigation = () => {
    navigate(0);
  };
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return;
    }
    let body;
    if (isServerError) {
      body = {
        message: Array.isArray(error.detail) ? error.detail[0].msg : error.detail,
        level: 'critical',
        context: { error: error, location: window.location.href },
      };
    }
    if (isRuntimeError) {
      body = {
        message: error.message,
        level: 'critical',
        context: {
          error: error.toString(),
          stackTrace: error.stack,
          location: window.location.href,
        },
      };
    }

    body &&
      client(getSystemLogs(), {
        body,
        method: 'POST',
        onlyAuthorized: false,
      }).catch((err) => console.log(err));
  }, [error]);

  const closeModal = () => setIsDetailsModalVisible(false);
  const showModal = () => setIsDetailsModalVisible(true);
  const getErrorMessage = (error) => {
    if (error.message && typeof error.message === 'string') {
      return error.message;
    }

    if (Array.isArray(error.detail)) {
      return error.detail[0]?.msg;
    } else {
      return error.detail;
    }
  };

  const canShowRefreshButton = () => {
    if (isRuntimeError) {
      return true;
    }
    return error?.response?.status === 503 || error?.response?.status === 504;
  };

  let modal;

  if (isDetailsModalVisible) {
    modal = (
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>
              {isRequestError && (
                <Translate
                  id="error.requestError"
                  defaultMessage="Request error {errorCode}"
                  values={{
                    errorCode: error.response.status,
                  }}
                />
              )}
              {isServerError && (
                <Translate
                  id="error.serverError"
                  defaultMessage="Server error {errorCode}"
                  values={{
                    errorCode: error.response.status,
                  }}
                />
              )}
              {isRuntimeError && (
                <Translate id="error.runTimeError" defaultMessage="Runtime error" />
              )}
            </h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiPanel
            hasBorder={true}
            hasShadow={true}
            style={{ whiteSpace: 'pre-wrap' }}
          >
            <EuiFlexGroup direction="column">
              <EuiFlexItem>
                {(isRequestError || isServerError) && getErrorMessage(error)}
                {isRuntimeError && error.message}
              </EuiFlexItem>
              {isRuntimeError && <EuiFlexItem>{error.stack}</EuiFlexItem>}
            </EuiFlexGroup>
          </EuiPanel>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton onClick={closeModal} fill>
            <Translate id="button.close" defaultMessage="Close"></Translate>
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    <EuiPage>
      <EuiFlexGroup
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ paddingTop: '15vh' }}
      >
        <EuiFlexItem>
          <EuiIcon type="alert" size="xxl" color="danger"></EuiIcon>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size="m">
            <h2>
              <Translate id="error.title" defaultMessage="Something went wrong" />
            </h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <Translate
              id="error.details"
              defaultMessage="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
            />
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem></EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup>
            {canShowRefreshButton() && (
              <EuiFlexItem
                grow={false}
                style={{ alignItems: 'center', display: 'flex' }}
              >
                <EuiButtonEmpty onClick={resetNavigation}>
                  <Translate id="error.button.restart" defaultMessage="Try again" />
                </EuiButtonEmpty>
              </EuiFlexItem>
            )}
            <EuiFlexItem>
              <EuiButtonEmpty onClick={showModal}>
                <Translate id="error.button.details" defaultMessage="Details" />
              </EuiButtonEmpty>
              {modal}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPage>
  );
}
