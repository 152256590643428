// Based on: https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import { QueryClient } from 'react-query';
import { useKeycloakStore } from 'utils/useGlobalSettings';
import { localLogout } from '../services/auth';
import { API_URL } from '../settings/constants';
import {
  checkIfAccessTokenValidAndTryRefresh,
  isKeycloakUsed,
  redirectToLogin,
} from './auth';
import { getCookie } from './state';

export default function client(
  endpoint,
  {
    body = undefined,
    method = '',
    onlyAuthorized = true,
    refreshToken = false,
    ...customConfig
  } = {}
) {
  let csrfHeader = {};
  let authorizationHeader = {};
  if (isKeycloakUsed()) {
    const jwt = useKeycloakStore.getState().token;
    authorizationHeader = { Authorization: `Bearer ${jwt}` };
  }
  if (refreshToken) {
    if (!isKeycloakUsed()) {
      csrfHeader = { 'X-CSRF-TOKEN': getCookie('csrf_refresh_token') };
    }
  } else if (onlyAuthorized) {
    const tokenValid = checkIfAccessTokenValidAndTryRefresh();
    if (!isKeycloakUsed()) {
      csrfHeader = { 'X-CSRF-TOKEN': getCookie('csrf_access_token') };
    }

    if (!tokenValid) {
      // We only redirect user to login if token expired.
      // We don't call logout endpoint as it requires token
      // and we can leave the expired tokens in cookies.
      console.log('tokens not valid, redirecting to login');
      redirectToLogin();
      return;
    }
  }

  const headers = customConfig.headers === undefined && {
    'content-type': 'application/json',
  };
  const config = {
    method: method ? method : body ? 'POST' : 'GET',
    credentials: 'include',
    ...customConfig,
    headers: {
      ...csrfHeader,
      ...authorizationHeader,
      ...headers,
    },
  };
  if (body && headers['content-type'] === 'application/json') {
    config.body = JSON.stringify(body);
  } else {
    config.body = body;
  }
  let data;
  const endpoint_url = endpoint.startsWith('api/')
    ? `${API_URL}/${endpoint}`
    : endpoint;
  return window.fetch(endpoint_url, config).then(async (response) => {
    const contentType = response.headers.get('content-type');
    switch (contentType) {
      case 'application/json':
        data = await response.json();
        break;
      default:
        data = await response.blob();
        break;
    }

    if (response.status === 401) {
      // TODO: Emit toast message.
      const tokenValid = checkIfAccessTokenValidAndTryRefresh();
      if (!tokenValid) {
        console.log('unauthorized request');
        localLogout();
        return Promise.reject(data);
      }
    } else if (
      response.status === 422 &&
      data.message === 'Signature verification failed'
    ) {
      console.log('JWT signature verification failed');
      redirectToLogin();
      return;
    }

    if (response.ok) {
      return data;
    } else {
      data = {
        ...data,
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      };
      return Promise.reject(data);
    }
  });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }) => {
        return await client(queryKey[0]);
      },
      // We don't want to refetch automatically in general, only on our request.
      staleTime: Infinity,
      // If automatic refetch enabled, we still don't want to refetch on window focus.
      refetchOnWindowFocus: false,
    },
  },
});
