import * as lodashJoins from 'lodash-joins';

export function getItemByUrl(items, url) {
  return items.find((item) => item.url === url);
}

// User attributes in API responses are only URLs to corresponding user resources.
// When displaying in UI, we need to replace them with their names.
export function replaceUserUrlsByNames(data, userAttributeName, users) {
  // Do not apply on already transformed data.
  data.forEach((item) => {
    const user = getItemByUrl(users, item[userAttributeName]);
    if (user) {
      item[userAttributeName] = user.name;
    }
  });
}

export function addDatapointValues(annotationValues, annotations, data) {
  if (annotationValues) {
    data.forEach((document) => {
      const foundAnnotations = annotations.filter(
        (annotation) => annotation.document_id === document.id
      );

      const joinedAnnotations = lodashJoins.hashInnerJoin(
        annotationValues,
        (annotationValue) => annotationValue.annotation_url,
        foundAnnotations,
        (foundAnnotation) => foundAnnotation.url
      );

      joinedAnnotations.forEach((annotation) => {
        document[annotation.schema_element_id] = annotation.normalized_value;
      });
    });
  }
}
