import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { ulid } from 'ulid';
import { DocumentView } from '../../../Definitions/DocumentView';
import { FilterValues } from '../../../Definitions/FilterValues';

export interface UserConfigs {
  language?: string;
  oldestAccessibleFolderId?: string;
  documentViews: DocumentView[];
}

export const userSettingsAtom = atomWithStorage<UserConfigs>('userConfig', {
  language: 'en',
  documentViews: [],
});

// In order to do proper extraction of translatable string, we require a specific pattern. Outside of react state this is requires adding an additional
// function for pattern purposes
const intl = {
  formatMessage: (data: { id: string; defaultMessage: string }) => data,
};

export const defaultDocumentViews = atom<DocumentView[]>([
  {
    id: ulid(),
    name: intl.formatMessage({ id: 'documentView.all', defaultMessage: 'All' }),
    filterValues: {},
    savedFilterValues: {},
    selected: true,
    position: 0,
    isDefault: true,
    visible: true,
    fixed: true,
  },
  {
    id: ulid(),
    name: intl.formatMessage({
      id: 'documentView.unprocessed',
      defaultMessage: 'Unprocessed',
    }),
    filterValues: {},
    savedFilterValues: { annotationStatuses: ['processing', 'processing_failed'] },
    selected: false,
    position: 1,
    isDefault: true,
    visible: true,
    fixed: false,
  },
  {
    id: ulid(),
    name: intl.formatMessage({ id: 'documentView.review', defaultMessage: 'Review' }),
    filterValues: {},
    savedFilterValues: {
      annotationStatuses: ['to_review', 'in_review', 'to_classify'],
    },
    selected: false,
    position: 2,
    isDefault: true,
    visible: true,
    fixed: false,
  },
  {
    id: ulid(),
    name: intl.formatMessage({
      id: 'documentView.approved',
      defaultMessage: 'Approved',
    }),
    filterValues: {},
    savedFilterValues: { annotationStatuses: ['approved'] },
    selected: false,
    position: 3,
    isDefault: true,
    visible: true,
    fixed: false,
  },
  {
    id: ulid(),
    name: intl.formatMessage({ id: 'documentView.export', defaultMessage: 'Export' }),
    filterValues: {},
    savedFilterValues: {
      annotationStatuses: ['to_export', 'exported', 'export_failed'],
    },
    selected: false,
    position: 4,
    isDefault: true,
    visible: true,
    fixed: false,
  },
  {
    id: ulid(),
    name: intl.formatMessage({
      id: 'documentView.postponed',
      defaultMessage: 'Postponed',
    }),
    filterValues: {},
    savedFilterValues: { annotationStatuses: ['postponed'] },
    selected: false,
    position: 5,
    isDefault: true,
    visible: true,
    fixed: false,
  },
  {
    id: ulid(),
    name: intl.formatMessage({
      id: 'documentView.rejected',
      defaultMessage: 'Rejected',
    }),
    filterValues: {},
    savedFilterValues: { annotationStatuses: ['rejected'] },
    selected: false,
    position: 6,
    isDefault: true,
    visible: true,
    fixed: false,
  },
]);

export const documentViews = atom(
  (get) => {
    const defaultDocumentsViews = (get(defaultDocumentViews) || []).map((view) => ({
      ...view,
      filterValues: { ...view.filterValues, ...view.savedFilterValues },
    }));

    const userSettings = get(userSettingsAtom);

    return userSettings.documentViews.length
      ? userSettings.documentViews
      : defaultDocumentsViews;
  },

  (get, set, { documentViews: newViews }: UserConfigs) => {
    const selectedView = newViews.find((view) => view.selected);

    const selectDefaultView = (): DocumentView[] => {
      return newViews.map((view) => {
        if (view.fixed) {
          return { ...view, selected: true };
        }
        return { ...view, selected: false };
      });
    };

    const selectLocalView = (selectedViewId: string): DocumentView[] => {
      return newViews.map((view) => {
        if (view.id === selectedViewId) {
          return { ...view, selected: true };
        }
        return { ...view, selected: false };
      });
    };
    const userSettings = get(userSettingsAtom);

    if (!selectedView) {
      const selectedView = userSettings.documentViews.find(
        (view) => view.selected
      ) as DocumentView;
      const isPresent = newViews.find((newView) => newView.id === selectedView?.id);

      if (isPresent) {
        newViews = selectLocalView(selectedView.id);
      } else {
        newViews = selectDefaultView();
      }
    }

    set(userSettingsAtom, { ...userSettings, documentViews: newViews });
  }
);

export const currentFilterValues = atom<FilterValues>({});
export const pageIndexAtom = atom<number>(0);
export const documentListPreferencesAtom = atomWithStorage(
  'documentListPreferences',
  {}
);
