import client from '../utils/client';
import {
  getGridAnnotationItemEndpoint,
  getGridContentAnnotationItemEndpoint,
} from './endpoints';

export function updateGridContent(annotationId, annotationItemId, gridId) {
  return client(
    getGridContentAnnotationItemEndpoint(annotationId, annotationItemId, gridId),
    { method: 'PUT' }
  );
}

export function deleteGridContent(annotationId, annotationItemId, gridId) {
  return client(
    getGridContentAnnotationItemEndpoint(annotationId, annotationItemId, gridId),
    { method: 'DELETE' }
  );
}

export function detectGrid(annotationId, annotationItemId, gridId) {
  return client(getGridAnnotationItemEndpoint(annotationId, annotationItemId, gridId), {
    method: 'PUT',
  });
}
