import { atom } from 'jotai';

// https://docs.pmnd.rs/jotai/guides/persistence#a-helper-function-with-local-storage-and-json-parse
export function atomWithLocalStorage(key, initialValue) {
  const getInitialValue = () => {
    const item = localStorage.getItem(key);
    if (item !== null) {
      try {
        return JSON.parse(item);
      } catch {
        console.log('could not parse value from local storage');
        return undefined;
      }
    }
    return initialValue;
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      nextValue !== null &&
        nextValue !== undefined &&
        localStorage.setItem(key, JSON.stringify(nextValue));
    }
  );
  return derivedAtom;
}
