import {
  EuiAccordion,
  EuiButton,
  EuiButtonIcon,
  EuiComboBox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSelect,
} from '@elastic/eui';
import { Api } from 'Definitions/Api';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { EnumChoice } from '../../../Definitions/ApiTypes';
import { DatapointType } from '../../../Definitions/DatapointType';
import { EditSchemaElementIdModal } from './SchemaBuilder';
import { SchemaHooks } from './SchemaHooks';

export const NestedDatapoint = (props: {
  index: number;
  id: string;
  label: string;
  type: DatapointType;
  sources: string[];
  hooks: string[];
  schemaContent: Api['SchemaContent'];
  enumChoices?: Array<EnumChoice & { localId: string }>;
  onIdChange: (index: number, value: string) => void;
  onLabelChange: (index: number, value: string) => void;
  onTypeChange: (index: number, value: DatapointType) => void;
  onEnumChoiceValueChange: (
    datapointIndex: number,
    choiceLocalId: string,
    value: string
  ) => void;
  onEnumChoiceLabelChange: (
    datapointIndex: number,
    choiceLocalId: string,
    value: string
  ) => void;
  onAddEnumChoice: (datapointIndex: number) => void;
  onRemoveEnumChoice: (datapointIndex: number, choiceId: string) => void;
  onSourceChange: (index: number, selectedOptions: string[]) => void;
  onCreateSource: (index: number, value: string) => void;
  onHooksChange: (datapointIndex: number, hookUris: string[]) => void;
  schemaId: string;
  closeFlyout: () => void;
  isEditing: boolean;
}) => {
  const {
    index,
    id,
    label,
    type,
    enumChoices,
    sources,
    hooks,
    schemaContent,
    onIdChange,
    onLabelChange,
    onTypeChange,
    onEnumChoiceLabelChange,
    onEnumChoiceValueChange,
    onAddEnumChoice,
    onRemoveEnumChoice,
    onCreateSource,
    onSourceChange,
    onHooksChange,
    schemaId,
    closeFlyout,
    isEditing,
  } = props;
  const [
    isVisibleEditSchemaElementIdModal,
    setIsVisibleEditSchemaElementIdModal,
  ] = useState(false);
  const intl = useIntl();

  function handleRemoveDatapointEnumChoice(choiceId: string) {
    onRemoveEnumChoice(index, choiceId);
  }

  function addNewEnumChoice() {
    onAddEnumChoice(index);
  }

  function handleDatapointIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    onIdChange(index, e.target.value);
  }

  function handleDatapointLabelChange(e: React.ChangeEvent<HTMLInputElement>) {
    onLabelChange(index, e.target.value);
  }

  function handleDatapointTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    onTypeChange(index, event.target.value as DatapointType);
  }

  function handleDatapointEnumValueChange(
    e: React.ChangeEvent<HTMLInputElement>,
    choiceId: string
  ) {
    onEnumChoiceValueChange(index, choiceId, e.target.value);
  }

  function handleDatapointEnumLabelChange(
    e: React.ChangeEvent<HTMLInputElement>,
    choiceId: string
  ) {
    onEnumChoiceLabelChange(index, choiceId, e.target.value);
  }

  function handleSourcesChange(selectedOptions: Array<{ label: string }>) {
    onSourceChange(
      index,
      selectedOptions.map((source) => source.label)
    );
  }

  function handleCreateSource(inputString: string) {
    onCreateSource(index, inputString);
  }

  function handleHooksChange(hookUris: string[]) {
    onHooksChange(index, hookUris);
  }

  return (
    <EuiForm component="form">
      {isVisibleEditSchemaElementIdModal && (
        <EditSchemaElementIdModal
          schemaId={schemaId}
          elementId={id}
          onClose={() => setIsVisibleEditSchemaElementIdModal(false)}
          onSuccess={closeFlyout}
        />
      )}
      <EuiFormRow
        label="ID"
        labelAppend={
          isEditing && (
            <EuiButtonIcon
              iconType="pencil"
              title={intl.formatMessage({ id: 'button.edit', defaultMessage: 'Edit' })}
              onClick={() => setIsVisibleEditSchemaElementIdModal(true)}
            />
          )
        }
      >
        <EuiFieldText
          name={`nested-datapoint-id-${index}`}
          disabled={isEditing}
          value={id}
          onChange={handleDatapointIdChange}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'nestedDatapoint.label',
          defaultMessage: 'Label',
        })}
      >
        <EuiFieldText
          name={`nested-datapoint-label-${index}`}
          value={label}
          onChange={handleDatapointLabelChange}
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'nestedDatapoint.type',
          defaultMessage: 'Type',
        })}
      >
        <EuiSelect
          id={`nested-datapoint-label-${index}`}
          options={[
            { value: DatapointType.String, text: 'String' },
            { value: DatapointType.Date, text: 'Date' },
            { value: DatapointType.Datetime, text: 'Datetime' },
            { value: DatapointType.Number, text: 'Number' },
            { value: DatapointType.Enum, text: 'Enum' },
          ]}
          value={type}
          onChange={handleDatapointTypeChange}
          aria-label="Select datapoint type"
        />
      </EuiFormRow>

      {type === DatapointType.Enum && (
        <EuiFormRow>
          <EuiAccordion
            id={`datapoint-enum-type-configuration-${index}`}
            buttonContent="Configure choices"
            paddingSize="l"
          >
            {!!enumChoices?.length &&
              enumChoices.map((choice, index) => {
                return (
                  <EuiFlexGroup gutterSize="s" key={`datapoint-enum-choice-${index}`}>
                    <EuiFlexItem grow={5}>
                      <EuiFieldText
                        placeholder={intl.formatMessage({
                          id: 'nestedDatapoint.value',
                          defaultMessage: 'Value',
                        })}
                        value={choice.value}
                        onChange={(e) =>
                          handleDatapointEnumValueChange(e, choice.localId)
                        }
                        aria-label="Set value of datapoint enum item"
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={5}>
                      <EuiFieldText
                        placeholder={intl.formatMessage({
                          id: 'nestedDatapoint.label',
                          defaultMessage: 'Label',
                        })}
                        value={choice.label}
                        onChange={(e) =>
                          handleDatapointEnumLabelChange(e, choice.localId)
                        }
                        aria-label="Set label of datapoint enum item"
                      />
                    </EuiFlexItem>
                    <EuiFlexItem
                      grow={1}
                      style={{ justifyContent: 'center', alignItems: 'flex-end' }}
                    >
                      <EuiButtonIcon
                        aria-label="Remove datapoint enum item"
                        iconType="trash"
                        title={intl.formatMessage({
                          id: 'button.delete',
                          defaultMessage: 'Delete',
                        })}
                        iconSize="m"
                        color="text"
                        onClick={() => handleRemoveDatapointEnumChoice(choice.localId)}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                );
              })}

            <EuiFlexGroup>
              <EuiFlexItem grow={true}>
                <EuiButton
                  fullWidth
                  iconType="plusInCircleFilled"
                  size="s"
                  aria-label="Add choice"
                  title={intl.formatMessage({
                    id: 'nestedDatapoint.button.addChoice',
                    defaultMessage: 'Add choice',
                  })}
                  onClick={addNewEnumChoice}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiAccordion>
        </EuiFormRow>
      )}

      <EuiFormRow
        label={intl.formatMessage({
          id: 'nestedDatapoint.sources',
          defaultMessage: 'Sources',
        })}
      >
        <EuiComboBox
          placeholder={intl.formatMessage({
            id: 'nestedDatapoint.selectSource',
            defaultMessage: 'Select source',
          })}
          options={[{ label: `trained/${id}` }]}
          selectedOptions={sources?.map((source) => ({ label: source })) || []}
          onChange={handleSourcesChange}
          onCreateOption={handleCreateSource}
          isClearable={true}
          className="eui-textBreakAll"
        />
      </EuiFormRow>

      <EuiFormRow
        label={intl.formatMessage({
          id: 'nestedDatapoint.hooks',
          defaultMessage: 'Hooks',
        })}
      >
        <SchemaHooks
          schemaContent={schemaContent}
          hooksSchemaElementId={id}
          hookUris={hooks}
          onChange={handleHooksChange}
        />
      </EuiFormRow>
    </EuiForm>
  );
};
