import { useAtom } from 'jotai';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { detectGrid } from 'services/tableGrid';
import { ulid } from 'ulid';
import { v4 as uuidv4 } from 'uuid';
import { invalidateAnnotationContent } from '../../../services/documents';
import { useAnnotationStore } from '../Annotation';
import { annotationItemIdAtom } from '../AnnotationState';
import { pageScaleAtom } from '../DocumentPanel/ImageBrowser';
import { useGetFilteredDatapoints } from '../useGetFilteredDatapoints';
import { InitialTableGrid } from './InitialTableGrid';
import { MainTableGrid } from './MainTableGrid';
import { OverlayTableGrid } from './OverlayTableGrid';
import { getGridSetFromTableGrid, sortTableGridLines } from './utils';

export const ScaledTableGridContext = createContext(null);

export function TableGridContainer({
  pageWidth,
  pageIndex,
  showInitialTableAnnotation,
  showMainTableAnnotation,
  showDrawOverlay,
  setShowMainTableAnnotation,
  originalImageSize,
  annotationId,
}) {
  const annotationSchema = useAnnotationStore((state) => state.schema);
  const [columnSchemasOptions, setColumnSchemasOptions] = useState(undefined);
  const [scale] = useAtom(pageScaleAtom);
  const annotationStore = useAnnotationStore();
  const [annotationItemId] = useAtom(annotationItemIdAtom);
  const [scaledTableGrid, setScaledTableGrid] = useState();
  const [currentGridSet, setCurrentGrid] = useState(undefined);
  const { getSectionElementById } = useGetFilteredDatapoints();
  const annotationItem = getSectionElementById(annotationItemId);

  const containerWidth = pageWidth;
  const containerHeight =
    (originalImageSize.height * pageWidth) / originalImageSize.width;

  const gridSetsAreDifferent = (newGridSet) => {
    return JSON.stringify(currentGridSet) !== JSON.stringify(newGridSet);
  };

  useEffect(() => {
    if (!annotationItem) {
      return;
    }

    const grid =
      annotationItem &&
      annotationItem.gridset?.grids?.length > 0 &&
      annotationItem.gridset.grids.find((grid) => pageIndex === grid.page_index);

    if (!grid && currentGridSet) {
      setShowMainTableAnnotation(false);
      setCurrentGrid(undefined);
      setScaledTableGrid(undefined);
    }

    if (grid && gridSetsAreDifferent(grid)) {
      const defaultTablePosition = mapGridSetPositionToTablePosition(grid.box);
      const defaultTableLines = sortTableGridLines(
        mapGridSetLinesToTableGrid(defaultTablePosition, grid.columns, grid.rows)
      );

      setCurrentGrid(grid);
      setScaledTableGrid({
        table: defaultTablePosition,
        id: grid.id,
        lines: defaultTableLines,
        scale,
        containerWidth,
        containerHeight,
        pageIndex,
      });
      setShowMainTableAnnotation(true);
    }
  }, [annotationItem]);

  useEffect(() => {
    if (annotationSchema) {
      const columnSchemas = annotationSchema.content.elements[0].elements.find(
        (element) => element.kind === 'table'
      ).elements.elements;
      setColumnSchemasOptions(columnSchemas);
    }
  }, [annotationSchema]);

  const mapGridSetPositionToTablePosition = (box) => {
    const x = box.x_min * containerWidth;
    const y = containerHeight * box.y_min;
    return {
      coordinates: { x, y },
      width: containerWidth * box.x_max - x,
      height: containerHeight * box.y_max - y,
    };
  };

  const mapGridSetLinesToTableGrid = (tableSizes, columns, rows) => {
    const updatedColumns = columns.map((column) => {
      const updatedX = containerWidth * column.x_min - tableSizes.coordinates.x;

      return {
        from: { x: updatedX, y: 0 },
        to: { x: updatedX, y: tableSizes.height },
        id: ulid(),
        type: 'column',
        scale,
        schemaElementId: column.schema_element_id,
      };
    });

    const updatedRows = rows.map((row) => {
      const updatedY = containerHeight * row.y_min - tableSizes.coordinates.y;

      return {
        from: { x: 0, y: updatedY },
        to: { x: tableSizes.width, y: updatedY },
        id: ulid(),
        type: 'row',
        scale,
        contentClass: row.content_class,
      };
    });

    return [...updatedRows, ...updatedColumns];
  };

  const hasTableCoordinates = useMemo(() => {
    return showMainTableAnnotation && scaledTableGrid;
  }, [showMainTableAnnotation, scaledTableGrid]);

  const tableAnnotationOverlay = (showInitialTableAnnotation ||
    !hasTableCoordinates) && (
    <OverlayTableGrid
      width={containerWidth}
      height={containerHeight}
      showDrawOverlay={showDrawOverlay}
    />
  );

  const detectCreatedGrid = () => {
    const grid = getGridSetFromTableGrid(scaledTableGrid);
    const restGrids =
      annotationItem.gridset?.grids?.filter(
        (annotationGrid) => annotationGrid.page_index !== grid.page_index
      ) || [];

    return annotationStore
      .patchAnnotationWithoutContentUpdate(annotationItemId, {
        gridset: {
          grids: [...restGrids, grid],
        },
      })
      .then(() => {
        return detectGrid(annotationId, annotationItemId, grid.id);
      })
      .then(() => invalidateAnnotationContent(annotationId))
      .catch((err) => console.log(err));
  };

  const setInitialTableSize = (table) => {
    const defaultRow = {
      from: { x: 0, y: 0 },
      to: { x: table.width, y: 0 },
      id: ulid(),
      type: 'row',
      scale,
    };

    const defaultColumn = {
      from: { x: 0, y: 0 },
      to: { x: 0, y: table.height },
      id: ulid(),
      type: 'column',
      scale,
    };

    setScaledTableGrid({
      table,
      id: uuidv4(),
      scale,
      lines: [defaultRow, defaultColumn],
      containerWidth,
      containerHeight,
      pageIndex,
    });
  };

  return (
    <ScaledTableGridContext.Provider
      value={{
        scaledGrid: scaledTableGrid,
        setScaledGrid: setScaledTableGrid,
        setShowMainTableAnnotation,
      }}
    >
      {tableAnnotationOverlay}
      {showInitialTableAnnotation && (
        <InitialTableGrid
          width={containerWidth}
          height={containerHeight}
          showMainTableAnnotation={setShowMainTableAnnotation}
          setTableParameters={setInitialTableSize}
        />
      )}
      {hasTableCoordinates && (
        <MainTableGrid
          pageWidth={containerWidth}
          pageHeight={containerHeight}
          columnSchemasOptions={columnSchemasOptions}
          detectGrid={detectCreatedGrid}
          annotationId={annotationId}
          annotationItem={annotationItem}
        />
      )}
    </ScaledTableGridContext.Provider>
  );
}
