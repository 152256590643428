import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiIcon,
  EuiPanel,
  EuiTitle,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useEmailContent, useEmailMetadata } from '../../services/documents';
import { getDocumentEndpoint } from '../../services/endpoints';
import client from '../../utils/client';
import { InLineLoader } from '../InlineLoader/InlineLoader';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { useToasts } from '../Toasts/Toasts';

interface EmailObject {
  email: string;
  name: string;
}

export const EmailFlyout = ({ setIsFlyoutOpen, emailId }) => {
  const intl = useTranslate();

  const { data: emailMetadata, isLoading: isEmailMetadataLoading } = useEmailMetadata(
    emailId
  );
  const { data: emailContent, isLoading: isEmailContentLoading } = useEmailContent(
    emailId
  );

  const [content, setContent] = useState<string | undefined>(undefined);
  const [emailData, setEmailData] = useState<{ title: string; description: string }[]>(
    []
  );
  const { showRequestError } = useToasts();

  const getDocument = (documentId: string) => {
    return client(getDocumentEndpoint(documentId)).catch((error) =>
      showRequestError(error)
    );
  };

  const getEmailDataPart = (emailObjects: Array<EmailObject>) => {
    return (
      <EuiFlexGroup gutterSize="xs" wrap>
        {emailObjects.map((emailObject, index) => (
          <>
            <EuiFlexItem grow={false}>{emailObject.email}</EuiFlexItem>
            {emailObjects.length > 1 && index !== emailObjects.length - 1 && (
              <EuiFlexItem grow={false}>, </EuiFlexItem>
            )}
          </>
        ))}
      </EuiFlexGroup>
    );
  };

  const getDocumentAttachments = async (documentAttachments: string) => {
    const attachments = [];
    const documents = [];
    for (const documentId of documentAttachments) {
      const document = await getDocument(documentId);
      if (document.email_part === 'body') {
        documents.push({
          id: documentId,
          documentName: document.original_file_name,
        });
      } else {
        attachments.push({
          id: documentId,
          documentName: document.original_file_name,
        });
      }
    }

    return {
      documents:
        documents.length >= 1 ? (
          <EuiFlexGroup gutterSize="xs" wrap>
            {documents.map((documentView, index) => (
              <>
                <EuiFlexItem
                  grow={false}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <Link to={`../documents/${documentView.id}`}>
                    {documentView.documentName}
                  </Link>
                  {index !== documents.length - 1 && <span>, </span>}
                </EuiFlexItem>
              </>
            ))}
          </EuiFlexGroup>
        ) : undefined,
      attachments:
        attachments.length >= 1 ? (
          <EuiFlexGroup gutterSize="xs" wrap>
            {attachments.map((documentView, index) => (
              <>
                <EuiFlexItem
                  grow={false}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <Link to={`../documents/${documentView.id}`}>
                    {documentView.documentName}
                  </Link>
                  {index !== attachments.length - 1 && <span>, </span>}
                </EuiFlexItem>
              </>
            ))}
          </EuiFlexGroup>
        ) : undefined,
    };
  };

  useEffect(() => {
    if (!emailMetadata) {
      return;
    }

    const getEmailData = async () => {
      if (!emailMetadata) {
        return;
      }
      return {
        subject: emailMetadata.subject,
        from: emailMetadata.from.email,
        sentAt: intl.asDateTime(emailMetadata.sent_at),
        cc:
          emailMetadata.cc && emailMetadata.cc.length > 0
            ? getEmailDataPart(emailMetadata.cc as Array<EmailObject>)
            : undefined,
        bcc:
          emailMetadata.bcc && emailMetadata.bcc.length > 0
            ? getEmailDataPart(emailMetadata.bcc as Array<EmailObject>)
            : undefined,
        documents: emailMetadata.documents
          ? await getDocumentAttachments(emailMetadata.documents)
          : undefined,
      };
    };

    getEmailData().then((data) => setEmailData(data));
  }, [emailMetadata]);

  useEffect(() => {
    if (emailContent) {
      (emailContent as Blob).text().then((content) => {
        setContent(content);
      });
    }
  }, [emailContent]);

  if (isEmailMetadataLoading || isEmailContentLoading) return <InLineLoader />;

  return (
    <EuiFlyout
      ownFocus
      onClose={() => setIsFlyoutOpen(false)}
      aria-labelledby="flyoutTitle"
      className="email_flyout"
    >
      <EuiFlyoutBody style={{ height: '100%' }}>
        <EuiFlexGroup
          style={{ height: '100%', overflowY: 'hidden' }}
          direction="column"
        >
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiTitle size="xs">
                  <h3>{emailData.subject}</h3>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                {emailData.from}, {emailData.sentAt}
              </EuiFlexItem>
              {emailData.cc && (
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup gutterSize="s">
                    <EuiFlexItem grow={false}>
                      <Translate id="emailFlyout.cc" defaultMessage="Cc:"></Translate>
                    </EuiFlexItem>
                    <EuiFlexItem>{emailData.cc}</EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              )}
              {emailData.bcc && (
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup gutterSize="s">
                    <EuiFlexItem grow={false}>
                      <Translate id="emailFlyout.bcc" defaultMessage="Bcc:"></Translate>
                    </EuiFlexItem>
                    <EuiFlexItem>{emailData.bcc}</EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              )}
              {emailData?.documents?.documents && (
                <EuiFlexItem grow={false} style={{ display: 'flex' }}>
                  <EuiFlexGroup gutterSize="s">
                    <EuiFlexItem grow={false}>
                      <EuiIcon type="email"></EuiIcon>
                    </EuiFlexItem>
                    <EuiFlexItem> {emailData?.documents.documents}</EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              )}
              {emailData?.documents?.attachments && (
                <EuiFlexItem grow={false} style={{ display: 'flex' }}>
                  <EuiFlexGroup gutterSize="s">
                    <EuiFlexItem grow={false}>
                      <EuiIcon type="paperClip"></EuiIcon>
                    </EuiFlexItem>
                    <EuiFlexItem> {emailData?.documents.attachments}</EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem className="eui-yScroll" grow={true} style={{ display: 'flex' }}>
            <EuiPanel
              hasBorder={true}
              hasShadow={true}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {content}
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};
