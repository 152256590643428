import { EuiFlexGroup, EuiFlexItem, EuiMarkdownFormat, EuiText } from '@elastic/eui';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect, useState } from 'react';
import changelogFile from '../../CHANGELOG.md';

const lastSeenVersionInChangelogAtom = atomWithStorage(
  'lastSeenVersionInChangelog',
  ''
);

export function useChangelog() {
  const [content, setContent] = useState('');
  const [currentVersion, setCurrentVersion] = useState('');
  const [isThereUnseenVersion, setIsThereUnseenVersion] = useState(false);
  const [lastSeenVersionInChangelog] = useAtom(lastSeenVersionInChangelogAtom);

  useEffect(() => {
    fetch(changelogFile)
      .then((content) => content.text())
      .then((text) => {
        setContent(text);
        const match = /##\s*`?\s*(?<version>\d+\-\d+\-\d+).*/.exec(text);
        const currentVersion = match.groups?.version;
        setCurrentVersion(currentVersion);
        setIsThereUnseenVersion(currentVersion !== lastSeenVersionInChangelog);
      });
  }, []);

  useEffect(() => {
    if (currentVersion && lastSeenVersionInChangelog) {
      setIsThereUnseenVersion(currentVersion !== lastSeenVersionInChangelog);
    }
  }, [currentVersion, lastSeenVersionInChangelog]);

  return {
    content: content,
    currentVersion: currentVersion,
    isThereUnseenVersion: isThereUnseenVersion,
  };
}

export default function Changelog() {
  const { content, currentVersion } = useChangelog();
  const [, setLastSeenVersionInChangelog] = useAtom(lastSeenVersionInChangelogAtom);

  useEffect(() => {
    if (currentVersion) {
      setLastSeenVersionInChangelog(currentVersion);
    }
  }, [currentVersion]);

  return (
    <EuiFlexGroup justifyContent="spaceAround">
      <EuiFlexItem grow={true} style={{ maxWidth: '900px', padding: '1em' }}>
        <EuiText>
          <EuiMarkdownFormat>{content}</EuiMarkdownFormat>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
