import { EuiComboBox, EuiHighlight } from '@elastic/eui';
import { QueryLoader } from 'components/InlineLoader/QueryLoader';
import React, { useEffect, useState } from 'react';
import { VISIBILITY } from '../../../settings/constants';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useSchemas } from './useSchemas';

export function SkillVersionSelector({ schemaId, onChange, compressed = true }) {
  const schemasHook = useSchemas();
  const getSkillVersions = schemasHook.useGetSkillVersions(schemaId, 0);

  const [options, setOptions] = useState([]);
  const [selectedSkillVersions, setSelectedSkillVersions] = useState([]);
  const intl = useTranslate();

  useEffect(() => {
    if (selectedSkillVersions.length === 1) {
      onChange(selectedSkillVersions[0].value);
    }
  }, [selectedSkillVersions]);

  useEffect(() => {
    if (getSkillVersions.data) {
      const options = getSkillVersions.data.data
        .filter((value) => ['active', 'inactive'].includes(value.status))
        .map((value) => ({
          label: `${intl.asDateTime(value.created_at)}${
            value.status === 'active' ? ' (active)' : ''
          }${value.description ? ': ' + value.description : ''}`,
          value: value.id,
          key: value.id,
        }));
      setOptions(options);
      const activeOption = getSkillVersions.data.data.filter(
        (version) => version.status === 'active'
      )[0];
      setSelectedSkillVersions(
        options.filter((option) => option.value === activeOption?.id)
      );
    }
  }, [getSkillVersions.data]);

  if (!getSkillVersions.isSuccess) {
    return QueryLoader({ queries: [getSkillVersions] });
  }

  const renderOption = (option, searchValue) => {
    const { label } = option;
    return (
      <>
        <span style={{ zIndex: VISIBILITY.SCHEMA_SELECTOR_OPTION }}>
          <EuiHighlight search={searchValue}>{label.toString()}</EuiHighlight>
        </span>
      </>
    );
  };

  return (
    <EuiComboBox
      fullWidth
      placeholder={intl.formatMessage({
        id: 'schemaSelector.selectSkillVersion',
        defaultMessage: 'Select skill version',
      })}
      singleSelection={{ asPlainText: true }}
      sortMatchesBy="startsWith"
      options={options}
      compressed={compressed}
      isClearable={false}
      selectedOptions={selectedSkillVersions}
      renderOption={renderOption}
      onChange={setSelectedSkillVersions}
    />
  );
}
