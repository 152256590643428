export function sortTableGridLines(lines) {
  const columns = lines
    .filter((line) => line.type === 'column')
    .sort((first, next) => {
      return first.from.x - next.from.x;
    });

  const rows = lines
    .filter((line) => line.type === 'row')
    .sort((first, next) => {
      return first.from.y - next.from.y;
    });

  return [...columns, ...rows];
}

export function getGridSetFromTableGrid(scaledTableGrid) {
  const rectSize = scaledTableGrid.table;
  const columns = scaledTableGrid.lines
    .filter((line) => line.type === 'column')
    .map((line) => {
      return {
        x_min: (line.from.x + rectSize.coordinates.x) / scaledTableGrid.containerWidth,
        schema_element_id: line.schemaElementId,
      };
    });

  const rows = scaledTableGrid.lines
    .filter((line) => line.type === 'row')
    .map((line) => {
      return {
        y_min: (line.from.y + rectSize.coordinates.y) / scaledTableGrid.containerHeight,
        content_class: line.contentClass,
      };
    });

  return {
    id: scaledTableGrid.id,
    page_index: scaledTableGrid.pageIndex,
    box: {
      x_max: (rectSize.width + rectSize.coordinates.x) / scaledTableGrid.containerWidth,
      y_max:
        (rectSize.height + rectSize.coordinates.y) / scaledTableGrid.containerHeight,
      x_min: rectSize.coordinates.x / scaledTableGrid.containerWidth,
      y_min: rectSize.coordinates.y / scaledTableGrid.containerHeight,
    },
    columns,
    rows,
  };
}
