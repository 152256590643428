import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { useFolders } from './useFolders';

export function CreateFolderModal(props: {
  setIsModalVisible: (state: boolean) => void;
  parentFolderId: string;
}) {
  const { createFolderMutation } = useFolders();
  const intl = useTranslate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const handleCloseModal = () => {
    props.setIsModalVisible(false);
  };

  const handleSubmitForm = (data, e) => {
    e.preventDefault();
    const payload = {
      folderName: data.folderName,
      parentFolderId: props.parentFolderId,
    };
    createFolderMutation.mutate(payload, {
      onSettled: () => handleCloseModal(),
    });
  };

  const { ref: folderNameRef, ...folderNameRest } = register('folderName', {
    required: true,
    maxLength: {
      value: 64,
      message: intl.formatMessage({
        id: 'createFolderModal.validation.maxLenght',
        defaultMessage: 'Max length is 64 characters.',
      }),
    },
    pattern: {
      value: /^[^\/]+$/,
      message: intl.formatMessage({
        id: 'createFolderModal.validation.cantInclude',
        defaultMessage: `Name cannot include "/".`,
      }),
    },
  });

  const form = (
    <EuiForm
      id="folderModalForm"
      component="form"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <EuiFormRow
        label={intl.formatMessage({
          id: 'createFolderModal.folderName',
          defaultMessage: 'Folder name',
        })}
        error={[errors?.folderName?.message]}
        isInvalid={!!errors?.folderName}
      >
        <EuiFieldText
          {...folderNameRest}
          autoFocus
          fullWidth
          name="folderName"
          autoComplete="off"
          inputRef={folderNameRef}
        />
      </EuiFormRow>
    </EuiForm>
  );

  const modal = (
    <EuiModal onClose={handleCloseModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>
            <Translate
              id="createFolderModal.createFolder"
              defaultMessage="Create folder"
            />
          </h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>{form}</EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={handleCloseModal}>
          {intl.message.cancel}
        </EuiButtonEmpty>
        <EuiButton
          type="submit"
          form="folderModalForm"
          isDisabled={!isEmpty(errors)}
          isLoading={createFolderMutation.isLoading}
          fill
        >
          <Translate id="button.create" defaultMessage="Create" />
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );

  return <div>{modal}</div>;
}
