import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useUsers } from '../../services/users';
import { userSettingsAtom } from '../Header/States/UserState';
import { InLineLoader } from '../InlineLoader/InlineLoader';
import { useTranslate } from '../Internationalisation/useTranslate';
import { useToasts } from '../Toasts/Toasts';

const FolderLayout = () => {
  const { folderId } = useParams();
  const { useGetUserPermissions } = useUsers();
  const [userSettings] = useAtom(userSettingsAtom);
  const { addToast } = useToasts();
  const intl = useTranslate();
  const navigate = useNavigate();

  const { data, error, isError, isLoading } = useGetUserPermissions(
    {
      folderId,
    },
    false
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.permissions?.length === 1) {
      addToast({
        title: intl.formatMessage({
          id: 'useFolders.message.noSufficientPermissions',
          defaultMessage:
            'Not sufficient permissions to view this folder or folder does not exist',
        }),
        color: 'danger',
      });
      navigate(`/folders/${userSettings.oldestAccessibleFolderId}/documents`);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (error.response.status === 422) {
        addToast({
          title: intl.formatMessage({
            id: 'useFolders.message.folderNotExists',
            defaultMessage: 'Folder does not exist',
          }),
          color: 'danger',
        });
        navigate(`/folders/${userSettings.oldestAccessibleFolderId}/documents`);
      }
    }
  }, [isError]);

  if (isLoading) {
    return <InLineLoader />;
  }

  return <Outlet />;
};

export default FolderLayout;
