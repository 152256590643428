import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { addDatapointValues, replaceUserUrlsByNames } from 'utils/api';
import { useFoldersTree } from '../components/Folders/Folders';
import client, { queryClient } from '../utils/client';
import {
  getAnnotationsByDocumentEndpoint,
  getDocumentEndpoint,
  getDocumentTrashEndpoint,
  getEmailContent,
  getEmailMetadata,
  getSchemaEndpoint,
} from './endpoints';

export function processDocument(documentId, schemaId, testSkillVersionId) {
  let url = `api/v1/documents/${documentId}/process`;
  if (schemaId) {
    url += `?schema_id=${schemaId}`;
  }
  if (testSkillVersionId) {
    url += `&run_test_for_skill_version_id=${testSkillVersionId}`;
  }
  return client(url, { method: 'POST' });
}

export function getQueryString({
  search,
  pageIndex = 0,
  pageSize = 10,
  sortField,
  sortDirection,
  md5,
  id,
  importedAtAfter,
  importedAtBefore,
  importedBy,
  schemaId,
  originalFileName,
  include = [],
  includeAnnotationValuesSchemaElementId = [],
  annotationStatuses = [],
  folderId,
  isTrashed = false,
}) {
  include.push('imported_by');
  let query = `api/v1/documents`;
  let queryParams = new URLSearchParams();

  if (pageIndex > -1) {
    queryParams.append('page_index', pageIndex.toString());
  }

  if (pageSize) {
    queryParams.append('page_size', pageSize.toString());
  }

  if (folderId) {
    queryParams.append('folder_id', folderId);
  }

  if (search) {
    queryParams.append('search', search);
  }

  if (sortField) {
    queryParams.append(
      'order_by',
      `${sortDirection === 'desc' ? '-' : ''}${sortField}`
    );
  }

  if (id) {
    queryParams.append('id', id);
  }

  if (md5) {
    queryParams.append('md5', md5);
  }

  if (importedAtAfter) {
    const dateToAppend =
      importedAtAfter instanceof Date ? importedAtAfter.toISOString() : importedAtAfter;
    queryParams.append('imported_at_after', dateToAppend);
  }

  if (importedAtBefore) {
    const dateToAppend =
      importedAtBefore instanceof Date
        ? importedAtBefore.toISOString()
        : importedAtBefore;
    queryParams.append('imported_at_before', dateToAppend);
  }

  if (importedBy) {
    queryParams.append('imported_by', importedBy);
  }

  if (schemaId) {
    queryParams.append('schema_id', schemaId);
  }

  if (originalFileName) {
    queryParams.append('original_file_name', originalFileName);
  }

  queryParams.append('is_trashed', isTrashed);

  annotationStatuses.forEach((status) =>
    queryParams.append('annotation_status', status)
  );

  query += `?${queryParams.toString()}`;
  include && include.forEach((item) => (query = query + `&include=${item}`));

  if (includeAnnotationValuesSchemaElementId.length > 0) {
    query = query + `&include=annotation_values`;

    includeAnnotationValuesSchemaElementId.forEach(
      (item) =>
        (query = query + `&include__annotation_values__schema_element_id=${item}`)
    );
  }

  return query;
}

export function useDocuments({
  search,
  pageIndex = 0,
  pageSize = 10,
  sortField,
  sortDirection,
  md5,
  id,
  importedAtAfter,
  importedAtBefore,
  importedBy,
  schemaId,
  originalFileName,
  include = [],
  includeAnnotationValuesSchemaElementId = [],
  annotationStatuses,
  folderId,
  enabled = true,
  isTrashed = false,
}) {
  const query = getQueryString({
    search,
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    md5,
    id,
    importedAtAfter,
    importedAtBefore,
    importedBy,
    schemaId,
    originalFileName,
    include,
    includeAnnotationValuesSchemaElementId,
    annotationStatuses,
    folderId,
    isTrashed,
  });

  return useQuery(
    [
      'get-documents',
      id,
      search,
      pageIndex,
      pageSize,
      sortField,
      sortDirection,
      md5,
      importedAtAfter,
      importedAtBefore,
      importedBy,
      schemaId,
      originalFileName,
      include,
      includeAnnotationValuesSchemaElementId,
      annotationStatuses,
      folderId,
      isTrashed,
    ],
    () =>
      client(query).then((data) => {
        if (data && data.included && data.data) {
          if (data.included.users) {
            replaceUserUrlsByNames(data.data, 'imported_by', data.included.users);
          }

          if (data.included.annotation_values && data.included.annotations)
            addDatapointValues(
              data.included.annotation_values,
              data.included.annotations,
              data.data
            );
        }
        return data;
      }),
    {
      refetchInterval: 15000,
      useErrorBoundary: true,
      enabled,
    }
  );
}

export function useDocument(documentId, options) {
  return useQuery(`api/v1/documents/${documentId}`, {
    ...options,
    useErrorBoundary: true,
  });
}

export function invalidateDocument(documentId) {
  return queryClient.invalidateQueries(`api/v1/documents/${documentId}`);
}

export function invalidateAllDocuments() {
  return queryClient.invalidateQueries('get-documents');
}

export function useDocumentThumbnail(documentId) {
  return useQuery(`api/v1/documents/${documentId}/thumbnail`);
}

export const getDocumentContentUrl = (documentId, getTransformedPdfContent) => {
  return client(
    `api/v1/documents/${documentId}/content${
      getTransformedPdfContent
        ? `?get_transformed_pdf_content=${getTransformedPdfContent}`
        : ``
    }`
  );
};

export function useDocumentContent(documentId, getTransformedPdfContent) {
  return useQuery(
    `api/v1/documents/${documentId}/content${
      getTransformedPdfContent
        ? `?get_transformed_pdf_content=${getTransformedPdfContent}`
        : ``
    }`,
    {
      useErrorBoundary: true,
    }
  );
}

// TODO: merge following functions with useAnnotation hook
export function useAnnotations(documentId, showTestAnnotations = false) {
  const userAttributes = ['created_by', 'modified_by', 'approved_by', 'exported_by'];
  const query = getAnnotationsByDocumentEndpoint(
    documentId,
    userAttributes,
    showTestAnnotations
  );
  return useQuery(
    [
      'get-document-annotations',
      documentId,
      { showTestAnnotations: showTestAnnotations },
    ],
    () => client(query),
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        userAttributes.forEach((attribute) =>
          replaceUserUrlsByNames(data.data, attribute, data.included.users)
        );
      },
      useErrorBoundary: true,
    }
  );
}

export function invalidateAnnotationContent(annotationId) {
  return queryClient.invalidateQueries(['annotations', annotationId, 'content']);
}

export function invalidateAnnotation(annotationId) {
  return queryClient.invalidateQueries(['annotations', annotationId]);
}

export function invalidateAnnotations(documentId) {
  return queryClient.invalidateQueries(['get-document-annotations', documentId]);
}

export function useGetSchemas(includeParentFolders = false) {
  const { folderId: selectedFolderId } = useParams();
  const { selectedFolderWithParentsIds } = useFoldersTree();
  let queryParams = new URLSearchParams();
  if (includeParentFolders) {
    selectedFolderWithParentsIds?.forEach((id) => queryParams.append('folder_id', id));
  } else {
    queryParams.append('folder_id', selectedFolderId);
  }
  const url = `api/v1/schemas?${queryParams.toString()}`;
  return useQuery(['schemas', { params: queryParams.toString() }], () => client(url), {
    useErrorBoundary: true,
  });
}

export function invalidateSchemas() {
  return queryClient.invalidateQueries(['schemas']);
}

export function invalidateAllSchemas() {
  return queryClient.invalidateQueries(['schemas']);
}

export function useSchema(schemaId, options) {
  return useQuery(getSchemaEndpoint(schemaId), { ...options, useErrorBoundary: true });
}

export function invalidateSchema(schemaId) {
  return queryClient.invalidateQueries(getSchemaEndpoint(schemaId));
}

export function patchDocument(props) {
  return client(getDocumentEndpoint(props.documentId), {
    body: props.patch,
    method: 'PATCH',
  });
}

export async function trashDocument(documentId) {
  return await client(getDocumentTrashEndpoint(documentId), {
    body: '',
    method: 'POST',
  });
}

export function useEmailMetadata(emailId) {
  return useQuery(getEmailMetadata(emailId), { useErrorBoundary: true });
}

export function useEmailContent(emailId) {
  return useQuery(getEmailContent(emailId), { useErrorBoundary: true });
}
