import {
  EuiButtonIcon,
  EuiCallOut,
  EuiContextMenu,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIconTip,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import { isTestModeEnabledAtom } from 'components/Annotation/AnnotationState';
import { useSelectedAnnotationStoreRefresh } from 'components/Annotation/useAnnotation';
import { BOTTOM_BAR_HEIGHT } from 'components/BottomBar/BottomBar';
import { SchemaBuilder } from 'components/Manage/Schema/SchemaBuilder';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useUsers } from 'services/users';
import { PERMISSION, useCan } from 'utils/permissions';
import {
  copyAnnotation,
  postMarkAsTemplate,
  putAnnotationStatus,
} from '../../../services/annotations';
import {
  invalidateAnnotation,
  invalidateAnnotations,
  useAnnotations,
} from '../../../services/documents';
import { THEME } from '../../../settings/constants';
import { useAnnotationsPeriodicRefresh } from '../../../utils/useAnnotationsPeriodicRefresh';
import { InLineLoader } from '../../InlineLoader/InlineLoader';
import { Translate } from '../../Internationalisation/translate';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useAnnotationStore } from '../Annotation';
import { AnnotationItems } from './AnnotationItems';
import { AnnotationSelector } from './AnnotationSelector';

export function AnnotationPanel({
  documentId,
  selectedAnnotationId,
  onAnnotationSelectionChange,
}) {
  const [showSchema, setShowSchema] = useState(false);
  useSelectedAnnotationStoreRefresh(selectedAnnotationId);
  const setIsDocumentProcessed = useAnnotationStore(
    (state) => state.setIsDocumentProcessed
  );
  const { search } = useLocation();
  const annotationIdGroundTruthParam = new URLSearchParams(search).get(
    'annotationIdGroundTruth'
  );
  const [isTestModeEnabled] = useAtom(isTestModeEnabledAtom);
  const [annotationIdGroundTruth, setAnnotationGroundTruth] = useState(
    annotationIdGroundTruthParam
  );
  const showTestAnnotations = !!annotationIdGroundTruthParam || isTestModeEnabled;
  const { data, isLoading } = useAnnotations(documentId, showTestAnnotations);
  const annotations = data?.data;
  const can = useCan();
  const annotationIdUrl = new URLSearchParams(search).get('annotationId');
  const navigate = useNavigate();
  let [, setSearchParams] = useSearchParams();
  const intl = useTranslate();

  function onAnnotationGroundTruthSelectionChange(id) {
    setAnnotationGroundTruth(id);
    setSearchParams({ annotationIdGroundTruth: id }, { replace: true });
  }

  useAnnotationsPeriodicRefresh(documentId);

  useEffect(() => {
    if (annotations?.length && isTestModeEnabled && !annotationIdGroundTruthParam) {
      onAnnotationGroundTruthSelectionChange(
        annotationIdGroundTruth || annotations[0].id
      );
    }
  }, [isTestModeEnabled, annotations]);

  useEffect(() => {
    if (annotationIdUrl) {
      onAnnotationSelectionChange(annotationIdUrl);
    } else if (!selectedAnnotationId && annotations?.length) {
      onAnnotationSelectionChange(annotations[0].id);
    } else {
      onAnnotationSelectionChange(selectedAnnotationId);
    }
  }, [annotationIdUrl, annotations, selectedAnnotationId]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const selectedAnnotation = annotations.filter(
      (annotation) => annotation.id === selectedAnnotationId
    )[0];
    // There is already processed annotation
    const isProcessed =
      annotations.length !== 0 &&
      !['processing', 'processing_failed', 'processing_canceled'].includes(
        selectedAnnotation?.status
      );
    setIsDocumentProcessed(isProcessed);
  }, [annotations, setIsDocumentProcessed, isLoading, selectedAnnotationId]);

  if (isLoading) return <InLineLoader />;

  const selectedAnnotation =
    !!annotations &&
    annotations.filter((annotation) => annotation.id === selectedAnnotationId)[0];

  const schema = !!selectedAnnotation && (
    <>
      <SchemaBuilder
        annotationId={selectedAnnotation.id}
        schemaId={selectedAnnotation.schema_id}
        flyoutType="push"
      />
    </>
  );

  const annotationContent = showSchema ? (
    schema
  ) : (
    <EuiFlexGroup>
      <EuiFlexItem style={{ overflow: 'hidden' }}>
        <AnnotationItems annotation={selectedAnnotation} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  function AnnotationSettings() {
    const [isOpened, setIsOpened] = useState(false);
    const { useGetCurrentUser } = useUsers();
    const { data: currentUser } = useGetCurrentUser();

    const button = (
      <EuiButtonIcon
        iconType="boxesVertical"
        title={intl.formatMessage({
          id: 'button.options',
          defaultMessage: 'Options',
        })}
        iconSize="m"
        size="m"
        color="text"
        onClick={() => setIsOpened(!isOpened)}
        aria-label="Options"
      />
    );
    const panels = [
      {
        id: 0,
        items: [
          {
            name: showSchema
              ? intl.formatMessage({
                  id: 'annotationPanel.button.showAnnotation',
                  defaultMessage: 'Show annotation',
                })
              : intl.formatMessage({
                  id: 'annotationPanel.button.showSchema',
                  defaultMessage: 'Show schema',
                }),
            onClick: () => {
              setShowSchema(!showSchema);
            },
          },
          {
            name: intl.formatMessage({
              id: 'annotationPanel.button.markAsReference',
              defaultMessage: 'Mark as reference',
            }),
            onClick: () => {
              // @ts-ignore
              putAnnotationStatus(selectedAnnotation.id, 'reference').then(() => {
                invalidateAnnotations(selectedAnnotation.document_id);
                invalidateAnnotation(selectedAnnotation.id);
              });
              setIsOpened(false);
            },
            disabled:
              selectedAnnotation?.status === 'reference' ||
              !can.can(PERMISSION.ANNOTATIONS_UPDATE),
          },
          {
            name: selectedAnnotation?.is_template
              ? intl.formatMessage({
                  id: 'annotationPanel.button.markedAsTemplate',
                  defaultMessage: 'Marked as template',
                })
              : intl.formatMessage({
                  id: 'annotationPanel.button.markAsTemplate',
                  defaultMessage: 'Mark as template',
                }),
            onClick: () => {
              if (!selectedAnnotation.is_template) {
                postMarkAsTemplate(selectedAnnotation.id).then(() => {
                  invalidateAnnotations(selectedAnnotation.document_id);
                  invalidateAnnotation(selectedAnnotation.id);
                });
              }
              setIsOpened(false);
            },
            disabled:
              !can.can(PERMISSION.EXTRACTORS_CREATE) || selectedAnnotation?.is_template,
          },
          {
            name: intl.formatMessage({
              id: 'annotationPanel.button.copyAnnotation',
              defaultMessage: 'Copy annotation',
            }),
            onClick: () => {
              // @ts-ignore
              copyAnnotation(selectedAnnotation.id).then(() => {
                invalidateAnnotations(selectedAnnotation.document_id);
                invalidateAnnotation(selectedAnnotation.id);
                navigate(window.location.pathname, { replace: true });
              });
              setIsOpened(false);
            },
            disabled: !can.can(PERMISSION.ANNOTATIONS_UPDATE),
          },
          {
            name: intl.formatMessage({
              id: 'annotationPanel.button.deleteAnnotation',
              defaultMessage: 'Delete annotation',
            }),
            onClick: async () => {
              // @ts-ignore
              await putAnnotationStatus(selectedAnnotation.id, 'deleted');
              await invalidateAnnotations(selectedAnnotation.document_id);
              await invalidateAnnotation(selectedAnnotation.id);
              setIsOpened(false);
              navigate(window.location.pathname, { replace: true });
            },
            disabled: !can.can(PERMISSION.ANNOTATIONS_UPDATE),
          },
        ],
      },
    ];

    const debuggingAppUrlBase = currentUser?.settings?.debugging_app_url;
    const debuggingAppUrl = `${debuggingAppUrlBase}/?annotation_id=${selectedAnnotation?.id}`;
    if (!!debuggingAppUrlBase) {
      panels[0].items.unshift({
        name: intl.formatMessage({
          id: 'annotationPanel.button.debugExtraction',
          defaultMessage: 'Debug extraction',
        }),
        href: encodeURI(debuggingAppUrl),
        target: '_blank',
        rel: 'noreferrer noopener',
      });
    }

    return (
      <EuiPopover
        id="annotationSettings"
        button={button}
        isOpen={isOpened}
        closePopover={() => setIsOpened(false)}
        panelPaddingSize="none"
        anchorPosition="downLeft"
      >
        <EuiContextMenu initialPanelId={0} panels={panels} />
      </EuiPopover>
    );
  }

  const annotationContentItems = (
    <EuiPanel paddingSize="l" hasShadow={false} style={{ paddingTop: '12px' }}>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiTitle size="xxs">
            <span>
              <Translate
                id="annotationPanel.title.annotation"
                defaultMessage="Annotation"
              />
            </span>
          </EuiTitle>
          <EuiFlexGroup alignItems="center" gutterSize="xs" direction="row">
            <EuiFlexItem>
              <AnnotationSelector
                documentId={documentId}
                annotationId={selectedAnnotationId}
                onChange={onAnnotationSelectionChange}
                showTestAnnotations={showTestAnnotations}
                schemaId={selectedAnnotation?.schema_id}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <AnnotationSettings />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {showTestAnnotations && (
          <EuiFlexItem>
            <EuiTitle size="xxs">
              <span>
                <Translate
                  id="annotationPanel.title.referenceAnnotation"
                  defaultMessage="Reference annotation"
                />{' '}
                <EuiIconTip
                  aria-label="info"
                  content={intl.formatMessage({
                    id: 'annotationItems.info',
                    defaultMessage: `
                  Comparing extracted values without user updates with reference values.
                  Red values are different than reference values, green are identical.
                  Lists and tables are not compared.
                  `,
                  })}
                />
              </span>
            </EuiTitle>
            <EuiFlexItem>
              <AnnotationSelector
                documentId={documentId}
                annotationId={annotationIdGroundTruth}
                onChange={onAnnotationGroundTruthSelectionChange}
                showTestAnnotations={showTestAnnotations}
                schemaId={selectedAnnotation?.schema_id}
              />
            </EuiFlexItem>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiSpacer size="m" />
      <AnnotationDetail
        annotationStatus={selectedAnnotation?.status}
        annotationContent={annotationContent}
      />
    </EuiPanel>
  );

  const annotationsExist = annotations.length > 0;

  return (
    annotationsExist && (
      <EuiPanel
        paddingSize="none"
        borderRadius="none"
        // To fit to page height.
        style={{
          minHeight: `calc(100vh - ${THEME.euiHeaderHeightCompensation} - ${BOTTOM_BAR_HEIGHT})`,
          paddingTop: '4px',
        }}
      >
        {annotationContentItems}
      </EuiPanel>
    )
  );
}

function AnnotationDetail({ annotationStatus, annotationContent }) {
  const intl = useTranslate();

  switch (annotationStatus) {
    case undefined:
      return null;
    case 'processing':
      return (
        <EuiCallOut
          color="warning"
          iconType="pin"
          title={intl.formatMessage({
            id: 'annotationDetail.callOut.title.annotationIsProcessing',
            defaultMessage: 'Annotation is in processing',
          })}
        >
          <Translate
            id="annotationDetail.callOut.annotationIsProcessing"
            defaultMessage="Please wait a few moments"
          />
        </EuiCallOut>
      );
    case 'to_classify':
      return (
        <>
          <EuiCallOut
            color="warning"
            iconType="pin"
            title={intl.formatMessage({
              id: 'annotationDetail.callOut.title.documentNotClassified',
              defaultMessage: 'Document not classified',
            })}
          >
            <Translate
              id="annotationDetail.callOut.documentNotClassified"
              defaultMessage="This annotation version did not have a document schema assigned. Please assign a schema and create new annotation."
            />
          </EuiCallOut>
          <EuiSpacer size="l" />
          {annotationContent}
        </>
      );
    case 'processing_failed':
      return (
        <EuiCallOut
          color="danger"
          iconType="pin"
          title={intl.formatMessage({
            id: 'annotationDetail.callOut.title.documentProcessingFailed',
            defaultMessage: 'Document processing failed',
          })}
        >
          <Translate
            id="annotationDetail.callOut.documentProcessingFailed"
            defaultMessage="Document processing failed for this annotation."
          />
        </EuiCallOut>
      );
    case 'processing_canceled':
      return (
        <EuiCallOut
          color="danger"
          iconType="pin"
          title={intl.formatMessage({
            id: 'annotationDetail.callOut.title.documentProcessingCanceled',
            defaultMessage: 'Document processing canceled',
          })}
        >
          <Translate
            id="annotationDetail.callOut.documentProcessingCanceled"
            defaultMessage="Document processing was canceled for this annotation."
          />
        </EuiCallOut>
      );
    default:
      return (
        <div>
          <EuiSpacer size="s" />
          {annotationContent}
        </div>
      );
  }
}
