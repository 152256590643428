import { ErrorComponent } from 'components/Errors/ErrorComponent';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { InLineLoader } from './InlineLoader';

export const QueryLoader = (props: { queries: UseQueryResult[] }) => {
  return (
    <>
      {props.queries.some((query) => query.isLoading) && <InLineLoader />}
      {props.queries.some((query) => query.isError) && <ErrorComponent />}
    </>
  );
};
