import { useEuiTheme } from '@elastic/eui';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ScaledTableGridContext } from './TableGridContainer';
import { sortTableGridLines } from './utils';

export function TableGrid({ highlightedLine, setHighlightedLine, highlightedArea }) {
  const minDist = 20;
  const [mouseDownCoordinates, setMouseDownCoordinates] = useState(undefined);
  const [cursorStyle, setCursorStyle] = useState('auto');
  const { scaledGrid, setScaledGrid } = useContext(ScaledTableGridContext);

  const { euiTheme } = useEuiTheme();

  const canvasGridRef = useRef(null);

  const startDragging = (event) => {
    const coordinates = getCoordinates(event);
    setMouseDownCoordinates(coordinates);
  };

  const stopDragging = () => {
    setMouseDownCoordinates(undefined);
    setHighlightedLine(false);
    setCursorStyle('auto');
  };

  useEffect(() => {
    if (highlightedLine) {
      const filteredGridLines = scaledGrid.lines
        .map((gridLine) => ({
          ...gridLine,
          strokeStyle: euiTheme.colors.primary,
          lineWidth: 1,
        }))
        .filter((gridLine) => gridLine.id !== highlightedLine.id);
      setScaledGrid({
        ...scaledGrid,
        lines: sortTableGridLines([
          ...filteredGridLines,
          { ...highlightedLine, strokeStyle: 'red', lineWidth: 2 },
        ]),
      });
      setCursorStyle('move');
    } else {
      const updatedTableGridLines = scaledGrid.lines.map((gridLine) => ({
        ...gridLine,
        strokeStyle: euiTheme.colors.primary,
        lineWidth: 1,
      }));
      setScaledGrid({
        ...scaledGrid,
        lines: sortTableGridLines(updatedTableGridLines),
      });
      setCursorStyle('auto');
    }
  }, [highlightedLine]);

  const drawLine = ({
    from,
    to,
    strokeStyle = euiTheme.colors.primary,
    lineWidth = 1,
  }) => {
    if (!canvasGridRef.current) {
      return;
    }
    const canvas = canvasGridRef.current;

    if (!canvas.getContext) {
      return;
    }
    const ctx = canvas.getContext('2d');
    canvas.style.cursor = cursorStyle;
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = lineWidth;

    // draw a red line
    ctx.beginPath();
    ctx.moveTo(from.x, from.y);
    ctx.lineTo(to.x, to.y);
    ctx.stroke();
  };

  const highlightLine = useCallback(
    (event) => {
      const coordinates = getCoordinates(event);
      if (coordinates && !mouseDownCoordinates) {
        const closestLine = getClosestLine(coordinates, minDist);
        if (closestLine) {
          if (closestLine.id !== highlightedLine?.id) {
            setHighlightedLine(closestLine);
          }
        } else {
          if (highlightedLine) {
            setHighlightedLine(undefined);
          }
        }
      }
    },
    [scaledGrid.lines]
  );

  const moveLine = useCallback(
    (event) => {
      if (!mouseDownCoordinates) {
        return;
      }
      const mouseCoordinates = getCoordinates(event);

      if (mouseCoordinates && highlightedLine) {
        const dx = mouseCoordinates.x - mouseDownCoordinates.x;
        const dy = mouseCoordinates.y - mouseDownCoordinates.y;

        if (highlightedLine.type === 'row') {
          const from = { x: highlightedLine.from.x, y: highlightedLine.from.y + dy };
          const to = { x: highlightedLine.to.x, y: highlightedLine.to.y + dy };
          const updatedLine = {
            ...highlightedLine,
            from,
            to,
            strokeStyle: 'red',
            lineWidth: 3,
          };
          const filteredTableGridLines = scaledGrid.lines.filter(
            (gridLine) => gridLine.id !== updatedLine.id
          );
          setScaledGrid({
            ...scaledGrid,
            lines: sortTableGridLines([...filteredTableGridLines, updatedLine]),
          });
          setCursorStyle('none');
        } else {
          const from = { x: highlightedLine.from.x + dx, y: highlightedLine.from.y };
          const to = { x: highlightedLine.to.x + dx, y: highlightedLine.to.y };
          const updatedLine = {
            ...highlightedLine,
            from,
            to,
            strokeStyle: 'red',
            lineWidth: 3,
          };
          const filteredTableGridLines = scaledGrid.lines.filter(
            (gridLine) => gridLine.id !== updatedLine.id
          );
          setScaledGrid({
            ...scaledGrid,
            lines: sortTableGridLines([...filteredTableGridLines, updatedLine]),
          });
          setCursorStyle('none');
        }
      }
    },
    [scaledGrid.lines, mouseDownCoordinates]
  );

  const removeHighlight = () => {
    setHighlightedLine(undefined);
  };

  const drawHighlightedArea = () => {
    if (!canvasGridRef.current) {
      return;
    }
    const canvas = canvasGridRef.current;

    if (!canvas.getContext) {
      return;
    }
    const ctx = canvas.getContext('2d');

    ctx.beginPath();
    const rectWidth = highlightedArea.width;
    const rectHeight = highlightedArea.height;
    ctx.rect(highlightedArea.x, highlightedArea.y, rectWidth, rectHeight);
    ctx.fillStyle = 'rgba(190,30,35,0.2)';
    ctx.fillRect(highlightedArea.x, highlightedArea.y, rectWidth, rectHeight);
  };

  useEffect(() => {
    canvasGridRef.current
      .getContext('2d')
      .clearRect(0, 0, scaledGrid.table.width, scaledGrid.table.height);
    scaledGrid.lines.forEach((rowLine) => {
      drawLine(rowLine);
    });
    if (highlightedArea) {
      drawHighlightedArea();
    }
  }, [scaledGrid, highlightedArea]);

  useEffect(() => {
    if (!canvasGridRef.current) {
      return;
    }
    const canvas = canvasGridRef.current;
    canvas.addEventListener('mousemove', highlightLine);
    return () => {
      canvas.removeEventListener('mousemove', highlightLine);
    };
  }, [highlightLine]);

  useEffect(() => {
    if (!canvasGridRef.current) {
      return;
    }
    const canvas = canvasGridRef.current;
    canvas.addEventListener('mouseleave', removeHighlight);
    return () => {
      canvas.removeEventListener('mouseleave', removeHighlight);
    };
  }, [highlightLine]);

  useEffect(() => {
    if (!canvasGridRef.current) {
      return;
    }
    const canvas = canvasGridRef.current;
    canvas.addEventListener('mousemove', moveLine);
    return () => {
      canvas.removeEventListener('mousemove', moveLine);
    };
  });

  useEffect(() => {
    if (!canvasGridRef.current) {
      return;
    }
    const canvas = canvasGridRef.current;
    canvas.addEventListener('mousedown', startDragging);
    canvas.addEventListener('mouseup', stopDragging);
    return () => {
      canvas.removeEventListener('mousedown', startDragging);
      canvas.removeEventListener('mouseup', stopDragging);
    };
  });

  const getCoordinates = (event) => {
    if (!canvasGridRef.current) {
      return;
    }

    const canvas = canvasGridRef.current;
    return {
      x: event.pageX - canvas.getBoundingClientRect().left,
      y: event.pageY - canvas.getBoundingClientRect().top,
    };
  };

  const distanceLineFromPoint = (line, point) => {
    const lx = line.from.x;
    const ly = line.from.y;
    const v1x = line.to.x - lx;
    const v1y = line.to.y - ly;
    const v2x = point.x - lx;
    const v2y = point.y - ly;
    // get unit dist of closest point
    const u = (v2x * v1x + v2y * v1y) / (v1y * v1y + v1x * v1x);
    if (u >= 0 && u <= 1) {
      // is the point on the line
      return Math.hypot(lx + v1x * u - point.x, ly + v1y * u - point.y);
    } else if (u < 0) {
      // point is before start
      return Math.hypot(lx - point.x, ly - point.y);
    }
    // point is after end of line
    return Math.hypot(line.to.x - point.x, line.to.y - point.y);
  };

  // this will extend the lines list
  const getClosestLine = (from, minDist) => {
    let closestLine = '';

    scaledGrid.lines.forEach((line) => {
      const dist = distanceLineFromPoint(line, from);
      if (dist < minDist) {
        closestLine = line;
        minDist = dist;
      }
    });

    return closestLine;
  };

  return (
    <canvas
      ref={canvasGridRef}
      id="canvasGrid"
      width={scaledGrid.table.width}
      height={scaledGrid.table.height}
    />
  );
}
