import { SchemasPostIn } from '../Definitions/ApiTypes';
import { CreateSchemaType } from '../Definitions/CreateSchemaType';
import client from '../utils/client';
import {
  getSchemaEndpoint,
  getSchemasEndpoint,
  getTrainSchemaElementSourcesEndpoint,
} from './endpoints';

export const createNewSchema = async (schemaSpecification: SchemasPostIn) => {
  return client(getSchemasEndpoint(), {
    body: schemaSpecification,
  });
};

export const updateSchema = async (params: {
  schemaId: string;
  content: CreateSchemaType['content'];
}) => {
  return client(getSchemaEndpoint(params.schemaId), {
    method: 'PATCH',
    body: {
      content: params.content,
    },
  });
};

export const trainSchemaElementSources = async (params: {
  schemaId: string;
  schemaElementIds?: string[];
}) => {
  return client(getTrainSchemaElementSourcesEndpoint(params.schemaId), {
    method: 'POST',
    body: params.schemaElementIds,
  });
};
