import { EuiGlobalToastList } from '@elastic/eui';
import { atom, useAtom } from 'jotai';
import React, { useCallback } from 'react';
import { ulid } from 'ulid';
import { useTranslate } from '../Internationalisation/useTranslate';

export const toastsAtom = atom([]);

export function Toasts() {
  const [toasts, setToasts] = useAtom(toastsAtom);

  const removeToast = useCallback(
    (removedToast) => {
      setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
    },
    [toasts]
  );

  return (
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={removeToast}
      toastLifeTimeMs={6000}
      side="left"
    />
  );
}

export function useToasts() {
  const [toasts, setToasts] = useAtom(toastsAtom);
  const intl = useTranslate();

  const addToasts = useCallback(
    (toasts) => {
      const readyToasts = toasts.map((toast) => ({
        id: ulid(),
        ...toast,
      }));
      setToasts(readyToasts);
    },
    [toasts]
  );

  const addToast = useCallback(
    ({
      title,
      text = undefined,
      color = 'primary',
      iconType = null,
      toastLifeTimeMs = 6000,
    }) => {
      setToasts(
        toasts.concat({
          id: ulid(),
          title: title,
          text: text,
          color: color,
          iconType: iconType,
          toastLifeTimeMs: toastLifeTimeMs,
        })
      );
    },
    [toasts]
  );

  const defaultErrorMessage = (error) => {
    const isRequestError =
      error?.response?.status >= 400 && error?.response?.status < 500;
    const isServerError = error?.response?.status >= 500;

    if (isRequestError) {
      return intl.formatMessage(
        {
          id: 'error.requestError',
          defaultMessage: 'Request error {errorCode}',
        },
        {
          errorCode: error?.response?.status || '',
        }
      );
    }

    if (isServerError) {
      return intl.formatMessage(
        {
          id: 'error.serverError',
          defaultMessage: 'Server error {errorCode}',
        },
        {
          errorCode: error?.response?.status || '',
        }
      );
    }

    return intl.formatMessage(
      {
        id: 'error.operationFailed',
        defaultMessage: 'Operation failed {errorCode}',
      },
      {
        errorCode: error?.response?.status || '',
      }
    );
  };

  const getErrorMessage = (error) => {
    if (error.message) {
      return error.message;
    } else {
      return Array.isArray(error?.detail) ? error?.detail[0]?.msg : error?.detail;
    }
  };

  const showRequestError = useCallback(
    (error, title = defaultErrorMessage(error)) => {
      if (typeof error === 'string') {
        addToast({ title: title, color: 'danger', text: error });
      } else {
        addToast({
          title: title,
          color: 'danger',
          text: getErrorMessage(error),
        });
      }
    },
    [toasts]
  );

  return {
    addToast: addToast,
    addToasts: addToasts,
    showRequestError: showRequestError,
  };
}
