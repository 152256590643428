import Changelog from 'components/Changelog/Changelog';
import { useAtom } from 'jotai';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import {
  AbilityContext,
  Can,
  PERMISSION,
  useConfiguredAbility,
} from 'utils/permissions';
import { AnnotationPage } from './components/Annotation/Annotation';
import { ErrorComponent } from './components/Errors/ErrorComponent';
import { PageNotAuthorized, PageNotFound } from './components/Errors/ErrorPages';
import { lastSelectedFolderAtom } from './components/Folders/FolderState';
import Header from './components/Header/Header';
import { userSettingsAtom } from './components/Header/States/UserState';
import { InLineLoader } from './components/InlineLoader/InlineLoader';
import FolderLayout from './components/Layout/FolderLayout';
import PageLayout from './components/Layout/PageLayout';
import { RedirectToNewPaths } from './components/Layout/RedirectToNewPath';
import { Toasts } from './components/Toasts/Toasts';
import { THEME_MODE } from './settings/constants';
import { useIsAuthenticated } from './utils/auth';
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Manage = lazy(() => import('./components/Manage/Manage'));
const Schema = lazy(() => import('./components/Manage/Schema/Schema'));
const SkillMonitoringPage = lazy(
  () => import('./components/Manage/Schema/SkillMonitoringPage')
);
const SkillVersionPage = lazy(
  () => import('./components/Manage/Schema/SkillVersionPage')
);
const ReferenceDataPage = lazy(
  () => import('./components/Manage/ReferenceData/components/ReferenceDataPage')
);
const HooksPage = lazy(() => import('./components/Manage/Hooks/HooksPage'));
const Login = lazy(() => import('./components/Login/Login'));
const DocumentsTable = lazy(() => import('./components/DocumentsTable/DocumentsTable'));

/** A wrapper for <Route> that redirects to the login if user is not authenticated. */
function RequireAuth({ children }) {
  const { isAuthenticated } = useIsAuthenticated();
  return isAuthenticated ? children : <Navigate replace to="/login" />;
}

export const RoutePath = {
  FOLDER: 'folders/:folderId',
  DOCUMENTS: 'documents',
  ANNOTATIONS: 'documents/:documentId',
  MANAGE: 'manage',
  SCHEMAS_BASE: 'skills',
  SCHEMAS: 'skills/:schemaId',
  REFERENCE_DATA_BASE: 'reference-data',
  REFERENCE_DATA: 'reference-data/:referenceDataId',
  HOOKS_BASE: 'hooks',
  HOOKS: 'hooks/:hookId',
  SKILL_MONITORING: 'skills/:schemaId/monitoring',
  SKILL_VERSIONS: 'skills/:schemaId/versions/:versionId',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  CHANGELOG: '/changelog',
};

export function AppRoutesAuth() {
  return (
    <Routes>
      <Route
        path={RoutePath.LOGIN}
        element={
          <Suspense fallback={<InLineLoader />}>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <Login />
            </ErrorBoundary>
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <RequireAuth>
            <AppRoutes />
          </RequireAuth>
        }
      ></Route>
    </Routes>
  );
}

export function AppRoutes() {
  const [userSettings] = useAtom(userSettingsAtom);
  // const { initialized: isKeycloakInitialized } = useKeycloak();
  const { ability, isLoading } = useConfiguredAbility();
  const [lastSelectedFolderId] = useAtom(lastSelectedFolderAtom);
  const redirectFolderId =
    lastSelectedFolderId || userSettings.oldestAccessibleFolderId;

  if (!userSettings?.oldestAccessibleFolderId) return <InLineLoader />;

  return (
    // (!isKeycloakUsed() || isKeycloakInitialized) &&
    <Suspense fallback={<InLineLoader />}>
      <Routes>
        <Route
          element={
            <>
              {isLoading ? (
                <InLineLoader />
              ) : (
                <AbilityContext.Provider value={ability}>
                  <Header />
                  <Outlet />
                  <Toasts />
                </AbilityContext.Provider>
              )}
            </>
          }
        >
          <Route
            path="/documents"
            element={<Navigate replace to={`/folders/${redirectFolderId}/documents`} />}
          />
          <Route
            path="/schemas"
            element={
              <Navigate replace to={`/folders/${redirectFolderId}/manage/skills`} />
            }
          />
          <Route path="/documents/:documentId" element={<RedirectToNewPaths />} />
          <Route path="/schemas/:schemaId" element={<RedirectToNewPaths />} />
          <Route
            path="/schemas/:schemaId/monitoring"
            element={<RedirectToNewPaths />}
          />
          <Route
            path="/schemas/:schemaId/versions/:versionId"
            element={<RedirectToNewPaths />}
          />
          <Route
            path="/"
            element={<Navigate replace to={`/folders/${redirectFolderId}/documents`} />}
          />
          <Route
            path={RoutePath.DASHBOARD}
            element={
              <PageLayout>
                <Suspense fallback={<InLineLoader />}>
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <Dashboard />
                  </ErrorBoundary>
                </Suspense>
              </PageLayout>
            }
          />
          <Route
            path={RoutePath.FOLDER}
            element={
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <FolderLayout></FolderLayout>
              </ErrorBoundary>
            }
          >
            <Route
              index
              path={RoutePath.DOCUMENTS}
              element={
                <Can I={PERMISSION.DOCUMENTS_READ} fallback={<PageNotAuthorized />}>
                  <Can I={PERMISSION.ANNOTATIONS_READ} fallback={<PageNotAuthorized />}>
                    <PageLayout>
                      <Suspense fallback={<InLineLoader />}>
                        <ErrorBoundary FallbackComponent={ErrorComponent}>
                          <DocumentsTable />
                        </ErrorBoundary>
                      </Suspense>
                    </PageLayout>
                  </Can>
                </Can>
              }
            />
            <Route
              path={RoutePath.ANNOTATIONS}
              element={
                <Can I={PERMISSION.DOCUMENTS_READ} fallback={<PageNotAuthorized />}>
                  <Can I={PERMISSION.ANNOTATIONS_READ} fallback={<PageNotAuthorized />}>
                    <div
                      style={{
                        backgroundColor: THEME_MODE === 'light' ? '#f0f0f0' : '#26272d',
                        // Small dots
                        backgroundImage: `radial-gradient( ${
                          THEME_MODE === 'light' ? '#dbdbdb' : '#3b3f4c'
                        } 1.5px, transparent 1.5px)`,
                        backgroundSize: '20px 20px',
                      }}
                    >
                      <Suspense fallback={<InLineLoader />}>
                        <ErrorBoundary FallbackComponent={ErrorComponent}>
                          <AnnotationPage />
                        </ErrorBoundary>
                      </Suspense>
                    </div>
                  </Can>
                </Can>
              }
            />
            <Route
              path={RoutePath.MANAGE}
              element={
                <Suspense fallback={<InLineLoader />}>
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <Manage />
                  </ErrorBoundary>
                </Suspense>
              }
            >
              <Route
                path={RoutePath.SCHEMAS_BASE}
                element={
                  <Can I={PERMISSION.SCHEMAS_READ} fallback={<PageNotAuthorized />}>
                    <Suspense fallback={<InLineLoader />}>
                      <ErrorBoundary FallbackComponent={ErrorComponent}>
                        <Schema />
                      </ErrorBoundary>
                    </Suspense>
                  </Can>
                }
              />
              <Route
                path={RoutePath.SCHEMAS}
                element={
                  <Can I={PERMISSION.SCHEMAS_READ} fallback={<PageNotAuthorized />}>
                    <Suspense fallback={<InLineLoader />}>
                      <ErrorBoundary FallbackComponent={ErrorComponent}>
                        <Schema />
                      </ErrorBoundary>
                    </Suspense>
                  </Can>
                }
              />
              <Route
                path={RoutePath.REFERENCE_DATA_BASE}
                element={
                  <Can
                    I={PERMISSION.REFERENCE_DATA_READ}
                    fallback={<PageNotAuthorized />}
                  >
                    <Suspense fallback={<InLineLoader />}>
                      <ErrorBoundary FallbackComponent={ErrorComponent}>
                        <ReferenceDataPage />
                      </ErrorBoundary>
                    </Suspense>
                  </Can>
                }
              />
              <Route
                path={RoutePath.REFERENCE_DATA}
                element={
                  <Can
                    I={PERMISSION.REFERENCE_DATA_READ}
                    fallback={<PageNotAuthorized />}
                  >
                    <Suspense fallback={<InLineLoader />}>
                      <ErrorBoundary FallbackComponent={ErrorComponent}>
                        <ReferenceDataPage />
                      </ErrorBoundary>
                    </Suspense>
                  </Can>
                }
              />
              <Route
                path={RoutePath.HOOKS_BASE}
                element={
                  <Can I={PERMISSION.HOOKS_READ} fallback={<PageNotAuthorized />}>
                    <Suspense fallback={<InLineLoader />}>
                      <ErrorBoundary FallbackComponent={ErrorComponent}>
                        <HooksPage />
                      </ErrorBoundary>
                    </Suspense>
                  </Can>
                }
              />
              <Route
                path={RoutePath.HOOKS}
                element={
                  <Can I={PERMISSION.HOOKS_READ} fallback={<PageNotAuthorized />}>
                    <Suspense fallback={<InLineLoader />}>
                      <ErrorBoundary FallbackComponent={ErrorComponent}>
                        <HooksPage />
                      </ErrorBoundary>
                    </Suspense>
                  </Can>
                }
              />
            </Route>
            <Route
              path={RoutePath.SKILL_MONITORING}
              element={
                <Can I={PERMISSION.SCHEMAS_READ} fallback={<PageNotAuthorized />}>
                  <Suspense fallback={<InLineLoader />}>
                    <ErrorBoundary FallbackComponent={ErrorComponent}>
                      <SkillMonitoringPage />
                    </ErrorBoundary>
                  </Suspense>
                </Can>
              }
            />
            <Route
              path={RoutePath.SKILL_VERSIONS}
              element={
                <Can
                  I={PERMISSION.SKILL_VERSIONS_READ}
                  fallback={<PageNotAuthorized />}
                >
                  <Suspense fallback={<InLineLoader />}>
                    <ErrorBoundary FallbackComponent={ErrorComponent}>
                      <SkillVersionPage />
                    </ErrorBoundary>
                  </Suspense>
                </Can>
              }
            />
          </Route>
          <Route
            path={RoutePath.CHANGELOG}
            element={
              <PageLayout restrictWidth="800px">
                <Suspense fallback={<InLineLoader />}>
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <Changelog />
                  </ErrorBoundary>
                </Suspense>
              </PageLayout>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
}
