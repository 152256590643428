import { EuiButtonIcon } from '@elastic/eui';
import { useAtom } from 'jotai';
import React from 'react';
import { usePapaParse } from 'react-papaparse';
import { useActivateDatapoint } from '../../../Annotation/AnnotationPanel/useActivateDatapoint';
import { focusTableAtom } from '../../../Annotation/AnnotationState';
import { useTranslate } from '../../../Internationalisation/useTranslate';
import { useReferenceData } from '../hooks/useReferenceData';
import { DatapointReferenceDataTable } from './DatapointReferenceDataTable';

export const ReferenceDataSearchButton = ({
  referenceData,
  datapointValue,
  id,
  tableId,
}: {
  referenceData: { referenceDataId: string; matchedElements: Array<any> };
  datapointValue: string | undefined;
  id: string;
  tableId: string;
}) => {
  const {
    getReferenceDataContent: {
      mutate: getReferenceData,
      isLoading: isReferenceDataLoading,
    },
  } = useReferenceData();
  const { readString } = usePapaParse();
  const { formatMessage } = useTranslate();
  const [, setFocusedTable] = useAtom(focusTableAtom);
  const { setActiveDatapoint } = useActivateDatapoint();

  const onClick = (e) => {
    e.stopPropagation();
    setActiveDatapoint(undefined, false);
    getReferenceData(referenceData.referenceDataId, {
      onSuccess: (data) => {
        readString(data, {
          worker: true,
          header: true,
          complete: ({ data, meta }) => {
            setReferenceTableVisible({
              fields: meta.fields || [],
              data,
            });
          },
        });
      },
    });
  };

  const setReferenceTableVisible = ({
    fields,
    data,
  }: {
    fields: Array<string>;
    data: Array<any>;
  }) => {
    setFocusedTable({
      id,
      tableComponent: (
        <DatapointReferenceDataTable
          fields={fields}
          defaultData={data}
          matchedElements={referenceData.matchedElements}
          referenceDataId={referenceData.referenceDataId}
          datapointValue={datapointValue}
          id={id}
          tableId={tableId}
        ></DatapointReferenceDataTable>
      ),
    });
  };

  return (
    <>
      <EuiButtonIcon
        onClick={onClick}
        iconType="search"
        isLoading={isReferenceDataLoading}
        title={formatMessage({
          id: 'referenceData.button.searchReferenceData',
          defaultMessage: 'Search reference data',
        })}
        aria-label="Search reference data"
      />
    </>
  );
};
