import { EuiButton } from '@elastic/eui';
import React, { useEffect, useMemo, useState } from 'react';
import { useDocumentContent } from 'services/documents';
import XLSX from 'xlsx';
import { InLineLoader } from '../../../InlineLoader/InlineLoader';
import { CanvasDataGrid } from './CanvasDataGrid';

/* generate an array of column objects */
const makeCols = (ref?: string) => {
  if (!ref) {
    return [];
  }

  const output = [];
  const column = XLSX.utils.decode_range(ref).e.c + 1;
  for (let i = 0; i < column; ++i) {
    output[i] = {
      name: XLSX.utils.encode_col(i),
      key: i,
    };
  }
  return output;
};

export function SpreadsheetBrowser(props: { documentId: string }) {
  const { data: documentContent, isLoading } = useDocumentContent(props.documentId);
  const [workBook, setWorkBook] = useState<any>();
  const [sheetNames, setSheetNames] = useState<string[]>([]);
  const [activeSheetName, setActiveSheetName] = useState<string>();
  const [activeSheet, setActiveSheet] = useState<any>();
  const [data, setData] = useState<any[]>([]);

  const documentContentURL = useMemo(
    () => !isLoading && URL.createObjectURL(documentContent),
    [documentContent, isLoading]
  );

  async function getWorkbook() {
    const data = await documentContent.arrayBuffer();
    return XLSX.read(data);
  }

  useEffect(() => {
    if (documentContentURL) {
      getWorkbook().then((workBook) => {
        setWorkBook(workBook);
      });
    }
  }, [documentContentURL]);

  useEffect(() => {
    if (workBook) {
      const workBookSheetNames = workBook.SheetNames;
      const activeWorkBookSheetName = workBook.SheetNames[0];
      setSheetNames(workBookSheetNames);
      setActiveSheetName(activeWorkBookSheetName);
      setActiveSheet(workBook.Sheets[activeWorkBookSheetName]);
    }
  }, [workBook]);

  useEffect(() => {
    if (activeSheet) {
      const data = XLSX.utils.sheet_to_json(activeSheet, { header: 1 });
      const cols = makeCols(activeSheet['!ref']);

      /**
       * CanvasDatagrid component needs to have all columns in the first row filled (at least with empty string)
       */
      if (Array.isArray(data)) {
        for (let i = 0; i < cols.length; ++i) {
          data[0][i] = data[0][i] || '';
        }
      }

      setData(data);
    }
  }, [activeSheet]);

  const handleSwitchActiveSheet = (sheetName: string) => {
    if (activeSheetName === sheetName) {
      return;
    }
    setActiveSheetName(sheetName);
    setActiveSheet(workBook.Sheets[sheetName]);
  };

  if (isLoading) return <InLineLoader />;

  return (
    <>
      <div style={{ height: '100%', overflowY: 'hidden', display: 'block' }}>
        {sheetNames && (
          <div className="sheets-list" style={{ height: '4%', marginBottom: '5px' }}>
            {sheetNames.map((sheetName, idx) => (
              <EuiButton
                key={`sheet-${idx}`}
                className="sheet-tab"
                fill={activeSheetName === sheetName}
                color="primary"
                size="s"
                onClick={() => handleSwitchActiveSheet(sheetName)}
              >
                {sheetName}
              </EuiButton>
            ))}
          </div>
        )}

        {data && data.length && <CanvasDataGrid data={data} />}
      </div>
    </>
  );
}
