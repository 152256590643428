import {
  EuiButtonIcon,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
} from '@elastic/eui';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import {
  createAnnotationBatchOperations,
  patchAnnotationItemMutate,
} from '../../services/annotations';
import { invalidateAnnotationContent } from '../../services/documents';
import { useTranslate } from '../Internationalisation/useTranslate';
import { useToasts } from '../Toasts/Toasts';
import { useAnnotationStore } from './Annotation';
import {
  hideSplitBarAtom,
  splitDrawModeActiveAtom,
  splitInProgress,
} from './AnnotationState';
import {
  DocumentClassificationContent,
  DocumentClassificationDatapoint,
  DocumentClassificationRow,
  DocumentClassificationSchemaId,
} from './DocumentPanel/ImageAnnotation/useDocumentClassification';
import { useSplitOperations } from './useSplitOperations';

export function SplitBar({
  documentClasses,
  documentClassification,
  pageIndex,
  pageNumber,
}: {
  documentClasses: Array<{ label: string; value: string }>;
  documentClassification: DocumentClassificationContent;
  pageIndex: number;
  pageNumber: number;
}) {
  const [classOptions, setClassOptions] = useState<
    { id: string; label: string; disabled?: boolean }[] | undefined
  >(undefined);
  const intl = useTranslate();
  const {
    getAddRowOperations,
    getUpdateOperation,
    createBatchOperations,
  } = useSplitOperations();
  const [isSplitInProgress, setSplitInProgress] = useAtom(splitInProgress);
  const [hideSplitBar, setHideSplitBar] = useAtom(hideSplitBarAtom);
  const [splitDrawModeActive] = useAtom(splitDrawModeActiveAtom);

  useEffect(() => {
    if (documentClasses) {
      const classOptions = documentClasses.map((documentClass) => ({
        id: documentClass.value as DocumentClassificationSchemaId,
        label: documentClass.label,
      }));
      setClassOptions(classOptions);
    }
  }, []);

  const foundRow = useMemo(() => {
    return documentClassification.rows.find((row) => row.startPageIndex === pageIndex);
  }, [documentClassification]);

  const selectedDocumentClass: Array<{
    id: DocumentClassificationSchemaId;
    label: string;
  }> = useMemo(() => {
    if (foundRow && classOptions) {
      const documentClassId = foundRow.datapoints.find(
        ({ schema_element_id }) => schema_element_id === '_document_class'
      )?.value as string;
      let foundOption = classOptions.find((option) => option.id === documentClassId);

      if (!foundOption) {
        foundOption = {
          id: documentClassId,
          label: intl.formatMessage(
            {
              id: 'annotation.split.unknownChoice',
              defaultMessage: 'Unknown choice "{choice}"',
            },
            { choice: documentClassId }
          ),
          disabled: true,
        };
        setClassOptions([...classOptions, foundOption]);
      }

      return [foundOption] as Array<{
        id: DocumentClassificationSchemaId;
        label: string;
      }>;
    }

    if (!foundRow && classOptions?.length === 1) {
      return [classOptions[0]];
    }
    return [];
  }, [foundRow, classOptions]);

  useEffect(() => {
    setSelectedDocumentClasses(selectedDocumentClass);
  }, [selectedDocumentClass]);

  const [selectedDocumentClasses, setSelectedDocumentClasses] = useState(
    selectedDocumentClass
  );
  const [isLoading, setIsLoading] = useState(false);
  const annotationStore = useAnnotationStore();
  const { addToast } = useToasts();

  const schemaUpdatedToast = (type: 'success' | 'danger') => {
    const messages = {
      success: {
        title: intl.formatMessage({
          defaultMessage: 'Split document config updated',
          id: 'annotation.split.splitDocumentConfigUpdated',
        }),
        color: 'success',
      },
      danger: {
        title: intl.formatMessage({
          defaultMessage: 'Error on updating document split',
          id: 'annotation.split.errorOnUpdatingSplitDocument',
        }),
        color: 'danger',
      },
    };

    addToast(messages[type]);
  };

  // Creates action for creating a new row and datapoints, also updating the previous datapoint row with a new index
  const handleAddRow = (
    documentClass: DocumentClassificationSchemaId,
    documentClassFirstPageIndex: number,
    documentClassLastPageIndex: number,
    tableId: string
  ) => {
    const batchActions = getAddRowOperations(
      documentClass,
      documentClassFirstPageIndex,
      documentClassLastPageIndex,
      tableId,
      pageIndex
    );

    const updatePreviousDatapointOperation = updatePreviousLasPageIndexDatapointOperation(
      pageIndex - 1,
      'create'
    );

    if (updatePreviousDatapointOperation) {
      // @ts-ignore
      batchActions.push(updatePreviousDatapointOperation);
    }

    // @ts-ignore
    createBatchOperations(
      annotationStore?.annotation?.id,
      batchActions,
      () => {
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        schemaUpdatedToast('danger');
      }
    );
  };

  // @ts-ignore
  const patchAnnotationMutation = useMutation(patchAnnotationItemMutate, {
    onSuccess: (data, variables) => {
      invalidateAnnotationContent(variables.annotationId).then(() => {
        setIsLoading(false);
        setSplitInProgress(false);
      });
    },
    onError: () => {
      setIsLoading(false);
      setSplitInProgress(false);
      schemaUpdatedToast('danger');
    },
  });

  const updateCurrentRow = () => {
    if (!foundRow) {
      return;
    }

    const toUpdateDatapoint = foundRow.datapoints.find(
      ({ schema_element_id }) => schema_element_id === '_document_class'
    );

    // @ts-ignore
    const patchProps = {
      annotationId: annotationStore?.annotation?.id,
      annotationItemId: toUpdateDatapoint?.id,
      patch: {
        value: selectedDocumentClasses[0]?.id,
        normalized_value: selectedDocumentClasses[0]?.id,
      },
    };
    setSplitInProgress(true);
    patchAnnotationMutation.mutate(patchProps);
  };

  const addNewRow = () => {
    let endIndex: number | undefined = documentClassification.rows.find(
      ({ startPageIndex }) => startPageIndex > pageIndex
    )?.startPageIndex;

    if (!endIndex) {
      endIndex = pageNumber;
    }

    handleAddRow(
      selectedDocumentClasses[0].id,
      pageIndex,
      endIndex - 1,
      documentClassification.tableId
    );
  };

  // Returns update payload for the previous datapoint when creating/deleting another split datapoint
  // ( needed in order to update the end page index )
  const updatePreviousLasPageIndexDatapointOperation = (
    updatedLastPageIndex: number,
    status: 'delete' | 'create'
  ) => {
    const previousLastPageIndexDatapoint:
      | DocumentClassificationDatapoint
      | undefined = documentClassification.rows.reduce(
      (
        previousDatapoint: DocumentClassificationDatapoint | undefined,
        currentRow: DocumentClassificationRow
      ) => {
        const lastPageIndexDatapoint = currentRow.datapoints.find(
          (datapoint) =>
            datapoint.schema_element_id === '_document_class_last_page_index'
        );
        const firstPageIndexDatapoint = currentRow.datapoints.find(
          (datapoint) =>
            datapoint.schema_element_id === '_document_class_first_page_index'
        );

        // @ts-ignore
        const canUpdate =
          status === 'delete'
            ? // @ts-ignore
              lastPageIndexDatapoint.value < pageIndex
            : // @ts-ignore
              firstPageIndexDatapoint.value < pageIndex &&
              pageIndex <= lastPageIndexDatapoint.value;

        if (lastPageIndexDatapoint && canUpdate) {
          previousDatapoint = lastPageIndexDatapoint;
        }

        return previousDatapoint;
      },
      undefined
    );

    if (previousLastPageIndexDatapoint) {
      return getUpdateOperation(
        previousLastPageIndexDatapoint?.id,
        updatedLastPageIndex
      );
    }
    return;
  };

  const deleteDatapoint = () => {
    setSelectedDocumentClasses([]);
    if (!foundRow) {
      return;
    }
    setIsLoading(true);
    const currentLastPageIndexDatapoint = foundRow.datapoints.find(
      (datapoint) => datapoint.schema_element_id === '_document_class_last_page_index'
    );

    const deleteOperation = {
      kind: 'delete',
      payload: {
        id: foundRow.id,
      },
    };

    const updateOperation = updatePreviousLasPageIndexDatapointOperation(
      currentLastPageIndexDatapoint?.value as number,
      'delete'
    );
    const batchActions = [deleteOperation];

    if (updateOperation) {
      batchActions.push(updateOperation);
    }

    createBatchOperations(
      annotationStore?.annotation?.id,
      batchActions,
      () => {
        setIsLoading(false);
      },
      () => {
        schemaUpdatedToast('danger');
      }
    );

    // @ts-ignore
    createAnnotationBatchOperations(annotationStore?.annotation?.id, {
      commands: batchActions,
    })
      .then(() => {
        return invalidateAnnotationContent(annotationStore?.annotation?.id);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        schemaUpdatedToast('danger');
      });
  };

  // If the row already exists, it will trigger an update action, in other case it will trigger create
  const splitDocument = () => {
    setIsLoading(true);
    if (foundRow) {
      updateCurrentRow();
    } else {
      addNewRow();
    }
  };

  // Triggers update datapoint when the class is selection class is changing
  const onClassChange = (classes: any) => {
    setSelectedDocumentClasses(classes);
    if (foundRow) {
      setIsLoading(true);
      // @ts-ignore
      const patchProps = {
        annotationId: annotationStore?.annotation?.id,
        annotationItemId: foundRow.datapoints.find(
          ({ schema_element_id }) => schema_element_id === '_document_class'
        )?.id,
        patch: {
          value: classes[0]?.id,
          normalized_value: classes[0].id,
        },
      };

      setSplitInProgress(true);
      patchAnnotationMutation.mutate(patchProps);
    }
  };

  return (
    <>
      {foundRow && (
        <div
          style={{
            position: 'absolute',
            top: '-7px',
            left: '0px',
            width: '100%',
            borderTop: '3px dashed red',
          }}
        />
      )}
      {!hideSplitBar && !splitDrawModeActive && (
        <div
          style={{
            position: 'absolute',
            top: '6px',
            left: '6px',
          }}
        >
          <EuiFlexGroup
            gutterSize="s"
            style={{
              width: '340px',
              boxShadow:
                '0 0 0.8px rgb(0 0 0 / 15%), 0 0 2px rgb(0 0 0 / 10%), 0 0 5px rgb(0 0 0 / 10%), 0 0 17px rgb(0 0 0 / 8%)',
              border: '1px solid rgb(52, 55, 65)',
              borderRadius: '4px',
              background: 'rgb(29, 30, 36)',
            }}
          >
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                size="s"
                iconType="cut"
                title={intl.formatMessage({
                  id: 'splitBar.button.markAsFirstPage',
                  defaultMessage: 'Mark as the first page of a new document',
                })}
                aria-label="Cut"
                onClick={splitDocument}
                isDisabled={
                  !selectedDocumentClasses.length || !!foundRow || isSplitInProgress
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiComboBox
                aria-label="Document Class"
                placeholder={intl.formatMessage({
                  id: 'splitBar.documentClasses',
                  defaultMessage: 'Document class',
                })}
                singleSelection={{ asPlainText: true }}
                compressed
                options={classOptions || []}
                selectedOptions={selectedDocumentClasses}
                onChange={onClassChange}
                isDisabled={isSplitInProgress}
                isClearable={false}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                size="s"
                iconType="cross"
                title={intl.formatMessage({
                  id: 'splitBar.button.clear',
                  defaultMessage: 'Remove split',
                })}
                aria-label="Delete"
                onClick={deleteDatapoint}
                isDisabled={
                  !selectedDocumentClasses.length ||
                  pageIndex === 0 ||
                  isSplitInProgress
                }
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                size="s"
                iconType="eye"
                title={intl.formatMessage({
                  id: 'splitBar.button.hideClassSelector',
                  defaultMessage: 'Hide class selector',
                })}
                aria-label="Hide class selector"
                onClick={() => setHideSplitBar(true)}
              />
            </EuiFlexItem>
            <EuiFlexItem style={{ alignSelf: 'center' }} grow={false}>
              {isLoading && <EuiLoadingSpinner size="m" />}
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      )}
    </>
  );
}
