import {
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiNotificationBadge,
  EuiSuperSelect,
  EuiText,
} from '@elastic/eui';
import React, { useEffect, useRef, useState } from 'react';
import {
  SchemaDatapoint,
  SchemaDatapointEnum,
  SchemaList,
  SchemaTable,
} from '../../../Definitions/ApiTypes';
import { DatapointType } from '../../../Definitions/DatapointType';
import { SchemaElementKind } from '../../../Definitions/SchemaElementKind';
import { THEME } from '../../../settings/constants';
import { useAnnotationStore } from '../Annotation';
import { getSectionAnnotationItemId } from '../utils';

export function NotExtractedSchemaElement(props: {
  schemaElement: SchemaDatapointEnum | SchemaDatapoint | SchemaTable | SchemaList;
}) {
  const { schemaElement } = props;
  const [showValueField, setShowValueField] = useState(false);
  const [elementValue, setElementValue] = useState('');
  const fieldRef = useRef(null);
  const annotationStore = useAnnotationStore();
  const annotationContent = useAnnotationStore((state) => state.annotationContent);
  const schema = useAnnotationStore((state) => state.schema);

  let datapointEnumChoices;
  if ('choices' in schemaElement && schemaElement.choices) {
    datapointEnumChoices = [
      ...schemaElement.choices.map((choice) => ({
        ...choice,
        inputDisplay: choice.label,
      })),
    ];
  }

  useEffect(() => {
    if (showValueField) {
      // @ts-ignore
      fieldRef.current?.focus();
    }
  }, [showValueField]);

  function onAddValueClick() {
    setShowValueField(true);
  }

  function onElementValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    setElementValue(e.target.value);
  }

  function onEnumValueChange(value: string) {
    setShowValueField(false);
    saveValue(value);
  }

  function filterInputKeys(e: any) {
    if (e.key === 'Enter') {
      saveValue();
    }
  }

  function saveValue(paramValue?: string) {
    const parentId = getSectionAnnotationItemId(
      schemaElement.id,
      // @ts-ignore
      schema.content,
      annotationContent
    );

    const payload = {
      schema_element_kind: SchemaElementKind.Datapoint,
      schema_element_id: schemaElement.id,
      parent_id: parentId,
      annotation_class: 'value',
      value: paramValue ? paramValue : elementValue,
      normalized_value: paramValue ? paramValue : elementValue,
    };

    // @ts-ignore
    annotationStore.createAnnotationItem(payload);
  }

  return (
    <EuiFlexGroup
      key={schemaElement.id}
      style={{ padding: '0.2em 0.0em' }}
      direction="row"
      justifyContent="spaceBetween"
      gutterSize="none"
      wrap={true}
    >
      <EuiFlexItem grow={false}>
        <EuiText size="s" style={{ padding: '0em 0.5em' }} className="eui-textBreakAll">
          <EuiNotificationBadge
            size="m"
            style={{
              marginRight: '0.5em',
              backgroundColor: THEME.euiColorSuccess,
            }}
          >
            <EuiIcon size="s" type="check" />
          </EuiNotificationBadge>
          {schemaElement.label}
        </EuiText>
      </EuiFlexItem>

      <EuiFlexItem style={{ padding: '0em 0.5em 0em 2.2em' }} grow={false}>
        {showValueField &&
          schemaElement.kind === SchemaElementKind.Datapoint &&
          'type' in schemaElement &&
          schemaElement.type !== DatapointType.Enum && (
            <div className="annotation-value-field-wrap">
              <EuiFieldText
                value={elementValue}
                onChange={onElementValueChange}
                aria-label="Enter value of datapoint"
                className="annotation-value-field"
                inputRef={fieldRef}
                onBlur={() => saveValue()}
                onKeyDown={filterInputKeys}
              />
            </div>
          )}

        {showValueField &&
          schemaElement.kind === SchemaElementKind.Datapoint &&
          'type' in schemaElement &&
          schemaElement.type === DatapointType.Enum && (
            <div className="annotation-value-field-wrap">
              <EuiSuperSelect
                className="annotation-value-enum"
                options={datapointEnumChoices || []}
                onChange={onEnumValueChange}
                aria-label="Choose value of datapoint"
                compressed
              />
            </div>
          )}

        {!showValueField && (
          <EuiButtonIcon
            iconType="plus"
            aria-label="Add value"
            onClick={onAddValueClick}
          />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
