import { SchemaElementKind } from '../../Definitions/SchemaElementKind';
import { getSchemaDataElement } from '../../utils/schemaUtils';

function skipElement(element, allowedAnnotationClasses) {
  if (
    'annotation_class' in element &&
    !allowedAnnotationClasses.includes(element.annotation_class)
  ) {
    return true;
  }

  if (!('annotation_class' in element) && 'elements' in element) {
    for (const innerElement of element.elements) {
      return skipElement(innerElement, allowedAnnotationClasses);
    }
  }

  return false;
}

export function getDatapoints(annotationContent, allowedAnnotationClasses) {
  return Array.from(iterateDatapoints(annotationContent, allowedAnnotationClasses));
}

export function* iterateDatapoints(annotationContent, allowedAnnotationClasses) {
  for (let section of annotationContent.elements) {
    for (let element of section.elements) {
      if (!!allowedAnnotationClasses) {
        if (skipElement(element, allowedAnnotationClasses)) {
          continue;
        }
      }
      switch (element.kind) {
        case 'datapoint':
          yield element;
          break;
        case 'list':
          for (let datapoint of element.elements) {
            yield datapoint;
          }
          break;
        case 'table':
          for (let tuple of element.elements) {
            for (let datapoint of tuple.elements) {
              yield datapoint;
            }
          }
          break;
      }
    }
  }
}

export function getDatapointSchemas(
  schemaContent,
  allowedTypes = ['datapoint', 'list', 'table']
) {
  const datapoints = [];
  schemaContent.elements.forEach((section) =>
    section.elements.forEach((element) => {
      switch (element.kind) {
        case 'datapoint':
          if (allowedTypes.includes('datapoint')) {
            datapoints.push(element);
          }
          break;
        case 'list':
          if (allowedTypes.includes('list')) {
            datapoints.push(element.elements);
          }
          break;
        case 'table':
          if (allowedTypes.includes('table')) {
            element.elements.elements.forEach((datapoint) =>
              datapoints.push(datapoint)
            );
          }
          break;
      }
    })
  );
  return datapoints;
}

export function getSectionAnnotationItemId(
  datapointSchemaElementId,
  schemaContent,
  annotationContent
) {
  let sectionSchemaElementId;
  for (let sectionSchema of schemaContent.elements) {
    for (let element of sectionSchema.elements) {
      if (element.id === datapointSchemaElementId) {
        sectionSchemaElementId = sectionSchema.id;
      }
    }
  }
  for (let sectionAnnotation of annotationContent.elements) {
    if (sectionAnnotation.schema_element_id === sectionSchemaElementId) {
      return sectionAnnotation.id;
    }
  }
}

// TODO: Use for sorting of annotation items in section from top to down.
export function sortElementsFromTopToDown(annotationElements) {
  return annotationElements.sort((a, b) => {
    // TODO: Sort by first datapoint in list and table.
    if (a.kind !== 'datapoint' || b.kind !== 'datapoint') return 0;
    if (a.page_index === b.page_index) {
      return a.box.y_min - b.box.y_min;
    } else {
      return a.page_index - b.page_index;
    }
  });
}

export function annotationToGeometryBox(box) {
  // If there is box with both zero coordinates or negative, it shows the box when mouse is
  // outside the image so we put very small x coordinate instead.
  const getCorrectedValue = (value) => {
    if (Math.sign(value) === -1 || !value) {
      return 0.0001;
    }

    return value;
  };

  return {
    x: getCorrectedValue(box.x_min * 100),
    y: getCorrectedValue(box.y_min * 100),
    width: getCorrectedValue((box.x_max - box.x_min) * 100),
    height: getCorrectedValue((box.y_max - box.y_min) * 100),
  };
}

export function geometryToAnnotationBox(box, isEnabled = true) {
  if (!isEnabled) {
    return;
  }
  return {
    xMin: box.x / 100,
    yMin: box.y / 100,
    xMax: (box.x + box.width) / 100,
    yMax: (box.y + box.height) / 100,
  };
}

export function getAllDatapoints(annotationContent, schema) {
  if (!schema) {
    return;
  }

  const annotationElements = annotationContent?.elements || [];
  let allAnnotations = [];

  // @ts-ignore
  for (const annotationItem of annotationElements) {
    if (annotationItem.kind === SchemaElementKind.Section) {
      for (const sectionElement of annotationItem.elements) {
        if (sectionElement.kind === SchemaElementKind.Datapoint) {
          const schemaElement = getSchemaDataElement(
            schema,
            sectionElement.schema_element_id
          );
          allAnnotations.push({
            ...sectionElement,
            choices: schemaElement?.choices,
            type: schemaElement?.type,
          });
        } else if (sectionElement.kind === SchemaElementKind.Table) {
          allAnnotations = [
            ...allAnnotations,
            ...getCurrentTableDatapoints(sectionElement, schema),
          ];
        } else if (sectionElement.kind === SchemaElementKind.List) {
          const sectionElements = sectionElement.elements.map((element) => {
            const schemaElement = getSchemaDataElement(
              schema,
              element.schema_element_id
            );
            return {
              ...element,
              type: schemaElement?.type,
              choices: schemaElement?.choices,
            };
          });
          allAnnotations = [...allAnnotations, ...sectionElements];
        }
      }
    }
  }

  return allAnnotations;
}

export function getCurrentTableDatapoints(tableElement, schema) {
  return tableElement.elements.reduce((elements, currentElement) => {
    const currentElements = currentElement.elements.map((element) => {
      const schemaElement = getSchemaDataElement(schema, element.schema_element_id);
      return {
        ...element,
        type: schemaElement?.type,
        choices: schemaElement?.choices,
      };
    });
    elements = [...elements, ...currentElements];
    return elements;
  }, []);
}
