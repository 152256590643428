import {
  EuiBottomBar,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import { useAnnotationStore } from 'components/Annotation/Annotation';
import { AnnotationStatusButton } from 'components/Annotation/AnnotationPanel/AnnotationButtons';
import {
  DocumentProcessingFlyout,
  DocumentPropertiesFlyout,
} from 'components/Annotation/AnnotationPanel/DocumentTab';
import { FileBrowserController } from 'components/Annotation/DocumentPanel/FileBrowserController';
import {
  AnnotationPagesMenu,
  AnnotationSettingsMenu,
} from 'components/Annotation/DocumentPanel/ImageAnnotationList';
import { useAnnotation } from 'components/Annotation/useAnnotation';
import { useSidebarConfig } from 'components/Folders/Sidebar';
import { AnnotationGetOut, DocumentGetOut, SchemaGetOut } from 'Definitions/ApiTypes';
import React, { useMemo, useState } from 'react';
import { useSchema } from 'services/documents';
import { PERMISSION, useCan } from 'utils/permissions';
import { EmailFlyout } from '../DocumentsTable/EmailFlyout';
import { useTranslate } from '../Internationalisation/useTranslate';

export const BOTTOM_BAR_HEIGHT = '42px';

export const useBottomBarButtonStyle = () => {
  const { euiTheme } = useEuiTheme();
  return {
    borderRadius: '6px',
    height: '32px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: euiTheme.colors.mediumShade,
    // background: euiTheme.colors.lightestShade,
  };
};

export const BottomBar = (props: {
  documentViewerType: string;
  document: DocumentGetOut;
}) => {
  const can = useCan();
  const { sidebarConfig, isSidebarOpen, isSidebarDocked } = useSidebarConfig();
  const { euiTheme } = useEuiTheme();
  const isDocumentProcessed = useAnnotationStore((state) => state.isDocumentProcessed);
  const [isDocumentFlyoutVisible, setIsDocumentFlyoutVisible] = useState(false);
  const [isEmailFlyoutVisible, setIsEmailFlyoutVisible] = useState(false);
  const intl = useTranslate();

  const [
    isDocumentProcessingFlyoutVisible,
    setIsDocumentProcessingFlyoutVisible,
  ] = useState(false);

  const { useGetAnnotation } = useAnnotation();
  const annotationInStore = useAnnotationStore(
    (state) => state.annotation
  ) as AnnotationGetOut;
  const { data: annotation } = useGetAnnotation(annotationInStore?.id) as {
    data: AnnotationGetOut;
  };
  const { data: schema } = useSchema(annotation?.schema_id, {
    enabled: !!annotation?.schema_id,
  }) as { data: SchemaGetOut };

  const emailType = useMemo(() => {
    return props.document.email_id && props.document.email_part;
  }, [props.document]);

  if (isDocumentProcessed && !annotation) return null;

  const schemaName = schema?.name || 'Process';
  const documentProcessingButton = (
    <EuiButtonEmpty
      color="text"
      isDisabled={!can.can(PERMISSION.SCHEMAS_READ)}
      onClick={() => setIsDocumentProcessingFlyoutVisible(true)}
      size="s"
      title={intl.formatMessage({
        id: 'bottomBar.button.processDocument',
        defaultMessage: 'Process current document',
      })}
      aria-label={schemaName}
      style={{ maxWidth: '15em' }}
    >
      {schemaName}
    </EuiButtonEmpty>
  );

  const documentProcessingFlyout = isDocumentProcessingFlyoutVisible && (
    <DocumentProcessingFlyout
      documentId={props.document.id}
      documentSchemaId={props.document.schema_id}
      setIsFlyoutVisible={setIsDocumentProcessingFlyoutVisible}
    />
  );

  const documentButton = (
    <EuiFlexGroup alignItems={'center'} gutterSize={'xs'}>
      <EuiFlexItem>
        <EuiButtonEmpty
          color="text"
          onClick={() => setIsDocumentFlyoutVisible(!isDocumentFlyoutVisible)}
          size="s"
          title={props.document.original_file_name}
          aria-label={props.document.original_file_name}
          style={{ maxWidth: '20em' }}
        >
          {props.document.original_file_name}
        </EuiButtonEmpty>
      </EuiFlexItem>
      {emailType && (
        <EuiFlexItem>
          <EuiButtonIcon
            iconType={emailType === 'body' ? 'email' : 'paperClip'}
            aria-label="email_document"
            onClick={() => setIsEmailFlyoutVisible(true)}
          />
          {isEmailFlyoutVisible && (
            <EmailFlyout
              setIsFlyoutOpen={setIsEmailFlyoutVisible}
              emailId={props.document.email_id}
            ></EmailFlyout>
          )}
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );

  const documentFlyout = isDocumentFlyoutVisible && (
    <DocumentPropertiesFlyout
      documentId={props.document.id}
      setIsFlyoutVisible={setIsDocumentFlyoutVisible}
    />
  );

  const bar = (
    <EuiBottomBar
      affordForDisplacement={false}
      position="fixed"
      left={isSidebarDocked && isSidebarOpen ? sidebarConfig.width : 0}
      style={{
        height: BOTTOM_BAR_HEIGHT,
        borderRadius: euiTheme.border.radius.small,
        borderTopStyle: 'solid',
        borderWidth: '1px',
        borderColor: euiTheme.colors.lightShade,
        background: euiTheme.colors.emptyShade,
      }}
      paddingSize="none"
    >
      {documentFlyout}
      {documentProcessingFlyout}
      <EuiFlexGroup
        justifyContent="spaceBetween"
        alignItems="center"
        gutterSize="none"
        responsive={false}
        style={{
          padding: '0px 8px',
          height: '100%',
        }}
      >
        <EuiFlexItem grow={false}>
          {isDocumentProcessed ? (
            <AnnotationStatusButton annotation={annotation} />
          ) : (
            <div></div>
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ paddingLeft: '8px' }}>
          <EuiFlexGroup alignItems="center" gutterSize="s" responsive={false}>
            <EuiFlexItem grow={false}>{documentProcessingButton}</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText color="subdued">|</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>{documentButton}</EuiFlexItem>
            <EuiFlexItem grow={false}>
              {isDocumentProcessed && props.documentViewerType != 'spreadsheet' && (
                <AnnotationPagesMenu />
              )}
              {!isDocumentProcessed &&
                ['pdf', 'image'].includes(props.documentViewerType) && (
                  <FileBrowserController />
                )}
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ paddingLeft: '8px' }}>
              {isDocumentProcessed ? <AnnotationSettingsMenu /> : <div></div>}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiBottomBar>
  );

  return <div>{bar}</div>;
};
