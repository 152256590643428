import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldPassword,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { usePassword } from './usePassword';

export function ChangePasswordModal(props: {
  setIsModalVisible: (state: boolean) => void;
}) {
  const { updatePasswordMutation } = usePassword();
  const intl = useTranslate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const handleCloseModal = () => {
    props.setIsModalVisible(false);
  };

  const handleSubmitForm = (data, e) => {
    e.preventDefault();

    const payload = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };
    updatePasswordMutation.mutate(payload, {
      onSuccess: () => handleCloseModal(),
    });
  };

  const { ref: currentPasswordRef, ...currentPasswordRest } = register(
    'currentPassword',
    {
      required: true,
    }
  );

  const { ref: newPasswordRef, ...newPasswordRest } = register('newPassword', {
    required: true,
    pattern: {
      value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{12,}$/,
      message: intl.formatMessage({
        id: 'updatePasswordModal.validation.newPasswordPattern',
        defaultMessage:
          'The password has to be at least 12 characters long and ' +
          'include at least one lower case, one upper case character and one digit.',
      }),
    },
  });

  const { ref: newPasswordAgainRef, ...newPasswordAgainRest } = register(
    'newPasswordAgain',
    {
      required: true,
      validate: (value) => {
        const { newPassword } = getValues();
        return (
          newPassword === value ||
          intl.formatMessage({
            id: 'updatePasswordModal.validation.passwordsDoNotMatch',
            defaultMessage: 'Passwords do not match.',
          })
        );
      },
    }
  );

  const form = (
    <EuiForm
      id="passwordModalForm"
      component="form"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <EuiFormRow
        label={intl.formatMessage({
          id: 'udpatePasswordModal.currentPassword',
          defaultMessage: 'Current password',
        })}
        error={[errors?.currentPassword?.message]}
        isInvalid={!!errors?.currentPassword}
      >
        <EuiFieldPassword
          {...currentPasswordRest}
          autoFocus
          fullWidth
          type="dual"
          name="currentPassword"
          autoComplete="off"
          inputRef={currentPasswordRef}
        />
      </EuiFormRow>
      <EuiFormRow
        label={intl.formatMessage({
          id: 'udpatePasswordModal.newPassword',
          defaultMessage: 'New password',
        })}
        error={[errors?.newPassword?.message]}
        isInvalid={!!errors?.newPassword}
      >
        <EuiFieldPassword
          {...newPasswordRest}
          fullWidth
          type="dual"
          name="newPassword"
          autoComplete="off"
          inputRef={newPasswordRef}
        />
      </EuiFormRow>
      <EuiFormRow
        label={intl.formatMessage({
          id: 'udpatePasswordModal.newPasswordAgain',
          defaultMessage: 'New password (again)',
        })}
        error={[errors?.newPasswordAgain?.message]}
        isInvalid={!!errors?.newPasswordAgain}
      >
        <EuiFieldPassword
          {...newPasswordAgainRest}
          fullWidth
          type="dual"
          name="newPasswordAgain"
          autoComplete="off"
          inputRef={newPasswordAgainRef}
        />
      </EuiFormRow>
    </EuiForm>
  );

  const modal = (
    <EuiModal onClose={handleCloseModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>
            <Translate
              id="headerUserMenu.changePassword"
              defaultMessage="Change password"
            />
          </h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>{form}</EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={handleCloseModal}>
          {intl.message.cancel}
        </EuiButtonEmpty>
        <EuiButton
          type="submit"
          form="passwordModalForm"
          isDisabled={!isEmpty(errors)}
          isLoading={updatePasswordMutation.isLoading}
          fill
        >
          <Translate id="button.update" defaultMessage="Update" />
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );

  return <div>{modal}</div>;
}
