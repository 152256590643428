// @ts-nocheck
// TODO: Check Babel and polyfill setup
import { EuiContext, EuiProvider } from '@elastic/eui';
import createCache from '@emotion/cache';
import { BOTTOM_BAR_HEIGHT } from 'components/BottomBar/BottomBar';
import { PageLoader } from 'components/InlineLoader/InlineLoader';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { useGlobalSettings } from 'utils/useGlobalSettings';
import { ErrorComponent } from './components/Errors/ErrorComponent';
import { AppRoutesAuth } from './routes';
import { getThemeMode, ThemeSelector } from './themes/ThemeSelector';
import { queryClient } from './utils/client';

export function App() {
  const { isLoading, globalSettings, translations } = useGlobalSettings();

  return isLoading ? (
    <PageLoader />
  ) : (
    <AppWithSettings globalSettings={globalSettings} translations={translations} />
  );
}

function AppWithSettings({ globalSettings, translations }) {
  // Keycloak is not used for authentication. Internal IDP auth is used instead.
  // const setToken = useKeycloakStore((state) => state.setToken);
  // const setKeycloakInStore = useKeycloakStore((state) => state.setKeycloak);
  // // https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc
  // const [keycloak] = useState(
  //   Keycloak({
  //     url: globalSettings.keycloak_server_url + '/auth',
  //     realm: globalSettings.keycloak_realm_name,
  //     clientId: globalSettings.keycloak_client_id,
  //   })
  // );

  // useEffect(() => {
  //   setKeycloakInStore(keycloak);
  // }, [keycloak, setKeycloakInStore]);

  const defaultCache = createCache({ key: 'css' });
  defaultCache.compat = true;

  const i18n = {
    mapping: translations.messages,
    formatNumber: (value) => new Intl.NumberFormat(translations.locale).format(value),
  };

  const app = (
    <BrowserRouter
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <EuiContext i18n={i18n}>
        {/* https://formatjs.io/docs/getting-started/message-distribution#distribution */}
        <IntlProvider
          locale={translations.locale}
          defaultLocale="en"
          messages={translations.messages}
        >
          <QueryClientProvider client={queryClient}>
            <EuiProvider colorMode={getThemeMode()} cache={defaultCache}>
              <ThemeSelector>
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <AppRoutesAuth />
                </ErrorBoundary>
              </ThemeSelector>
            </EuiProvider>
            <ReactQueryDevtools
              initialIsOpen={false}
              position="bottom-right"
              toggleButtonProps={{
                style: { marginBottom: BOTTOM_BAR_HEIGHT, opacity: 0.5 },
              }}
            />
          </QueryClientProvider>
        </IntlProvider>
      </EuiContext>
    </BrowserRouter>
  );

  return (
    <React.StrictMode>
      {/* <ReactKeycloakProvider
        authClient={keycloak}
        autoRefreshToken={true}
        // Show loading page before Keycloak is initialized only if Keycloak is used.
        // We include Keycloak provider also when Keycloak is not used so
        // Keycloak hooks don't complain about missing context.
        LoadingComponent={isKeycloakUsed() && <PageLoader />}
        initOptions={
          isKeycloakUsed() && {
            // Redirect user automatically to Keycloak login page if not authenticated.
            onLoad: 'login-required',
            checkLoginIframe: false,
          }
        }
        onTokens={(tokens) => {
          tokens?.token && setToken(tokens['token']);
        }}
      > */}
      {app}
      {/* </ReactKeycloakProvider> */}
    </React.StrictMode>
  );
}
