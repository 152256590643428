import SplitDatapointAnnotationPopup from 'components/Annotation/DocumentPanel/DatapointAnnotationPopups/SplitDatapointAnnotationPopup';
import { atom, useAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import Annotation from 'react-image-annotation';
import reactImageSize from 'react-image-size';
import { VISIBILITY } from 'settings/constants';
import client from '../../../../utils/client';
import { useIsMounted } from '../../../../utils/useIsMounted';
import { InLineLoader } from '../../../InlineLoader/InlineLoader';
import { useAnnotationStore } from '../../Annotation';
import { useActivateDatapoint } from '../../AnnotationPanel/useActivateDatapoint';
import {
  documentSplit,
  focusTableAtom,
  hideSplitBarAtom,
  isExtendedAnnotationEnabledAtom,
  showBoundingBoxesAtom,
  showSearchInDocumentBoxAtom,
} from '../../AnnotationState';
import { SplitBar } from '../../SplitBar';
import { TableGridContainer } from '../../TableGridConfig/TableGridContainer';
import { AnnotationPopup } from '../DatapointAnnotationPopups/AnnotationPopup';
import { AnnotationBoxSelector } from './AnnotationBoxSelector';
import { AnnotationHighlight } from './AnnotationHighlight';
import { useDocumentClasses } from './useDocumentClasses';
import { useDocumentClassification } from './useDocumentClassification';

export const showTableGridAnnotationAtom = atom(false);

export function ImageAnnotation({
  annotationId,
  pageIndex,
  pageUrl,
  pageWidth,
  pageHeight,
  schemaId,
  searchResults,
  pageNumber,
}) {
  const annotationStore = useAnnotationStore();
  const [imageUrl, setImageUrl] = useState();
  const [originalImageSize, setOriginalImageSize] = useState();
  const [isImageLoading, setIsImageLoading] = useState(true);
  // Currently edited field annotation created by annotation package.
  const [annotation, setAnnotation] = useState({});
  const [showBoundingBoxes] = useAtom(showBoundingBoxesAtom);
  const [isExtendedAnnotationEnabled] = useAtom(isExtendedAnnotationEnabledAtom);
  const [isSearchBoxVisible] = useAtom(showSearchInDocumentBoxAtom);
  const {
    pinnedDatapoint,
    activeDatapoint,
    setActiveDatapoint,
  } = useActivateDatapoint();
  const [showTableGridAnnotation, setShowTableGridAnnotationAtom] = useAtom(
    showTableGridAnnotationAtom
  );
  const annotationContent = useAnnotationStore((state) => state.annotationContent);
  const [showMainTableAnnotation, setShowMainTableAnnotation] = useState(false);
  const [showDrawOverlay, setDrawOverlay] = useState(true);
  const [annotationDrawMode, setAnnotationDrawMode] = useState(undefined);
  const [annotations, setAnnotations] = useState([]);
  const [annotationUpdated, setAnnotationUpdated] = useState(false);

  const showInitialTableAnnotation = useMemo(() => {
    return showTableGridAnnotation && !showMainTableAnnotation;
  }, [showTableGridAnnotation, showMainTableAnnotation]);

  const documentClasses = useDocumentClasses(schemaId);
  const splitDocuments = useDocumentClassification();
  const [isDocumentSplit] = useAtom(documentSplit);
  const isMounted = useIsMounted();
  const [focusedTable] = useAtom(focusTableAtom);
  const [, setHideSplitBar] = useAtom(hideSplitBarAtom);

  useEffect(() => {
    setShowTableGridAnnotationAtom(false);
    return () => {
      setShowTableGridAnnotationAtom(false);
    };
  }, []);

  const currentImageSize = useMemo(() => {
    return (
      originalImageSize && {
        width: pageWidth,
        height: (originalImageSize.height * pageWidth) / originalImageSize.width,
      }
    );
  }, [originalImageSize, pageWidth]);

  useEffect(() => {
    let currentAnnotations = isSearchBoxVisible
      ? getSearchResultsAnnotations(pageIndex)
      : getDocumentAnnotations() || [];

    // We need current image size in annotation data, so it is available in AnnotationBox
    // where Rnd needs absolute coordinates instead of relative ones.
    // It is used only by renderHighlight function.
    if (currentAnnotations) {
      currentAnnotations = currentAnnotations.map((annotation) => {
        const data = { ...annotation.data, imageSize: currentImageSize };
        return { ...annotation, data };
      });
      setAnnotations(currentAnnotations);
    }
  }, [
    isSearchBoxVisible,
    searchResults,
    pageIndex,
    showBoundingBoxes,
    isExtendedAnnotationEnabled,
    currentImageSize,
    annotationId,
    // We have to get new annotations when the content is changed in store.
    // It can be loaded a bit later than this component is rendered with new annotationId.
    annotationContent,
  ]);

  useEffect(() => {
    if (annotationUpdated) {
      let currentAnnotations = isSearchBoxVisible
        ? getSearchResultsAnnotations(pageIndex)
        : getDocumentAnnotations();

      currentAnnotations = currentAnnotations.map((annotation) => {
        const data = { ...annotation.data, imageSize: currentImageSize };
        return { ...annotation, data };
      });

      setAnnotations(currentAnnotations);
      setAnnotationUpdated(false);
    }
  }, [annotationUpdated]);

  useEffect(() => {
    client(`${pageUrl}/content`)
      .then((content) => {
        const url = URL.createObjectURL(content);
        if (isMounted.current) {
          setImageUrl(url);
        }
        return reactImageSize(url);
      })
      .then(({ width, height }) => {
        if (isMounted.current) {
          setOriginalImageSize({ width: width, height: height });
        }
        if (isMounted.current) {
          setIsImageLoading(false);
        }
      })
      .catch(console.log);
  }, [pageUrl]);

  if (isImageLoading) {
    return (
      <div style={{ height: pageHeight }}>
        <InLineLoader />
      </div>
    );
  }

  const onChange = (changedAnnotation) => {
    if (JSON.stringify(annotation) !== JSON.stringify(changedAnnotation)) {
      setAnnotationDrawMode(changedAnnotation?.selection?.mode);
      setAnnotation(changedAnnotation);
      if (activeDatapoint !== undefined && !pinnedDatapoint) {
        setActiveDatapoint(undefined);
      }
    }
  };

  const resolveEditor = (props) => {
    if (isDocumentSplit) {
      return renderSplitDatapointEditor(props);
    }

    return focusedTable.tableComponent
      ? renderTableDatapointEditor(props)
      : renderDefaultEditor(props);
  };

  const updateAnnotationState = (annotation) => {
    setAnnotationDrawMode(undefined);
    setAnnotation(annotation);
    setAnnotationUpdated(true);
  };

  const renderSplitDatapointEditor = (props) => {
    return (
      <SplitDatapointAnnotationPopup
        geometry={props.annotation.geometry}
        annotationId={annotationId}
        annotation={props.annotation}
        pageIndex={pageIndex}
        schemaId={schemaId}
        setAnnotation={updateAnnotationState}
      />
    );
  };

  const renderDefaultEditor = (props) => {
    const annotationGeometry = props.annotation.geometry;
    const geometry = {
      xMin: annotationGeometry.x / 100,
      yMin: annotationGeometry.y / 100,
      xMax: (annotationGeometry.x + annotationGeometry.width) / 100,
      yMax: (annotationGeometry.y + annotationGeometry.height) / 100,
    };

    const panelStyle = {
      minWidth: '50%',
      position: 'absolute',
      fontSize: 12,
      left: `${
        annotationGeometry.x < 50
          ? annotationGeometry.x
          : annotationGeometry.x + annotationGeometry.width - 50
      }%`,
      top: `${annotationGeometry.y + annotationGeometry.height}%`,
      zIndex: VISIBILITY.ANNOTATION_POPUP,
    };

    return (
      <AnnotationPopup
        selectionBounds={geometry}
        pageIndex={pageIndex}
        schemaId={schemaId}
        annotation={props.annotation}
        annotationId={annotationId}
        setAnnotation={updateAnnotationState}
        selectNone={() => {}}
        isPositionChanged={true}
        panelStyle={panelStyle}
      />
    );
  };

  const renderTableDatapointEditor = (props) => {
    if (pinnedDatapoint?.isTableDatapoint) {
      const annotationGeometry = props.annotation.geometry;
      const geometry = {
        xMin: annotationGeometry.x / 100,
        yMin: annotationGeometry.y / 100,
        xMax: (annotationGeometry.x + annotationGeometry.width) / 100,
        yMax: (annotationGeometry.y + annotationGeometry.height) / 100,
      };

      const panelStyle = {
        minWidth: '30%',
        position: 'absolute',
        fontSize: 12,
        left: `${
          annotationGeometry.x < 50
            ? annotationGeometry.x
            : annotationGeometry.x + annotationGeometry.width - 30
        }%`,
        top: `${annotationGeometry.y + annotationGeometry.height}%`,
        zIndex: VISIBILITY.ANNOTATION_POPUP,
      };
      return (
        <AnnotationPopup
          selectionBounds={geometry}
          pageIndex={pageIndex}
          schemaId={schemaId}
          annotation={annotation}
          annotationId={annotationId}
          setAnnotation={updateAnnotationState}
          selectNone={() => {}}
          isPositionChanged={false}
          panelStyle={panelStyle}
          isUpdate={false}
        />
      );
    }

    return null;
  };

  function renderEditPopup({ annotation }) {
    if (
      isSearchBoxVisible ||
      annotationDrawMode === 'SELECTING' ||
      annotationDrawMode === 'EDITING'
    ) {
      return;
    }

    const annotationGeometry = annotation.geometry;
    const geometry = {
      xMin: annotationGeometry.x / 100,
      yMin: annotationGeometry.y / 100,
      xMax: (annotationGeometry.x + annotationGeometry.width) / 100,
      yMax: (annotationGeometry.y + annotationGeometry.height) / 100,
    };

    const panelStyle = {
      minWidth: '30%',
      position: 'absolute',
      fontSize: 12,
      left: `${
        annotationGeometry.x < 50
          ? annotationGeometry.x
          : annotationGeometry.x + annotationGeometry.width - 30
      }%`,
      top: `${annotationGeometry.y + annotationGeometry.height}%`,
      zIndex: VISIBILITY.ANNOTATION_POPUP,
    };

    return (
      <AnnotationPopup
        selectionBounds={geometry}
        pageIndex={pageIndex}
        schemaId={schemaId}
        annotation={annotation}
        annotationId={annotationId}
        setAnnotation={updateAnnotationState}
        selectNone={() => {}}
        isPositionChanged={false}
        panelStyle={panelStyle}
        isUpdate={true}
      />
    );
  }

  function renderHighlight({ annotation, active }) {
    if (annotationDrawMode === 'SELECTING' || annotationDrawMode === 'EDITING') {
      return;
    }
    return (
      <AnnotationHighlight
        annotation={annotation}
        active={active}
        annotationDrawMode={annotationDrawMode}
      />
    );
  }

  function getDocumentAnnotations() {
    return showBoundingBoxes
      ? annotationStore.getImageAnnotations(
          isExtendedAnnotationEnabled ? null : ['value']
        )[pageIndex]
      : [];
  }

  function getDocumentActiveAnnotations() {
    return pinnedDatapoint
      ? [pinnedDatapoint.id]
      : !activeDatapoint
      ? []
      : [activeDatapoint.id];
  }

  function getSearchResultsAnnotations(pageIndex) {
    return searchResults
      .filter((item) => item.page_index === pageIndex)
      .map((item, idx) => ({
        data: {
          id: `search-result-${idx}`,
          annotation_class: item.focused ? 'value' : 'keyword',
        },
        geometry: {
          type: 'RECTANGLE',
          x: item.box.x_min * 100,
          y: item.box.y_min * 100,
          width: (item.box.x_max - item.box.x_min) * 100,
          height: (item.box.y_max - item.box.y_min) * 100,
        },
      }));
  }

  function getSearchResultsActiveAnnotations() {
    return [];
  }

  const canDrawAnnotationBox = () => {
    return showInitialTableAnnotation || showMainTableAnnotation;
  };

  const activeAnnotationComparator = (a, b) => {
    return a.data.id === b;
  };

  const activeAnnotations = isSearchBoxVisible
    ? getSearchResultsActiveAnnotations()
    : getDocumentActiveAnnotations();

  const AnnotationBox = () => {
    return (
      <>
        {/* https://github.com/Secretmapper/react-image-annotation/issues/3#issuecomment-389743850 */}
        <div
          onMouseLeave={() => setDrawOverlay(true)}
          onMouseEnter={() => setDrawOverlay(false)}
          onMouseUpCapture={() => splitDocuments && setHideSplitBar(false)}
          style={{
            width: pageWidth,
            objectFit: 'contain',
            position: 'relative',
            display: 'block',
            margin: 'auto',
          }}
        >
          <Annotation
            key={imageUrl}
            src={imageUrl}
            alt={`Page ${pageIndex + 1}`}
            activeAnnotationComparator={activeAnnotationComparator}
            activeAnnotations={activeAnnotations}
            annotations={annotations}
            type="RECTANGLE"
            value={annotation}
            onChange={onChange}
            renderContent={renderEditPopup}
            renderHighlight={renderHighlight}
            renderEditor={resolveEditor}
            renderSelector={AnnotationBoxSelector}
            disableOverlay={true}
            disableAnnotation={canDrawAnnotationBox()}
            disableSelector={canDrawAnnotationBox()}
            className="shadowDocumentPage"
          />
          {showTableGridAnnotation && (
            <TableGridContainer
              pageWidth={pageWidth}
              pageIndex={pageIndex}
              annotationId={annotationId}
              showInitialTableAnnotation={showInitialTableAnnotation}
              showMainTableAnnotation={showMainTableAnnotation}
              showDrawOverlay={showDrawOverlay}
              setShowMainTableAnnotation={setShowMainTableAnnotation}
              originalImageSize={originalImageSize}
            />
          )}
          {isDocumentSplit && splitDocuments && (
            <SplitBar
              pageIndex={pageIndex}
              documentClasses={documentClasses}
              documentClassification={splitDocuments}
              pageNumber={pageNumber}
            />
          )}
        </div>
      </>
    );
  };

  return AnnotationBox();
}
