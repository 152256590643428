import * as euiVarsDark from '@elastic/eui/dist/eui_theme_dark.json';
import * as euiVarsLight from '@elastic/eui/dist/eui_theme_light.json';
import { getThemeMode } from '../themes/ThemeSelector';
import { ThemeMode } from './ThemeMode';

export const FE_URL = `${window.location.protocol}//${window.location.host}`;
export const LOGIN_URL = `${FE_URL}/login`;
export const API_URL = process.env.REACT_APP_API_URL
  ? // For local development we load API URL from .env.development (development API URL)
    process.env.REACT_APP_API_URL
  : window.location.hostname === 'localhost'
  ? // For local production build we use fixed URL where local production API runs
    'http://localhost:8000'
  : window.location.hostname === 'idp-fe'
  ? // For testing from inside Docker container
    'http://idp-core-api:8000'
  : // In cloud we use the same domain for FE and API.
    // Requests are forwarded to API service based on /api prefix in URL.
    `https://${window.location.host}`;

export const DEFAULT_THEME = ThemeMode.dark;
export const THEME_MODE = getThemeMode();
export const THEME =
  THEME_MODE === ThemeMode.dark ? euiVarsDark.default : euiVarsLight.default;

// For EUI components zIndexes see:
// https://github.com/elastic/eui/blob/f7f233647505cc717b0766ddb643bcdd59fd2cfd/src-docs/src/views/theme/_json/eui_theme_light.json#L721
export const VISIBILITY = {
  // Tooltip=99999
  POPOVER: 99000,
  // Toasts=8000
  // Modal=8000
  // Mask=6000
  // Flyout=6000
  FLOATING_TABLE_ANNOTATIONS: 5400,
  ANNOTATION_POPUP: 5300,
  FLOATING_MENU: 5200,
  // With 3000 the bounding box would be above popover with page and zoom slider in bottom bar
  BOUNDING_BOX_RND: 2900,
  SCHEMA_SELECTOR_OPTION: 1000,
};

export const SUPPORTED_LANGUAGES = {
  en: 'English',
  de: 'Deutsch',
};

export const IDP_USER_ID = '00000000-0000-0000-0000-000000000000';
