import React from 'react';
import { defineMessage, FormattedDate, useIntl } from 'react-intl';

export function asDateTime(datetime: any, showSeconds = false, showTimeZone = false) {
  return (
    <FormattedDate
      value={datetime}
      year="numeric"
      month="short"
      day="numeric"
      hour="numeric"
      minute="numeric"
      second={showSeconds ? 'numeric' : undefined}
      timeZoneName={showTimeZone ? 'short' : undefined}
    />
  );
}

export function asDate(datetime: any) {
  return <FormattedDate value={datetime} year="numeric" month="short" day="numeric" />;
}

export const useTranslate = () => {
  const intl = useIntl();

  const formatMessage = intl.formatMessage;
  const formatDate = intl.formatDate;

  const $t = ({ id, defaultMessage }: { id: string; defaultMessage: string }) => {
    return intl.formatMessage({
      id: id,
      defaultMessage: defaultMessage,
    });
  };

  const asDateTime = (datetime: any, showSeconds = false, showTimeZone = false) => {
    return intl.formatDate(datetime, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: showSeconds ? 'numeric' : undefined,
      timeZoneName: showTimeZone ? 'short' : undefined,
    });
  };

  const asDate = (date: any) => {
    return intl.formatDate(date, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const messageClickToCopyToClipboard = defineMessage({
    id: 'command.copyToClipboard',
    defaultMessage: 'click to copy to clipboard',
  });

  const message = {
    yes: $t({ id: 'general.yes', defaultMessage: 'Yes' }),
    no: $t({ id: 'general.no', defaultMessage: 'No' }),
    cancel: $t({ id: 'general.cancel', defaultMessage: 'Cancel' }),
    edit: $t({ id: 'general.edit', defaultMessage: 'Edit' }),
    update: $t({ id: 'general.update', defaultMessage: 'Update' }),
    delete: $t({ id: 'general.delete', defaultMessage: 'Delete' }),
    deleted: $t({ id: 'general.deleted', defaultMessage: 'Deleted' }),
    save: $t({ id: 'general.save', defaultMessage: 'Save' }),
    saved: $t({ id: 'general.saved', defaultMessage: 'Saved' }),
    create: $t({ id: 'general.create', defaultMessage: 'Create' }),
    created: $t({ id: 'general.created', defaultMessage: 'Created' }),
    description: $t({ id: 'general.description', defaultMessage: 'Description' }),
    options: $t({ id: 'general.options', defaultMessage: 'Options' }),
    settings: $t({ id: 'general.settings', defaultMessage: 'Settings' }),
    folder: $t({ id: 'general.folder', defaultMessage: 'Folder' }),
    skill: $t({ id: 'general.skill', defaultMessage: 'Skill' }),
  };

  return {
    asDate: asDate,
    asDateTime: asDateTime,
    $t: $t,
    formatMessage: formatMessage,
    formatDate: formatDate,
    message: message,
    messageClickToCopyToClipboard: messageClickToCopyToClipboard,
  };
};
