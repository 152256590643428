import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { DatapointType } from '../../../Definitions/DatapointType';
import { SchemaElementKind } from '../../../Definitions/SchemaElementKind';
import { useSchema } from '../../../services/documents';
import { getAllDatapoints, getSectionAnnotationItemId } from '../../Annotation/utils';
import { useAnnotationStore } from '../Annotation';
import {
  elementsWithEmptyValueAtom,
  notExtractedElementsAtom,
} from '../AnnotationState';
import { useAnnotation } from '../useAnnotation';

export function useAnnotationItems(annotationId, schemaId, annotationIdGroundTruth) {
  const annotationStore = useAnnotationStore();
  const { useGetAnnotationContent } = useAnnotation();
  const setAnnotationContent = useAnnotationStore(
    (state) => state.setAnnotationContent
  );
  const setAnnotationAllDatapoints = useAnnotationStore(
    (state) => state.setAllDatapoints
  );
  const setSchema = useAnnotationStore((state) => state.setSchema);
  const {
    isIdle: isIdleAnnotationContent,
    data: annotationContent,
    isLoading: isLoadingAnnotation,
    // When comparing annotation with ground truth annotation, load values without user updates
  } = useGetAnnotationContent(annotationId, !!annotationIdGroundTruth, {
    enabled: !!annotationId,
    onSuccess: (data) => setAnnotationContent(data.content),
  });
  const annotationContentGroundTruth = useGetAnnotationContent(
    annotationIdGroundTruth,
    false,
    {
      enabled: !!annotationIdGroundTruth,
    }
  );

  const { isIdle: isIdleSchema, data: schema, isLoading: isLoadingSchema } = useSchema(
    schemaId,
    {
      enabled: !!schemaId,
      onSuccess: setSchema,
    }
  );

  useEffect(() => {
    if (schema && annotationContent) {
      setAnnotationAllDatapoints(getAllDatapoints(annotationContent?.content, schema));
    }
  }, [annotationContent, schema]);

  const [chosenNotExtractedElement, setChosenNotExtractedElement] = useState();
  const [notExtractedElements, setNotExtractedElements] = useAtom(
    notExtractedElementsAtom
  );
  const [, setElementsWithEmptyValue] = useAtom(elementsWithEmptyValueAtom);

  const annotationSections = useMemo(() => {
    const sections = annotationContent?.content?.elements || [];

    // If comparing with other annotation, add compared values to annotation
    if (annotationContentGroundTruth.data) {
      const sectionsToCompare =
        annotationContentGroundTruth.data?.content?.elements || [];
      let toCompareHash = {};
      for (const section of sectionsToCompare) {
        for (const element of section.elements) {
          if (element.annotation_class == 'value') {
            toCompareHash[element.schema_element_id] = element.normalized_value;
          }
        }
      }
      for (const section of sections) {
        for (const element of section.elements) {
          // We don't compare lists and tables
          if (element.kind === 'datapoint' && element.annotation_class === 'value') {
            element['normalized_value_compared'] =
              toCompareHash[element.schema_element_id];
          }
        }
      }
    }
    return sections;
  }, [annotationContent, annotationContentGroundTruth.data]);

  const schemaSections = useMemo(() => schema?.content?.elements || [], [schema]);

  useEffect(() => {
    const notExtractedItems = getNotExtractedElements();
    setNotExtractedElements(notExtractedItems);

    const emptyElements = getElementsWithEmptyValue();
    setElementsWithEmptyValue(emptyElements);
  }, [annotationSections, schemaSections]);

  function getElementsWithEmptyValue() {
    const nullElements = [];

    for (const schemaSection of schemaSections) {
      if (schemaSection.elements) {
        for (const element of schemaSection.elements) {
          const schemaElementAnnotationItems = getSectionElementItems(
            annotationSections,
            element.id
          );

          const nullValueDatapoint = schemaElementAnnotationItems.find(
            (item) =>
              item.kind === 'datapoint' &&
              item.annotation_class === 'value' &&
              ((!item.value && element.type !== DatapointType.Enum) ||
                (!item.normalized_value && element.type === DatapointType.Enum))
          );

          if (nullValueDatapoint) {
            nullElements.push({
              value: element.id,
              label: element.label,
              kind: element.kind,
              is_hidden: element.is_hidden,
              datapointId: nullValueDatapoint.id,
            });
          }
        }
      }
    }

    return nullElements;
  }

  function getNotExtractedElements() {
    const notExtractedElements = [];

    for (const schemaSection of schemaSections) {
      if (schemaSection.elements) {
        for (const element of schemaSection.elements) {
          const schemaElementAnnotationItems = getSectionElementItems(
            annotationSections,
            element.id
          );

          if (
            element.kind === 'datapoint' &&
            !schemaElementAnnotationItems.find(
              (item) => item.annotation_class === 'value'
            )
          ) {
            notExtractedElements.push({
              value: element.id,
              label: element.label,
              kind: element.kind,
              is_hidden: element.is_hidden,
            });
          }
        }
      }
    }

    return notExtractedElements;
  }

  function onNotExtractedElementChange(value) {
    setChosenNotExtractedElement(value);
  }

  function addEmptyAnnotation() {
    if (chosenNotExtractedElement) {
      const parentId = getSectionAnnotationItemId(
        chosenNotExtractedElement,
        schema.content,
        annotationContent.content
      );

      const selectedNotExtractedItem = notExtractedElements.find(
        (item) => item.value === chosenNotExtractedElement
      );

      const payload = {
        schema_element_kind:
          selectedNotExtractedItem?.kind || SchemaElementKind.Datapoint,
        schema_element_id: chosenNotExtractedElement,
        parent_id: parentId,
        annotation_class: 'value',
        value: null,
        normalized_value: null,
      };

      annotationStore.createAnnotationItem(payload);
    }
  }

  function getSectionElementItems(annotationSections, schemaElementId) {
    const items = [];
    for (const section of annotationSections) {
      for (const element of section.elements) {
        if (element.schema_element_id === schemaElementId) {
          items.push(element);
        }
      }
    }
    items.sort((a, b) => {
      return a.id > b.id ? 1 : -1;
    });
    return items;
  }

  return {
    isLoading:
      isIdleAnnotationContent || isIdleSchema || isLoadingAnnotation || isLoadingSchema,
    schemaSections: schemaSections,
    annotationSections: annotationSections,
    getSectionElementItems: getSectionElementItems,
    addEmptyAnnotation: addEmptyAnnotation,
    onNotExtractedElementChange: onNotExtractedElementChange,
  };
}
