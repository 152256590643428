import {
  EuiButtonIcon,
  EuiComboBox,
  EuiIcon,
  EuiPopover,
  useEuiTheme,
} from '@elastic/eui';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { ScaledTableGridContext } from './TableGridContainer';
import { sortTableGridLines } from './utils';

export function RowConfigToggle({ onEnter, onLeave, line }) {
  const rowOptions = [
    { label: 'Value', value: 'value' },
    { label: 'Header', value: 'column_header' },
    { label: 'Skip', value: 'null' },
  ];

  const { euiTheme } = useEuiTheme();
  const [backgroundColor, setBackgroundColor] = useState(euiTheme.colors.primary);
  const [selectedOptions, setSelectedOption] = useState([]);
  const [, setShowTitle] = useState(false);
  const [isPopoverOpen, setPopover] = useState(false);
  const intl = useTranslate();

  const { setScaledGrid: updateGrid } = useContext(ScaledTableGridContext);

  useEffect(() => {
    const findOption = rowOptions.filter(
      (option) => option.value === line.contentClass
    );
    if (findOption.length) {
      setSelectedOption(findOption);
    } else {
      setSelectedOption([]);
    }
  }, [line]);

  const onMouseEnter = () => {
    onEnter(line);
    setShowTitle(true);
    setBackgroundColor(euiTheme.colors.secondary);
  };

  const onMouseLeave = () => {
    onLeave(!isPopoverOpen);
    setShowTitle(false);
    setBackgroundColor(euiTheme.colors.primary);
  };

  const onColumnSchemaChange = (selectedSchema) => {
    setSelectedOption(selectedSchema);
    updateGrid((previousScaleGrid) => {
      const updatedLine = { ...line, contentClass: selectedSchema[0].value };
      const filteredGridLines = previousScaleGrid.lines.filter(
        (line) => line.id !== updatedLine.id
      );
      return {
        ...previousScaleGrid,
        lines: sortTableGridLines([...filteredGridLines, updatedLine]),
      };
    });
    togglePopover();
  };

  const togglePopover = () => {
    setShowTitle(false);
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const button = (
    <EuiButtonIcon
      onClick={togglePopover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        color: 'white',
        backgroundColor: `${backgroundColor}`,
        borderRadius: '5px 0px 0px 5px',
      }}
      iconType="pencil"
      display="fill"
      title={intl.formatMessage({
        id: 'rowConfigToggle.button.toggleSchema',
        defaultMessage: 'Toggle schema pop over',
      })}
      aria-label="Toggle schema pop over"
      size="xs"
    />
  );

  return (
    <>
      <EuiPopover
        style={{
          position: 'absolute',
          cursor: 'pointer',
          top: `${line.from.y}px`,
          left: '-25px',
          padding: '2px',
        }}
        anchorPosition="rightCenter"
        button={button}
        isOpen={isPopoverOpen}
        closePopover={closePopover}
      >
        <div style={{ width: '300px', zIndex: '99' }}>
          <EuiComboBox
            singleSelection={{ asPlainText: true }}
            options={rowOptions}
            selectedOptions={selectedOptions}
            onChange={onColumnSchemaChange}
          />
        </div>
      </EuiPopover>
      {selectedOptions[0]?.value === 'column_header' && (
        <EuiIcon
          style={{
            position: 'absolute',
            left: '-50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            top: `${line.from.y}px`,
          }}
          color="danger"
          size="l"
          type="editorTable"
          title={intl.formatMessage({
            id: 'rowConfigToggle.icon.columnHeader',
            defaultMessage: 'Column header',
          })}
        />
      )}
    </>
  );
}
