import React from 'react';
import { AnnotationBox } from './AnnotationBox';

// TODO: Enable resizing for initial box selector. Now not working properly with RnD as
// it probably conflicts with annotation package box drawing.
export function AnnotationBoxSelector({ annotation }) {
  const { geometry } = annotation;
  const color = '#ff6666';
  return (
    geometry && (
      <AnnotationBox
        geometry={geometry}
        style={{
          border: `solid 1px ${color}`,
          background: `${color + '30'}`,
          mixBlendMode: 'multiply',
        }}
      />
    )
  );
}
