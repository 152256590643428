import { EuiConfirmModal } from '@elastic/eui';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export function ChangePageModal({
  when,
  onConfirm,
  title,
  confirmText,
  cancelText,
  message = '',
}) {
  const [showPrompt, setShowPrompt] = useState(false);
  const [transition, setTransition] = useState();
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const {
      block,
      location: { pathname },
    } = navigator;

    const unblock = block((transition) => {
      const {
        location: { pathname: targetPathname },
      } = transition;

      // Note: We don't want to show the alert if the user is navigating to the same page.
      if (targetPathname === pathname) {
        return;
      }

      setShowPrompt(true);

      setTransition({
        ...transition,
        location: { pathname: '/documents' },
        retry() {
          unblock();
          transition.retry();
        },
      });
    });

    return unblock;
  }, [navigator, when]);

  const handleOK = useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        transition?.retry();
        setShowPrompt(false);
      }
    }
  }, [transition, onConfirm]);

  const handleCancel = useCallback(() => {
    setShowPrompt(false);
  }, [setShowPrompt]);

  return showPrompt ? (
    <EuiConfirmModal
      title={title}
      onConfirm={handleOK}
      onCancel={handleCancel}
      confirmButtonText={confirmText}
      cancelButtonText={cancelText}
      closable={true}
    >
      {message}
    </EuiConfirmModal>
  ) : null;
}
