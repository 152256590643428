export function getCookie(name) {
  let cookies = {};
  document.cookie.split(';').forEach(function (el) {
    let [k, v] = el.split('=');
    cookies[k.trim()] = v;
  });
  const cookieValue = cookies[name];
  return cookieValue;
}

export function deleteCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}
