import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiRadioGroup,
  EuiRadioGroupOption,
} from '@elastic/eui';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useUsers } from '../../services/users';
import { SUPPORTED_LANGUAGES } from '../../settings/constants';
import { Translate } from '../Internationalisation/translate';
import { userSettingsAtom } from './States/UserState';

export function UserSettingsModal(props: {
  setIsModalVisible: (state: boolean) => void;
}) {
  const [userSettings, setUserSettings] = useAtom(userSettingsAtom);
  const [radioIdSelected, setRadioIdSelected] = useState<string>();
  const { updateUserLanguage } = useUsers();

  useEffect(() => {
    setRadioIdSelected(userSettings.language as string);
  }, [userSettings.language]);

  const handleCloseModal = () => {
    props.setIsModalVisible(false);
  };

  const onChange = (optionId: string) => {
    setRadioIdSelected(optionId);
  };

  const radios: EuiRadioGroupOption[] = Object.entries(SUPPORTED_LANGUAGES).map(
    ([id, label]) => {
      return {
        id,
        label,
      };
    }
  );

  const handleSave = () => {
    setUserSettings({ ...userSettings, language: radioIdSelected });
    // @ts-ignore
    updateUserLanguage.mutate(radioIdSelected);
    handleCloseModal();
  };

  const modal = (
    <EuiModal onClose={handleCloseModal}>
      <EuiModalHeader>
        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <EuiModalHeaderTitle>
              <Translate id="userSettings.language" defaultMessage="Language" />
            </EuiModalHeaderTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiRadioGroup
          options={radios}
          idSelected={radioIdSelected}
          onChange={(id) => onChange(id)}
          name="radio group"
        />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={handleCloseModal}>
          <Translate id="userSettings.cancel" defaultMessage="Cancel" />
        </EuiButtonEmpty>
        <EuiButton
          type="submit"
          form="modalFormId"
          disabled={userSettings.language === radioIdSelected}
          onClick={handleSave}
          fill
        >
          <Translate id="userSettings.save" defaultMessage="Save" />
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );

  return <div>{modal}</div>;
}
