import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiRange } from '@elastic/eui';
import { pdfPageCountAtom } from 'components/Annotation/DocumentPanel/PdfBrowser';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import React from 'react';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useAnnotationStore } from '../Annotation';
import { pageRotationAngleAtom, pageScaleAtom } from './ImageBrowser';

export function FileBrowserController() {
  const pageIndex = useAnnotationStore((state) => state.focusedPageIndex);
  const [scale, setScale] = useAtom(pageScaleAtom);
  const setPageIndex = useAnnotationStore((state) => state.focusPage);
  const pdfPageCount = useAtomValue(pdfPageCountAtom);
  const pageCount = pdfPageCount || 1;
  const [rotationAngle, setRotationAngle] = useAtom(pageRotationAngleAtom);

  const MIN_ZOOM_SCALE = 0.5;
  const MAX_ZOOM_SCALE = 2;
  const zoomOut = () => setScale(roundTo(Math.max(scale - 0.1, MIN_ZOOM_SCALE)));
  const zoomIn = () => setScale(roundTo(Math.min(scale + 0.1, MAX_ZOOM_SCALE)));
  const intl = useTranslate();

  const goToPreviousPage = (
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        color="text"
        iconType="arrowLeft"
        title="Previous page"
        isDisabled={pageIndex <= 0}
        aria-label="Previous page"
        onClick={() => {
          if (pageIndex > 0) {
            setPageIndex(pageIndex - 1);
          }
        }}
      />
    </EuiFlexItem>
  );
  const goToNextPage = (
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        color="text"
        iconType="arrowRight"
        title="Next page"
        aria-label="Next page"
        isDisabled={pageIndex >= pageCount - 1}
        onClick={() => {
          if (pageIndex + 1 < pageCount) {
            setPageIndex(pageIndex + 1);
          }
        }}
      />
    </EuiFlexItem>
  );

  const rotateLeft = (
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        color="text"
        aria-label="Rotate left"
        title={intl.formatMessage({
          id: 'fileBrowserController.rotateLeft',
          defaultMessage: 'Rotate left',
        })}
        iconType="editorUndo"
        onClick={() => {
          setRotationAngle((rotationAngle - 90) % 360);
        }}
      />
    </EuiFlexItem>
  );
  const rotateRight = (
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        color="text"
        aria-label="Rotate right"
        title={intl.formatMessage({
          id: 'fileBrowserController.rotateRight',
          defaultMessage: 'Rotate right',
        })}
        iconType="editorRedo"
        onClick={() => {
          setRotationAngle((rotationAngle + 90) % 360);
        }}
      />
    </EuiFlexItem>
  );
  const pageButtons = (
    <>
      {/* {goToFirstPage} */}
      {rotateLeft}
      {rotateRight}
      {goToPreviousPage}
      {goToNextPage}
      {/* {goToLastPage} */}
    </>
  );

  function roundTo(number, precision = 10) {
    return Math.round(number * precision) / precision;
  }

  const zoomOutButton = (
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        color="text"
        iconType="minus"
        aria-label="Zoom out"
        title={intl.formatMessage({
          id: 'fileBrowserController.zoomOut',
          defaultMessage: 'Zoom out',
        })}
        onClick={() => zoomOut()}
      ></EuiButtonIcon>
    </EuiFlexItem>
  );

  const zoomInButton = (
    <EuiFlexItem grow={false}>
      <EuiButtonIcon
        color="text"
        aria-label="Zoom in"
        title={intl.formatMessage({
          id: 'fileBrowserController.zoomIn',
          defaultMessage: 'Zoom in',
        })}
        iconType="plus"
        onClick={() => zoomIn()}
      ></EuiButtonIcon>
    </EuiFlexItem>
  );

  const zoomButtons = (
    <>
      {zoomOutButton}
      {zoomInButton}
    </>
  );
  return (
    <EuiFlexGroup direction="row" gutterSize="m" responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiRange
          id="page-slider"
          value={Math.min(Math.max(1, pageIndex + 1), pageCount || 1)}
          prepend={pageButtons}
          append={pageCount && `of ${pageCount}`}
          step={1}
          autoSize={true}
          compressed
          min={1}
          readOnly={!pageCount || pageCount == 1}
          max={pageCount}
          onChange={(event) =>
            !!event.currentTarget.value &&
            setPageIndex(
              Math.min(Math.max(1, event.currentTarget.value), pageCount) - 1
            )
          }
          showInput="inputWithPopover"
          showLabels
          aria-label="Page"
        />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiRange
          id="zoom-slider"
          value={roundTo(scale * 100)}
          prepend={zoomButtons}
          append="%"
          autoSize={true}
          compressed
          min={MIN_ZOOM_SCALE * 100}
          max={MAX_ZOOM_SCALE * 100}
          onChange={(event) => setScale(event.currentTarget.value / 100)}
          showInput="inputWithPopover"
          showLabels
          aria-label="Page zoom"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
