import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
} from '@elastic/eui';
import { QueryLoader } from 'components/InlineLoader/QueryLoader';
import { SchemaSelector } from 'components/Manage/Schema/SchemaSelector';
import { useEffect, useState } from 'react';
import { PERMISSION, useCan } from 'utils/permissions';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { MoveToFolder } from '../shared/MoveToFolder';
import { useToasts } from '../Toasts/Toasts';
import { CreateFolderModal } from './CreateFolderModal';
import { FolderInfo, useFolders } from './useFolders';
export function FolderSettings(props: {
  folderId: string;
  cancelCallback: () => void;
}) {
  const intl = useTranslate();
  const foldersHook = useFolders();
  const getFolderHook = foldersHook.useGetFolder(props.folderId);
  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] = useState(false);
  const [skillId, setSkillId] = useState();
  const [folderName, setFolderName] = useState<string>('');
  const [processAfterImport, setProcessAfterImport] = useState();
  const [allowApproveWithErrors, setAllowApproveWithErrors] = useState();
  const [trashDocumentsAfterHours, setTrashDocumentsAfterHours] = useState('');
  const [deleteDocumentsAfterHours, setDeleteDocumentsAfterHours] = useState('');
  const can = useCan();
  const { addToast } = useToasts();

  useEffect(() => {
    setSkillId(getFolderHook.data?.skill_id);
    setProcessAfterImport(getFolderHook.data?.process_after_import);
    setAllowApproveWithErrors(getFolderHook.data?.allow_approve_with_errors);
  }, [getFolderHook.data]);

  const handleSave = () => {
    foldersHook.patchFolderMutation.mutate(
      {
        folderId: props.folderId,
        patch: {
          skill_id: skillId,
          process_after_import: processAfterImport,
          allow_approve_with_errors: allowApproveWithErrors,
          name: folderName,
          trash_documents_after_hours:
            trashDocumentsAfterHours === '' ? null : trashDocumentsAfterHours,
          delete_documents_after_hours:
            deleteDocumentsAfterHours === '' ? null : deleteDocumentsAfterHours,
        },
      },
      {
        onSuccess: () => {
          props.cancelCallback();
        },
      }
    );
  };

  useEffect(() => {
    if (getFolderHook?.data?.name) {
      setFolderName(getFolderHook?.data?.name);
    }
  }, [getFolderHook?.data?.name]);

  const onFolderNameChange = (e: InputEvent) => {
    setFolderName(e?.target?.value);
  };

  useEffect(() => {
    if (getFolderHook.data?.trash_documents_after_hours) {
      setTrashDocumentsAfterHours(getFolderHook.data?.trash_documents_after_hours);
    }
  }, [getFolderHook.data?.trash_documents_after_hours]);

  const onTrashDocumentsAfterHoursChange = (e: InputEvent) => {
    const value = e?.target?.value?.replace(/\D/g, '');
    setTrashDocumentsAfterHours(value);
  };

  useEffect(() => {
    if (getFolderHook.data?.delete_documents_after_hours) {
      setDeleteDocumentsAfterHours(getFolderHook.data?.delete_documents_after_hours);
    }
  }, [getFolderHook.data?.delete_documents_after_hours]);

  const onDeleteDocumentsAfterHoursChange = (e: InputEvent) => {
    const value = e?.target?.value?.replace(/\D/g, '');
    setDeleteDocumentsAfterHours(value);
  };

  const onMoveToFolder = (selectedFolder: FolderInfo) => {
    foldersHook.patchFolderMutation.mutate(
      {
        folderId: props.folderId,
        patch: {
          parent_id: selectedFolder.id,
        },
      },
      {
        onSuccess: () => {
          addToast({
            title: intl.formatMessage(
              {
                id: 'folderSettings.onMoveFolder.success',
                defaultMessage: 'Folder successfully moved under "{parentFolderName}"',
              },
              {
                parentFolderName: selectedFolder.name,
              }
            ),
            color: 'success',
          });
          props.cancelCallback();
        },
      }
    );
  };

  const onCloseCreateFolderModal = (value: boolean) => {
    setIsCreateFolderModalVisible(value);
    props.cancelCallback();
  };

  if (!getFolderHook.isSuccess) {
    return <QueryLoader queries={[getFolderHook]} />;
  }

  const settings = (
    <EuiForm component="form">
      <EuiFormRow
        label={intl.formatMessage({
          id: 'folderSettings.name',
          defaultMessage: 'Folder name',
        })}
        fullWidth
      >
        <EuiFieldText
          name="folderName"
          fullWidth
          onChange={onFolderNameChange}
          value={folderName}
        />
      </EuiFormRow>

      <EuiSpacer />
      <EuiFlexGroup justifyContent="spaceAround">
        <EuiFlexItem grow={true}>
          <MoveToFolder
            onFolderSelected={onMoveToFolder}
            type="folder"
            anchorPosition="downCenter"
            buttonProps={{
              fill: true,
              color: 'text',
              isDisabled: !can.can(PERMISSION.FOLDERS_CREATE),
              isEmpty: false,
            }}
            permission={PERMISSION.FOLDERS_UPDATE}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiButton
            onClick={() => setIsCreateFolderModalVisible(true)}
            isDisabled={!can.can(PERMISSION.FOLDERS_CREATE)}
          >
            <Translate id="folders.createSubfolder" defaultMessage="Create subfolder" />
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiButton
            onClick={() => foldersHook.deleteFolderMutation.mutate(props.folderId)}
            color="danger"
            isDisabled={
              !can.can(PERMISSION.FOLDERS_DELETE) || !getFolderHook.data.parent_id
            }
          >
            <Translate id="folders.deleteFolder" defaultMessage="Delete folder" />
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xl" />
      <EuiFormRow
        label={intl.formatMessage({
          id: 'folderSettings.skill',
          defaultMessage: 'Default skill',
        })}
      >
        <SchemaSelector
          defaultSchemaId={skillId}
          onChange={(id) => setSkillId(id)}
          isDisabled={!can.can(PERMISSION.FOLDERS_UPDATE)}
          includeParentFolders={true}
          hasLink={true}
          addNullOption={true}
        />
      </EuiFormRow>
      <EuiFormRow>
        <EuiSwitch
          label={intl.formatMessage({
            id: 'folderSettings.processAfterImport',
            defaultMessage: 'Automatically process documents after import',
          })}
          checked={processAfterImport}
          onChange={(event) =>
            setProcessAfterImport(event.target.checked ? true : false)
          }
          disabled={!can.can(PERMISSION.FOLDERS_UPDATE)}
        />
      </EuiFormRow>
      <EuiFormRow>
        <EuiSwitch
          label={intl.formatMessage({
            id: 'folderSettings.allowApproveWithErrors',
            defaultMessage: 'Allow annotations with error messages to be approved',
          })}
          checked={allowApproveWithErrors}
          onChange={(event) =>
            setAllowApproveWithErrors(event.target.checked ? true : false)
          }
          disabled={!can.can(PERMISSION.FOLDERS_UPDATE)}
        />
      </EuiFormRow>
      <EuiSpacer size="xl" />
      <EuiFormRow
        label={intl.formatMessage({
          id: 'folderSettings.inbox.address',
          defaultMessage: 'Folder inbox address',
        })}
        fullWidth
      >
        <EuiFieldText
          name="inboxAddress"
          fullWidth
          readOnly
          defaultValue={getFolderHook.data.inbox_address}
        />
      </EuiFormRow>
      <EuiSpacer size="xl" />
      <EuiFormRow
        label={intl.formatMessage({
          id: 'folderSettings.trashDocumentsAfterHours',
          defaultMessage: 'Trash documents after hours',
        })}
        fullWidth
      >
        <EuiFieldText
          name="trashDocumentsAfterHours"
          fullWidth
          onChange={onTrashDocumentsAfterHoursChange}
          value={trashDocumentsAfterHours}
          isDisabled={!can.can(PERMISSION.FOLDERS_UPDATE)}
        />
      </EuiFormRow>
      <EuiSpacer size="xl" />
      <EuiFormRow
        label={intl.formatMessage({
          id: 'folderSettings.deleteDocumentsAfterHours',
          defaultMessage: 'Delete documents after hours',
        })}
        fullWidth
      >
        <EuiFieldText
          name="deleteDocumentsAfterHours"
          fullWidth
          onChange={onDeleteDocumentsAfterHoursChange}
          value={deleteDocumentsAfterHours}
          isDisabled={!can.can(PERMISSION.FOLDERS_UPDATE)}
        />
      </EuiFormRow>
    </EuiForm>
  );

  return (
    <>
      <EuiFlyoutBody>
        <EuiFlexGroup direction="column" gutterSize="m">
          <EuiFlexItem>{settings}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutBody>

      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton fill onClick={handleSave}>
              {intl.message.save}
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={props.cancelCallback}>
              <Translate
                id="folders.configuration.button.cancel"
                defaultMessage="Cancel"
              />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>

      {isCreateFolderModalVisible && (
        <CreateFolderModal
          setIsModalVisible={onCloseCreateFolderModal}
          parentFolderId={props.folderId}
        />
      )}
    </>
  );
}
