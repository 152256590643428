import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { getGlobalSettingsEndpoint } from 'services/endpoints';
import client from 'utils/client';
import { atomWithLocalStorage } from 'utils/persistence';
import create from 'zustand';
import { userSettingsAtom } from '../components/Header/States/UserState';
import { SUPPORTED_LANGUAGES } from '../settings/constants';

const globalSettingsAtom = atomWithLocalStorage('globalSettings', {
  loaded_at: null,
  auth_type: 'idp',
  keycloak_server_url: null,
  keycloak_realm_name: null,
  keycloak_client_id: null,
});

const messagesLoader = Object.entries(SUPPORTED_LANGUAGES).reduce((ac, [key]) => {
  return { ...ac, [key]: () => import(`../assets/lang/${key}.json`) };
}, {});

// Temporary solution before we transform plain functions to hooks.
// Keycloak hook cannot be used in plain functions so we use this store now.
export const useKeycloakStore = create((set) => ({
  isKeycloakUsed: undefined,
  keycloak: undefined,
  token: undefined,
  isAuthenticated: undefined,
  setKeycloak: (keycloak) => set({ keycloak: keycloak }),
  setToken: (token) => set({ token: token }),
  setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated: isAuthenticated }),
  setIsKeycloakUsed: (isKeycloakUsed) => set({ isKeycloakUsed: isKeycloakUsed }),
}));

export function useGlobalSettings() {
  const [globalSettings, setGlobalSettings] = useAtom(globalSettingsAtom);
  const [userSetting] = useAtom(userSettingsAtom);
  const setIsKeycloakUsed = useKeycloakStore((state) => state.setIsKeycloakUsed);
  const [isLoading, setIsLoading] = useState(true);
  const [isKeycloackLoading, setIsKeycloackLoaded] = useState(false);
  const [translations, setTranslations] = useState();

  const refresh = useCallback(() => {
    client(getGlobalSettingsEndpoint(), { onlyAuthorized: false })
      .then((settings) => {
        setGlobalSettings(settings);
        // Stored separately to zustand store so it is accessible outside React tree.
        setIsKeycloakUsed(settings.auth_type === 'keycloak');
        setIsKeycloackLoaded(true);
      })
      .catch(console.log);
  }, [setGlobalSettings, setIsKeycloakUsed]);

  useEffect(() => {
    messagesLoader[userSetting.language]().then((messages) => {
      setTranslations({
        locale: userSetting.language,
        messages,
      });
    });
  }, [userSetting.language]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (isKeycloackLoading && translations) {
      setIsLoading(false);
    }
  }, [isKeycloackLoading, translations]);

  return {
    globalSettings,
    refresh,
    isLoading,
    translations,
  };
}
