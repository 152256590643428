import { atom } from 'jotai';
import { AnnotationClass } from '../../../../Definitions/ApiTypes';
import { DatapointType } from '../../../../Definitions/DatapointType';

export interface ActiveDatapoint {
  value?: string;
  normalizedValue?: string;
  box: {
    xMin: number;
    yMin: number;
    xMax: number;
    yMax: number;
  } | null;
  kind: string;
  pageIndex?: number;
  id?: string;
  schemaElementId?: string;
  annotationClass?: AnnotationClass;
  type?: DatapointType;
  isTableDatapoint?: boolean;
}

export interface ReceivedDatapoint {
  id?: string;
  annotation_class?: AnnotationClass;
  box: { x_min: number; y_min: number; x_max: number; y_max: number };
  kind: string;
  normalized_value?: string;
  page_index?: number;
  schema_element_id?: string;
  value?: string;
  isTableDatapoint?: boolean;
  type?: DatapointType;
}

export const activeDatapointAtom = atom<ActiveDatapoint | undefined>(undefined);
export const pinnedDatapointAtom = atom<ActiveDatapoint | undefined>(undefined);
export const previousActiveSchemaElementIdAtom = atom<string | undefined>(undefined);
