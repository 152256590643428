import React, { Fragment } from 'react';
import { InLineLoader } from '../components/InlineLoader/InlineLoader';
import { DEFAULT_THEME } from '../settings/constants';
import { ThemeMode } from '../settings/ThemeMode';

const ThemeDark = React.lazy(() => import('./ThemeDark'));
const ThemeLight = React.lazy(() => import('./ThemeLight'));

/**
 * The theme components only imports it's theme CSS file. These components are lazy
 * loaded to enable code splitting.
 * See: https://github.com/elastic/eui/discussions/2574#discussioncomment-127121
 */
export const ThemeSelector: React.FC = ({ children }) => {
  const currentThemeMode = getThemeMode();

  return (
    <Fragment>
      <React.Suspense fallback={<InLineLoader />}>
        {currentThemeMode === ThemeMode.dark && <ThemeDark />}
        {currentThemeMode === ThemeMode.light && <ThemeLight />}
      </React.Suspense>
      {children}
    </Fragment>
  );
};

export function getThemeMode() {
  return (localStorage.getItem('themeMode') as ThemeMode) || DEFAULT_THEME;
}

export function invertThemeMode() {
  const currentTheme = getThemeMode();
  const invertedTheme =
    currentTheme === ThemeMode.light ? ThemeMode.dark : ThemeMode.light;
  localStorage.setItem('themeMode', invertedTheme.valueOf());
  window.location.reload();
}
