import { EuiButton, EuiButtonIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { useBottomBarButtonStyle } from 'components/BottomBar/BottomBar';
import { useFolders } from 'components/Folders/useFolders';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
import { AnnotationStatus } from 'Definitions/ApiTypes';
import { useAtom } from 'jotai';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Can, PERMISSION, useCan } from 'utils/permissions';
import {
  postSplitDocumentByAnnotation,
  putAnnotationStatus,
} from '../../../services/annotations';
import {
  invalidateAllDocuments,
  invalidateAnnotation,
  invalidateAnnotations,
} from '../../../services/documents';
import { Translate } from '../../Internationalisation/translate';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useToasts } from '../../Toasts/Toasts';
import {
  annotationHasErrorMessagesAtom,
  currentReviewAnnotation,
  isTestModeEnabledAtom,
} from '../AnnotationState';
import { useDocumentClassification } from '../DocumentPanel/ImageAnnotation/useDocumentClassification';
import { useReviewDocuments } from './useReviewDocuments';

export function AnnotationStatusButton(props: {
  annotation: { document_id: string; id: string; status: string };
}) {
  const { annotation } = props;
  const [reviewAnnotation] = useAtom(currentReviewAnnotation);
  const [isTestModeEnabled] = useAtom(isTestModeEnabledAtom);
  const [annotationHasErrorMessages] = useAtom(annotationHasErrorMessagesAtom);
  const bottomBarButtonStyle = useBottomBarButtonStyle();
  const { reviewDocuments } = useReviewDocuments();
  const intl = useTranslate();
  const can = useCan();
  const { addToast } = useToasts();
  const [splitInProgress, setSplitInProgress] = useState(false);

  const { folderId } = useParams();
  const foldersHook = useFolders();
  const getFolderHook = foldersHook.useGetFolder(folderId);

  const reviewMode = useMemo(() => {
    return (
      reviewAnnotation?.annotationId === annotation.id &&
      annotation.status === 'in_review'
    );
  }, [reviewAnnotation, annotation]);

  const splitDocuments = useDocumentClassification();

  if (!getFolderHook.isSuccess) return <InLineLoader />;

  const cannotApproveWithErrors =
    !getFolderHook.data?.allow_approve_with_errors && annotationHasErrorMessages;

  const updateAnnotationStatus = (status: AnnotationStatus) => {
    // @ts-ignore
    putAnnotationStatus(annotation.id, status)
      .then(() => invalidateAnnotations(annotation.document_id))
      .then(() => invalidateAnnotation(annotation.id))
      .then(() => {
        // @ts-ignore
        const reviewButtonStatuses: Set<AnnotationStatus> = new Set([
          'approved',
          'rejected',
          'postponed',
          'to_export',
        ]);
        if (reviewMode && reviewButtonStatuses.has(status)) {
          reviewDocuments();
        }
      });
  };

  const splitDocument = () => {
    setSplitInProgress(true);
    // @ts-ignore
    postSplitDocumentByAnnotation(annotation.id).then(() => {
      invalidateAnnotations(annotation.document_id)
        .then(() => invalidateAnnotation(annotation.id))
        .then(() => invalidateAllDocuments())
        .then(() => {
          setSplitInProgress(false);
          addToast({
            title: intl.formatMessage({
              id: 'split.message.documentSuccessfullySplit',
              defaultMessage: 'Document successfully split',
            }),
            color: 'success',
          });
        })
        .catch((_) => {
          setSplitInProgress(false);
        });
    });
  };

  const getActionButton = (props: {
    title: string;
    iconType: string;
    status: AnnotationStatus;
    disabled?: boolean;
  }) => {
    return (
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          display="empty"
          color="text"
          style={bottomBarButtonStyle}
          aria-label={props.title}
          size="s"
          iconSize="m"
          disabled={props.disabled || annotation.status === props.status}
          title={props.title}
          iconType={props.iconType}
          onClick={() => updateAnnotationStatus(props.status)}
        />
      </EuiFlexItem>
    );
  };

  const actionButtons = (
    <>
      {isTestModeEnabled &&
        getActionButton({
          title: intl.formatMessage({
            id: 'button.testToReview',
            defaultMessage: 'Test',
          }),
          iconType: 'broom',
          status: 'test_to_review',
        })}
      {getActionButton({
        title: intl.formatMessage({
          id: 'button.reject',
          defaultMessage: 'Reject',
        }),
        iconType: 'minusInCircle',
        status: 'rejected',
      })}
      {getActionButton({
        title: intl.formatMessage({
          id: 'button.postpone',
          defaultMessage: 'Postpone',
        }),
        iconType: 'clock',
        status: 'postponed',
      })}
      {getActionButton({
        title: intl.formatMessage({
          id: 'button.toExport',
          defaultMessage: 'To export',
        }),
        iconType: 'push',
        status: 'to_export',
        disabled: cannotApproveWithErrors,
      })}
      {getActionButton({
        title: intl.formatMessage({
          id: 'button.toReview',
          defaultMessage: 'To review',
        }),
        iconType: 'training',
        status: 'to_review',
        disabled: reviewMode,
      })}
      {!['to_review', 'in_review'].includes(annotation.status) &&
        getActionButton({
          title: intl.formatMessage({
            id: 'button.restartReview',
            defaultMessage: 'Restart review',
          }),
          iconType: 'reporter',
          status: 'in_review',
        })}
    </>
  );

  const approveButton = (
    <EuiButton
      fill
      size="s"
      color="primary"
      iconType="check"
      disabled={cannotApproveWithErrors}
      title={
        cannotApproveWithErrors
          ? intl.formatMessage({
              id: 'message.annotationHasErrors',
              defaultMessage: 'Annotation has errors',
            })
          : undefined
      }
      onClick={() => updateAnnotationStatus('approved')}
    >
      <Translate id="button.approve" defaultMessage="Approve" />
    </EuiButton>
  );

  const startReviewButton = (
    <EuiButton
      fill
      size="s"
      iconType="reporter"
      onClick={() => updateAnnotationStatus('in_review')}
    >
      <Translate id="button.review" defaultMessage="Review" />
    </EuiButton>
  );

  const splitDocumentButton = (
    <EuiButton
      fill
      size="s"
      iconType="cut"
      onClick={() => splitDocument()}
      disabled={!can.can(PERMISSION.DOCUMENTS_SPLIT)}
      isLoading={splitInProgress}
    >
      <Translate id="button.splitDocument" defaultMessage="Split document" />
    </EuiButton>
  );

  return (
    <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
      <EuiFlexGroup
        gutterSize="s"
        direction="row"
        alignItems="center"
        responsive={false}
      >
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize="xs" justifyContent="flexEnd" responsive={false}>
            {actionButtons}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          {annotation.status === 'to_review' && startReviewButton}
          {annotation.status === 'in_review' && approveButton}
        </EuiFlexItem>
        <EuiFlexItem>
          {splitDocuments &&
            splitDocuments.rows.length >= 2 &&
            annotation.status === 'approved' &&
            splitDocumentButton}
        </EuiFlexItem>
      </EuiFlexGroup>
    </Can>
  );
}
