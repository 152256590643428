import React, { FC } from 'react';
import ReactLoading from 'react-loading';
import { THEME } from '../../settings/constants';

export const InLineLoader: FC = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.5em',
      }}
    >
      {/* ReactLoading supports delay so it is not shown when the loading is fast. */}
      {/* https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component */}
      <ReactLoading
        type="bubbles"
        color={THEME.euiColorPrimary}
        height="3em"
        width="3em"
        delay={1500}
      />
    </div>
  );
};

// Empty page with loader in the center.
export const PageLoader: FC = () => {
  return (
    <div
      style={{
        backgroundColor: THEME.euiPageBackgroundColor,
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* ReactLoading supports delay so it is not shown when the loading is fast. */}
      {/* https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component */}
      <ReactLoading
        type="bubbles"
        color={THEME.euiColorPrimary}
        height="3em"
        width="3em"
        delay={1500}
      />
    </div>
  );
};
