import { EuiComboBox } from '@elastic/eui';
import React, { useState } from 'react';

export function OptionsSelector({
  defaultValue = null,
  onChange,
  options,
  placeholder,
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const selectedOptions = options.filter((option) => option.value === selectedValue);

  return (
    <EuiComboBox
      fullWidth
      placeholder={placeholder}
      singleSelection={{ asPlainText: true }}
      sortMatchesBy="none"
      options={options}
      compressed={true}
      isClearable={false}
      selectedOptions={selectedOptions}
      onChange={(value) => {
        setSelectedValue(value[0]?.value);
        onChange(value[0]?.value);
      }}
    />
  );
}
