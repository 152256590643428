import queryString from 'query-string';

export function useGetReferenceDataParams(hooks: string[]) {
  if (hooks.length) {
    const foundReferenceHook = hooks.find((hook) =>
      hook.includes('matching/reference_data')
    );

    if (foundReferenceHook) {
      const parsedQuery = queryString.parse(foundReferenceHook);

      if (parsedQuery.parameters) {
        const parameters = JSON.parse(parsedQuery.parameters as string);

        if (parameters.reference_data_id && parameters.matched_elements) {
          return {
            referenceDataId: parameters.reference_data_id,
            matchedElements: parameters.matched_elements,
          };
        }
      }
    }
    return undefined;
  }

  return undefined;
}
