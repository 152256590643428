import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import { getMessages } from 'components/Annotation/AnnotationPanel/Messages';
import { useAnnotationItems } from 'components/Annotation/AnnotationPanel/useAnnotationItems';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Can, PERMISSION } from 'utils/permissions';
import { showElementsEnum } from '../../../Definitions/AnnotationsPreferences';
import { OptionsSelector } from '../../Document/DocumentAttributeSelectors';
import { InLineLoader } from '../../InlineLoader/InlineLoader';
import { useTranslate } from '../../Internationalisation/useTranslate';
import {
  annotationHasErrorMessagesAtom,
  annotationPreferencesAtom,
  documentSplit,
  isExtendedAnnotationEnabledAtom,
  isTestModeEnabledAtom,
} from '../AnnotationState';
import { AnnotationElementInSection } from './AnnotationElementInSection';
import { NotExtractedSchemaElement } from './NotExtractedSchemaElement';

export function AnnotationItems({ annotation }) {
  const { search } = useLocation();
  const annotationIdGroundTruth = new URLSearchParams(search).get(
    'annotationIdGroundTruth'
  );
  const [isDocumentSplit] = useAtom(documentSplit);

  const {
    isLoading,
    schemaSections,
    annotationSections,
    getSectionElementItems,
    onNotExtractedElementChange,
    notExtractedElements,
    addEmptyAnnotation,
  } = useAnnotationItems(
    annotation?.id,
    annotation?.schema_id,
    annotationIdGroundTruth
  );
  const [isExtendedAnnotationEnabled] = useAtom(isExtendedAnnotationEnabledAtom);
  const [annotationPreferences] = useAtom(annotationPreferencesAtom);
  const [, setAnnotationHasErrorMessages] = useAtom(annotationHasErrorMessagesAtom);
  const [isTestModeEnabled] = useAtom(isTestModeEnabledAtom);
  const intl = useTranslate();

  useEffect(() => {
    const messages = getMessages(annotationSections);
    const errorMessages = messages.filter((m) => m.type === 'error');
    setAnnotationHasErrorMessages(errorMessages.length > 0);
  }, [annotationSections]);

  if (isLoading) return <InLineLoader />;

  return (
    <>
      <EuiFlexGroup direction="column">
        {annotationSections &&
          schemaSections.length > 0 &&
          schemaSections
            .filter((section) => {
              if (section.id === '_metacontent') {
                return isDocumentSplit;
              }
              return !isDocumentSplit;
            })
            .map((section) => (
              <EuiFlexItem key={`section-${section.id}`}>
                <EuiTitle size="xxs">
                  <span>{section.label}</span>
                </EuiTitle>
                <>
                  {section.elements.map((element) => {
                    if (element.is_hidden) {
                      return null;
                    }
                    const schemaElementAnnotationItems = getSectionElementItems(
                      annotationSections,
                      element.id
                    );

                    // If annotation item is not created yet, offer to create it.
                    // But we started to create items even when value is not extracted
                    // so in most cases there are now items with null values instead.
                    if (
                      !schemaElementAnnotationItems.length &&
                      annotationPreferences.showElements === showElementsEnum.ALL
                    ) {
                      return (
                        <NotExtractedSchemaElement
                          key={`not-extracted-schema-element-${element.id}`}
                          schemaElement={element}
                        />
                      );
                    }

                    return schemaElementAnnotationItems.map((item) => {
                      if (
                        annotationPreferences.showElements ===
                          showElementsEnum.ONLY_ANNOTATED &&
                        item.normalized_value === null &&
                        // When comparing annotation with ground truth, show always all items
                        // as otherwise some differences might be hidden
                        !annotationIdGroundTruth
                      ) {
                        return null;
                      }

                      return (
                        <AnnotationElementInSection
                          key={`annotation-element-in-section-${item.id}`}
                          elementSchema={element}
                          elementAnnotation={item}
                          isExtendedAnnotationEnabled={isExtendedAnnotationEnabled}
                        />
                      );
                    });
                  })}
                </>
              </EuiFlexItem>
            ))}
      </EuiFlexGroup>
      {!isTestModeEnabled && (
        <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
          <EuiSpacer size="s" />
          {!!notExtractedElements?.length &&
            annotationPreferences.showElements === showElementsEnum.ONLY_ANNOTATED && (
              <EuiFlexGroup>
                <EuiFlexItem>
                  <OptionsSelector
                    onChange={onNotExtractedElementChange}
                    options={notExtractedElements}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    style={{ marginLeft: '5px' }}
                    size="s"
                    iconType="plus"
                    color="text"
                    aria-label={intl.formatMessage({
                      id: 'annotationItems.button.addChosenElements',
                      defaultMessage: 'Add chosen element',
                    })}
                    onClick={addEmptyAnnotation}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
        </Can>
      )}
    </>
  );
}
