import { useToasts } from 'components/Toasts/Toasts';
import { useMutation } from 'react-query';
import client from 'utils/client';

export type FolderInfo = { id: string; name: string };

export function usePassword() {
  const { showRequestError } = useToasts();

  const passwordUrl = 'api/v1/auth/password';

  const updatePassword = (props: { currentPassword: string; newPassword: string }) => {
    const queryParams = new URLSearchParams();
    queryParams.set('current_password', props.currentPassword);
    queryParams.set('new_password', props.newPassword);
    const queryParamsString = queryParams.toString();
    return client(`${passwordUrl}?${queryParamsString}`, {
      method: 'PUT',
    });
  };

  // @ts-ignore
  const updatePasswordMutation = useMutation(updatePassword, {
    onError: (error) => {
      showRequestError(error);
    },
  });

  return {
    updatePasswordMutation: updatePasswordMutation,
  };
}
