import { useAtom } from 'jotai';
import { useAnnotationStore } from '../Annotation';
import { focusTableAtom } from '../AnnotationState';
import {
  ActiveDatapoint,
  activeDatapointAtom,
  pinnedDatapointAtom,
  previousActiveSchemaElementIdAtom,
  ReceivedDatapoint,
} from '../DocumentPanel/DatapointAnnotationPopups/AnnotationPopupState';

export function useActivateDatapoint() {
  const [currentDatapoint, setCurrentDatapoint] = useAtom(activeDatapointAtom);
  const [pinnedDatapoint, setPinnedDatapoint] = useAtom(pinnedDatapointAtom);
  const [previouslyActiveSchemaElementId, setPreviousActiveSchemaElementId] = useAtom(
    previousActiveSchemaElementIdAtom
  );
  const setFocusPage = useAnnotationStore((state) => state.focusPage);
  const allDatapoints = useAnnotationStore((state) => state.allDatapoints);
  const [, setFocusedTable] = useAtom(focusTableAtom);

  const receivedToActiveDatapoint = (
    receivedDatapoint: ReceivedDatapoint
  ): ActiveDatapoint => {
    const box = receivedDatapoint.box
      ? {
          xMin: receivedDatapoint.box.x_min,
          yMin: receivedDatapoint.box.y_min,
          xMax: receivedDatapoint.box.x_max,
          yMax: receivedDatapoint.box.y_max,
        }
      : null;

    return {
      value: receivedDatapoint.value,
      normalizedValue: receivedDatapoint.normalized_value,
      kind: receivedDatapoint.kind,
      box,
      pageIndex: receivedDatapoint.page_index,
      id: receivedDatapoint.id,
      schemaElementId: receivedDatapoint.schema_element_id,
      annotationClass: receivedDatapoint.annotation_class,
      type: receivedDatapoint.type,
      isTableDatapoint: receivedDatapoint.isTableDatapoint || false,
    };
  };

  const setActiveDatapoint = (
    receivedDatapoint: ReceivedDatapoint | undefined,
    pinDatapoint?: boolean,
    focusPage = true,
    hideTable = false
  ) => {
    // Remove active datapoint if clicked one more time or if removed

    if (pinDatapoint === false) {
      setPinnedDatapoint(undefined);
    }

    if (receivedDatapoint === undefined) {
      removeActiveDatapoint();
      return;
    }

    // Go to page when a datapoint is active
    if (focusPage && receivedDatapoint.page_index !== undefined) {
      setFocusPage(receivedDatapoint.page_index);
    }

    // Remove table focus if the datapoint is not a tuple or table
    if (hideTable) {
      setFocusedTable(undefined);
    }

    const activeDatapoint = receivedToActiveDatapoint(receivedDatapoint);

    // Setting pined datapoint, if false then will remove pinDatapoint
    if (pinDatapoint) {
      setPinnedDatapoint(activeDatapoint);
    }

    setCurrentDatapoint(activeDatapoint);
    setPreviousActiveSchemaElementId(activeDatapoint.schemaElementId);
  };

  const removeActiveDatapoint = () => {
    setCurrentDatapoint(undefined);
  };

  const setActiveDatapointById = (
    datapointId,
    pinDatapoint?: boolean,
    focusPage = true,
    hideTable = false
  ) => {
    // Setting pined datapoint, if false then will remove pinDatapoint
    if (pinDatapoint === false) {
      setPinnedDatapoint(undefined);
    }

    if (
      datapointId === undefined ||
      (currentDatapoint && datapointId === currentDatapoint.id)
    ) {
      removeActiveDatapoint();
      return;
    }

    if (allDatapoints) {
      const foundDatapoint = allDatapoints.find(
        (datapoint) => datapoint.id === datapointId
      );

      setActiveDatapoint({ ...foundDatapoint }, pinDatapoint, focusPage, hideTable);
    }
  };

  return {
    activeDatapoint: currentDatapoint,
    pinnedDatapoint: pinnedDatapoint,
    setActiveDatapoint: setActiveDatapoint,
    setActiveDatapointById: setActiveDatapointById,
    previouslyActiveSchemaElementId: previouslyActiveSchemaElementId,
  };
}
