import { EuiSuperSelect, EuiText } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useAnnotations, useSchema } from '../../../services/documents';
import { getAnnotationsByDocumentEndpoint } from '../../../services/endpoints';
import { queryClient } from '../../../utils/client';
import { InLineLoader } from '../../InlineLoader/InlineLoader';
import { Translate } from '../../Internationalisation/translate';
import { asDateTime } from '../../Internationalisation/useTranslate';
import { useSchemas } from '../../Manage/Schema/useSchemas';
import { useAnnotationStatusList } from './useAnnotationStatusList';

export function AnnotationSelector({
  documentId,
  annotationId,
  onChange,
  showTestAnnotations,
  schemaId,
}) {
  const { data: annotationData, isLoading: isAnnotationLoading } = useAnnotations(
    documentId,
    showTestAnnotations
  );
  const annotations = annotationData?.data;
  const { useGetSkillVersions } = useSchemas();
  const { data: schemaData, isLoading: isSchemaLoading } = useSchema(schemaId, {
    enabled: !!schemaId,
  });

  const {
    data: skillVersionsData,
    isLoading: areVersionsLoading,
  } = useGetSkillVersions(schemaId, false, !!schemaId);

  const [selectedAnnotationId, setSelectedAnnotationId] = useState(annotationId);
  const { getMapAnnotationStatusList } = useAnnotationStatusList();
  const annotationStatusList = getMapAnnotationStatusList(true);

  useEffect(() => {
    setSelectedAnnotationId(annotationId);
  }, [annotationId]);

  useEffect(() => {
    !selectedAnnotationId && annotations?.length > 0 && onChange(annotations[0].id);
  }, [selectedAnnotationId]);

  if (isAnnotationLoading || isSchemaLoading || areVersionsLoading)
    return <InLineLoader />;

  const getSkillVersionCreatedData = (skillVersionId) => {
    const version = skillVersionsData?.data?.find(
      (skillVersion) => skillVersion.id === skillVersionId
    );
    return version?.created_at ? asDateTime(version.created_at) : '';
  };

  const options = annotations.map((value) => ({
    inputDisplay: (
      <>
        {asDateTime(value.created_at)} ({annotationStatusList[value.status].label})
      </>
    ),
    value: value.id,
    dropdownDisplay: (
      <>
        <strong>
          {asDateTime(value.created_at)} ({annotationStatusList[value.status].label})
        </strong>
        <EuiText size="s" color="subdued">
          <p>
            <Translate
              id="annotationSelector.createdBy"
              defaultMessage="Created by {createdBy}"
              values={{
                createdBy: value.created_by,
              }}
            />
            {value.approved_at && (
              <>
                <br />
                <Translate
                  id="annotationSelector.approvedAt"
                  defaultMessage="Approved at {approvedAt} by {approvedBy}"
                  values={{
                    approvedAt: asDateTime(value.approved_at),
                    approvedBy: value.approved_by,
                  }}
                />
              </>
            )}
            {value.modified_at && (
              <>
                <br />
                <Translate
                  id="annotationSelector.modifiedBy"
                  defaultMessage="Modified at {modifiedAt} by {modifiedBy}"
                  values={{
                    modifiedAt: asDateTime(value.modified_at),
                    modifiedBy: value.modified_by,
                  }}
                />
              </>
            )}
            {showTestAnnotations && (
              <>
                <br />
                <Translate
                  id="annotationSelector.modifiedAt"
                  defaultMessage="Skill: {name} ({version})"
                  values={{
                    name: schemaData.name,
                    version: getSkillVersionCreatedData(value.skill_version_id),
                  }}
                />
              </>
            )}
            <br />
          </p>
        </EuiText>
      </>
    ),
  }));

  return annotations.length == 0 ? (
    <EuiText textAlign="center">
      <Translate
        id="annotationSelector.documentNotBeenProcessed"
        defaultMessage=" Document has not been processed yet."
      />
    </EuiText>
  ) : (
    <EuiSuperSelect
      options={options}
      valueOfSelected={selectedAnnotationId || annotations[0].id}
      onChange={(value) => {
        setSelectedAnnotationId(value);
        onChange(value);
      }}
      itemLayoutAlign="top"
      hasDividers
      fullWidth
      onFocus={() =>
        queryClient.invalidateQueries(getAnnotationsByDocumentEndpoint(documentId))
      }
    />
  );
}
