import { useMutation, useQuery } from 'react-query';
import client, { queryClient } from 'utils/client';
import {
  getReferenceDataContentEndpoint,
  getReferenceDataEndpoint,
  getReferenceDataListEndpoint,
} from '../../../../services/endpoints';
import { readBlob } from '../../../../utils/ file';
import { useTranslate } from '../../../Internationalisation/useTranslate';
import { useToasts } from '../../../Toasts/Toasts';

export function useReferenceData() {
  const { showRequestError } = useToasts();
  const intl = useTranslate();
  const useGetReferenceDataContent = (
    referenceDataId: string | undefined,
    options = {}
  ) => {
    return useQuery(
      ['referenceDataContent', referenceDataId],
      () =>
        // @ts-ignore
        client(getReferenceDataContentEndpoint(referenceDataId)).then((data) => {
          return readBlob(data);
        }),
      {
        ...options,
        onError: (error) => {
          showRequestError(
            error,
            intl.formatMessage({
              id: 'referenceData.contentError',
              defaultMessage: "Can't get reference data content",
            })
          );
        },
      }
    );
  };

  const getReferenceDataContentMutate = (referenceDataId: string) => {
    // @ts-ignore
    return client(getReferenceDataContentEndpoint(referenceDataId)).then((data) => {
      return readBlob(data);
    });
  };

  const getReferenceDataContent = useMutation(getReferenceDataContentMutate, {
    onError: (error) => {
      showRequestError(
        error,
        intl.formatMessage({
          id: 'referenceData.contentError',
          defaultMessage: "Can't get reference data content",
        })
      );
    },
  });

  const useGetReferenceDataList = (
    folderIdList: string[] | undefined,
    options: { enabled: boolean }
  ) => {
    return useQuery(
      ['referenceDataList', folderIdList],
      () =>
        // @ts-ignore
        client(getReferenceDataListEndpoint(folderIdList)),
      {
        ...options,
        useErrorBoundary: true,
      }
    );
  };

  const useGetReferenceData = (
    referenceDataId: string | undefined,
    options: { enabled: boolean }
  ) => {
    return useQuery(
      ['referenceData', referenceDataId],
      () => client(getReferenceDataEndpoint(referenceDataId)),
      {
        ...options,
        useErrorBoundary: true,
      }
    );
  };

  const putReferenceDataContentMutate = ({ referenceDataId, data }) => {
    return client(getReferenceDataContentEndpoint(referenceDataId), {
      body: data,
      method: 'PUT',
      headers: {},
    });
  };

  const postReferenceDataMutate = ({ data, params }) => {
    const { name, folderId, description } = params;

    let url = `${getReferenceDataEndpoint()}?name=${name}`;
    if (!!folderId) {
      url = url + `&folder_id=${folderId}`;
    }
    if (!!description) {
      url = url + `&description=${description}`;
    }

    return client(url, {
      body: data,
      headers: {},
    });
  };

  const deleteReferenceDataMutate = ({ referenceDataId }) => {
    return client(getReferenceDataEndpoint(referenceDataId), {
      method: 'DELETE',
    });
  };

  // @ts-ignore
  const putReferenceData = useMutation(putReferenceDataContentMutate, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        'referenceDataContent',
        variables.referenceDataId,
      ]);
      queryClient.invalidateQueries(['referenceDataList']);
    },
    onError: (err) => {
      showRequestError(err);
    },
  });

  // @ts-ignore
  const postReferenceData = useMutation(postReferenceDataMutate, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['referenceDataList']);
      queryClient.invalidateQueries(['referenceData', variables.referenceDataId]);
    },
    onError: (err) => {
      showRequestError(err);
    },
  });

  const deleteReferenceData = useMutation(deleteReferenceDataMutate, {
    onSuccess: () => {
      queryClient.invalidateQueries(['referenceDataList']);
    },
    onError: (err) => {
      showRequestError(err);
    },
  });

  return {
    useGetReferenceData: useGetReferenceData,
    useGetReferenceDataList: useGetReferenceDataList,
    useGetReferenceDataContent: useGetReferenceDataContent,
    putReferenceData: putReferenceData,
    postReferenceData: postReferenceData,
    deleteReferenceData: deleteReferenceData,
    getReferenceDataContent: getReferenceDataContent,
  };
}
