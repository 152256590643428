import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { putAnnotationStatus } from '../../../services/annotations';
import {
  invalidateAnnotation,
  invalidateAnnotations,
} from '../../../services/documents';
import client from '../../../utils/client';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useToasts } from '../../Toasts/Toasts';
import {
  currentReviewAnnotation,
  reviewDocumentsNumberAtom,
  reviewDocumentsQueryStringAtom,
} from '../AnnotationState';

export function useReviewDocuments() {
  const [, setCurrentReviewAnnotationDocument] = useAtom(currentReviewAnnotation);
  const [reviewDocumentsQueryString, setReviewDocumentsQueryString] = useAtom(
    reviewDocumentsQueryStringAtom
  );
  const [, setReviewDocumentsNumber] = useAtom(reviewDocumentsNumberAtom);
  const { addToast, showRequestError } = useToasts();
  const { folderId } = useParams();
  const navigate = useNavigate();
  const intl = useTranslate();

  const reviewDocuments = (isStart = false, query?: string) => {
    const clearReviewDocuments = () => {
      setReviewDocumentsQueryString(undefined);
      setReviewDocumentsNumber(undefined);
      setCurrentReviewAnnotationDocument(undefined);
    };

    const updateAnnotationStatus = (annotationId: string, documentId: string) => {
      // @ts-ignore
      putAnnotationStatus(annotationId, 'in_review')
        .then(() => invalidateAnnotations(documentId))
        .then(() => invalidateAnnotation(annotationId))
        .then(() => {
          setCurrentReviewAnnotationDocument({ annotationId, documentId });
          navigate(`../documents/${documentId}?annotationId=${annotationId}`);
        });
    };

    // @ts-ignore
    client(query || reviewDocumentsQueryString)
      .then((data) => {
        const documents = data.data;
        const annotations = data.included.annotations;
        const numberOfDocuments = data.pagination.total;
        if (!documents.length) {
          clearReviewDocuments();
          if (isStart) {
            addToast({
              title: intl.formatMessage({
                id: 'message.error.noDocumentsToReview',
                defaultMessage: 'No documents to review',
              }),
              color: 'warning',
            });
          } else {
            addToast({
              title: intl.formatMessage({
                id: 'message.success.allDocumentsHaveBeenReviewed',
                defaultMessage: 'All documents have been reviewed',
              }),
              color: 'success',
            });
          }
          navigate(`../documents`);
          return;
        }

        const nextDocumentAnnotation = annotations.find(
          (nextAnnotation: { document_id: string }) =>
            nextAnnotation.document_id === documents[0].id
        );
        const nextDocument = {
          documentId: documents[0].id as string,
          annotationId: nextDocumentAnnotation.id as string,
        };

        setReviewDocumentsNumber(numberOfDocuments);

        updateAnnotationStatus(nextDocument.annotationId, nextDocument.documentId);
      })
      .catch((error) => showRequestError(error));
  };

  return {
    reviewDocuments: reviewDocuments,
  };
}
