import { atom, useAtom } from 'jotai';
import React, { useEffect } from 'react';
import AceEditor from 'react-ace';
import { useSchema } from '../../../services/documents';
import { InLineLoader } from '../../InlineLoader/InlineLoader';
import { useTranslate } from '../../Internationalisation/useTranslate';

require('ace-builds/src-noconflict/mode-json');
require('ace-builds/src-noconflict/theme-monokai');

export const schemaContentJsonString = atom('');

export function SchemaContentEditor({ schemaId, aceEditorRef }) {
  const { data: schema, isLoading } = useSchema(schemaId);
  const [schemaContent, setSchemaContent] = useAtom(schemaContentJsonString);
  const intl = useTranslate();

  useEffect(() => {
    if (schema?.content) {
      setSchemaContent(JSON.stringify(schema.content, null, 2));
    }
  }, [schema, setSchemaContent]);

  function handleSchemaContentChange(newValue) {
    setSchemaContent(newValue);
  }

  if (isLoading) return <InLineLoader />;

  return (
    <AceEditor
      // https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
      ref={aceEditorRef}
      placeholder={intl.formatMessage({
        id: 'schemaBuilder.schemaContent',
        defaultMessage: 'Schema content',
      })}
      mode="json"
      theme="monokai"
      // TODO: Tune styles to be in line with the rest and support light mode.
      style={{ backgroundColor: '#1D1E24' }}
      name="schema-content"
      width={'100%'}
      height={'100%'}
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={schemaContent}
      onChange={handleSchemaContentChange}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  );
}
