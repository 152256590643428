import { EuiFlexGroup, EuiIcon, EuiPage, EuiSpacer, EuiText } from '@elastic/eui';
import { useFoldersTree } from 'components/Folders/Folders';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useIsAuthenticated } from 'utils/auth';
import Header from '../Header/Header';
import { useTranslate } from '../Internationalisation/useTranslate';

interface PageErrorProps {
  title: string;
  errorCode: string;
  message: string;
}

function PageError(props: PageErrorProps) {
  return (
    <EuiPage>
      <Header />
      <EuiFlexGroup
        justifyContent="center"
        direction="column"
        alignItems="center"
        style={{ paddingTop: '15vh' }}
      >
        <EuiIcon type="alert" size="xxl" color="danger" />
        <EuiSpacer size="l" />
        <EuiText textAlign="center">
          <h3>
            {props.errorCode}
            <br />
            {props.title}
          </h3>
          <EuiSpacer size="l" />
          <div dangerouslySetInnerHTML={{ __html: props.message }} />
        </EuiText>
      </EuiFlexGroup>
    </EuiPage>
  );
}

export const PageNotFound = () => {
  const intl = useTranslate();

  return (
    <PageError
      title={intl.formatMessage({
        id: 'pageNotFound.title',
        defaultMessage: 'Page not found',
      })}
      errorCode="404"
      message={intl.formatMessage({
        id: 'pageNotFound.description',
        defaultMessage: 'Make sure the address is correct and the page has not moved.',
      })}
    />
  );
};

export const PageNotAuthorized = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const { folderId: selectedFolderId } = useParams();
  const { foldersTree } = useFoldersTree();
  const intl = useTranslate();

  let message;
  if (foldersTree && foldersTree.length > 0) {
    message = !selectedFolderId
      ? intl.formatMessage({
          id: 'errorPages.pleaseSelectFolder',
          defaultMessage: 'Please select a folder.',
        })
      : intl.formatMessage({
          id: 'errorPages.folder.notSufficientPermissions',
          defaultMessage:
            'You do not have sufficient permissions to view this page in the selected folder.',
        });
  } else {
    message = intl.formatMessage({
      id: 'errorPages.folder.noGrantedPermissions',
      defaultMessage: `You have not been granted permissions for any folder yet. Please ask one of the folder owners to grant you the required permissions.`,
    });
  }

  return (
    isAuthenticated && (
      <PageError
        title={intl.formatMessage({
          id: 'errorPages.folder.notAuthorised',
          defaultMessage: 'Not authorized',
        })}
        errorCode="403"
        message={message}
      />
    )
  );
};
