import { useToasts } from 'components/Toasts/Toasts';
import { useMutation, useQuery } from 'react-query';
import { getPermissionEndpoint, getPermissionsEndpoint } from 'services/endpoints';
import client, { queryClient } from 'utils/client';
import { useTranslate } from '../Internationalisation/useTranslate';

export type Role = 'viewer' | 'annotator' | 'operator' | 'editor' | 'owner' | 'admin';
export type Permission = { id: string; folderId: string; userId: string; role: Role };

export function usePermissions() {
  const { addToast } = useToasts();
  const intl = useTranslate();

  const useGetPermissions = (folderId: string) => {
    return useQuery(
      ['get-permissions', folderId],
      () => client(getPermissionsEndpoint(folderId)),
      {
        useErrorBoundary: true,
      }
    );
  };

  const deletePermission = (permissionId: string) => {
    return client(getPermissionEndpoint(permissionId), {
      method: 'DELETE',
    });
  };

  // @ts-ignore
  const deletePermissionMutation = useMutation(deletePermission, {
    onError: () => {
      addToast({
        title: intl.formatMessage({
          id: 'usePermissions.message.permissionNotDeleted',
          defaultMessage: 'Permission not deleted',
        }),
        color: 'danger',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['get-permissions']).then(() =>
        addToast({
          title: intl.formatMessage({
            id: 'usePermissions.message.permissionDeleted',
            defaultMessage: 'Permission deleted',
          }),
          color: 'success',
        })
      );
    },
  });

  const createPermission = (permission: {
    folderId: string;
    userId: string;
    role: Role;
  }) => {
    return client(getPermissionsEndpoint(), {
      method: 'POST',
      body: {
        folder_id: permission.folderId,
        user_id: permission.userId,
        role: permission.role,
      },
    });
  };

  // @ts-ignore
  const createPermissionMutation = useMutation(createPermission, {
    onError: () => {
      addToast({
        title: intl.formatMessage({
          id: 'usePermissions.message.permissionNotCreated',
          defaultMessage: 'Permission not created',
        }),
        color: 'danger',
      });
    },
    onSuccess: (data, variables) => {
      return queryClient
        .invalidateQueries(['get-permissions', variables.folderId])
        .then(() =>
          addToast({
            title: intl.formatMessage({
              id: 'usePermissions.message.permissionCreated',
              defaultMessage: 'Permission created',
            }),
            color: 'success',
          })
        );
    },
  });

  return {
    useGetPermissions: useGetPermissions,
    deletePermissionMutation: deletePermissionMutation,
    createPermissionMutation: createPermissionMutation,
  };
}
