import { useAnnotation } from 'components/Annotation/useAnnotation';
import { useToasts } from 'components/Toasts/Toasts';
import { useCallback, useState } from 'react';
import { useIsMutating, useMutation, useQueryClient } from 'react-query';
import { getSchemaEndpoint } from 'services/endpoints';
import { ulid } from 'ulid';
import { useTranslate } from '../components/Internationalisation/useTranslate';
import { CreateSchemaType } from '../Definitions/CreateSchemaType';
import { invalidateSchema } from '../services/documents';
import { updateSchema } from '../services/schemas';

export function useSchemaUpdate(schemaId: string) {
  const queryClient = useQueryClient();
  const [error, setError] = useState<never>();
  const [errorCode, setErrorCode] = useState<number>();
  const [savedSuccessfully, setSavedSuccessfully] = useState<boolean>();
  const [updateFinishCode, setUpdateFinishedCode] = useState<string>();
  const { invalidateAnnotations } = useAnnotation();
  const { addToast } = useToasts();
  // Beware that `isLoading` return value from useMutation is not properly shared
  // accross more components and `useIsMutating` has to be used instead:
  // https://github.com/tannerlinsley/react-query/issues/2304
  const isMutating = !!useIsMutating([getSchemaEndpoint(schemaId)]);
  const intl = useTranslate();

  const { mutate } = useMutation(updateSchema, {
    onError: (err) => {
      // @ts-ignore
      setError(err);
      // @ts-ignore
      setErrorCode(err?.response?.status);
      setSavedSuccessfully(false);
      const message = err?.detail[0]?.msg;
      addToast({
        title: 'Schema cannot be updated',
        text: message ? message : '',
        color: 'danger',
      });
    },
    onSuccess: () => {
      setError(undefined);
      setErrorCode(undefined);
      setSavedSuccessfully(true);
      queryClient.invalidateQueries('get-document-annotations');
      invalidateAnnotations();
      // When schema edit flyout is opened, it is closed when toast dissappears
      // so we don't show success toast.
    },
    onSettled: () => {
      setUpdateFinishedCode(ulid());
      invalidateSchema(schemaId);
    },
  });

  const saveSchemaChanges = useCallback(
    (content: CreateSchemaType['content'], runAfterSave) => {
      mutate(
        { schemaId, content },
        {
          onSuccess: () => {
            addToast({
              title: intl.formatMessage({
                id: 'schemaBuilder.toast.skillSaved',
                defaultMessage: 'Skill saved',
              }),
              color: 'success',
            });
          },
          onSettled: () => {
            runAfterSave();
          },
        }
      );
    },
    [schemaId, mutate]
  );

  return {
    saveSchemaChanges,
    isSaving: isMutating,
    updateFinishCode,
    savedSuccessfully,
    error,
    errorCode,
  };
}
