import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiTextArea,
} from '@elastic/eui';
import { useAnnotationStore } from 'components/Annotation/Annotation';
import {
  annotationRenderCountAtom,
  isBoxAutoFitEnabledAtom,
  splitDrawModeActiveAtom,
} from 'components/Annotation/AnnotationState';
import {
  annotationToGeometryBox,
  geometryToAnnotationBox,
} from 'components/Annotation/utils';
import { DatapointType } from 'Definitions/ApiTypes';
import { GeometryType } from 'Definitions/GeometryType';
import { SchemaElementKind } from 'Definitions/SchemaElementKind';
import produce from 'immer';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTextInBox } from 'services/annotations';
import { VISIBILITY } from 'settings/constants';
import { v4 as uuidv4 } from 'uuid';
import { useActivateDatapoint } from '../../AnnotationPanel/useActivateDatapoint';
import { useDocumentClassification } from '../ImageAnnotation/useDocumentClassification';

export default function SplitDatapointAnnotationPopup(props: {
  annotation: any;
  annotationId: string;
  geometry: GeometryType;
  pageIndex: number;
  schemaId: string;
  setAnnotation: (annotation: any) => void;
}) {
  const annotationStore = useAnnotationStore();

  const { activeDatapoint, setActiveDatapoint } = useActivateDatapoint();

  const [isBoxAutoFitEnabled] = useAtom(isBoxAutoFitEnabledAtom);
  const { geometry, pageIndex, annotationId } = props;
  const [editedField, setEditedField] = useState<{
    value?: string;
    normalizedValue?: string;
    fieldId?: string;
    schemaElementId?: string;
    elementType?: DatapointType | string;
    annotationClass?: 'value' | 'keyword' | 'column_header';
  }>({
    value: activeDatapoint?.value,
    normalizedValue: undefined,
    fieldId: undefined,
    schemaElementId: undefined,
    elementType: activeDatapoint?.type,
    annotationClass: activeDatapoint?.annotationClass,
  });
  const [, setAnnotationRenderCount] = useAtom(annotationRenderCountAtom);
  const textInBox = useTextInBox({
    annotationId: annotationId,
    pageIndex: pageIndex,
    ...geometryToAnnotationBox(geometry),
  });
  const intl = useIntl();
  const documentClassification = useDocumentClassification();
  const [, setSplitDrawMode] = useAtom(splitDrawModeActiveAtom);

  const onChangeTextValue = (value: string) => {
    setEditedField(
      produce(editedField, (draft) => {
        // @ts-ignore
        draft.value = value;
        draft.normalizedValue = value;
      })
    );
  };

  const foundRowInterval = useMemo(() => {
    if (documentClassification) {
      return documentClassification.rows.reduce((foundRow, currentRow) => {
        if (
          foundRow.startPageIndex < currentRow.startPageIndex &&
          currentRow.startPageIndex <= pageIndex
        ) {
          foundRow = currentRow;
        }
        return foundRow;
      }, documentClassification.rows[0]);
    }
    return;
  }, [documentClassification, pageIndex]);

  useEffect(() => {
    setSplitDrawMode(true);

    return () => {
      setSplitDrawMode(false);
    };
  }, []);

  useEffect(() => {
    if (textInBox) {
      // @ts-ignore
      onChangeTextValue(textInBox.text);
      // @ts-ignore
      const box = annotationToGeometryBox(textInBox.box);
      // Edit box in place and rerender box by updating shared state.
      if (isBoxAutoFitEnabled) {
        geometry.x = box.x;
        geometry.y = box.y;
        geometry.width = box.width;
        geometry.height = box.height;
      }
      setAnnotationRenderCount((state: any) => state + 1);
    }
  }, [textInBox, isBoxAutoFitEnabled]);

  if (!geometry) return null;

  const onSubmit = () => {
    if (!foundRowInterval) {
      return;
    }
    // @ts-ignore
    annotationStore
      .createAnnotationItem({
        id: uuidv4(),
        annotation_class: 'keyword',
        parent_id: foundRowInterval.id,
        schema_element_id: '_document_class_first_page_index',
        schema_element_kind: SchemaElementKind.Datapoint,
        value: editedField.value,
        normalized_value: editedField.normalizedValue,
        page_index: pageIndex,
        box: {
          x_min: geometry.x / 100,
          y_min: geometry.y / 100,
          x_max: (geometry.x + geometry.width) / 100,
          y_max: (geometry.y + geometry.height) / 100,
        },
      })
      .then(() => {
        setActiveDatapoint(undefined);
        props.setAnnotation({});
      });
  };

  const onCancel = () => {
    setActiveDatapoint(undefined);
    props.setAnnotation({});
  };

  // @ts-ignore
  return (
    <EuiFlexGroup alignItems="center" gutterSize="none" direction="column">
      <EuiPanel
        paddingSize="none"
        style={{
          minWidth: '30%',
          position: 'absolute',
          fontSize: 12,
          left: `${geometry.x < 50 ? geometry.x : geometry.x + geometry.width - 30}%`,
          top: `${geometry.y + geometry.height}%`,
          zIndex: VISIBILITY.ANNOTATION_POPUP,
        }}
      >
        <EuiFlexItem grow={true}>
          <EuiTextArea
            placeholder={intl.formatMessage({
              id: 'tableDatapointAnnotationPopup.value',
              defaultMessage: 'Value',
            })}
            compressed={true}
            style={{
              maxWidth: '1200px',
              textAlign: 'left',
            }}
            resize="both"
            aria-label="Value"
            value={editedField.value}
            onChange={(e) => {
              onChangeTextValue(e.target.value);
            }}
          />
        </EuiFlexItem>
        <EuiFlexGroup justifyContent="spaceBetween" gutterSize="none" direction="row">
          <EuiFlexItem grow={true}>
            <EuiPanel
              color="plain"
              style={{
                justifyContent: 'center',
                paddingTop: '0.5em',
                paddingBottom: '0.5em',
              }}
              aria-label="Update"
              title={intl.formatMessage({
                id: 'button.update',
                defaultMessage: 'Update',
              })}
              onClick={() => {
                onSubmit();
              }}
            >
              <EuiFlexGroup justifyContent="center">
                <EuiFlexItem grow={false}>
                  <EuiIcon
                    color="text"
                    size="l"
                    title={intl.formatMessage({
                      id: 'splitDatapoint.button.addKeyword',
                      defaultMessage: 'Add keyword',
                    })}
                    aria-label="Add keyword"
                    type="check"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={true}>
            <EuiPanel
              color="plain"
              style={{
                justifyContent: 'center',
                paddingTop: '0.5em',
                paddingBottom: '0.5em',
              }}
              aria-label="Cancel"
              title={intl.formatMessage({
                id: 'button.cancel',
                defaultMessage: 'Cancel',
              })}
              onClick={() => {
                onCancel();
              }}
            >
              <EuiFlexGroup justifyContent="center">
                <EuiFlexItem grow={false}>
                  <EuiIcon
                    color="text"
                    size="l"
                    title={intl.formatMessage({
                      id: 'button.delete',
                      defaultMessage: 'Delete',
                    })}
                    aria-label="Delete"
                    type="cross"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </EuiFlexGroup>
  );
}
