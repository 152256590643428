import { useToasts } from 'components/Toasts/Toasts';
import { Api } from 'Definitions/Api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { invalidateSchemas } from 'services/documents';
import { getSchemaElementEndpoint, getSchemaEndpoint } from 'services/endpoints';
import client from 'utils/client';
import { useTranslate } from '../../Internationalisation/useTranslate';

export function useSchemas() {
  const { addToast, showRequestError } = useToasts();
  const queryClient = useQueryClient();
  const intl = useTranslate();

  const schemasUrl = 'api/v1/schemas';

  const useGetSchema = (schemaId: string) => {
    return useQuery(
      ['schemas', schemaId],
      (): Promise<Api['SchemaGetOut']> => client(`${schemasUrl}/${schemaId}`),
      {
        useErrorBoundary: true,
      }
    );
  };

  const useGetAllSchemas = () => {
    return useQuery(
      ['schemas', 'all'],
      (): Promise<Api['SchemaGetOut']> => client(schemasUrl),
      {
        useErrorBoundary: true,
      }
    );
  };

  const useGetSkillStatistics = (
    schemaId: string,
    start: string,
    end: string,
    timeFrequency: string,
    folderId?: string
  ) => {
    let url = `${schemasUrl}/${schemaId}/statistics`;
    let queryParams = new URLSearchParams();
    queryParams.append('processed_after', start);
    queryParams.append('processed_before', end);
    queryParams.append('time_frequency', timeFrequency);
    if (!!folderId) {
      queryParams.append('folder_id', folderId);
    }
    url += `?${queryParams.toString()}`;
    return useQuery(
      [
        'schemas',
        schemaId,
        'statistics',
        { start: start, end: end, timeFrequency: timeFrequency, folderId: folderId },
      ],
      (): Promise<Api['SkillStatisticsGetOut']> => client(url),
      {
        useErrorBoundary: true,
      }
    );
  };

  const useGetSchemaHooks = (language: string) => {
    return useQuery(
      ['schemas', 'hooks', { language: language }],
      (): Promise<Api['SchemaHooks']> =>
        client(`${schemasUrl}/hooks?language=${language}`),
      {
        useErrorBoundary: true,
      }
    );
  };

  const useGetSkillVersions = (
    schemaId: string,
    refetchIntervalMs?: number | boolean,
    enabled: boolean = true
  ) => {
    return useQuery(
      ['schemas', schemaId, 'versions'],
      (): Promise<Api['SkillVersionsGetOut']> =>
        client(`${schemasUrl}/${schemaId}/versions`),
      {
        enabled,
        refetchInterval: refetchIntervalMs,
        useErrorBoundary: true,
      }
    );
  };

  const useGetSkillVersionEvaluation = (
    schemaId: string,
    versionId: string,
    groupingDatapointId?: string,
    folderId?: string
  ) => {
    let url = `${schemasUrl}/${schemaId}/versions/${versionId}/evaluation`;
    let queryParams = new URLSearchParams();
    if (!!groupingDatapointId) {
      queryParams.append('grouping_datapoint_id', groupingDatapointId);
    }
    if (!!folderId) {
      queryParams.append('folder_id', folderId);
    }
    url += `?${queryParams.toString()}`;
    return useQuery(
      [
        'schemas',
        schemaId,
        'versions',
        versionId,
        'evaluation',
        { groupingDatapointId: groupingDatapointId, folderId: folderId },
      ],
      (): Promise<Api['SkillVersionEvaluationGetOut']> => client(url),
      {
        useErrorBoundary: true,
      }
    );
  };

  const invalidateGetSkillVersionEvaluation = (schemaId: string, versionId: string) => {
    return queryClient.invalidateQueries([
      'schemas',
      schemaId,
      'versions',
      versionId,
      'evaluation',
    ]);
  };

  const testSkillVersion = (props: {
    schemaId: string;
    skillVersionId: string;
    testDocumentsCount?: number;
    groupingDatapointId?: string;
    usetTestDocumentsFromActiveVersion?: boolean;
  }) => {
    return client(
      `${schemasUrl}/${props.schemaId}/versions/${props.skillVersionId}/evaluation`,
      {
        method: 'POST',
        // @ts-ignore
        body: {
          test_documents_count: props.testDocumentsCount,
          grouping_datapoint_id: props.groupingDatapointId,
          use_test_documents_from_active_version:
            props.usetTestDocumentsFromActiveVersion,
        },
      }
    );
  };

  // @ts-ignore
  const testSkillVersionMutation = useMutation(testSkillVersion, {
    onError: (error) =>
      showRequestError(
        error,
        intl.formatMessage({
          id: 'error.couldNotRunTest',
          defaultMessage: 'Could not run test',
        })
      ),
    onSuccess: (data, variables) => invalidateSkillVersions(variables.schemaId),
  });

  const invalidateSkillVersions = (schemaId: string) => {
    return queryClient.invalidateQueries(['schemas', schemaId, 'versions']);
  };

  const postSkillVersion = (props: {
    schemaId: string;
    payload: Api['SkillVersionsPostIn'];
  }) => {
    return client(`${schemasUrl}/${props.schemaId}/versions`, {
      method: 'POST',
      body: props.payload,
    });
  };

  // @ts-ignore
  const createSkillVersionMutation = useMutation(postSkillVersion, {
    onError: () => {
      addToast({
        title: intl.formatMessage({
          id: 'error.skillVersionNotCreated',
          defaultMessage: 'Skill version not created',
        }),
        color: 'danger',
      });
    },
    onSuccess: (data, variables) => {
      return queryClient.invalidateQueries(['schemas', variables.schemaId, 'versions']);
    },
  });

  const deleteSkillVersion = (props: { schemaId: string; skillVersionId: string }) => {
    return client(`${schemasUrl}/${props.schemaId}/versions/${props.skillVersionId}`, {
      method: 'DELETE',
    });
  };

  // @ts-ignore
  const deleteSkillVersionMutation = useMutation(deleteSkillVersion, {
    onError: (error) => showRequestError(error),
    onSuccess: (data, variables) => invalidateSkillVersions(variables.schemaId),
  });

  const patchSkillVersion = (props: {
    schemaId: string;
    skillVersionId: string;
    description: string;
  }) => {
    return client(`${schemasUrl}/${props.schemaId}/versions/${props.skillVersionId}`, {
      method: 'PATCH',
      // @ts-ignore
      body: { description: props.description },
    });
  };

  // @ts-ignore
  const patchSkillVersionMutation = useMutation(patchSkillVersion, {
    onError: (error) => showRequestError(error),
    onSuccess: (data, variables) => invalidateSkillVersions(variables.schemaId),
  });

  const activateSkillVersion = (props: {
    schemaId: string;
    skillVersionId: string;
  }) => {
    return client(
      `${schemasUrl}/${props.schemaId}/versions/${props.skillVersionId}/activate`,
      { method: 'POST' }
    );
  };

  // @ts-ignore
  const activateSkillVersionMutation = useMutation(activateSkillVersion, {
    onError: () => {
      addToast({
        title: intl.formatMessage({
          id: 'error.skillVersionNotActivated',
          defaultMessage: 'Skill version not activated',
        }),
        color: 'danger',
      });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['schemas', variables.schemaId, 'versions']);
    },
  });

  const patchSchemaElement = (props: {
    schemaId: string;
    elementId: string;
    patch: { id: string };
  }) => {
    return client(getSchemaElementEndpoint(props.schemaId, props.elementId), {
      method: 'PATCH',
      // @ts-ignore
      body: props.patch,
    });
  };

  const patchSchema = (props: { schemaId: string; patch: { folder_id: string } }) => {
    return client(getSchemaEndpoint(props.schemaId), {
      method: 'PATCH',
      body: props.patch,
    });
  };

  // @ts-ignore
  const patchSchemaElementMutation = useMutation(patchSchemaElement, {
    onError: () => {
      addToast({
        title: intl.formatMessage({
          id: 'error.schemaElementNotUpdated',
          defaultMessage: 'Schema element not updated',
        }),
        color: 'danger',
      });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(getSchemaEndpoint(variables.schemaId));
      // We don't show success toast because when it dissappears it closes visible
      // schema element edit flyout.
    },
  });

  // @ts-ignore
  const patchSchemaMutation = useMutation(patchSchema, {
    onError: () => {
      addToast({
        title: intl.formatMessage({
          id: 'error.skillNotUpdated',
          defaultMessage: 'Skill not updated',
        }),
        color: 'danger',
      });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(getSchemaEndpoint(variables.schemaId));
    },
  });

  const deleteSchema = async (params: { schemaId: string }) => {
    return client(getSchemaEndpoint(params.schemaId), {
      method: 'DELETE',
    });
  };

  const deleteSchemaMutation = useMutation(deleteSchema, {
    onError: (error) =>
      showRequestError(
        error,
        intl.formatMessage({
          id: 'error.skillNotDeleted',
          defaultMessage: 'Skill not deleted',
        })
      ),
    onSuccess: () => {
      invalidateSchemas();
    },
  });

  return {
    useGetSchema: useGetSchema,
    useGetSchemaHooks: useGetSchemaHooks,
    useGetAllSchemas: useGetAllSchemas,
    useGetSkillStatistics: useGetSkillStatistics,
    useGetSkillVersions: useGetSkillVersions,
    useGetSkillVersionEvaluation: useGetSkillVersionEvaluation,
    invalidateGetSkillVersionEvaluation: invalidateGetSkillVersionEvaluation,
    invalidateSkillVersions: invalidateSkillVersions,
    createSkillVersionMutation: createSkillVersionMutation,
    activateSkillVersionMutation: activateSkillVersionMutation,
    testSkillVersionMutation: testSkillVersionMutation,
    patchSchemaElementMutation: patchSchemaElementMutation,
    patchSchemaMutation: patchSchemaMutation,
    deleteSchemaMutation: deleteSchemaMutation,
    deleteSkillVersionMutation: deleteSkillVersionMutation,
    patchSkillVersionMutation: patchSkillVersionMutation,
  };
}
