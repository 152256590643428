import { SchemaDatapoint, SchemaGetOut } from '../Definitions/ApiTypes';
import { SchemaElementKind } from '../Definitions/SchemaElementKind';

export interface SchemaDataElement extends SchemaDatapoint {
  parentKind: SchemaElementKind;
}

export function getSchemaDataElement(
  schema: SchemaGetOut,
  elementId: string
): SchemaDataElement | undefined {
  if (!schema || !elementId) {
    return undefined;
  }

  let foundElement;

  const findInTableElement = (schemaTableElement) => {
    const founditem = schemaTableElement.elements.elements.find(
      (element) => element.id === elementId
    );

    if (founditem) {
      return { ...founditem, parentKind: SchemaElementKind.Tuple };
    }
    return undefined;
  };

  const findInListElement = (schemaListElement) => {
    const founditem =
      schemaListElement.elements.id === elementId
        ? schemaListElement.elements
        : undefined;

    if (founditem) {
      return { ...founditem, parentKind: SchemaElementKind.List };
    }
    return undefined;
  };

  const findAsDatapointElement = (item) => {
    const foundItem = item.id === elementId ? item : undefined;

    if (foundItem) {
      return { ...foundItem, parentKind: SchemaElementKind.Section };
    }

    return undefined;
  };

  const findInSectionElement = (sectionElement) => {
    let foundInSection;

    for (const item of sectionElement.elements) {
      if (foundInSection) {
        return foundInSection;
      }
      switch (item.kind) {
        case SchemaElementKind.List:
          foundInSection = findInListElement(item);
          break;
        case SchemaElementKind.Table:
          foundInSection = findInTableElement(item);
          break;
        case SchemaElementKind.Datapoint:
          foundInSection = findAsDatapointElement(item);
      }
    }
    return foundInSection;
  };

  for (const item of schema.content.elements) {
    if (foundElement) {
      return foundElement;
    }

    switch (item.kind) {
      case SchemaElementKind.Section:
        foundElement = findInSectionElement(item);
        break;
      case SchemaElementKind.List:
        foundElement = findInListElement(item);
        break;
      case SchemaElementKind.Table:
        foundElement = findInTableElement(item);
        break;
      case SchemaElementKind.Datapoint:
        foundElement = findAsDatapointElement(item);
        break;
      default:
        foundElement = findInSectionElement(item);
        break;
    }
  }

  return foundElement;
}
