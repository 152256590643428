import { InLineLoader } from 'components/InlineLoader/InlineLoader';
import { atom, useAtom } from 'jotai';
import React, { useMemo } from 'react';
import { useDocumentContent } from 'services/documents';
import { atomWithLocalStorage } from 'utils/persistence';
import { THEME } from '../../../settings/constants';

export const pageScaleAtom = atomWithLocalStorage('pageScale', 1);
export const pageRotationAngleAtom = atom(0);

export function ImageBrowser({ documentId }) {
  const { data: documentContent, isLoading } = useDocumentContent(documentId);
  const [scale] = useAtom(pageScaleAtom);
  const [rotationAngle] = useAtom(pageRotationAngleAtom);

  const documentContentURL = useMemo(
    () => !isLoading && URL.createObjectURL(documentContent),
    [documentContent, isLoading]
  );

  if (isLoading) return <InLineLoader />;

  return (
    <>
      {documentContentURL && (
        <>
          <img
            style={{
              transform: `rotate(${rotationAngle}deg)`,
              minWidth: `clamp(250px, ${scale * 900}px, 200%)`,
              maxWidth: `clamp(250px, ${scale * 900}px, 200%)`,
              // TODO: Make it work properly even for high scale
              marginTop: `calc((${
                THEME.euiHeaderHeightCompensation
              } + 8em) * ${Math.min(1, Math.abs(rotationAngle % 180))})`,
            }}
            src={documentContentURL}
            alt="Document"
            className="shadowDocumentPage"
          />
        </>
      )}
    </>
  );
}
