/* eslint no-use-before-define: 0 */

import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSideNav,
  EuiText,
  _EuiSideNavItemButtonProps,
} from '@elastic/eui';
import { FolderConfigurationFlyout } from 'components/Folders/FolderConfiguration';
import { FolderInfo, useFolders } from 'components/Folders/useFolders';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
import { atom, useAtom } from 'jotai';
import { isEmpty } from 'lodash';
import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isElementVisible } from '../../utils/visibility';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { lastSelectedFolderAtom } from './FolderState';
export const expandedSideNavItemsAtom = atom({});
export const searchedSidenavFoldersAtom = atom(undefined);

/**
 * Based on default item render:
 * https://github.com/elastic/eui/blob/91b416dcd51e116edb2cb4a2cac4c306512e28c7/src/components/side_nav/side_nav_item.tsx#L101
 * We ignore href, target, rel properties as we use only onClick.
 */
const FolderItem = ({
  onClick,
  className,
  children,
  disabled,
  folderId,
  isRootFolder,
  sideNavItem,
  isfolderselected,
  ...data
}: _EuiSideNavItemButtonProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { folderId: currentSelectedFolderId } = useParams();
  const hasChildren = sideNavItem.data.items?.length > 0;
  const isConfigureFolderFlyoutVisible = useMemo(() => {
    return currentSelectedFolderId === folderId && searchParams.get('folderConfig');
  }, [searchParams]);

  const sideNavItemRef = useRef<HTMLElement>(null);
  const intl = useTranslate();

  const closeFlyout = () => {
    searchParams.delete('folderConfig');
    setSearchParams(searchParams);
  };

  const [expandedSideNavItems, setExpandedSideNavItems] = useAtom(
    expandedSideNavItemsAtom
  );

  const [displaySidenavFolders] = useAtom(searchedSidenavFoldersAtom);

  useLayoutEffect(() => {
    if (
      isfolderselected &&
      sideNavItemRef?.current &&
      !isElementVisible(sideNavItemRef.current)
    ) {
      //wait for the sidenav to render each item individually before scrolling
      setTimeout(() => {
        if (sideNavItemRef.current) {
          sideNavItemRef.current.scrollIntoView();
        }
      }, 300);
    }
  }, [isfolderselected]);

  const collapseChildren = (sideItem, expandedItems) => {
    delete expandedItems[sideItem.folderId];

    if (sideItem.items && sideItem.items.length) {
      sideItem.items.forEach((item) => {
        collapseChildren(item, expandedItems);
      });
    }
    return expandedItems;
  };

  const findSelectedChild = (sideItem, value) => {
    if (sideItem.isSelected) {
      return true;
    } else if (!value && sideItem.items && sideItem.items.length) {
      return sideItem.items.some((item) => {
        return findSelectedChild(item, value) === true;
      });
    } else {
      return value;
    }
  };

  const hasSelectedChild = useMemo(() => {
    return findSelectedChild({ ...sideNavItem, items: sideNavItem.data.items }, false);
  }, [sideNavItem]);

  const onCollapseClick = () => {
    if (expandedSideNavItems[sideNavItem.folderId]) {
      setExpandedSideNavItems((prevState) => {
        collapseChildren({ ...sideNavItem, items: sideNavItem.data.items }, prevState);
        const newItem = Object.assign({}, prevState);
        return newItem;
      });
    } else {
      expandedSideNavItems[sideNavItem.folderId] = sideNavItem;

      setExpandedSideNavItems((prevState) => {
        return { ...prevState, [sideNavItem.folderId]: sideNavItem };
      });
    }
  };

  const menuButton = isfolderselected ? (
    <EuiButtonIcon
      title={intl.formatMessage({ id: 'folders.options', defaultMessage: 'Options' })}
      iconType="gear"
      color="primary"
      aria-label="Options"
      size="s"
      iconSize="m"
      onClick={() => {
        searchParams.append('folderConfig', 'settings');
        setSearchParams(searchParams);
      }}
    />
  ) : (
    <EuiIcon
      title={intl.formatMessage({ id: 'folders.folder', defaultMessage: 'Folder' })}
      type="folderClosed"
      color="text"
      size="m"
      style={{ margin: '8px' }}
    />
  );

  if (!disabled) {
    return (
      <>
        <EuiFlexGroup
          ref={sideNavItemRef}
          responsive={false}
          gutterSize="none"
          justifyContent="flexStart"
          alignItems="center"
        >
          <EuiFlexItem grow={false}>{menuButton}</EuiFlexItem>
          <EuiFlexItem grow={true} style={{ width: 'calc(100% - 64px)' }}>
            <button
              type="button"
              title={children}
              className={className}
              onClick={onClick}
              disabled={disabled}
              {...data}
            >
              {children}
            </button>
          </EuiFlexItem>
          {!isRootFolder && hasChildren && (
            <EuiFlexItem style={{ display: 'flex', alignItems: 'flex-end' }}>
              <EuiButtonIcon
                iconType={
                  expandedSideNavItems[sideNavItem.folderId] ||
                  isfolderselected ||
                  hasSelectedChild ||
                  (displaySidenavFolders && displaySidenavFolders[sideNavItem.folderId])
                    ? 'arrowDown'
                    : 'arrowRight'
                }
                color="text"
                aria-label="Options"
                size="s"
                iconSize="s"
                title={intl.formatMessage({
                  id: 'folders.button.toggleCollapseFolder',
                  defaultMessage: 'Toggle collapse state',
                })}
                disabled={
                  hasSelectedChild ||
                  isfolderselected ||
                  (displaySidenavFolders && displaySidenavFolders[sideNavItem.folderId])
                }
                onClick={onCollapseClick}
              />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        {isConfigureFolderFlyoutVisible && (
          <FolderConfigurationFlyout
            closeFlyout={closeFlyout}
            folderId={folderId}
            defaultSelectedTab={searchParams.get('folderConfig')}
          />
        )}
      </>
    );
  }

  return <div className={className}>{children}</div>;
};

export function useFoldersTree() {
  const { useGetFolders } = useFolders();
  const { data: folders, isLoading } = useGetFolders();
  const { folderId: selectedFolderId } = useParams();
  const navigate = useNavigate();
  const [, setLastSelectedFolderId] = useAtom(lastSelectedFolderAtom);
  const [expandedSideNavItems, setExpandedSideNavItems] = useAtom(
    expandedSideNavItemsAtom
  );
  const [searchedSidenavFolders] = useAtom(searchedSidenavFoldersAtom);

  const createItem = useCallback(
    (item: {
      name: string;
      folderId: string;
      parentfolderid: string;
      isRootFolder: boolean;
      data: any;
    }) => {
      return {
        id: item.folderId,
        name: item.name,
        truncate: true,
        renderItem: (props) => <FolderItem {...props} sideNavItem={item} />,
        isSelected: selectedFolderId === item.folderId,
        // isSelected is not passed to FolderItem so we pass it as extra data attribute.
        // the parameter should be lowerCase in order to avoid errors in console regarding unsupported parameters.
        isfolderselected: selectedFolderId === item.folderId ? 1 : 0,
        onClick: () => handleSelectFolder(item.folderId),
        folderId: item.folderId,
        isRootFolder: item.isRootFolder,
        parentfolderid: item.parentfolderid,
        ...item.data,
      };
    },
    [selectedFolderId]
  );

  const foldersToFolderItemsHashTable = useCallback(
    (folders: any[]) => {
      const hashTable = Object.create({});
      // Hidden root folder needed to display the tree properly via EUI.
      const hiddenRootFolder = {
        name: '',
        id: 'hidden_root',
        parent_id: null,
      };
      const foldersWithRoot = folders
        .concat([hiddenRootFolder])
        .sort((folder: any, nextFolder: any) =>
          folder.name.localeCompare(nextFolder.name)
        );
      foldersWithRoot.forEach(
        (folder: any) =>
          (hashTable[folder.id] = createItem({
            folderId: folder.id,
            parentfolderid: folder.parent_id,
            name: folder.name,
            // Top = home = root folder of the whole folders tree
            isRootFolder: !folder.parent_id && folder.id !== 'hidden_root',
            data: { items: [], disabled: folder.id === 'hidden_root' ? true : false },
          }))
      );
      return hashTable;
    },
    [createItem]
  );

  const handleSelectFolder = (folderId: string) => {
    setLastSelectedFolderId(folderId);
    navigate(`/folders/${encodeURIComponent(folderId)}/documents`);
  };

  const hashTable = useMemo(() => {
    if (folders?.data && folders?.data.length > 0) {
      return foldersToFolderItemsHashTable(folders.data);
    }
  }, [folders, foldersToFolderItemsHashTable]);

  const sideNavFolderHashTable = useMemo(() => {
    if (folders?.data && folders?.data.length > 0) {
      let displayFolders = folders.data;
      if (searchedSidenavFolders) {
        displayFolders = !isEmpty(searchedSidenavFolders)
          ? displayFolders.filter((folder) => {
              return !!searchedSidenavFolders[folder.id];
            })
          : [];
      }
      return foldersToFolderItemsHashTable(displayFolders);
    }
  }, [
    folders,
    foldersToFolderItemsHashTable,
    expandedSideNavItems,
    searchedSidenavFolders,
  ]);

  /** Get array of ordered FolderInfos from root folder down to folder with `folderId`. */
  const getFolderInfoWithParents = useCallback(
    (folderId: string, providedHashTable = hashTable): FolderInfo[] => {
      const parents = [];
      let currentItem = providedHashTable[folderId];
      if (!currentItem) {
        return [];
      }
      let iterate = true;
      while (iterate) {
        parents.push({ id: currentItem.folderId, name: currentItem.name });
        if (
          currentItem.parentfolderid in providedHashTable &&
          !currentItem.isRootFolder
        ) {
          currentItem = providedHashTable[currentItem.parentfolderid];
        } else {
          iterate = false;
        }
      }
      return parents.reverse();
    },
    [hashTable]
  );

  /** Get array of folders where `folderId` is the parent folder . */
  const getFolderInfoWithChildren = useCallback(
    (folderId: string, providedHashTable = hashTable): FolderInfo[] => {
      const currentItem = providedHashTable[folderId];
      if (!currentItem) {
        return [];
      }
      let children: Record<string, FolderInfo> = { [folderId]: currentItem };
      let iterate = true;

      while (iterate) {
        iterate = false;
        Object.entries(providedHashTable).forEach(([key, value]: [string, any]) => {
          if (!children[key] && value.parentfolderid in children) {
            children = { ...children, [value.folderId]: value };
            iterate = true;
          }
        });
      }

      return Object.values(children);
    },
    [hashTable]
  );

  const folderItemsHashTableToTree = (hashTable: any[]) => {
    const tree: any[] = [];
    Object.values(hashTable).forEach((folder) => {
      const parent_id =
        folder.id === 'hidden_root'
          ? null
          : folder.isRootFolder
          ? 'hidden_root'
          : folder.parentfolderid;

      // If there is a folder whose parent is not present in data because user
      // does not have read permission for it, it is shown as root of separate tree.
      // TODO: This separate tree does not have the hidden root so the first subfolder
      // of this separate tree is not visually indented.
      const modifiedFolder = {
        ...hashTable[folder.id],
        forceOpen:
          !!expandedSideNavItems[folder.id] ||
          (searchedSidenavFolders && !!searchedSidenavFolders[folder.id]),
      };

      if (parent_id && hashTable[parent_id])
        hashTable[parent_id].items.push(modifiedFolder);
      else tree.push(modifiedFolder);
    });
    return tree;
  };

  const foldersTree = useMemo(
    () => sideNavFolderHashTable && folderItemsHashTableToTree(sideNavFolderHashTable),
    [sideNavFolderHashTable]
  );

  const selectedFolderWithParents = useMemo(
    () =>
      selectedFolderId &&
      hashTable &&
      getFolderInfoWithParents(selectedFolderId, hashTable),
    [getFolderInfoWithParents, selectedFolderId, hashTable]
  );

  const selectedFolderWithParentsIds = useMemo(() => {
    if (!!selectedFolderWithParents) {
      const parentfolderids = selectedFolderWithParents.map(
        (folderInfo) => folderInfo.id
      );
      return parentfolderids;
    }
  }, [selectedFolderWithParents]);

  const getFolderParents = (selectedFolderId: string) => {
    return getFolderInfoWithParents(selectedFolderId).filter(
      (folder) => folder.id !== selectedFolderId
    );
  };

  const getFolderParentsDisplayString = (selectedFolderId: string) => {
    const parentFolders = getFolderParents(selectedFolderId);
    let folderParentsString = '';
    parentFolders.forEach((folder, index) => {
      if (index === 0) {
        folderParentsString += '/';
      }

      if (parentFolders.length - 1 === index) {
        folderParentsString += `${folder.name}`;
      } else {
        folderParentsString += `${folder.name}/`;
      }
    });

    return folderParentsString;
  };

  const toggleCollapseTree = useCallback(() => {
    isEmpty(expandedSideNavItems);
    if (isEmpty(expandedSideNavItems)) {
      setExpandedSideNavItems(hashTable);
    } else {
      setExpandedSideNavItems({});
    }
  }, [hashTable, expandedSideNavItems]);

  return {
    selectedFolderWithParents: selectedFolderWithParents,
    selectedFolderWithParentsIds: selectedFolderWithParentsIds,
    getFolderInfoWithParents: getFolderInfoWithParents,
    getFolderInfoWithChildren: getFolderInfoWithChildren,
    getFolderParentsDisplayString: getFolderParentsDisplayString,
    foldersTree: foldersTree,
    isLoading: isLoading,
    toggleCollapseTree: toggleCollapseTree,
  };
}

export function FoldersNavigation() {
  const { foldersTree, isLoading } = useFoldersTree();

  if (isLoading) return <InLineLoader />;

  const notFolders = foldersTree.length === 1 && !foldersTree[0].items.length;

  return notFolders ? (
    <EuiText size="s" color="subdued" style={{ padding: '5px' }}>
      <Translate id="folders.noMatchesFound" defaultMessage="No matches" />
    </EuiText>
  ) : (
    <EuiSideNav
      aria-label="Folders"
      mobileBreakpoints={[]}
      items={foldersTree}
      truncate={true}
      style={{
        margin: '-8px 8px 0px 0px',
      }}
    />
  );
}
