import { EuiButton } from '@elastic/eui';
import { schemaContentJsonString } from 'components/Manage/Schema/SchemaContentEditor';
import { useToasts } from 'components/Toasts/Toasts';
import { SchemaUpdateProps } from 'Definitions/SchemaUpdateProps';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { SchemaContent, SchemaGetOut } from '../../../Definitions/ApiTypes';
import { SchemaContentType } from '../../../Definitions/SchemaContentType';
import { useSchema } from '../../../services/documents';
import { useSchemaUpdate } from '../../../utils/useSchemaUpdate';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { SchemaSaveAsNewModalWindow } from './SchemaSaveAsNewModalWindow';

export function SchemaUpdate(props: SchemaUpdateProps) {
  const { addToast } = useToasts();
  const { saveSchemaChanges, isSaving, updateFinishCode, errorCode } = useSchemaUpdate(
    props.schemaId
  );
  const [schemaNewContentString] = useAtom(schemaContentJsonString);
  const { data: schema } = useSchema(props.schemaId);
  const [isOpenSaveAsNewModal, setIsOpenSaveAsNewModal] = useState<boolean>(false);
  const [schemaNewContent, setSchemaNewContent] = useState<SchemaContent>({
    elements: [],
  });
  const [
    isActiveInvalidJsonFormatWarning,
    setIsActiveInvalidJsonFormatWarning,
  ] = useState<boolean>(false);
  const intl = useTranslate();

  useEffect(() => {
    let newContent = { elements: [] };
    if (schemaNewContentString.length) {
      try {
        newContent = JSON.parse(schemaNewContentString);
        setIsActiveInvalidJsonFormatWarning(false);
      } catch (e) {
        if (!isActiveInvalidJsonFormatWarning) {
          addToast({
            title: intl.formatMessage({
              id: 'schemaUpdate.invalidJsonFormat.title',
              defaultMessage: 'Invalid JSON format',
            }),
            // @ts-ignore
            text: intl.formatMessage({
              id: 'schemaUpdate.invalidJsonFormat.description',
              defaultMessage: 'Schema content has invalid format',
            }),
            color: 'warning',
          });
          setIsActiveInvalidJsonFormatWarning(true);
        }
      } finally {
        if (newContent !== null) {
          setSchemaNewContent(newContent);
        }
      }
    }
  }, [schemaNewContentString]);

  useEffect(() => {
    if (updateFinishCode) {
      if (errorCode === 405) {
        setIsOpenSaveAsNewModal(true);
      } else {
        setIsOpenSaveAsNewModal(false);
      }
    }
  }, [updateFinishCode]);

  function handleSaveButtonClick() {
    saveSchemaChanges(
      (JSON.parse(schemaNewContentString) as unknown) as SchemaContentType,
      props.closeFlyout
    );
  }

  return (
    <>
      <EuiButton
        fill
        iconType="save"
        onClick={handleSaveButtonClick}
        isLoading={isSaving}
      >
        Save
      </EuiButton>
      <SchemaSaveAsNewModalWindow
        open={isOpenSaveAsNewModal}
        originalSchema={schema as SchemaGetOut}
        newContent={schemaNewContent}
        onClose={() => setIsOpenSaveAsNewModal(false)}
      />
    </>
  );
}
