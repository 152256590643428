import {
  EuiButtonIcon,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  useEuiTheme,
} from '@elastic/eui';
import { atom, useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { fetchSearchInDocumentResults } from '../../services/annotations';
import { useAnnotationStore } from './Annotation';
import { showSearchInDocumentBoxAtom } from './AnnotationState';

export const documentSearchResultsAtom = atom([]);

export function SearchInDocumentBox() {
  const [isSearchBoxVisible, setIsSearchBoxVisible] = useAtom(
    showSearchInDocumentBoxAtom
  );
  const [documentSearchResults, setDocumentSearchResults] = useAtom(
    documentSearchResultsAtom
  );
  const annotation = useAnnotationStore<any>((state) => state.annotation);
  const focusPage = useAnnotationStore((state) => state.focusPage);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [focusedResult, setFocusedResult] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { euiTheme } = useEuiTheme();
  const intl = useIntl();

  let searchBox;

  useEffect(() => {
    if (focusedResult > 0 && documentSearchResults.length) {
      const focusedIndex = focusedResult - 1;
      const markedResults = documentSearchResults.map((item: any, idx: number) => {
        const isFocused = idx === focusedIndex;

        if (isFocused) {
          // @ts-ignore
          focusPage(item.page_index);
        }

        return {
          ...item,
          focused: isFocused,
        };
      });
      // @ts-ignore
      setDocumentSearchResults(markedResults);
    }
  }, [focusedResult, documentSearchResults.length]);

  function handleSearchDocument(event: string | React.MouseEvent) {
    if (event === '') {
      return;
    }

    setIsLoading(true);
    // @ts-ignore
    fetchSearchInDocumentResults(annotation?.id, searchPhrase)
      .then((response) => {
        setFocusedResult(response.results.length ? 1 : 0);
        const markedResults = response.results.map((item: any, idx: number) => ({
          ...item,
          focused: idx === 0,
        }));
        setDocumentSearchResults(markedResults);
      })
      .finally(() => setIsLoading(false));
  }

  function handleSearchPhraseChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchPhrase(e.target.value || '');
    setFocusedResult(0);
    setDocumentSearchResults([]);
  }

  function handlePreviousResult() {
    setFocusedResult(focusedResult - 1);
  }

  function handleNextResult() {
    setFocusedResult(focusedResult + 1);
  }

  if (isSearchBoxVisible) {
    searchBox = (
      <EuiFlexGroup
        direction="row"
        responsive={false}
        gutterSize="s"
        alignItems="center"
        style={{
          background: euiTheme.colors.lightestShade,
          borderRadius: euiTheme.border.radius.small,
          borderStyle: 'solid',
          borderWidth: '0px',
        }}
      >
        <EuiFlexItem grow={false}>
          <EuiFieldSearch
            className="no-search-icon"
            placeholder={intl.formatMessage({
              id: 'searchInDocumentBox.searchInDocument',
              defaultMessage: 'Search in document',
            })}
            value={searchPhrase}
            onChange={handleSearchPhraseChange}
            onSearch={handleSearchDocument}
            isClearable={true}
            isLoading={isLoading}
            aria-label="Search the phrase in this document"
            append={[
              <EuiButtonIcon
                iconType="search"
                aria-label="Search"
                title={intl.formatMessage({
                  id: 'button.search',
                  defaultMessage: 'Search',
                })}
                onClick={handleSearchDocument}
                disabled={isLoading || searchPhrase.length === 0}
              />,
              `${focusedResult}/${documentSearchResults.length}`,
            ]}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>|</EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            color="text"
            onClick={handlePreviousResult}
            iconType="arrowUp"
            aria-label="Previous"
            title={intl.formatMessage({
              id: 'button.previous',
              defaultMessage: 'Previous',
            })}
            disabled={
              (documentSearchResults.length
                ? focusedResult === 1
                : focusedResult === 0) || isLoading
            }
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            color="text"
            onClick={handleNextResult}
            iconType="arrowDown"
            aria-label="Next"
            title={intl.formatMessage({ id: 'button.next', defaultMessage: 'Next' })}
            disabled={focusedResult === documentSearchResults.length || isLoading}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            color="text"
            onClick={() => setIsSearchBoxVisible(false)}
            iconType="crossInACircleFilled"
            title={intl.formatMessage({ id: 'button.close', defaultMessage: 'Close' })}
            aria-label="Close"
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return <>{searchBox}</>;
}
