import { useToasts } from 'components/Toasts/Toasts';
import { useQuery } from 'react-query';
import { useTranslate } from '../components/Internationalisation/useTranslate';
import client from '../utils/client';
import {
  getAnnotationExportEndpoint,
  getAnnotationItemCommandsEndpoint,
  getAnnotationItemEndpoint,
  getAnnotationItemsEndpoint,
  getAnnotationSplitEndpoint,
  getAnnotationTemplateEndpoint,
  getSearchInDocumentEndpoint,
} from './endpoints';

export function putAnnotationStatus(annotationId, newStatus) {
  return client(`api/v1/annotations/${annotationId}/status?new_status=${newStatus}`, {
    method: 'PUT',
  });
}

export function putAnnotationStatuses({ annotationIdList, status }) {
  const body = { annotation_ids: annotationIdList, new_status: status };
  return client(`api/v1/annotations/status`, {
    method: 'PUT',
    body,
  });
}

export function useAnnotationExport(annotationId) {
  return useQuery(getAnnotationExportEndpoint(annotationId), {
    useErrorBoundary: true,
  });
}

export function postMarkAsTemplate(annotationId) {
  return client(getAnnotationTemplateEndpoint(annotationId), {
    method: 'POST',
  });
}

export function postSplitDocumentByAnnotation(annotationId) {
  return client(getAnnotationSplitEndpoint(annotationId), {
    method: 'POST',
  });
}

export function copyAnnotation(annotationId) {
  return client(`api/v1/annotations/${annotationId}/copy`, {
    method: 'POST',
  });
}

export function deleteAnnotationItem(annotationId, annotationItemId) {
  return client(getAnnotationItemEndpoint(annotationId, annotationItemId), {
    method: 'DELETE',
  });
}
export function patchAnnotationItem(annotationId, annotationItemId, patch) {
  return client(getAnnotationItemEndpoint(annotationId, annotationItemId), {
    body: patch,
    method: 'PATCH',
  });
}
export function patchAnnotationItemMutate(props) {
  return client(getAnnotationItemEndpoint(props.annotationId, props.annotationItemId), {
    body: props.patch,
    method: 'PATCH',
  });
}
export function createAnnotationItem(annotationId, annotationItem) {
  return client(getAnnotationItemsEndpoint(annotationId), {
    body: annotationItem,
    method: 'POST',
  });
}

export function createAnnotationBatchOperations(
  annotationId,
  annotationBatchOperations
) {
  return client(getAnnotationItemCommandsEndpoint(annotationId), {
    body: annotationBatchOperations,
    method: 'POST',
  });
}

export function createAnnotationItemMutation(props) {
  return client(getAnnotationItemsEndpoint(props.annotationId), {
    body: props.payload,
    method: 'POST',
  });
}

export function getTextInBox({ annotationId, pageIndex, xMin, yMin, xMax, yMax }) {
  return client(
    `api/v1/annotations/${annotationId}/text_in_box?page_index=${pageIndex}&x_min=${xMin}&x_max=${xMax}&y_min=${yMin}&y_max=${yMax}`
  );
}

export function useTextInBox({
  annotationId,
  pageIndex,
  xMin,
  yMin,
  xMax,
  yMax,
  areaThreshold = 0.5,
  isEnabled = true,
}) {
  const { addToast } = useToasts();
  const intl = useTranslate();
  const { data, isIdle, isLoading, isError } = useQuery(
    `api/v1/annotations/${annotationId}/text_in_box` +
      `?page_index=${pageIndex}` +
      `&x_min=${Math.max(0, xMin)}&x_max=${Math.min(1, xMax)}` +
      `&y_min=${Math.max(0, yMin)}&y_max=${Math.min(1, yMax)}` +
      `&area_threshold=${areaThreshold}`,
    {
      enabled: isEnabled,
      retry: (failureCount, error) => {
        return failureCount < 3 && error?.response?.status != 404;
      },
      onError: (error) => {
        if (error.response?.status == 404) {
          addToast({
            title: intl.formatMessage({
              id: 'annotations.noTextSelected',
              defaultMessage: 'No text selected',
            }),
            color: 'warning',
          });
        }
      },
    }
  );
  if (isIdle || isLoading || isError) {
    return undefined;
  }
  return data;
}

export function fetchSearchInDocumentResults(annotationId, searchPhrase) {
  return client(getSearchInDocumentEndpoint(annotationId, searchPhrase));
}
