import { EuiFlexGroup, EuiFlexItem, useEuiTheme } from '@elastic/eui';
import { useAtom } from 'jotai';
import Tabs, { TabPane } from 'rc-tabs';
import React, { useEffect, useMemo, useState } from 'react';
import XLSX from 'xlsx';
import { useDocumentContent } from '../../../../services/documents';
import { InLineLoader } from '../../../InlineLoader/InlineLoader';
import { useAnnotationStore } from '../../Annotation';
import { useActivateDatapoint } from '../../AnnotationPanel/useActivateDatapoint';
import { focusTableAtom } from '../../AnnotationState';
import { useGetFilteredDatapoints } from '../../useGetFilteredDatapoints';
import { CanvasDataGrid } from './CanvasDataGrid';

const makeCols = (ref?: string) => {
  if (!ref) {
    return [];
  }

  const output = [];
  const column = XLSX.utils.decode_range(ref).e.c + 1;
  for (let i = 0; i < column; ++i) {
    output[i] = {
      name: XLSX.utils.encode_col(i),
      key: i,
    };
  }
  return output;
};

export function SpreadsheetAnnotations({ documentId }: { documentId: string }) {
  const { data: documentContent, isLoading } = useDocumentContent(documentId);
  const [workBook, setWorkBook] = useState<any>();
  const [sheetNames, setSheetNames] = useState<string[]>([]);
  const [activeSheetName, setActiveSheetName] = useState<string>();
  const [activeSheetIndex, setActiveSheetIndex] = useState<number>(0);
  const [activeSheetAnnotations, setActiveSheetAnnotations] = useState<
    any[] | undefined
  >(undefined);
  const [activeSheet, setActiveSheet] = useState<any>();
  const [data, setData] = useState<any[]>([]);
  const annotation = useAnnotationStore((state) => state.annotation);
  const annotationContent = useAnnotationStore((state) => state.annotationContent);
  const schema = useAnnotationStore((state) => state.schema);
  const { euiTheme } = useEuiTheme();
  const {
    getNormalDatapoints,
    getSectionElementDatapoints,
  } = useGetFilteredDatapoints();
  const normalDatapoints = getNormalDatapoints();
  const documentContentURL = useMemo(
    () => !isLoading && URL.createObjectURL(documentContent),
    [documentContent, isLoading]
  );
  const [focusedTable] = useAtom(focusTableAtom);

  const [availableDatapoints, setAvailableDatapoints] = useState(normalDatapoints);
  const { activeDatapoint, setActiveDatapoint } = useActivateDatapoint();
  const [clearCanvas, setClearCanvas] = useState(false);

  useEffect(() => {
    if (!schema) {
      return;
    }
    if (focusedTable && focusedTable.id) {
      const sectionElementDatapoints = getSectionElementDatapoints(focusedTable.id);
      setAvailableDatapoints(sectionElementDatapoints);
    } else {
      const normalDatapoints = getNormalDatapoints();
      setAvailableDatapoints(normalDatapoints);
    }
  }, [focusedTable, annotationContent, schema]);

  async function getWorkbook() {
    // @ts-ignore
    const data = await documentContent.arrayBuffer();
    return XLSX.read(data);
  }

  useEffect(() => {
    if (availableDatapoints) {
      const sheetAnnotations = getSheetAnnotations(activeSheetIndex);
      setActiveSheetAnnotations((prevState) => {
        if (JSON.stringify(prevState) !== JSON.stringify(sheetAnnotations)) {
          return sheetAnnotations;
        }
        return prevState;
      });
    }
  }, [activeSheetIndex, annotationContent, availableDatapoints]);

  useEffect(() => {
    if (documentContentURL) {
      getWorkbook().then((workBook) => {
        setWorkBook(workBook);
      });
    }
  }, [documentContentURL]);

  useEffect(() => {
    if (workBook) {
      const workBookSheetNames = workBook.SheetNames;
      const activeWorkBookSheetName = workBook.SheetNames[0];
      setSheetNames(workBookSheetNames);
      setActiveSheetName(activeWorkBookSheetName);
      setActiveSheet(workBook.Sheets[activeWorkBookSheetName]);
    }
  }, [workBook]);

  useEffect(() => {
    if (activeSheet) {
      const data = XLSX.utils.sheet_to_json(activeSheet, { header: 1 });
      const cols = makeCols(activeSheet['!ref']);

      /**
       * CanvasDatagrid component needs to have all columns in the first row filled (at least with empty string)
       */
      if (Array.isArray(data)) {
        for (let i = 0; i < cols.length; ++i) {
          data[0][i] = data[0][i] || '';
        }
      }

      setData(data);
    }
  }, [activeSheet]);

  const getSheetAnnotations = (pageIndex: number) => {
    // @ts-ignore
    const pageAnnotations = [];

    availableDatapoints.forEach((annotation) => {
      if (annotation.page_index === Number(pageIndex)) {
        pageAnnotations.push(annotation);
      }
    });
    return pageAnnotations;
  };

  const handleSwitchActiveSheet = (sheetIndex: any) => {
    if (activeSheetIndex === sheetIndex) {
      return;
    }
    setActiveSheetName(sheetNames[sheetIndex]);
    setActiveSheetIndex(sheetIndex);
    setActiveSheet(workBook.Sheets[sheetNames[sheetIndex]]);
    activeDatapoint?.pageIndex !== sheetIndex && setActiveDatapoint(undefined);
  };

  const renderTabs = () => {
    return sheetNames.map((sheetName, idx) => {
      const sheetSelected = sheetName === activeSheetName;
      const tabTitle = (
        <div
          style={{
            color: sheetSelected ? euiTheme.colors.primary : '',
            borderBottom: `${
              sheetSelected ? `2px solid ${euiTheme.colors.primary}` : 'none'
            }`,
            padding: '0px 8px',
          }}
        >
          {sheetName}
        </div>
      );
      return <TabPane active={sheetSelected} key={idx} tab={tabTitle} />;
    });
  };

  const removeActiveAnnotation = () => {
    setClearCanvas(true);
  };

  if (isLoading) return <InLineLoader />;

  return (
    <>
      <div
        onMouseLeave={removeActiveAnnotation}
        style={{ height: '100%', width: '100%', overflow: 'hidden', display: 'block' }}
      >
        {sheetNames && (
          <EuiFlexGroup
            direction="row"
            alignItems="center"
            gutterSize="s"
            responsive={false}
          >
            <EuiFlexItem
              className="eui-fullHeight"
              style={{
                borderBottom: `1px solid ${euiTheme.colors.lightShade}`,
              }}
            >
              {activeSheetName && (
                <Tabs
                  activeKey={activeSheetName}
                  onChange={handleSwitchActiveSheet}
                  tabBarGutter={10}
                  moreIcon="..."
                  style={{ margin: '4px 4px 0px 4px' }}
                >
                  {renderTabs()}
                </Tabs>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
        {data && (
          <CanvasDataGrid
            data={data}
            annotation={annotation}
            annotations={activeSheetAnnotations}
            enableAnnotationCreation={true}
            activeSheetIndex={activeSheetIndex}
            switchSheetByIndex={handleSwitchActiveSheet}
            clearCanvas={clearCanvas}
            setClearCanvas={setClearCanvas}
          />
        )}
      </div>
    </>
  );
}
