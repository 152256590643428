import { EuiFieldText } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { DatapointAnnotationPopupLabelProps } from '../../Definitions/DatapointAnnotationPopupLabelProps';
import { useSchema } from '../../services/documents';

export const DatapointAnnotationPopupLabel = (
  props: DatapointAnnotationPopupLabelProps
) => {
  const { defaultSchemaElementId, schemaId } = props;
  const { data: schema } = useSchema(schemaId);
  const [label, setLabel] = useState<string>();

  const getSchemaElementLabel = (
    schemaElementId: string,
    content?: any | any[]
  ): string | null => {
    if (content?.id === schemaElementId) {
      return content.label;
    }

    if ('elements' in content) {
      return getSchemaElementLabel(schemaElementId, content.elements);
    }

    if (Array.isArray(content)) {
      for (const elementItem of content) {
        if (elementItem.id === schemaElementId) {
          return elementItem.label;
        } else if ('elements' in elementItem) {
          const labelFromChildren = getSchemaElementLabel(
            schemaElementId,
            elementItem.elements
          );
          if (labelFromChildren) {
            return labelFromChildren;
          }
        }
      }
    }

    return null;
  };

  useEffect(() => {
    if (schema && defaultSchemaElementId) {
      // @ts-ignore
      setLabel(
        getSchemaElementLabel(defaultSchemaElementId, schema.content?.elements) ||
          undefined
      );
    }
  }, [schema, defaultSchemaElementId]);

  if (!label) {
    return null;
  }

  return (
    <EuiFieldText
      compressed
      fullWidth
      readOnly
      value={label}
      style={{ textAlign: props.textAlign }}
    />
  );
};
