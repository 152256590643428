import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiSelect,
  EuiTextArea,
} from '@elastic/eui';
import { produce } from 'immer';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { Can, PERMISSION, useCan } from 'utils/permissions';
import { DatapointType } from '../../../../Definitions/DatapointType';
import { SchemaElementKind } from '../../../../Definitions/SchemaElementKind';
import { useTextInBox } from '../../../../services/annotations';
import { useSchema } from '../../../../services/documents';
import { getSchemaDataElement } from '../../../../utils/schemaUtils';
import { useTranslate } from '../../../Internationalisation/useTranslate';
import { useAnnotationStore } from '../../Annotation';
import { useActivateDatapoint } from '../../AnnotationPanel/useActivateDatapoint';
import {
  annotationRenderCountAtom,
  hasBoxChangedAtom,
  isBoxAutoFitEnabledAtom,
  isExtendedAnnotationEnabledAtom,
  isTestModeEnabledAtom,
} from '../../AnnotationState';
import { DatapointAnnotationPopupLabel } from '../../DatapointAnnotationPopupLabel';
import { annotationToGeometryBox, getSectionAnnotationItemId } from '../../utils';
import { AnnotationClassSelector } from './AnnotationClassSelector';
import { SchemaElementSelector } from './SchemaElementSelector';

export type AnnotationPopupProps = {
  selectionBounds: { xMin: number; xMax: number; yMin: number; yMax: number };
  annotationId: string;
  pageIndex: number;
  schemaId: string;
  annotation: any;
  setAnnotation?: () => {};
  existingTextValue?: string;
  isPositionChanged?: boolean;
  datapoint?: any;
  panelStyle?: any;
  isUpdate?: boolean;
  selectNone?: () => void;
};

export type EditField = {
  value?: string;
  normalizedValue?: string;
  fieldId?: string;
  schemaElementId?: string;
  elementType?: string;
  choices?: string[];
  annotationClass?: 'value' | 'column_header' | 'keyword';
};

const defaultPanelStyle = {
  minWidth: '260px',
  fontSize: 12,
};
export const AnnotationPopup = (props: AnnotationPopupProps) => {
  const {
    selectionBounds,
    pageIndex,
    schemaId,
    annotation,
    annotationId,
    setAnnotation,
    existingTextValue,
    isPositionChanged,
    panelStyle,
    isUpdate,
    selectNone,
  } = props;

  // Atoms hooks
  const [, setAnnotationRenderCount] = useAtom(annotationRenderCountAtom);
  const annotationContent = useAnnotationStore((state) => state.annotationContent);
  const documentType = useAnnotationStore((state) => state.documentType);
  const allDatapoints = useAnnotationStore((state) => state.allDatapoints);
  const annotationStore = useAnnotationStore();
  const [isExtendedAnnotationEnabled] = useAtom(isExtendedAnnotationEnabledAtom);
  const [isBoxAutoFitEnabled] = useAtom(isBoxAutoFitEnabledAtom);
  const [hasBoxChanged, setHasBoxChanged] = useAtom(hasBoxChangedAtom);

  const isSpreadsheetDatapoint = useMemo(() => {
    return documentType === 'spreadsheet';
  }, [documentType]);

  const {
    pinnedDatapoint,
    activeDatapoint,
    previouslyActiveSchemaElementId,
    setActiveDatapoint,
  } = useActivateDatapoint();

  // Hooks
  const can = useCan();
  const { data: schema } = useSchema(schemaId);
  const intl = useTranslate();
  const [isTestModeEnabled] = useAtom(isTestModeEnabledAtom);

  const showTrash = useMemo(() => {
    return !isPositionChanged && activeDatapoint;
  }, [activeDatapoint, isPositionChanged]);

  // Local state hooks
  const [textValue, setTextValue] = useState(existingTextValue);
  const [box, setBox] = useState(selectionBounds);
  const [editedField, setEditedField] = useState<EditField>({
    value: undefined,
    normalizedValue: undefined,
    fieldId: undefined,
    schemaElementId: undefined,
    elementType: undefined,
    choices: undefined,
    annotationClass: 'value',
  });

  const textBoxEnabled = useMemo(() => {
    if (hasBoxChanged) {
      return true;
    }

    if (!isSpreadsheetDatapoint && !isUpdate) {
      return true;
    }

    return false;
  }, [hasBoxChanged, isSpreadsheetDatapoint]);

  const textInBox: any = useTextInBox({
    annotationId: annotationId,
    pageIndex: pageIndex,
    ...box,
    isEnabled: textBoxEnabled,
  });

  const [isFormInvalid, setIsFormInvalid] = useState({
    fieldSelector: false,
    fieldValue: false,
  });

  useEffect(() => {
    const hasBox = textInBox && textInBox.box;
    if (hasBox || (hasBox && isUpdate && hasBoxChanged)) {
      setTextValue(textInBox.text);
      if (isBoxAutoFitEnabled) {
        setAnnotationGeometry(textInBox.box);
        setBox({
          xMin: textInBox.box.x_min,
          yMin: textInBox.box.y_min,
          xMax: textInBox.box.x_max,
          yMax: textInBox.box.y_max,
        });
      }
      setHasBoxChanged(false);
    } else if (editedField.value || existingTextValue) {
      setTextValue(editedField.value || existingTextValue);
    }
  }, [existingTextValue, textInBox, isBoxAutoFitEnabled, hasBoxChanged]);

  const setAnnotationGeometry = (textBox) => {
    const box = annotationToGeometryBox(textBox);

    annotation.geometry.x = box.x;
    annotation.geometry.y = box.y;
    annotation.geometry.width = box.width;
    annotation.geometry.height = box.height;
    setAnnotationRenderCount((state) => state + 1);
  };

  const onChangeTextValue = (value: string) => {
    setEditedField((prevEditField) => ({
      ...prevEditField,
      value: value,
      normalizedValue:
        editedField.elementType !== DatapointType.Enum
          ? value
          : editedField.normalizedValue,
    }));
  };

  useEffect(() => {
    if (textValue) {
      onChangeTextValue(textValue);
    }
  }, [textValue]);

  useEffect(() => {
    if (activeDatapoint || !pinnedDatapoint) {
      return;
    }
    const schemaElement = getSchemaDataElement(schema, pinnedDatapoint.schemaElementId);
    setEditedField((prevEditField) => ({
      ...prevEditField,
      fieldId: pinnedDatapoint.id,
      schemaElementId: pinnedDatapoint.schemaElementId,
      choices: schemaElement?.choices || [],
      elementType: schemaElement?.type,
      annotationClass: pinnedDatapoint.annotationClass || 'value',
    }));
  }, [pinnedDatapoint]);

  useEffect(() => {
    if (!activeDatapoint) {
      return;
    }
    const schemaElement = getSchemaDataElement(schema, activeDatapoint.schemaElementId);
    const annotationClass = activeDatapoint.annotationClass || 'value';
    const isEnumDatapoint =
      schemaElement.type === DatapointType.Enum && annotationClass === 'value';
    const value = isEnumDatapoint
      ? activeDatapoint.value
      : activeDatapoint.normalizedValue || activeDatapoint.value || '';

    setEditedField({
      value,
      normalizedValue: activeDatapoint.normalizedValue,
      fieldId: activeDatapoint.id,
      schemaElementId: activeDatapoint.schemaElementId,
      choices: schemaElement?.choices || [],
      elementType: schemaElement?.type,
      annotationClass,
    });
  }, [activeDatapoint]);

  const onChangeEnumValue = (value: string) => {
    setEditedField({
      ...editedField,
      normalizedValue: value,
    });
  };

  const onSchemaElementChange = (schemaElementId: string) => {
    const schemaElement = getSchemaDataElement(schema, schemaElementId);
    if (editedField.annotationClass === 'value') {
      const foundDatapoint = allDatapoints.find(
        (datapoint) => datapoint.schema_element_id === schemaElementId
      );

      setEditedField(
        produce(editedField, (draft) => {
          draft.schemaElementId = schemaElementId;
          draft.fieldId = foundDatapoint?.datapointId;
          if (schemaElement && schemaElement.type === DatapointType.Enum) {
            draft.elementType = schemaElement.type;
            draft.choices = schemaElement.choices || [];
            draft.normalizedValue = schemaElement.choices
              ? schemaElement.choices[0].value
              : null;
          } else {
            draft.elementType = schemaElement?.type || undefined;
            draft.choices = undefined;
          }
        })
      );
    } else {
      setEditedField({
        ...editedField,
        schemaElementId: schemaElementId,
        choices: undefined,
        elementType: undefined,
      });
    }
  };

  const onKeyPress = (event) => {
    if (!event.shiftKey && event.key == 'Enter') {
      onSubmit();
    }
  };

  const deselectDatapoint = () => {
    setActiveDatapoint(undefined, false);
    setAnnotation && setAnnotation({});
    setEditedField({});
    selectNone && selectNone();
  };

  const updatedAnnotationDatapoint = (foundValueDatapoint: { id: string }) => {
    const fieldId = foundValueDatapoint.id || editedField.fieldId;
    let patchObject = {
      value: editedField.value,
      normalized_value:
        editedField.annotationClass === 'value' &&
        editedField.elementType === DatapointType.Enum
          ? editedField.normalizedValue
          : editedField.value,
      page_index: pageIndex,
    };

    if (!isSpreadsheetDatapoint) {
      patchObject = {
        ...patchObject,
        box_x_min: box.xMin,
        box_y_min: box.yMin,
        box_x_max: box.xMax,
        box_y_max: box.yMax,
      };
    }

    if (isPositionChanged && isSpreadsheetDatapoint) {
      patchObject = {
        ...patchObject,
        box_x_min: box.xMin,
        box_y_min: box.yMin,
        box_x_max: box.xMax,
        box_y_max: box.yMax,
      };
    }
    annotationStore.patchAnnotation(fieldId, patchObject).then(() => {
      deselectDatapoint();
    });
  };

  const createAnnotationDatapoint = () => {
    const annotationClass = isExtendedAnnotationEnabled
      ? editedField.annotationClass
      : 'value';

    if (editedField.schemaElementId === undefined) {
      setIsFormInvalid({
        fieldSelector: true,
        fieldValue: false,
      });
      return;
    }

    const updatedAnnotation = {
      ...annotation,
      data: {
        value: editedField.value === undefined ? '' : editedField.value,
        normalizedValue: editedField.normalizedValue || editedField.value,
        itemId: editedField.fieldId,
        schemaElementId: editedField.schemaElementId,
        annotationClass: annotationClass,
      },
    };

    const parentId = getSectionAnnotationItemId(
      updatedAnnotation.data.schemaElementId,
      schema.content,
      annotationContent
    );

    annotationStore
      .createAnnotationItem(
        createDatapointAnnotationItemFromImageAnnotation(
          updatedAnnotation,
          pageIndex,
          parentId,
          box
        )
      )
      .then(() => {
        deselectDatapoint();
      })
      .catch(console.log);
  };

  const deleteDatapoint = () => {
    annotationStore.deleteAnnotationItem(editedField.fieldId).then(() => {
      deselectDatapoint();
    });
  };

  const removeValueFromDatapoint = (fieldId) => {
    deselectDatapoint();
    const patchObject = {
      value: null,
      normalized_value: null,
      box_x_min: null,
      box_x_max: null,
      box_y_min: null,
      box_y_max: null,
    };
    annotationStore.patchAnnotation(fieldId, patchObject);
  };

  const deleteOrCancelDatapoint = () => {
    if (
      activeDatapoint &&
      (activeDatapoint.value || activeDatapoint.box) &&
      !isPositionChanged
    ) {
      const schemaElement = getSchemaDataElement(
        schema,
        activeDatapoint.schemaElementId
      );

      if (
        editedField.annotationClass === 'value' &&
        (schemaElement.parentKind === SchemaElementKind.Section ||
          schemaElement.parentKind === SchemaElementKind.Tuple)
      ) {
        removeValueFromDatapoint(editedField.fieldId);
      } else {
        deleteDatapoint();
      }
    } else {
      deselectDatapoint();
    }
  };

  const onSubmit = () => {
    let datapointToUpdate =
      pinnedDatapoint && pinnedDatapoint.isTableDatapoint
        ? pinnedDatapoint
        : activeDatapoint;
    if (!datapointToUpdate) {
      datapointToUpdate =
        editedField?.annotationClass === 'value' &&
        allDatapoints.find(
          (datapoint) => editedField.schemaElementId === datapoint.schema_element_id
        );
    }

    if (datapointToUpdate) {
      updatedAnnotationDatapoint(datapointToUpdate);
    } else {
      createAnnotationDatapoint();
    }
  };

  const valueHasNewLine = () => {
    return editedField.value?.includes('\n');
  };

  const onAnnotationClassChange = (annotationClass) => {
    if (editedField.elementType === DatapointType.Enum) {
      if (annotationClass !== 'value') {
        setEditedField({
          ...editedField,
          normalizedValue: editedField.value,
          annotationClass,
        });
      } else {
        const schemaElement = getSchemaDataElement(schema, editedField.schemaElementId);
        setEditedField({
          ...editedField,
          normalizedValue: schemaElement.choices[0].value,
          annotationClass,
        });
      }
    } else {
      setEditedField({
        ...editedField,
        annotationClass,
      });
    }
  };

  return (
    <EuiFlexGroup
      alignItems="center"
      gutterSize="none"
      direction="column"
      onKeyPress={(event) => {
        onKeyPress(event);
      }}
    >
      <EuiPanel paddingSize="none" style={panelStyle || defaultPanelStyle}>
        <EuiFlexItem grow={true}>
          {editedField.value?.length > 50 || valueHasNewLine(editedField.value) ? (
            <EuiTextArea
              autoFocus={true}
              placeholder={intl.formatMessage({
                id: 'spreadsheetAnnotationPopup.value',
                defaultMessage: 'Value',
              })}
              compressed={false}
              style={{
                maxWidth: '1200px',
                minHeight: '50px',
                height: '100px',
                maxHeight: '200px',
                border: 'none',
                outline: 'none',
              }}
              className="eui-yScroll eui-xScroll"
              resize="both"
              aria-label="Value"
              readOnly={!can.can(PERMISSION.ANNOTATIONS_UPDATE) || isTestModeEnabled}
              value={editedField.value}
              onChange={(e) => {
                onChangeTextValue(e.target.value);
              }}
              isInvalid={isFormInvalid.fieldValue}
            />
          ) : (
            <EuiFieldText
              autoFocus={true}
              placeholder={intl.formatMessage({
                id: 'spreadsheetAnnotationPopup.value',
                defaultMessage: 'Value',
              })}
              compressed={false}
              style={{
                maxWidth: '1200px',
                minHeight: '50px',
                border: 'none',
                outline: 'none',
              }}
              aria-label="Value"
              readOnly={!can.can(PERMISSION.ANNOTATIONS_UPDATE) || isTestModeEnabled}
              value={editedField.value}
              onChange={(e) => {
                onChangeTextValue(e.target.value);
              }}
              isInvalid={isFormInvalid.fieldValue}
            />
          )}
          {editedField.elementType === DatapointType.Enum &&
            editedField.annotationClass === 'value' && (
              <EuiSelect
                id="elementChoices"
                options={editedField.choices.map((item) => ({
                  value: item.value,
                  text: item.label,
                }))}
                value={editedField.normalizedValue}
                disabled={!can.can(PERMISSION.ANNOTATIONS_UPDATE) || isTestModeEnabled}
                onChange={(e) => {
                  onChangeEnumValue(e.target.value);
                }}
                aria-label="Datapoint enum choices"
              />
            )}
        </EuiFlexItem>
        {!isTestModeEnabled && (
          <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
            <EuiFlexItem>
              {(activeDatapoint || pinnedDatapoint) && (
                <DatapointAnnotationPopupLabel
                  defaultSchemaElementId={editedField.schemaElementId}
                  schemaId={schemaId}
                />
              )}
              {!activeDatapoint && !pinnedDatapoint && (
                <SchemaElementSelector
                  defaultSchemaElementId={
                    editedField.schemaElementId || previouslyActiveSchemaElementId
                  }
                  schemaId={schemaId}
                  onSchemaElementChange={onSchemaElementChange}
                  isInvalid={isFormInvalid.fieldSelector}
                  annotationClass={editedField.annotationClass}
                />
              )}
            </EuiFlexItem>
            {isExtendedAnnotationEnabled && (
              <EuiFlexItem>
                <AnnotationClassSelector
                  defaultAnnotationClass={editedField.annotationClass}
                  isDisabled={
                    (!!activeDatapoint && !isPositionChanged) ||
                    pinnedDatapoint?.isTableDatapoint
                  }
                  onAnnotationClassChange={onAnnotationClassChange}
                  isSpreadsheetDatapoint={isSpreadsheetDatapoint}
                />
              </EuiFlexItem>
            )}
            <EuiFlexGroup
              justifyContent="spaceBetween"
              gutterSize="none"
              direction="row"
            >
              <EuiFlexItem grow={true} style={{ alignItems: 'center' }}>
                <EuiPanel
                  color="ghost"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '0.5em',
                    paddingBottom: '0.5em',
                  }}
                  size="m"
                  aria-label="Update"
                  title={intl.formatMessage({
                    id: 'spreadsheetAnnotationPopup.panel.update',
                    defaultMessage: 'Update',
                  })}
                  onClick={onSubmit}
                >
                  <EuiIcon color="text" size="l" aria-label="Create" type="check" />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem grow={true} style={{ alignItems: 'center' }}>
                <EuiPanel
                  color="ghost"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: '0.5em',
                    paddingBottom: '0.5em',
                  }}
                  size="m"
                  aria-label="Cancel"
                  title={
                    showTrash
                      ? intl.formatMessage({
                          id: 'spreadsheetAnnotationPopup.panel.delete',
                          defaultMessage: 'Delete',
                        })
                      : intl.formatMessage({
                          id: 'spreadsheetAnnotationPopup.panel.cancel',
                          defaultMessage: 'Cancel',
                        })
                  }
                  onClick={deleteOrCancelDatapoint}
                >
                  <EuiIcon
                    color="text"
                    size="m"
                    aria-label="Create"
                    type={showTrash ? 'trash' : 'cross'}
                  />
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
          </Can>
        )}
      </EuiPanel>
    </EuiFlexGroup>
  );
};

function createDatapointAnnotationItemFromImageAnnotation(
  annotation,
  pageIndex,
  parentAnnotationItemId,
  box
) {
  const { data } = annotation;

  return {
    schema_element_kind: 'datapoint',
    schema_element_id: data.schemaElementId,
    annotation_class: data.annotationClass,
    parent_id: parentAnnotationItemId,
    value: data.value,
    normalized_value: data.normalizedValue,
    page_index: pageIndex,
    box: {
      x_min: box.xMin,
      y_min: box.yMin,
      x_max: box.xMax,
      y_max: box.yMax,
    },
  };
}
