import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiTab,
  EuiTabs,
  EuiTitle,
} from '@elastic/eui';
import { FolderPermissions } from 'components/Folders/FolderPermissions';
import React, { useMemo, useState } from 'react';
import { PERMISSION, useCan } from 'utils/permissions';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { FolderSettings } from './FolderSettings';
import { FolderStatisticsTab } from './FolderStatistics';

export function FolderConfigurationFlyout({
  folderId,
  closeFlyout,
  defaultSelectedTab = 'settings',
}: {
  folderId: string;
  closeFlyout: () => void;
  defaultSelectedTab: string | null;
}) {
  const [selectedTabId, setSelectedTabId] = useState(defaultSelectedTab);
  const intl = useTranslate();
  const can = useCan();

  const tabs = [
    {
      id: 'settings',
      name: intl.message.settings,
      content: <FolderSettings folderId={folderId} cancelCallback={closeFlyout} />,
    },
    {
      id: 'permissions',
      name: intl.formatMessage({
        id: 'folderConfiguration.permissions',
        defaultMessage: 'Permissions',
      }),
      disabled: !can.can(PERMISSION.PERMISSIONS_READ),
      content: <FolderPermissions folderId={folderId} cancelCallback={closeFlyout} />,
    },
    {
      id: 'statistics',
      name: intl.formatMessage({
        id: 'folderConfiguration.statistics',
        defaultMessage: 'Statistics',
      }),
      content: <FolderStatisticsTab folderId={folderId} cancelCallback={closeFlyout} />,
    },
  ];

  const selectedTabContent = useMemo(() => {
    return tabs.find((obj) => obj.id === selectedTabId)?.content;
  }, [selectedTabId]);

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id);
  };

  const renderTabs = () => {
    return tabs.map((tab, index) => (
      <EuiTab
        key={index}
        href={tab.href}
        onClick={() => onSelectedTabChanged(tab.id)}
        isSelected={tab.id === selectedTabId}
        disabled={tab.disabled}
        prepend={tab.prepend}
        append={tab.append}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  const flyout = (
    <EuiFlyout
      ownFocus
      onClose={closeFlyout}
      aria-labelledby="folderConfiguration"
      size="m"
      style={{ maxWidth: '800px' }}
    >
      <EuiFlyoutHeader>
        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <EuiTitle size="m">
              <h2>
                <Translate
                  id="folders.configuration.title"
                  defaultMessage="Folder configuration"
                />
              </h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiTabs>{renderTabs()}</EuiTabs>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutHeader>
      {selectedTabContent}
    </EuiFlyout>
  );

  return <>{flyout}</>;
}
