import React, { useCallback, useEffect, useRef, useState } from 'react';

type Coordinate = {
  x: number;
  y: number;
};

interface CanvasProps {
  width: number;
  height: number;
  showMainTableAnnotation: (value: boolean) => void;
  setTableParameters: (tableParameter: {
    coordinates: Coordinate;
    width: number;
    height: number;
  }) => void;
}

export function InitialTableGrid({
  width,
  height,
  showMainTableAnnotation,
  setTableParameters,
}: CanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isPainting, setIsPainting] = useState(false);
  const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(undefined);
  const [rectangleParameters, setRectangleParameters] = useState<{
    coordinates: Coordinate;
    width: number;
    height: number;
  }>();

  const startPaint = useCallback((event: MouseEvent) => {
    const coordinates = getCoordinates(event);
    if (coordinates) {
      setMousePosition(coordinates);
      setIsPainting(true);
    }
  }, []);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;
    canvas.addEventListener('mousedown', startPaint);
    return () => {
      canvas.removeEventListener('mousedown', startPaint);
    };
  }, [startPaint]);

  const paint = useCallback(
    (event: MouseEvent) => {
      if (isPainting) {
        const newMousePosition = getCoordinates(event);
        if (mousePosition && newMousePosition) {
          drawRect(mousePosition, newMousePosition);
        }
      }
    },
    [isPainting, mousePosition]
  );

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;
    canvas.addEventListener('mousemove', paint);
    return () => {
      canvas.removeEventListener('mousemove', paint);
    };
  }, [paint]);

  const exitPaint = useCallback(() => {
    setIsPainting(false);
    setMousePosition(undefined);
    showMainTableAnnotation(!!rectangleParameters);
    rectangleParameters && setTableParameters(rectangleParameters);
  }, [rectangleParameters]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;
    canvas.addEventListener('mouseup', exitPaint);
    canvas.addEventListener('mouseleave', exitPaint);
    return () => {
      canvas.removeEventListener('mouseup', exitPaint);
      canvas.removeEventListener('mouseleave', exitPaint);
    };
  }, [exitPaint]);

  const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    return {
      x: event.pageX - canvas.getBoundingClientRect().left,
      y: event.pageY - canvas.getBoundingClientRect().top,
    };
  };

  const drawRect = (
    originalMousePosition: Coordinate,
    newMousePosition: Coordinate
  ) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas: HTMLCanvasElement = canvasRef.current;
    const context = canvas.getContext('2d');
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height); //clear canvas
      context.beginPath();
      const rectWidth = newMousePosition.x - originalMousePosition.x;
      const rectHeight = newMousePosition.y - originalMousePosition.y;
      context.rect(
        originalMousePosition.x,
        originalMousePosition.y,
        rectWidth,
        rectHeight
      );
      context.strokeStyle = 'rgb(18,99,192)';
      context.fillStyle = 'rgba(30,102,190,0.2)';
      context.fillRect(
        originalMousePosition.x,
        originalMousePosition.y,
        rectWidth,
        rectHeight
      );
      context.lineWidth = 2;
      context.stroke();
      setRectangleParameters({
        coordinates: originalMousePosition,
        width: rectWidth,
        height: rectHeight,
      });
    }
  };

  return (
    <>
      <canvas
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          cursor: 'crosshair',
        }}
        ref={canvasRef}
        id="canvas"
        width={width}
        height={height}
      />
    </>
  );
}
