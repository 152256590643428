import {
  Axis,
  Chart,
  LineSeries,
  Position,
  ScaleType,
  Settings,
} from '@elastic/charts';
import {
  EuiButton,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiStat,
  EuiText,
} from '@elastic/eui';
import {
  EUI_CHARTS_THEME_DARK,
  EUI_CHARTS_THEME_LIGHT,
} from '@elastic/eui/dist/eui_charts_theme';
import { QueryLoader } from 'components/InlineLoader/QueryLoader';
import moment from 'moment';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { THEME_MODE } from 'settings/constants';
import { ThemeMode } from 'settings/ThemeMode';
import { Translate } from '../Internationalisation/translate';
import { useFolders } from './useFolders';

export function FolderStatisticsChart(props: { folderId: string }) {
  const foldersHook = useFolders();
  const [startDate, setStartDate] = useState(moment().subtract(28, 'd'));
  const [endDate, setEndDate] = useState(moment());
  const start = startDate.clone().startOf('day').toISOString();
  const end = endDate.clone().add(1, 'd').startOf('day').toISOString();
  const statisticsQuery = foldersHook.useGetFolderStatistics(
    props.folderId,
    start,
    end
  );
  const intl = useIntl();

  if (!statisticsQuery.isSuccess) {
    return <QueryLoader queries={[statisticsQuery]} />;
  }

  const dateSelector = (
    <EuiDatePickerRange
      startDateControl={
        <EuiDatePicker
          selected={startDate}
          onChange={setStartDate}
          startDate={startDate}
          endDate={endDate}
          isInvalid={startDate > endDate}
          aria-label="Start date"
          dateFormat="YYYY-MM-DD"
          dateFormatCalendar="YYYY-MM-DD"
        />
      }
      endDateControl={
        <EuiDatePicker
          selected={endDate}
          onChange={setEndDate}
          startDate={startDate}
          endDate={endDate}
          isInvalid={startDate > endDate}
          aria-label="End date"
          dateFormat="YYYY-MM-DD"
          dateFormatCalendar="YYYY-MM-DD"
        />
      }
    />
  );

  const data = statisticsQuery.data.data;
  const totalDocuments = data.reduce(
    (total, { documents_count }) => total + documents_count,
    0
  );
  const totalPages = data.reduce((total, { pages_count }) => total + pages_count, 0);

  return (
    <EuiFlexGroup direction="column" gutterSize="l">
      <EuiFlexItem>{dateSelector}</EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row" gutterSize="m">
          <EuiFlexItem>
            <EuiStat
              title={totalDocuments}
              titleSize="s"
              description={intl.formatMessage({
                id: 'folderStatistics.totalDocuments',
                defaultMessage: 'Total documents',
              })}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiStat
              title={totalPages}
              titleSize="s"
              description={intl.formatMessage({
                id: 'folderStatistics.totalPages',
                defaultMessage: 'Total pages',
              })}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <Chart size={{ height: 200 }}>
          <Settings
            showLegend
            legendPosition={Position.Bottom}
            theme={
              THEME_MODE === ThemeMode.dark
                ? EUI_CHARTS_THEME_DARK.theme
                : EUI_CHARTS_THEME_LIGHT.theme
            }
          />
          <LineSeries
            id="documents_count"
            name={intl.formatMessage({
              id: 'folderStatistics.documentsCount',
              defaultMessage: 'Document count',
            })}
            xScaleType={ScaleType.Ordinal}
            yScaleType={ScaleType.Linear}
            xAccessor="date"
            yAccessors={['documents_count']}
            data={data}
          />
          <LineSeries
            id="pages_count"
            name={intl.formatMessage({
              id: 'folderStatistics.pagesCount',
              defaultMessage: 'Page count',
            })}
            xScaleType={ScaleType.Ordinal}
            yScaleType={ScaleType.Linear}
            xAccessor="date"
            yAccessors={['pages_count']}
            data={data}
          />
          <LineSeries
            id="pages_median"
            name={intl.formatMessage({
              id: 'folderStatistics.pagesMedian',
              defaultMessage: 'Page median',
            })}
            xScaleType={ScaleType.Ordinal}
            yScaleType={ScaleType.Linear}
            xAccessor="date"
            yAccessors={['pages_median']}
            data={data}
          />
          <Axis id="bottom-axis" position="bottom" showGridLines={false} />
          <Axis id="left-axis" position="left" showGridLines={false} />
        </Chart>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export function FolderStatisticsTab(props: {
  folderId: string;
  cancelCallback: () => void;
}) {
  return (
    <>
      <EuiFlyoutBody>
        <EuiFlexGroup direction="column" gutterSize="m">
          <EuiFlexItem>
            <EuiText size="s">
              <Translate
                id="folderStatistics.summaryDescription"
                defaultMessage="Summary statistics about documents in this folder imported during the selected time period."
              />
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <FolderStatisticsChart folderId={props.folderId} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutBody>

      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton onClick={props.cancelCallback}>
              <Translate id="button.cancel" defaultMessage="Cancel" />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </>
  );
}
