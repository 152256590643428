import {
  EuiAccordion,
  EuiButton,
  EuiButtonIcon,
  EuiComboBox,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { Role, usePermissions } from 'components/Folders/usePermissions';
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
import { UsersGetOut } from 'Definitions/ApiTypes';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import { useUsers } from 'services/users';
import { PERMISSION, useCan } from 'utils/permissions';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { useFolders } from './useFolders';

const PermissionItem = (props: {
  folderId: string;
  permissionId?: string;
  permissionFolderId?: string;
  defaultUserOption: { id: string; label: string } | undefined;
  isInherited: boolean;
  users: UsersGetOut;
  hasOnlyOneOwner: boolean;
  role?: Role;
}) => {
  const intl = useTranslate();
  const showExistingPermission = !!props.permissionId;
  const navigate = useNavigate();
  const roleOptions = [
    {
      id: 'viewer',
      label: intl.formatMessage({
        id: 'folderPermissions.viewer',
        defaultMessage: 'Viewer',
      }),
    },
    {
      id: 'annotator',
      label: intl.formatMessage({
        id: 'folderPermissions.annotator',
        defaultMessage: 'Annotator',
      }),
    },
    {
      id: 'operator',
      label: intl.formatMessage({
        id: 'folderPermissions.operator',
        defaultMessage: 'Operator',
      }),
    },
    {
      id: 'editor',
      label: intl.formatMessage({
        id: 'folderPermissions.editor',
        defaultMessage: 'Editor',
      }),
    },
    {
      id: 'owner',
      label: intl.formatMessage({
        id: 'folderPermissions.owner',
        defaultMessage: 'Owner',
      }),
    },
  ];
  const defaultUserRole = !props.role
    ? []
    : roleOptions.filter((role) => role.id === props.role);

  const [selectedRoleOptions, setSelectedRoleOptions] = useState(defaultUserRole);
  const [selectedUserOptions, setSelectedUserOptions] = useState(
    props.defaultUserOption ? [props.defaultUserOption] : []
  );
  const { createPermissionMutation, deletePermissionMutation } = usePermissions();
  const can = useCan();

  const onRoleChange = (selectedOptions) => {
    setSelectedRoleOptions(selectedOptions);
  };

  const userOptions = props.users.data.map((user) => {
    return { id: user.id, label: user.name };
  });

  const roleSelector = (
    <EuiComboBox
      aria-label="Role"
      placeholder={intl.formatMessage({
        id: 'folderPermissions.role',
        defaultMessage: 'Role',
      })}
      singleSelection={{ asPlainText: true }}
      options={roleOptions}
      selectedOptions={selectedRoleOptions}
      onChange={onRoleChange}
      compressed
      isClearable={false}
      isDisabled={showExistingPermission}
    />
  );

  const onUserChange = (selectedOptions) => {
    setSelectedUserOptions(selectedOptions);
  };

  const userSelector = (
    <EuiComboBox
      aria-label="User"
      placeholder={intl.formatMessage({
        id: 'folderPermissions.user',
        defaultMessage: 'User',
      })}
      singleSelection={{ asPlainText: true }}
      options={userOptions}
      selectedOptions={selectedUserOptions}
      onChange={onUserChange}
      compressed
      isClearable={false}
      isDisabled={showExistingPermission}
    />
  );

  const handleAddPermission = () => {
    const userId = selectedUserOptions[0]?.id;
    const role = selectedRoleOptions[0]?.id as Role;
    createPermissionMutation.mutate(
      {
        folderId: props.folderId,
        userId: userId,
        role: role,
      },
      {
        onSuccess: () => {
          setSelectedRoleOptions([]);
          setSelectedUserOptions([]);
        },
      }
    );
  };

  const addPermissionButton = (
    <EuiButtonIcon
      iconType="plus"
      title={intl.formatMessage({
        id: 'folderPermissions.button.addPermission',
        defaultMessage: 'Add permission',
      })}
      aria-label="Add permission"
      onClick={handleAddPermission}
      isDisabled={
        createPermissionMutation.isLoading || !can.can(PERMISSION.PERMISSIONS_CREATE)
      }
    />
  );

  const handleDeletePermission = () => {
    deletePermissionMutation.mutate(props.permissionId);
  };

  const goToFolderPermissions = () => {
    if (props.permissionFolderId) {
      navigate(
        `/folders/${encodeURIComponent(
          props.permissionFolderId
        )}/documents?folderConfig=permissions`
      );
    }
  };

  const isTheOnlyOwnerPermission = props.hasOnlyOneOwner && props.role === 'owner';
  const deletePermissionButton = (
    <EuiButtonIcon
      iconType="trash"
      aria-label="Delete permission"
      onClick={handleDeletePermission}
      title={
        isTheOnlyOwnerPermission
          ? intl.formatMessage({
              id: 'folderPermissions.button.cannotDeleteOnlyOwner',
              defaultMessage: 'Cannot delete the only owner',
            })
          : intl.formatMessage({
              id: 'folderPermissions.button.deletePermission',
              defaultMessage: 'Delete permission',
            })
      }
      isDisabled={
        deletePermissionMutation.isLoading ||
        isTheOnlyOwnerPermission ||
        !can.can(PERMISSION.PERMISSIONS_DELETE)
      }
    />
  );

  return (
    <EuiFlexGroup justifyContent="flexStart" gutterSize="xs" key={props.permissionId}>
      <EuiFlexItem style={{ maxWidth: '400px' }}>{userSelector}</EuiFlexItem>
      <EuiFlexItem style={{ maxWidth: '170px' }}>{roleSelector}</EuiFlexItem>
      {showExistingPermission ? (
        props.isInherited ? (
          <EuiFlexItem grow={false} style={{ alignSelf: 'center' }}>
            <EuiButtonIcon
              iconType="sortUp"
              iconSize="m"
              color="text"
              title={intl.formatMessage({
                id: 'folderPermissions.permission.inheritedInfo',
                defaultMessage: 'Go to the inherited permissions folder',
              })}
              onClick={goToFolderPermissions}
            ></EuiButtonIcon>
          </EuiFlexItem>
        ) : (
          <EuiFlexItem grow={false}>{deletePermissionButton}</EuiFlexItem>
        )
      ) : (
        <EuiFlexItem grow={false}>{addPermissionButton}</EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};

export function FolderPermissions(props: {
  folderId: string;
  cancelCallback: () => void;
}) {
  const { useGetPermissions } = usePermissions();
  const intl = useTranslate();
  const { data: permissions, isLoading: isLoadingPermissions } = useGetPermissions(
    props.folderId
  );
  const { useGetUsers } = useUsers();
  const { data: users, isLoading: isLoadingUsers } = useGetUsers('active');
  const { useGetFolder } = useFolders();

  const { data: folder, isLoading: isLoadingFolder } = useGetFolder(props.folderId);

  if (isLoadingPermissions || isLoadingUsers || isLoadingFolder)
    return <InLineLoader />;

  const permissionsCsvData = () => {
    if (!users && !permissions) {
      return [];
    }
    const userList = users.data as Array<{ id: string; name: string }>;
    const permissionList = permissions.data;
    if (!permissions.data || !users.data) {
      return;
    }

    const userString = intl.formatMessage({
      id: 'folderPermissions.user',
      defaultMessage: 'User',
    });
    const permissionString = intl.formatMessage({
      id: 'folderConfiguration.permission',
      defaultMessage: 'Permission',
    });

    const columns = [userString, permissionString];
    const userPermissions = permissionList.map((permission: any) => {
      const user = userList.find(
        (user: { id: string; name: string }) => permission.user_id === user.id
      );

      return [user?.name, permission.role];
    });
    return [columns, ...userPermissions];
  };

  const hasOnlyOneOwner =
    permissions.data.filter((p) => p.role === 'owner').length === 1;
  const addPermissionItem = <PermissionItem folderId={props.folderId} users={users} />;
  const getPermissionsList = (permissions: any[]) => {
    const sortedPermissions = permissions.data
      .map((permission) => {
        const defaultUserOption = permission.user_id && {
          id: permission.user_id,
          label: users.data.find((user) => user.id === permission.user_id)?.name,
        };
        return {
          ...permission,
          user: defaultUserOption,
        };
      })
      .sort((a, b) => {
        const nameA = a.user.label.toUpperCase();
        const nameB = b.user.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

    return (
      <EuiFlexGroup direction="column" justifyContent="flexStart" gutterSize="s">
        {sortedPermissions.map((permission) => (
          <EuiFlexItem key={permission.id}>
            <PermissionItem
              folderId={props.folderId}
              permissionId={permission.id}
              role={permission.role}
              defaultUserOption={permission.user}
              isInherited={permission.is_inherited}
              permissionFolderId={permission.folder_id}
              users={users}
              hasOnlyOneOwner={hasOnlyOneOwner}
            />
          </EuiFlexItem>
        ))}
        <EuiFlexItem>{addPermissionItem}</EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  const roles = [
    {
      title: intl.formatMessage({
        id: 'folderPermissions.role.viewer',
        defaultMessage: 'Viewer',
      }),
      description: intl.formatMessage({
        id: 'folderPermissions.role.viewer.description',
        defaultMessage: 'can view all resources in given folder.',
      }),
    },
    {
      title: intl.formatMessage({
        id: 'folderPermissions.role.annotator',
        defaultMessage: 'Annotator',
      }),
      description: intl.formatMessage({
        id: 'folderPermissions.role.annotator.description',
        defaultMessage: 'can in addition edit annotations.',
      }),
    },
    {
      title: intl.formatMessage({
        id: 'folderPermissions.role.operator',
        defaultMessage: 'Operator',
      }),
      description: intl.formatMessage({
        id: 'folderPermissions.role.operator.description',
        defaultMessage: 'can in addition import, process and trash documents.',
      }),
    },
    {
      title: intl.formatMessage({
        id: 'folderPermissions.role.editor',
        defaultMessage: 'Editor',
      }),
      description: intl.formatMessage({
        id: 'folderPermissions.role.editor.description',
        defaultMessage:
          'can manage all resources in a given folder except the folder itself.',
      }),
    },
    {
      title: intl.formatMessage({
        id: 'folderPermissions.role.owner',
        defaultMessage: 'Owner',
      }),
      description: intl.formatMessage({
        id: 'folderPermissions.role.owner.description',
        defaultMessage:
          'can in addition manage folder with its permissions and create new subfolders.',
      }),
    },
  ];

  return (
    <>
      <EuiFlyoutBody>
        <EuiFlexGroup direction="column" gutterSize="m">
          <EuiFlexItem>
            <EuiText size="s">
              <Translate
                id="folderPermissions.assignRole.description"
                defaultMessage={`
                Assign roles to users for this folder. Permissions assigned for this
                folder are inherited by subfolders.
              `}
              />
            </EuiText>
            <EuiSpacer />
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiAccordion
                  id="rolesDescription"
                  buttonContent={intl.formatMessage({
                    id: 'folderPermissions.button.roleDescription',
                    defaultMessage: 'Roles description',
                  })}
                  buttonContentClassName="eui-textTruncate"
                  paddingSize="l"
                >
                  <EuiDescriptionList
                    type="row"
                    listItems={roles}
                    align="left"
                    compressed
                  />
                </EuiAccordion>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <CSVLink
                  filename={`${folder.name}.csv`}
                  style={{ width: 'fit-content' }}
                  data={permissionsCsvData()}
                >
                  <EuiButtonIcon
                    iconType="download"
                    aria-label="Download permissions"
                    onClick={() => {}}
                    title={intl.formatMessage({
                      id: 'folderPermissions.button.downloadPermission.title',
                      defaultMessage: 'Download list of permissions as CSV',
                    })}
                  />
                </CSVLink>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}></EuiFlexItem>
          <EuiFlexItem>{getPermissionsList(permissions)}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutBody>

      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton onClick={props.cancelCallback}>
              <Translate
                id="folders.configuration.button.cancel"
                defaultMessage="Cancel"
              />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </>
  );
}
