import {
  EuiButtonGroup,
  EuiDataGrid,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
} from '@elastic/eui';
import { useAtom } from 'jotai';
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { referenceDataTablePreferences } from '../../../Annotation/AnnotationState';
import { Translate } from '../../../Internationalisation/translate';
import { useTranslate } from '../../../Internationalisation/useTranslate';

export const ReferenceDataTable = ({
  fields,
  defaultData,
  data,
  setData,
  title = '',
  datapointValue = undefined,
  referenceDataId = undefined,
  leadingControlColumns = undefined,
  rowClasses = {},
  getColumnTitle = undefined,
  shouldUpdateTablePreferences = true,
  usedColumns = undefined,
}: {
  fields: Array<string>;
  data: any;
  setData: (value: any) => void;
  title?: ReactNode | undefined;
  defaultData: Array<Record<string, string>>;
  datapointValue?: string;
  referenceDataId?: string;
  leadingControlColumns?: Array<any>;
  rowClasses?: any;
  getColumnTitle?: (field: string) => ReactNode;
  shouldUpdateTablePreferences?: boolean;
  usedColumns?: Set<string>;
}) => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [searchableQuery, setSearchableQuery] = useState(datapointValue || '');
  const intl = useTranslate();

  // @ts-ignore
  const [tablePreferencesCollection, setTablePreferencesCollection] = useAtom<
    Record<string, { columnConfigs: Record<string, number>; visibleColumns: string[] }>
  >(referenceDataTablePreferences);

  const initialVisibleColumns = useMemo(() => {
    return tablePreferencesCollection[referenceDataId]?.visibleColumns || fields;
  }, [referenceDataId, fields]);

  const [visibleColumns, setVisibleColumns] = useState(fields);

  const [toggleAdvanceSearch, setToggleAdvanceSearch] = useState('simple');

  useEffect(() => {
    setVisibleColumns(initialVisibleColumns);
  }, [initialVisibleColumns]);

  useEffect(() => {
    if (shouldUpdateTablePreferences) {
      updateTablePreferences(undefined, visibleColumns);
    }
  }, [visibleColumns]);

  const updateTablePreferences = (
    columnConfig: { columnId: string; width: number } | undefined,
    visibleColumns: string[] | undefined
  ) => {
    let columnConfigs = {};
    let tablePreferences: {
      columnConfigs: Record<string, number>;
      visibleColumns: string[];
    } = { columnConfigs: {}, visibleColumns: [] };

    if (tablePreferencesCollection[referenceDataId]) {
      columnConfigs = tablePreferencesCollection[referenceDataId].columnConfigs;
      tablePreferences = tablePreferencesCollection[referenceDataId];
    }

    if (visibleColumns) {
      tablePreferences = visibleColumns && { ...tablePreferences, visibleColumns };
    }

    if (columnConfig) {
      columnConfigs = {
        ...columnConfigs,
        [columnConfig.columnId]: columnConfig.width,
      };
      tablePreferences = columnConfigs && { ...tablePreferences, columnConfigs };
    }

    const newTablePreferencesCollection = { ...tablePreferencesCollection };
    newTablePreferencesCollection[referenceDataId] = tablePreferences;
    // @ts-ignore
    setTablePreferencesCollection(newTablePreferencesCollection);
  };

  const initialVisibleColumnMap = useMemo(() => {
    const map = new Map();
    initialVisibleColumns.forEach((column, index) => {
      map.set(column, index);
    });
    return map;
  }, [initialVisibleColumns]);

  //Based on this issue we have to declare the columns as a state https://github.com/elastic/eui/issues/4731
  const columns = useMemo(() => {
    const filteredColumns = fields.map((field) => {
      const savedConfig = tablePreferencesCollection[referenceDataId]?.columnConfigs;

      return {
        id: field,
        actions: false,
        display: getColumnTitle ? getColumnTitle(field) : field,
        isResizable: true,
        schema: usedColumns
          ? usedColumns.has(field)
            ? 'selected-row'
            : ''
          : undefined,
        isSortable: false,
        isExpandable: false,
        initialWidth: shouldUpdateTablePreferences
          ? (savedConfig && savedConfig[field]) || 200
          : undefined,
      };
    });

    if (initialVisibleColumnMap) {
      return filteredColumns.sort(
        (a, b) => initialVisibleColumnMap.get(a.id) - initialVisibleColumnMap.get(b.id)
      );
    }
    return filteredColumns;
  }, [initialVisibleColumnMap]);

  const filterableDataByColumns = useMemo(() => {
    return defaultData.map((row) => Object.values(row).join(' ').toLowerCase());
  }, [defaultData]);

  const filterableDataByColumn = useMemo(() => {
    return defaultData.map((row) =>
      Object.values(row).map((value: string) => value.toLowerCase())
    );
  }, [defaultData]);

  const onChangePage = useCallback(
    (pageIndex: number) =>
      setPagination((pagination) => ({ ...pagination, pageIndex })),
    [setPagination]
  );

  const onChangeItemsPerPage = useCallback(
    (pageSize: number) =>
      setPagination((pagination) => ({
        ...pagination,
        pageSize,
        pageIndex: 0,
      })),
    [setPagination]
  );

  const filterRows = (query: string) => {
    const rowIndexes = new Set();

    if (toggleAdvanceSearch === 'simple') {
      filterableDataByColumn.forEach((row, index) => {
        if (row.find((value) => value.includes(query.trim()))) {
          rowIndexes.add(index);
        }
      });
    } else {
      const queryWordList = query.split(' ');

      filterableDataByColumns.forEach((rowString, index) => {
        let isMatch = true;

        queryWordList.forEach((word) => {
          if (!isMatch) {
            return;
          }

          if (!rowString.toLowerCase().includes(word.toLowerCase())) {
            isMatch = false;
          }
        });

        if (isMatch) {
          rowIndexes.add(index);
        }
      });
    }

    const filteredData = defaultData.filter((row, index) => rowIndexes.has(index));
    setData(filteredData || []);
  };

  useEffect(() => {
    filterRows(searchableQuery.toLowerCase());

    if (pagination.pageIndex !== 0) {
      setPagination({ ...pagination, pageIndex: 0 });
    }
  }, [searchableQuery, toggleAdvanceSearch]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchableQuery(e.target.value);
  };

  const toggleSearchButtons = [
    {
      id: 'simple',
      label: intl.formatMessage({
        id: 'referenceDataSearchModal.button.searchSimple',
        defaultMessage: 'Single-column',
      }),
      title: intl.formatMessage({
        id: 'referenceDataSearchModal.button.searchSimpleTitle',
        defaultMessage: 'Filter rows with all words in the same column',
      }),
    },
    {
      id: 'advance',
      label: intl.formatMessage({
        id: 'referenceDataSearchModal.button.searchAdvance',
        defaultMessage: 'Multi-column',
      }),
      title: intl.formatMessage({
        id: 'referenceDataSearchModal.button.searchAdvanceTitle',
        defaultMessage: 'Filter rows with all words in any columns of the same row',
      }),
    },
  ];

  const onChange = (optionId: 'advance' | 'simple') => {
    setToggleAdvanceSearch(optionId);
  };

  return (
    <>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup justifyContent="spaceBetween">
          {title}
          <EuiFlexItem className="referenceDataTitle" grow={false}>
            <EuiFieldSearch
              placeholder={intl.formatMessage({
                id: 'input.search',
                defaultMessage: 'Search',
              })}
              incremental={false}
              value={searchableQuery}
              onChange={(e) => onSearch(e)}
              isClearable={true}
              aria-label="Search reference data"
              append={
                <EuiButtonGroup
                  className="hidden-outline"
                  legend="Button group for changing search feature"
                  color="primary"
                  options={toggleSearchButtons}
                  idSelected={toggleAdvanceSearch}
                  onChange={(id) => onChange(id as 'simple' | 'advance')}
                  buttonSize="m"
                />
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        {data.length > 0 && (
          <EuiDataGrid
            aria-label="Reference data grid"
            style={{ height: '95%' }}
            toolbarVisibility={{
              showDisplaySelector: true,
              showFullScreenSelector: true,
              showColumnSelector: true,
            }}
            leadingControlColumns={leadingControlColumns}
            columns={columns}
            columnVisibility={{ visibleColumns, setVisibleColumns }}
            rowCount={data.length}
            renderCellValue={({ rowIndex, columnId }) => {
              let value = '';
              if (data.length > 0) {
                value = data[rowIndex][columnId] || '';
              }
              return <span title={value}>{value}</span>;
            }}
            pagination={{
              ...pagination,
              onChangePage: onChangePage,
              onChangeItemsPerPage: onChangeItemsPerPage,
            }}
            gridStyle={{ rowClasses, rowHover: 'none' }}
            onColumnResize={(data) =>
              shouldUpdateTablePreferences && updateTablePreferences(data, undefined)
            }
          />
        )}

        {!data ||
          (data.length === 0 && (
            <EuiFlexGroup justifyContent="center" alignItems="center">
              <EuiFlexItem grow={false}>
                <EuiTitle size="xs">
                  <span>
                    <Translate
                      id="referenceDataTable.noData"
                      defaultMessage="No reference data found"
                    ></Translate>
                  </span>
                </EuiTitle>
              </EuiFlexItem>
            </EuiFlexGroup>
          ))}
      </EuiFlexItem>
    </>
  );
};
