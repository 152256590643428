import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiText,
  EuiToken,
  useEuiTheme,
} from '@elastic/eui';
import { AnnotationItemMessagesIcon } from 'components/Annotation/AnnotationPanel/Messages';
import React, { useMemo, useState } from 'react';
import { AnnotationControlVariant } from '../../../Definitions/DatapointValueControlVariant';
import { SchemaElementKind } from '../../../Definitions/SchemaElementKind';
import { THEME } from '../../../settings/constants';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useAnnotationStore } from '../Annotation';
import { DatapointValueControl } from '../DatapointValueControl';
import { useActivateDatapoint } from './useActivateDatapoint';

export function FieldAnnotationItem({ elementAnnotation, schemaElement }) {
  const {
    id: elementId,
    updated_by: updatedBy,
    normalized_value: value,
    value: originalValue,
    annotation_class: annotationClass,
    confidence: sourceConfidence,
    messages,
    normalized_value_compared: comparedValue,
  } = elementAnnotation;
  const name = schemaElement?.label;
  const { euiTheme } = useEuiTheme();
  const intl = useTranslate();
  const { pinnedDatapoint, setActiveDatapointById } = useActivateDatapoint();
  const { activeDatapoint } = useActivateDatapoint();
  const [showPinButton, setShowPinButton] = useState(false);
  const annotationStore = useAnnotationStore();

  const pinCurrentDatapoint = (e) => {
    e.stopPropagation();
    if (pinActive) {
      setActiveDatapointById(undefined, false);
    } else {
      setActiveDatapointById(elementId, true);
    }
  };

  const handleOnClickAnnotationFieldRow = () => {
    setActiveDatapointById(elementId, undefined, true, true);
  };

  const togglePinButton = (e) => {
    e.nativeEvent.stopPropagation();
    setShowPinButton(!showPinButton);
  };

  const pinActive = useMemo(() => {
    return pinnedDatapoint?.id === elementId;
  }, [pinnedDatapoint]);

  const fontColorDifferent = euiTheme.colors.dangerText;
  const fontColorIdentical = euiTheme.colors.successText;
  const fontColor =
    comparedValue === undefined
      ? euiTheme.colors.text
      : value?.replace(/[^\w.]/gim, '').toLowerCase() ===
        comparedValue?.replace(/[^\w.]/gim, '').toLowerCase()
      ? fontColorIdentical
      : fontColorDifferent;

  const fieldRow = (
    <EuiLink
      className="annotation-field-row"
      color="primary"
      style={{
        width: '100%',
        color:
          activeDatapoint?.id !== elementId ? fontColor : THEME.euiColorPrimaryText,
      }}
      onClick={handleOnClickAnnotationFieldRow}
      onMouseEnter={togglePinButton}
      onMouseLeave={togglePinButton}
    >
      <div
        style={{
          padding: '0.2em 0em 0.2em 0.1em',
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '40%',
          }}
        >
          <AnnotationItemMessagesIcon
            confidence={sourceConfidence?.toPrecision(2)}
            updatedBy={updatedBy}
            messages={messages}
            style={{
              display: 'inline',
            }}
          />
          <EuiText
            size="s"
            style={{
              display: 'inline',
            }}
            title={
              fontColor === fontColorDifferent
                ? intl.formatMessage(
                    {
                      id: 'fieldAnnotation.expectedValue',
                      defaultMessage: 'Expected: {comparedValue}',
                    },
                    { comparedValue: comparedValue || '' }
                  )
                : name
            }
          >
            {name}
          </EuiText>
        </div>
        {annotationClass !== 'value' && fontColor !== fontColorDifferent && (
          <div style={{ display: 'inline', maxWidth: '80px' }}>
            <EuiFlexGroup gutterSize="none">
              {fontColor == fontColorDifferent && (
                <EuiFlexItem grow={false}>
                  <div style={{ display: 'inline' }}>
                    <EuiIcon
                      type="questionInCircle"
                      title={intl.formatMessage(
                        {
                          id: 'fieldAnnotation.expectedValue',
                          defaultMessage: 'Expected: {comparedValue}',
                        },
                        { comparedValue: comparedValue || '' }
                      )}
                      style={{ marginLeft: '0.5em' }}
                    />
                  </div>
                </EuiFlexItem>
              )}
              {annotationClass === 'keyword' && (
                <EuiFlexItem grow={false}>
                  <EuiToken
                    iconType="tokenKey"
                    fill="dark"
                    title="Keyword"
                    shape="square"
                    color="euiColorVis5"
                    style={{ marginLeft: '0.5em', display: 'inline' }}
                  />
                </EuiFlexItem>
              )}
              {annotationClass === 'column_header' && (
                <EuiFlexItem grow={false}>
                  <EuiToken
                    iconType="editorTable"
                    fill="dark"
                    title={intl.formatMessage({
                      id: 'fieldAnnotation.columnHeader',
                      defaultMessage: 'Column header',
                    })}
                    shape="square"
                    color="euiColorVis5"
                    style={{ marginLeft: '0.5em', display: 'inline' }}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </div>
        )}
        <div
          // Without whiteSpace='pre-wrap', spaces in values are collapsed.
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
            alignContent: 'right',
            maxWidth: '55%',
            minWidth: '40%',
          }}
        >
          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem grow={10} style={{ alignItems: 'flex-end', height: '100%' }}>
              <DatapointValueControl
                messages={messages}
                parentId={annotationStore?.annotation?.id}
                datapointId={schemaElement.id}
                datapointType={schemaElement.type}
                elementKind={schemaElement.kind || SchemaElementKind.Datapoint}
                datapointEnumChoices={schemaElement.choices}
                variant={AnnotationControlVariant.ListItem}
                value={value}
                originalValue={originalValue}
                id={elementId}
                isKnownDatapoint={true}
                hooks={schemaElement.hooks || []}
              />
            </EuiFlexItem>
            {/*Disable for now untill the feature get's approved.*/}
            {/*<EuiFlexItem grow={1} style={{ justifyContent: 'center' }}>*/}
            {/*  {(showPinButton || pinActive) && (*/}
            {/*    <EuiButtonIcon*/}
            {/*      style={{ margin: 0 }}*/}
            {/*      size="xs"*/}
            {/*      onClick={pinCurrentDatapoint}*/}
            {/*      iconType={pinActive ? 'pinFilled' : 'pin'}*/}
            {/*      title={intl.formatMessage({*/}
            {/*        id: 'fieldAnnotation.pinDatapoint',*/}
            {/*        defaultMessage: 'Pin current datapoint',*/}
            {/*      })}*/}
            {/*      shape*/}
            {/*    ></EuiButtonIcon>*/}
            {/*  )}*/}
            {/*</EuiFlexItem>*/}
          </EuiFlexGroup>
        </div>
      </div>
    </EuiLink>
  );

  return fieldRow;
  // <EuiFlexGroup direction="row" gutterSize="none">
  //   <EuiFlexItem>{fieldRow}</EuiFlexItem>
  // </EuiFlexGroup>
}
