import {
  ButtonColor,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonEmptyColor,
  EuiButtonEmptyProps,
  EuiButtonProps,
  EuiPopover,
  PopoverAnchorPosition,
} from '@elastic/eui';
import React, { useState } from 'react';
import { FolderInfo } from '../Folders/useFolders';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { FolderSearch } from './FolderSearch';

export interface MoveToFolderProps {
  onFolderSelected: (folder: any) => void;
  type: 'skill' | 'document' | 'folder';
  anchorPosition: PopoverAnchorPosition;
  permission: string;

  buttonProps: {
    fill: boolean;
    color: ButtonColor | EuiButtonEmptyColor;
    isDisabled: boolean;
    isEmpty: boolean;
  };
}

export function MoveToFolder(props: MoveToFolderProps) {
  const buttonProps = props.buttonProps;
  const intl = useTranslate();
  const [isMoveToPopOverOpen, setIsMoveToPopOverOpen] = useState(false);

  const toggleMoveToPopOver = () =>
    setIsMoveToPopOverOpen((isMoveToPopOverOpen) => !isMoveToPopOverOpen);

  const closeMoveToPopOver = () => setIsMoveToPopOverOpen(false);

  const buttonComponentProps: EuiButtonEmptyProps | EuiButtonProps = {
    fill: buttonProps.fill,
    color: buttonProps.color,
    iconType: 'folderClosed',
    ['aria-label']: 'Move files to folder',
    onClick: toggleMoveToPopOver,
    isDisabled: buttonProps.isDisabled,
    title: intl.formatMessage(
      {
        id: 'moveToFolder.button.moveToFolder.title',
        defaultMessage: `Move this {type} to a different folder`,
      },
      { type: props.type }
    ),
    size: 's',
    style: { height: '100%', width: '100%' },
  };
  return (
    <EuiPopover
      className="move-to-folder-popover"
      style={{ height: '100%' }}
      repositionOnScroll={true}
      button={
        buttonProps.isEmpty ? (
          <EuiButtonEmpty {...buttonComponentProps}>
            <Translate
              id="moveToFolder.button.moveToFolder"
              defaultMessage="Move to folder"
            />
          </EuiButtonEmpty>
        ) : (
          <EuiButton {...buttonComponentProps}>
            <Translate
              id="moveToFolder.button.moveToFolder"
              defaultMessage="Move to folder"
            />
          </EuiButton>
        )
      }
      isOpen={isMoveToPopOverOpen}
      closePopover={closeMoveToPopOver}
      anchorPosition={props.anchorPosition}
    >
      <div style={{ width: '400px' }}>
        <FolderSearch
          actionName={intl.formatMessage({
            id: 'documentTable.batchActions.moveTo.moveToButton',
            defaultMessage: 'Move to',
          })}
          onFolderSelect={(folder: FolderInfo) => {
            props.onFolderSelected(folder);
            closeMoveToPopOver();
          }}
          permission={props.permission}
        />
      </div>
    </EuiPopover>
  );
}
