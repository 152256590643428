import { atom, useAtom } from 'jotai';
import { getDocument } from 'pdfjs-dist';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useDocumentContent } from '../../../services/documents';
import { InLineLoader } from '../../InlineLoader/InlineLoader';
import { useAnnotationStore } from '../Annotation';
import { pageRotationAngleAtom, pageScaleAtom } from './ImageBrowser';

export function PdfBrowser({ documentId, getTransformedPdfContent }) {
  const { data: documentContent, isLoading } = useDocumentContent(
    documentId,
    getTransformedPdfContent
  );
  const pageIndex = useAnnotationStore((state) => state.focusedPageIndex);
  const [rotationAngle] = useAtom(pageRotationAngleAtom);
  const [scale] = useAtom(pageScaleAtom);
  const [defaultRotationAngle, setDefaultRotationAngle] = useState(undefined);

  const documentContentURL = useMemo(
    () => !isLoading && URL.createObjectURL(documentContent),
    [documentContent, isLoading]
  );
  usePdfPageCount(documentContentURL);

  if (isLoading) return <InLineLoader />;

  return (
    <>
      <Document file={documentContentURL} loading={<InLineLoader />}>
        <Page
          pageIndex={pageIndex}
          // Rotate with respect to default page rotation:
          // https://github.com/wojtekmaj/react-pdf/issues/277
          rotate={
            defaultRotationAngle !== undefined
              ? defaultRotationAngle + rotationAngle
              : undefined
          }
          scale={scale}
          loading={<InLineLoader />}
          width="900"
          onLoadSuccess={(page) => setDefaultRotationAngle(page.rotate)}
          style={{ justifyContent: 'center' }}
        />
      </Document>
    </>
  );
}

export const pdfPageCountAtom = atom();
function usePdfPageCount(documentContentURL) {
  const [pageCount, setPageCount] = useAtom(pdfPageCountAtom);

  useLayoutEffect(() => {
    documentContentURL &&
      getDocument(documentContentURL)
        .promise.then(function (document) {
          setPageCount(document.numPages);
        })
        .catch(console.log);
  }, [setPageCount, documentContentURL]);

  return pageCount;
}
