import {
  EuiButtonEmpty,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFoldersTree } from '../Folders/Folders';
import { useFolders } from '../Folders/useFolders';
import { InLineLoader } from '../InlineLoader/InlineLoader';
import { useTranslate } from '../Internationalisation/useTranslate';

export function FolderSearch({ actionName, onFolderSelect, permission }) {
  const { useGetFolders } = useFolders();
  const { data: folders, isLoading } = useGetFolders(permission);
  const [query, setQuery] = useState('');
  const [filteredFolders, setFilteredFolders] = useState([]);
  const intl = useTranslate();
  const { folderId: selectedFolderId } = useParams();
  const { getFolderParentsDisplayString } = useFoldersTree();

  useEffect(() => {
    if (!folders?.data) {
      return;
    }
    const timer = setTimeout(() => {
      const filteredFolders = filterFolders(folders.data, query.toLowerCase());
      setFilteredFolders(
        filteredFolders.flat().filter((folder) => selectedFolderId !== folder.id)
      );
    }, 500);

    return () => clearTimeout(timer);
  }, [folders?.data, query]);

  const filterFolders = (folders, query) => {
    let queryWordList = query.split(/\s+/);

    if (queryWordList.length === 2 && queryWordList[1] === '') {
      queryWordList = [queryWordList[0]];
    }

    return folders.filter((folder) => {
      const folderString = getFolderParentsDisplayString(folder.id);
      const folderName = folder.name.toLowerCase();
      let isMatch = true;

      const isMatchingFolder = (queryWord) => {
        return folderName.includes(queryWord) && selectedFolderId !== folder.id;
      };

      queryWordList.forEach((word, index) => {
        if (!isMatch) {
          return;
        }

        const lastIndex = queryWordList.length - 1;

        if (!folderString.toLowerCase().includes(word.toLowerCase())) {
          isMatch = false;
        }

        if (lastIndex === index && isMatchingFolder(word)) {
          isMatch = true;
        }
      });

      if (isMatchingFolder(query)) {
        isMatch = true;
      }

      return isMatch;
    });
  };

  if (isLoading) return <InLineLoader />;

  const onChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <EuiFieldSearch
        placeholder={intl.formatMessage({
          id: 'documentTable.batchActions.moveTo.searchFolder',
          defaultMessage: 'Search folder',
        })}
        value={query}
        incremental={true}
        onChange={(e) => onChange(e)}
        isClearable={true}
        aria-label="Search folder"
      />
      <div
        style={{ height: '150px', marginTop: '10px' }}
        className="eui-yScrollWithShadows"
      >
        {filteredFolders &&
          filteredFolders.map((folder) => (
            <EuiPanel paddingSize="s" style={{ marginBottom: '5px', marginTop: '5px' }}>
              <EuiFlexGroup
                alignItems="center"
                justifyContent="spaceBetween"
                gutterSize="s"
              >
                <EuiFlexItem grow={false} style={{ maxWidth: '75%' }}>
                  <span>
                    <EuiFlexGroup direction={'column'} gutterSize="s">
                      <EuiFlexItem grow={false}>
                        <strong
                          title={folder.name}
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {folder.name}
                        </strong>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <span
                          title={getFolderParentsDisplayString(folder.id)}
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {getFolderParentsDisplayString(folder.id)}
                        </span>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </span>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ maxWidth: '25%' }}>
                  <EuiButtonEmpty size="xs" onClick={() => onFolderSelect(folder)}>
                    {actionName}
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          ))}
      </div>
    </>
  );
}
