import CanvasDatagrid from 'canvas-datagrid';
import { useAtom } from 'jotai';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useActivateDatapoint } from '../../AnnotationPanel/useActivateDatapoint';
import { AnnotationPopup } from '../DatapointAnnotationPopups/AnnotationPopup';
import { documentPanelResizeAtom } from '../ResizeState';

let grid = null;

const selectedAreaColor = '#ff6666';
const keywordHeaderColor = 'rgb(145,190,230)';
const valueColor = 'rgb(244,182,182)';

export const CanvasDataGrid = (props) => {
  const targetNode = useRef();
  const [menuPosition, setMenuPosition] = useState(undefined);
  const [selectionBounds, setSelectionBounds] = useState(undefined);
  const [mouseOverMenu, _setMouseOverMenu] = useState(false);
  const [documentPanelResized] = useAtom(documentPanelResizeAtom);
  const [cellHasAnnotation, setCellHasAnnotation] = useState(false);
  const mouseOverMenuRef = useRef(mouseOverMenu);
  const setMouseOverMenu = (state) => {
    mouseOverMenuRef.current = state;
    _setMouseOverMenu(state);
  };
  const { activeDatapoint, setActiveDatapoint } = useActivateDatapoint();
  const activeDatapointRef = useRef();

  activeDatapointRef.current = activeDatapoint;

  useEffect(() => {
    if (props.clearCanvas) {
      setMenuPosition(undefined);
      setSelectionBounds(undefined);
      setMouseOverMenu(false);
      grid && grid.selectNone();
      props.setClearCanvas(false);
      setActiveDatapoint(undefined);
    }
  }, [props.clearCanvas]);

  useEffect(() => {
    if (grid) {
      grid.selectNone();
      setSelectionBounds(undefined);
      setMouseOverMenu(false);
    }
  }, [props.activeSheetIndex]);

  useEffect(() => {
    const removeSelectionBounds = (e) => {
      setSelectionBounds(undefined);
      setTimeout(() => {
        if (targetNode.current && grid) {
          grid.style.height = `${targetNode.current.offsetHeight}px`;
          grid.style.width = `${targetNode.current.offsetWidth}px`;
        }
      }, 0);
    };

    const removeSelection = (e) => {
      if (e.target.attributes.id === 'canvas-datagrid') {
        setMenuPosition(undefined);
        setSelectionBounds(undefined);
        setMouseOverMenu(false);
        grid && grid.selectNone();
        props.setClearCanvas(false);
        setActiveDatapoint(undefined);
      }
    };

    window.addEventListener('scroll', (e) => removeSelectionBounds(e));
    window.addEventListener('resize', (e) => removeSelectionBounds(e));
    window.addEventListener('wheel', removeSelection);

    return () => {
      window.removeEventListener('scroll', (e) => removeSelectionBounds(e));
      window.removeEventListener('resize', (e) => removeSelectionBounds(e));
      window.removeEventListener('wheel', removeSelection);
    };
  }, []);

  // remove all focused fields from store before initialization
  useEffect(() => {
    setActiveDatapoint(undefined);
    setMenuPosition(undefined);
  }, []);

  // Highlights the datapoint area and shows the annotation popup, in other case it will hide the menu and popup
  useEffect(() => {
    if (!activeDatapoint || !activeDatapoint.box) {
      setMenuPosition(undefined);
      setSelectionBounds(undefined);
      setMouseOverMenu(false);
      return;
    }
    if (grid && props.annotations) {
      if (activeDatapoint.pageIndex !== props.activeSheetIndex) {
        if (activeDatapoint.pageIndex !== null) {
          props.switchSheetByIndex(activeDatapoint.pageIndex);
        }
        // Don't select area if datapoint has another page_index then the one displayed
        return;
      }
      if (!grid.isCellVisible(activeDatapoint.box.xMin, activeDatapoint.box.yMin)) {
        grid.gotoCell(activeDatapoint.box.xMin, activeDatapoint.box.yMin);
      }
      setMenuPositionFromAnnotation(activeDatapoint);
      selectArea(activeDatapoint.box);
      setCellHasAnnotation(true);
    }
  }, [activeDatapoint, documentPanelResized]);

  useEffect(() => {
    //When the grid rerenders check if there is anny active fousFieldId and higlight it
    const setActiveDatapointOnSheetChange = () => {
      if (activeDatapoint && activeDatapoint.box) {
        // On switch page_index check if there is any datapoint highlighted, if so then we will set that datapoint as active
        grid.gotoCell(activeDatapoint.box.xMin, activeDatapoint.box.yMin);
        setMenuPositionFromAnnotation(activeDatapoint);
        selectArea(activeDatapoint.box);
        setCellHasAnnotation(true);
      }
    };
    // Needed in order to wait when the sheet index is properly changed
    setTimeout(() => {
      setActiveDatapointOnSheetChange();
    });
  }, [props.activeSheetIndex]);

  // HIde the native context menu
  const contextMenuCallback = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (grid && props.data) {
      grid.data = props.data;
      setTimeout(() => {
        if (
          grid.style.height !== `${targetNode.current.offsetHeight}px` ||
          grid.style.width !== `${targetNode.current.offsetWidth}px`
        ) {
          grid.style.height = `${targetNode.current.offsetHeight}px`;
          grid.style.width = `${targetNode.current.offsetWidth}px`;
        }
      }, 0);
    }
  }, [props.data, props.annotations, documentPanelResized]);

  // Render canvas-grid based on data-points
  useLayoutEffect(() => {
    if (targetNode.current) {
      setMouseOverMenu(false);
      grid = CanvasDatagrid({
        id: 'canvas-datagrid',
        parentNode: targetNode.current,
        data: props.data || [],
        editable: false,
        allowFreezingColumns: true,
        allowFreezingRows: true,
        style: {
          cellSelectedBackgroundColor: selectedAreaColor,
          activeCellSelectedBackgroundColor: selectedAreaColor,
          selectionOverlayBorderColor: selectedAreaColor,
          selectionOverlayBorderWidth: 2,
        },
      });
    }

    return () => {
      grid.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    const selectAreaCallback = (e) => {
      if (mouseOverMenuRef.current) {
        return;
      }
      const datapoint = getCellDatapoint(e.cell);

      if (datapoint) {
        if (datapoint.id !== activeDatapointRef?.current?.id) {
          setActiveDatapoint(datapoint);
        }
      }
    };
    grid.addEventListener('contextmenu', contextMenuCallback);
    grid.addEventListener('click', clickHandler);
    grid.addEventListener('beforesortcolumn', disableSorting);
    grid.addEventListener('mouseup', mouseUpHandler);
    grid.addEventListener('mousedown', mouseDownAndClickHandler);
    grid.addEventListener('cellmouseover', selectAreaCallback);
    grid.addEventListener('rendercell', renderCellHandler);
    return () => {
      grid.removeEventListener('rendercell', renderCellHandler);
      grid.removeEventListener('contextmenu', contextMenuCallback);
      grid.removeEventListener('click', clickHandler);
      grid.removeEventListener('beforesortcolumn', disableSorting);
      grid.removeEventListener('mouseup', mouseUpHandler);
      grid.removeEventListener('mousedown', mouseDownAndClickHandler);
      grid.removeEventListener('cellmouseover', selectAreaCallback);
    };
  }, [props.annotations]);

  const areSelectionBoundsInfinity = (grid) => {
    return (
      grid.selectionBounds.left === Infinity || grid.selectionBounds.left === -Infinity
    );
  };

  // Sets the menu coordinates from annotation
  const setMenuPositionFromAnnotation = (datapoint) => {
    const cell = grid.getVisibleCellByIndex(datapoint.box.xMax, datapoint.box.yMax);

    if (cell) {
      // Needs to be executed after the grid is rerender, otherwise it will not set the new position of the menu
      setTimeout(() => {
        if (areSelectionBoundsInfinity(grid)) {
          return;
        }
        setSelectionBounds(grid.selectionBounds);
        setMenuPosition({
          x: cell.x + targetNode.current.getBoundingClientRect().x,
          y: cell.y + cell.height + targetNode.current.getBoundingClientRect().y,
        });
      }, 0);
    }
  };

  // Changes the styles of the cell based on annotationClass
  const renderCellHandler = useCallback(
    (e) => {
      if (!props.annotations) {
        return;
      }
      const annotation = getCellDatapoint(e.cell);
      if (annotation) {
        if (annotation.annotation_class === 'value') {
          e.ctx.fillStyle = valueColor;
        } else {
          e.ctx.fillStyle = keywordHeaderColor;
        }
      }
    },
    [props.annotations]
  );

  const mouseUpHandler = (e) => {
    const datapoint = getCellDatapoint(e.cell);
    if (
      e.NativeEvent.button !== 0 ||
      !props.enableAnnotationCreation ||
      mouseOverMenuRef.current
    ) {
      return;
    }

    if (datapoint) {
      e.preventDefault();
      return;
    }

    setActiveDatapoint(undefined);
    setMenuPosition(undefined);
    setCellHasAnnotation(false);

    if (e.cell.isBackground || e.cell.isColumnHeader) {
      selectNone();
      return;
    }

    if (e.cell.isScrollBar) {
      setMenuPosition(undefined);
      return;
    }

    if (!areSelectionBoundsInfinity(grid)) {
      setSelectionBounds(grid.selectionBounds);
      setMenuPosition({
        x: e.NativeEvent.x,
        y: e.NativeEvent.y,
      });
    }
  };

  const clickHandler = (e) => {
    if (grid.selectionBounds) {
      e.preventDefault();
    } else {
      mouseDownAndClickHandler(e);
    }
  };

  const mouseDownAndClickHandler = (e) => {
    const datapoint = getCellDatapoint(e.cell);
    if (datapoint) {
      e.preventDefault();
    } else {
      hideMenuHandler();
    }
  };

  const hideMenuHandler = () => {
    setMenuPosition(undefined);
  };

  const selectNone = () => {
    if (grid) {
      setActiveDatapoint(undefined, false);
      grid.selectNone();
      setSelectionBounds(undefined);
      setMouseOverMenu(false);
    }
  };

  const disableSorting = (e) => {
    e.preventDefault();
  };

  const getCellDatapoint = useCallback(
    (cell) => {
      return props.annotations.find((datapoint) => {
        if (datapoint.box && cell.style === 'cell') {
          if (
            cell.boundColumnIndex >= datapoint.box.x_min &&
            cell.boundColumnIndex <= datapoint.box.x_max &&
            cell.boundRowIndex >= datapoint.box.y_min &&
            cell.boundRowIndex <= datapoint.box.y_max
          ) {
            return datapoint;
          }
        }
      });
    },
    [props.annotations]
  );

  // Creates AnnotationPopup and set's the coordinates based on selected cell/area
  const renderCreateAnnotation = () => {
    if (!grid) {
      return;
    }
    if (selectionBounds && !areSelectionBoundsInfinity(grid)) {
      const text = grid.selectedCells
        .filter((cell) => cell)
        .map((row) => Object.values(row).join('  |  '))
        .join('\r\n');
      const bounds = {
        xMin: selectionBounds.left,
        yMin: selectionBounds.top,
        xMax: selectionBounds.right,
        yMax: selectionBounds.bottom,
      };
      return (
        <AnnotationPopup
          selectionBounds={bounds}
          pageIndex={props.activeSheetIndex}
          schemaId={props.annotation.schema_id}
          annotation={props.annotation}
          annotationId={props.annotation.id}
          datapoint={activeDatapointRef.current}
          existingTextValue={text}
          isPositionChanged={!cellHasAnnotation}
          isSpreadsheetAnnotation={true}
          selectNone={selectNone}
        />
      );
    }
  };

  const selectArea = (box) => {
    if (grid) {
      grid.selectArea({
        top: box.yMin,
        bottom: box.yMax,
        left: box.xMin,
        right: box.xMax,
      });
      grid.draw();
    }
  };

  return (
    <>
      <div style={{ position: 'relative', height: '90%', width: '100%' }}>
        <div
          style={{ display: 'block', height: '100%', width: '100%' }}
          ref={targetNode}
        />
        {menuPosition && (
          <div
            style={{
              position: 'fixed',
              top: `${menuPosition.y}px`,
              display: 'block',
              left: `${menuPosition.x}px`,
              zIndex: '9999',
            }}
            onMouseEnter={() => setMouseOverMenu(true)}
            onMouseLeave={() => setMouseOverMenu(false)}
          >
            {renderCreateAnnotation()}
          </div>
        )}
      </div>
    </>
  );
};
