import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiConfirmModal,
  EuiDataGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSwitch,
  EuiToken,
} from '@elastic/eui';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import { Can, PERMISSION, useCan } from 'utils/permissions';
import { v4 as uuidv4 } from 'uuid';
import { DatapointType } from '../../../Definitions/DatapointType';
import { AnnotationControlVariant } from '../../../Definitions/DatapointValueControlVariant';
import { SchemaElementKind } from '../../../Definitions/SchemaElementKind';
import { createAnnotationBatchOperations } from '../../../services/annotations';
import { invalidateAnnotationContent } from '../../../services/documents';
import { getThemeJson } from '../../../themes/ThemeJson';
import { Translate } from '../../Internationalisation/translate';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useAnnotationStore } from '../Annotation';
import {
  annotationItemIdAtom,
  focusTableAtom,
  isExtendedAnnotationEnabledAtom,
  openedKeywordAnnotationAccordion,
  openedKeywordAnnotationExpandedRow,
  tablePreferences,
  wasNewListRowAddedAtom,
  wasNewRowAddedAtom,
} from '../AnnotationState';
import { DatapointValueControl } from '../DatapointValueControl';
import { showTableGridAnnotationAtom } from '../DocumentPanel/ImageAnnotation/ImageAnnotation';
import { KeywordAnnotationMenu } from './KeywordAnnotationMenu';
import { KeywordRowCard } from './KeywordRowCard';
import { AnnotationItemMessagesIcon } from './Messages';
import { useActivateDatapoint } from './useActivateDatapoint';

export function TableAnnotation({
  elementAnnotation,
  items,
  columns,
  schemaElement,
  metadata,
}) {
  const can = useCan();
  const schema = useAnnotationStore((state) => state.schema);
  const documentType = useAnnotationStore((state) => state.documentType);
  const intl = useTranslate();

  const tableId = useMemo(() => {
    if (schema && elementAnnotation) {
      return `${schema.id}_${elementAnnotation.schema_element_id}`;
    }
  }, [schema, elementAnnotation]);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(
    parseInt(localStorage.getItem('tableAnnotation.pageSize')) || 50
  );
  const [wasNewRowAdded, setWasNewRowAddedAtom] = useAtom(wasNewRowAddedAtom);
  const [, setWasNewListRowAdded] = useAtom(wasNewListRowAddedAtom);
  const annotationStore = useAnnotationStore();

  const [showTableGridAnnotation, setShowTableGridAnnotation] = useAtom(
    showTableGridAnnotationAtom
  );
  const [isExtendedAnnotationEnabled] = useAtom(isExtendedAnnotationEnabledAtom);

  const { setActiveDatapoint } = useActivateDatapoint();

  const [tablePreferencesCollection, setTablePreferencesCollection] = useAtom(
    tablePreferences
  );

  const defaultVisibleColumns = useMemo(
    () => columns.filter(({ isHidden }) => !isHidden).map(({ field }) => field),
    [columns]
  );

  const [, setAnnotationItemId] = useAtom(annotationItemIdAtom);
  const [visibleColumns, setVisibleColumns] = useState(defaultVisibleColumns);

  const [pagination, setPagination] = useState({
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: items.length,
    pageSizeOptions: [2, 4, 6, 8, 10, 15, 20, 50, 100],
  });

  const isSpreadsheetDatapoint = useMemo(() => {
    return documentType === 'spreadsheet';
  }, [documentType]);

  useEffect(() => {
    setShowTableGridAnnotation(false);
  }, []);

  useEffect(() => {
    setActiveDatapoint(undefined);
    setVisibleColumns(defaultVisibleColumns);
  }, [defaultVisibleColumns]);

  const [allItemsValues, setAllItemsValues] = useState([]);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [tupleToDeleteId, setTupleToDeleteId] = useState();
  const [newCellsIds, setNewCellsIds] = useState([]);
  const [, setOpenedAccordionId] = useAtom(openedKeywordAnnotationAccordion);
  const [expandedRows, setExpandedRows] = useAtom(openedKeywordAnnotationExpandedRow);
  const [, setFocusedTable] = useAtom(focusTableAtom);

  const updateTablePreferences = (rowsConfigs, columnConfig) => {
    let columnConfigs = {};
    let tablePreferences = {};

    if (tablePreferencesCollection[tableId]) {
      tablePreferences = tablePreferencesCollection[tableId];
      columnConfigs = tablePreferencesCollection[tableId].columnConfigs;
    }

    if (rowsConfigs) {
      tablePreferences = { ...tablePreferences, rowsConfigs };
    }

    if (columnConfig) {
      columnConfigs = {
        ...columnConfigs,
        [columnConfig.columnId]: columnConfig.width,
      };
      tablePreferences = columnConfigs && { ...tablePreferences, columnConfigs };
    }

    const newTablePreferencesCollection = { ...tablePreferencesCollection };
    newTablePreferencesCollection[tableId] = tablePreferences;
    setTablePreferencesCollection(newTablePreferencesCollection);
  };

  const toggleExpandRow = (rowId, expand = false) => {
    setExpandedRows((previousSet) => {
      const newExpandedRows = new Set(previousSet);
      if (newExpandedRows.has(rowId) && !expand) {
        newExpandedRows.delete(rowId);
      } else {
        newExpandedRows.add(rowId);
      }
      return newExpandedRows;
    });
  };

  let deleteAnnotationModal;

  useEffect(() => {
    if (isExtendedAnnotationEnabled === false) {
      setOpenedAccordionId(undefined);
    }
  }, [isExtendedAnnotationEnabled]);

  useEffect(() => {
    if (wasNewRowAdded) {
      setPageIndex(Math.floor(items.length / pageSize));
    }

    const itemsValues = [];

    for (const item of items) {
      if (item.kind === SchemaElementKind.Tuple) {
        for (const element of item.elements) {
          itemsValues.push({
            id: element.id,
            value: element.normalized_value,
            messages: element.messages,
            rowId: item.id,
            box: element.box,
            annotation_class: element.annotation_class,
            schema_element_id: element.schema_element_id,
            pageIndex: element.page_index,
          });
        }
      } else if (item.kind === SchemaElementKind.Datapoint) {
        itemsValues.push({
          id: item.id,
          value: item.normalized_value,
          messages: item.messages,
          rowId: item.id,
          box: item.box,
          annotation_class: item.annotation_class,
          schema_element_id: item.schema_element_id,
          pageIndex: item.page_index,
        });
      }
    }
    setAllItemsValues(itemsValues);
  }, [items, isExtendedAnnotationEnabled]);

  function getNewDatapointId(schemaElementId, rowId) {
    const newIdRecord = newCellsIds.find(
      (item) => item.key === `${schemaElementId}__${rowId}`
    );

    return newIdRecord?.value || null;
  }

  function handleAddRow() {
    const rowId = uuidv4();

    const newRowBatchOperation = {
      kind: 'create',
      payload: {
        id: rowId,
        schema_element_kind:
          schemaElement.kind === SchemaElementKind.List
            ? SchemaElementKind.Datapoint
            : SchemaElementKind.Tuple,
        schema_element_id:
          schemaElement.kind === SchemaElementKind.List
            ? schemaElement.elements.id
            : schemaElement.id,
        parent_id: elementAnnotation.id,
        annotation_class: 'value',
      },
    };

    let commands = [newRowBatchOperation];
    if (schemaElement.kind !== SchemaElementKind.List) {
      const datapointOperations = getDatapointCreationOperations(schemaElement, rowId);
      commands = [...commands, ...datapointOperations];
    }

    createAnnotationBatchOperations(annotationStore.annotation.id, {
      commands,
    })
      .then(() => {
        setWasNewRowAddedAtom(true);
        return invalidateAnnotationContent(annotationStore.annotation.id).then(() => {
          if (schemaElement.kind === SchemaElementKind.List) {
            setWasNewListRowAdded(newRowBatchOperation.payload.id);
          }
        });
      })
      .catch(console.log);
  }

  const [sortingColumns, setSortingColumns] = useState([]);
  const onSort = useCallback(
    (sortingColumns) => {
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns]
  );

  const getDatapointCreationOperations = (schemaElement, rowId) => {
    const schemaElements = schemaElement.elements;

    if (Array.isArray(schemaElements)) {
      return schemaElements.map((element) => {
        return {
          kind: 'create',
          payload: {
            id: uuidv4(),
            schema_element_kind: element.kind,
            schema_element_id: element.id,
            parent_id: rowId,
            annotation_class: 'value',
          },
        };
      });
    } else {
      return [
        {
          kind: 'create',
          payload: {
            id: uuidv4(),
            schema_element_kind: schemaElements.kind,
            schema_element_id: schemaElements.id,
            parent_id: rowId,
            annotation_class: 'value',
          },
        },
      ];
    }
  };

  function deleteRow(rowId) {
    setTupleToDeleteId(rowId);
    setShowDeleteConfirmModal(true);
  }

  function forceDeleteRow() {
    annotationStore.deleteAnnotationItem(tupleToDeleteId);
    setShowDeleteConfirmModal(false);
  }

  const getCellContent = (columnId, rowId) => {
    const data = allItemsValues.filter(
      (element) => element.schema_element_id === columnId
    );
    let foundElement = data.find(
      (element) => element.annotation_class === 'value' && element.rowId === rowId
    );
    const keyword = data.find(
      (element) => element.annotation_class === 'keyword' && element.rowId === rowId
    );
    const header = data.find(
      (element) =>
        element.annotation_class === 'column_header' && element.rowId === rowId
    );

    if (!foundElement) {
      foundElement = header || keyword;
    }

    let cellElementSchema;
    let parentId = rowId;

    if (schemaElement.kind === SchemaElementKind.List) {
      cellElementSchema = schemaElement.elements;
      parentId = elementAnnotation.id;
    } else {
      cellElementSchema = schemaElement.elements?.find((item) => item.id === columnId);
    }

    if (foundElement) {
      const datapoints = allItemsValues.filter(
        (element) => element.rowId === rowId && element.annotation_class !== 'value'
      );

      const additionalDatapoints = datapoints.filter(
        (datapoint) => datapoint.schema_element_id === columnId
      );

      return (
        <div key={`${columnId} + ${rowId}`} style={{ width: '100%' }}>
          <EuiFlexGroup gutterSize="xs" direction="column">
            <EuiFlexItem>
              <EuiFlexGroup direction="row" gutterSize="s">
                <EuiFlexItem grow={false}>
                  <AnnotationItemMessagesIcon
                    showOnlyNonempty={true}
                    confidence={foundElement.confidence}
                    updatedBy={foundElement.updated_by}
                    messages={foundElement.messages}
                  />
                </EuiFlexItem>
                {isExtendedAnnotationEnabled &&
                  !isSpreadsheetDatapoint &&
                  schemaElement.kind !== SchemaElementKind.List && (
                    <EuiFlexItem grow={false} style={{ justifyContent: 'flex-start' }}>
                      <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
                        <KeywordAnnotationMenu
                          parentId={parentId}
                          foundElement={foundElement}
                          toggleRow={toggleExpandRow}
                          rowId={rowId}
                        />
                      </Can>
                    </EuiFlexItem>
                  )}
                {foundElement.annotation_class === 'column_header' &&
                  !isSpreadsheetDatapoint && (
                    <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
                      <EuiToken
                        iconType="editorTable"
                        fill="dark"
                        title={intl.formatMessage({
                          id: 'tableAnnotationItem.token.columnHeader',
                          defaultMessage: 'Column header',
                        })}
                        shape="square"
                        color="euiColorVis5"
                        style={{ marginLeft: '0.5em' }}
                      />
                    </EuiFlexItem>
                  )}
                {foundElement.annotation_class === 'keyword' &&
                  !isSpreadsheetDatapoint && (
                    <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
                      <EuiToken
                        iconType="tokenKey"
                        fill="dark"
                        title={intl.formatMessage({
                          id: 'tableAnnotationItem.token.keyword',
                          defaultMessage: 'Keyword',
                        })}
                        shape="square"
                        color="euiColorVis5"
                        style={{ marginLeft: '0.5em' }}
                      />
                    </EuiFlexItem>
                  )}
                <EuiFlexItem>
                  <DatapointValueControl
                    parentId={parentId}
                    datapointId={cellElementSchema.id}
                    datapointType={cellElementSchema.type}
                    elementKind={cellElementSchema.kind || SchemaElementKind.Datapoint}
                    datapointEnumChoices={
                      cellElementSchema.type === DatapointType.Enum
                        ? cellElementSchema.choices
                        : undefined
                    }
                    variant={AnnotationControlVariant.TableCell}
                    value={foundElement.value}
                    messages={foundElement.messages}
                    id={foundElement.id}
                    box={foundElement.box}
                    isKnownDatapoint={true}
                    hooks={cellElementSchema.hooks || []}
                    pageIndex={foundElement.pageIndex}
                    tableId={elementAnnotation.id}
                    annotationClass={foundElement.annotation_class}
                    canChangeAnnotationClass={
                      schemaElement.kind !== SchemaElementKind.List
                    }
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            {isExtendedAnnotationEnabled && expandedRows.has(rowId) && (
              <EuiFlexItem grow={false}>
                <KeywordRowCard
                  datapoints={additionalDatapoints}
                  rowId={rowId}
                  columnId={columnId}
                  elementAnnotation={elementAnnotation}
                  schemaElement={schemaElement}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </div>
      );
    } else {
      let newId = getNewDatapointId(columnId, rowId);

      if (!newId) {
        newId = uuidv4();
        setNewCellsIds([
          ...newCellsIds,
          { key: `${columnId}__${rowId}`, value: newId },
        ]);
      }

      return (
        <EuiFlexGroup gutterSize="s" alignItems="center">
          {isExtendedAnnotationEnabled &&
            schemaElement.kind !== SchemaElementKind.List && (
              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  iconType="layers"
                  disabled
                  aria-label="At first create value"
                  title={intl.formatMessage({
                    id: 'tableAnnotationItem.button.createValue',
                    defaultMessage: 'First create value',
                  })}
                />
              </EuiFlexItem>
            )}
          <EuiFlexItem>
            <DatapointValueControl
              parentId={parentId}
              datapointId={cellElementSchema.id}
              datapointType={cellElementSchema.type}
              elementKind={cellElementSchema.kind || SchemaElementKind.Datapoint}
              datapointEnumChoices={
                cellElementSchema.type === DatapointType.Enum
                  ? cellElementSchema.choices
                  : undefined
              }
              variant={AnnotationControlVariant.TableCell}
              isKnownDatapoint={false}
              id={newId}
              hooks={cellElementSchema.hooks || []}
              tableId={elementAnnotation.id}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    }
  };

  function getPageOfItems() {
    const sortedItems = items.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    const pageItems = sortedItems.slice(
      pageIndex * pageSize,
      (pageIndex + 1) * pageSize
    );
    return pageItems;
  }

  function handleCloseTable() {
    setFocusedTable(undefined);
    setShowTableGridAnnotation(false);
  }

  const renderCellValue = ({ rowIndex, columnId }) => {
    const rowId = items[rowIndex].id;
    return getCellContent(columnId, rowId);
  };

  const collapseRows = () => {
    setExpandedRows(new Set());
  };
  const expandRows = () => {
    const rows = getPageOfItems();
    if (rows.length === expandedRows.size) {
      return;
    }
    rows.forEach((row) => {
      toggleExpandRow(row.id, true);
    });
  };

  if (showDeleteConfirmModal) {
    deleteAnnotationModal = (
      <EuiConfirmModal
        title={intl.formatMessage({
          id: 'tableAnnotationItem.confirmModal.deleteAnnotation',
          defaultMessage: 'Delete annotation?',
        })}
        onCancel={() => setShowDeleteConfirmModal(false)}
        onConfirm={forceDeleteRow}
        cancelButtonText={intl.formatMessage({
          id: 'button.cancel',
          defaultMessage: 'Cancel',
        })}
        confirmButtonText={intl.formatMessage({
          id: 'tableAnnotationItem.confirmModal.button.confirm',
          defaultMessage: 'Yes, delete it',
        })}
        buttonColor="danger"
        defaultFocusedButton="confirm"
      >
        <p>
          {schemaElement.kind !== SchemaElementKind.List ? (
            <Translate
              id="tableAnnotationItem.confirmModal.confirmMessage"
              defaultMessage="Are you sure you want to delete this table row?"
            />
          ) : (
            <Translate
              id="tableAnnotationItem.confirmModal.confirmMessageDeleteListDatapoint"
              defaultMessage="Are you sure you want to delete this datapoint?"
            />
          )}
        </p>
      </EuiConfirmModal>
    );
  }

  const leadingControlColumns = useMemo(() => {
    if (isSpreadsheetDatapoint) {
      return [];
    }
    return [
      {
        id: 'expandActions',
        width: 40,
        headerCellRender: () => null,
        rowCellRender: (item) => {
          const rowId = items[item.rowIndex].id;
          return (
            schemaElement.kind !== SchemaElementKind.List && (
              <EuiButtonIcon
                disabled={!isExtendedAnnotationEnabled}
                onClick={() => toggleExpandRow(rowId)}
                aria-label={expandedRows.has(rowId) ? 'Collapse' : 'Expand'}
                title={
                  expandedRows.has(rowId)
                    ? intl.formatMessage({
                        id: 'tableAnnotationItem.button.collapse',
                        defaultMessage: 'Collapse',
                      })
                    : intl.formatMessage({
                        id: 'tableAnnotationItem.button.expand',
                        defaultMessage: 'Expand',
                      })
                }
                iconType={expandedRows.has(rowId) ? 'arrowUp' : 'arrowDown'}
              />
            )
          );
        },
      },
    ];
  }, [isExtendedAnnotationEnabled, expandedRows, items]);

  const trailingControlColumns = [
    {
      id: 'deleteActions',
      width: 40,
      headerCellRender: () => null,
      rowCellRender: (item) => {
        const rowId = items[item.rowIndex].id;
        return (
          <EuiButtonIcon
            disabled={!can.can(PERMISSION.ANNOTATIONS_UPDATE)}
            onClick={() => deleteRow(rowId)}
            aria-label={expandedRows.has(rowId) ? 'Collapse' : 'Expand'}
            title={
              schemaElement.kind !== SchemaElementKind.List
                ? intl.formatMessage({
                    id: 'tableAnnotationItem.button.delete.description',
                    defaultMessage: 'Delete row',
                  })
                : intl.formatMessage({
                    id: 'tableAnnotationItem.button.deleteLine.description',
                    defaultMessage: 'Delete datapoint',
                  })
            }
            iconType="trash"
            color="danger"
          />
        );
      },
    },
  ];

  const columnTitle = (field) => (
    <span
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      title={field}
    >
      {field}
    </span>
  );

  const onChangePage = useCallback(
    (pageIndex) => setPagination((pagination) => ({ ...pagination, pageIndex })),
    [pagination]
  );

  const onChangeItemsPerPage = useCallback(
    (pageSize) => {
      setPageSize(pageSize);
      setPagination((pagination) => ({
        ...pagination,
        pageSize,
        pageIndex: 0,
      }));
    },
    [pagination]
  );

  const defaultVisibleColumnsMap = useMemo(() => {
    let map = new Map();
    defaultVisibleColumns.forEach((column, index) => {
      map.set(column, index);
    });
    return map;
  }, [defaultVisibleColumns]);

  //Based on this issue we have to declare the columns as a state https://github.com/elastic/eui/issues/4731
  const dataGridColumns = useMemo(() => {
    const filteredColumns = columns
      .filter(({ isHidden }) => !isHidden)
      .map(({ field, name }) => {
        const savedConfig = tablePreferencesCollection[tableId]?.columnConfigs;
        return {
          id: field,
          actions: {
            showHide: false,
          },
          display: columnTitle(name),
          isResizable: true,
          isExpandable: false,
          isSortable: true,
          initialWidth: (savedConfig && savedConfig[field]) || undefined,
        };
      });

    if (defaultVisibleColumnsMap) {
      return filteredColumns.sort(
        (a, b) =>
          defaultVisibleColumnsMap.get(a.id) - defaultVisibleColumnsMap.get(b.id)
      );
    }
    return filteredColumns;
  }, [defaultVisibleColumnsMap]);

  const rowHeightsOptions = {
    defaultHeight:
      tablePreferencesCollection[tableId]?.rowsConfigs?.defaultHeight || 'auto',
    onChange: (data) => updateTablePreferences(data),
  };

  return (
    <>
      <EuiDataGrid
        style={{ height: '95%' }}
        inMemory={{ level: 'sorting' }}
        sorting={{ columns: sortingColumns, onSort }}
        aria-label="Table annotation item"
        leadingControlColumns={leadingControlColumns}
        trailingControlColumns={trailingControlColumns}
        toolbarVisibility={{
          showDisplaySelector: schemaElement.kind !== SchemaElementKind.List,
          showFullScreenSelector: false,
          showColumnSelector: false,
          showSortSelector: false,
        }}
        columns={dataGridColumns}
        rowHeightsOptions={rowHeightsOptions}
        columnVisibility={{ visibleColumns, setVisibleColumns }}
        rowCount={items.length}
        renderCellValue={renderCellValue}
        pagination={{
          ...pagination,
          onChangePage: onChangePage,
          onChangeItemsPerPage: onChangeItemsPerPage,
        }}
        gridStyle={{ rowHover: 'none' }}
        onColumnResize={(data) => updateTablePreferences(undefined, data)}
      />

      {Object.keys(metadata).length !== 0 && (
        <div style={{ paddingTop: '1em' }}>
          <ReactJson
            src={metadata}
            theme={getThemeJson()}
            name={false}
            collapsed={false}
            displayDataTypes={false}
            displayObjectSize={false}
            quotesOnKeys={false}
          />
        </div>
      )}

      <EuiFlexGroup justifyContent="spaceBetween" gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
            <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={() => handleAddRow()} color="text" size="xs">
                  {schemaElement.kind !== SchemaElementKind.List ? (
                    <Translate
                      id="tableAnnotationItem.button.addRow"
                      defaultMessage="Add row"
                    />
                  ) : (
                    <Translate
                      id="tableAnnotationItem.button.addDatapoint"
                      defaultMessage="Add datapoint"
                    />
                  )}
                </EuiButtonEmpty>
              </EuiFlexItem>
            </Can>
            {isExtendedAnnotationEnabled &&
              schemaElement.kind !== SchemaElementKind.List && (
                <>
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                      onClick={() => collapseRows()}
                      color="text"
                      size="xs"
                    >
                      <Translate
                        id="tableAnnotationItem.button.collapseRows"
                        defaultMessage="Collapse rows"
                      />
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty onClick={() => expandRows()} color="text" size="xs">
                      <Translate
                        id="tableAnnotationItem.button.expandRows"
                        defaultMessage="Expand rows"
                      />
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                </>
              )}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup justifyContent="flexStart" gutterSize="s">
            <EuiFlexItem grow={false} style={{ marginRight: '2em' }}>
              <EuiSwitch
                label={intl.formatMessage({
                  id:
                    'tableAnnotationItem.tableAnnotation.editTableAnnotation.showTableGrid',
                  defaultMessage: 'Show table grid',
                })}
                checked={showTableGridAnnotation}
                onChange={() => {
                  setShowTableGridAnnotation(!showTableGridAnnotation);
                  setAnnotationItemId(elementAnnotation.id);
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                color="text"
                onClick={handleCloseTable}
                iconType="cross"
                title={intl.formatMessage({
                  id: 'button.close',
                  defaultMessage: 'Close',
                })}
                aria-label="Close Table"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      {deleteAnnotationModal}
    </>
  );
}
