import {
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiPopover,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { useAtom } from 'jotai';
import React, { useContext, useState } from 'react';
import { deleteGridContent } from 'services/tableGrid';
import { invalidateAnnotationContent } from '../../../services/documents';
import { VISIBILITY } from '../../../settings/constants';
import { Translate } from '../../Internationalisation/translate';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useToasts } from '../../Toasts/Toasts';
import { useAnnotationStore } from '../Annotation';
import { annotationItemIdAtom } from '../AnnotationState';
import { ScaledTableGridContext } from './TableGridContainer';
import { getGridSetFromTableGrid } from './utils';

export function TableConfigToggle() {
  const intl = useTranslate();
  const [isPopoverOpen, setPopover] = useState(false);
  const smallContextMenuPopoverId = useGeneratedHtmlId({
    prefix: 'smallContextMenuPopover',
  });

  const { scaledGrid, setScaledGrid } = useContext(ScaledTableGridContext);

  const annotationStore = useAnnotationStore();
  const [annotationItemId] = useAtom(annotationItemIdAtom);
  const annotation = useAnnotationStore((state) => state.annotation);
  const { addToast } = useToasts();

  const onDeleteColumns = () => {
    const filteredTableGridLines = scaledGrid.lines.filter(
      (line) => line.type !== 'column'
    );
    setScaledGrid({ ...scaledGrid, lines: filteredTableGridLines });
    addToast({
      title: intl.formatMessage({
        id: 'tableConfigToggle.message.gridColumnDeleted',
        defaultMessage: 'Grid columns were successfully deleted from UI',
      }),
      color: 'success',
    });
    closePopover();
  };

  const onDeleteRows = () => {
    const filteredTableGridLines = scaledGrid.lines.filter(
      (line) => line.type !== 'row'
    );
    setScaledGrid({ ...scaledGrid, lines: filteredTableGridLines });
    addToast({
      title: intl.formatMessage({
        id: 'tableConfigToggle.message.gridRowsDeleted',
        defaultMessage: 'Grid rows were successfully deleted from UI',
      }),
      color: 'success',
    });
    closePopover();
  };

  // Old delete grid function which deletes only the table grid not it's content.
  // const onDeleteGrid = () => {
  //   annotationStore
  //     .patchAnnotation(annotationItem.id, {
  //       gridset: {
  //         grids: [],
  //       },
  //     })
  //     .then(() => {
  //       addToast({ title: 'Table grid successfully deleted', color: 'success' });
  //       closePopover();
  //     });
  // };

  // Combined function to delete the grid and it's content as well with one button.
  const onDeleteGrid = () => {
    deleteGridContent(annotation.id, annotationItemId, scaledGrid.id)
      .then(() => {
        return annotationStore.patchAnnotationWithoutContentUpdate(annotationItemId, {
          gridset: {
            grids: [],
          },
        });
      })
      .then(() => {
        return invalidateAnnotationContent(annotation.id);
      })
      .then(() => {
        addToast({
          title: intl.formatMessage({
            id: 'tableConfigToggle.message.tableGridSuccessfullyDeleted',
            defaultMessage: 'Table grid successfully deleted',
          }),
          color: 'success',
        });
        closePopover();
      })
      .catch((err) => console.error(err));
  };

  const onSaveGrid = () => {
    const grid = getGridSetFromTableGrid(scaledGrid);
    annotationStore
      .patchAnnotation(annotationItem.id, {
        gridset: {
          grids: [grid],
        },
      })
      .then(() => {
        addToast({
          title: intl.formatMessage({
            id: 'tableConfigToggle.message.tableGridSuccessfullySaved',
            defaultMessage: 'Table grid successfully saved',
          }),
          color: 'success',
        });
        closePopover();
      });
  };

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const items = [
    <EuiContextMenuItem key="save" icon="save" onClick={onSaveGrid}>
      <Translate
        id="tableAnnotationConfig.mainTable.saveGrid.title"
        defaultMessage="Save grid"
      />
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="deleteRows"
      icon="trash"
      onClick={onDeleteRows}
      toolTipContent={intl.formatMessage({
        id: 'tableAnnotationConfig.mainTable.deleteRows.tooltip',
        defaultMessage: 'Delete all grid rows ( on UI only)',
      })}
    >
      <Translate
        id="tableAnnotationConfig.mainTable.deleteRows.title"
        defaultMessage="Delete Rows"
      />
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="deleteColumns"
      icon="trash"
      toolTipContent={intl.formatMessage({
        id: 'tableAnnotationConfig.mainTable.deleteColumns.tooltip',
        defaultMessage: 'Delete all grid columns ( on UI only)',
      })}
      onClick={onDeleteColumns}
    >
      <Translate
        id="tableAnnotationConfig.mainTable.deleteColumns.title"
        defaultMessage="Delete Columns"
      />
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="deleteGrid"
      icon="trash"
      tool
      onClick={onDeleteGrid}
      toolTipContent={intl.formatMessage({
        id: 'tableAnnotationConfig.mainTable.deleteGrid.tooltip',
        defaultMessage: 'Delete grid with updating the annotation',
      })}
    >
      <Translate
        id="tableAnnotationConfig.mainTable.deleteGrid.title"
        defaultMessage="Delete Grid"
      />
    </EuiContextMenuItem>,
    // Hidden button for deleting table grid content.
    // <EuiContextMenuItem
    //   key="deleteGridContent"
    //   icon="trash"
    //   tool
    //   onClick={onDeleteGrid}
    //   toolTipContent={intl.formatMessage({
    //     id: 'tableAnnotationConfig.mainTable.deleteGridContent.tooltip',
    //     defaultMessage: 'Delete grid content with all datapoint boxes',
    //   })}
    // >
    //   <Translate
    //     id="tableAnnotationConfig.mainTable.deleteGridContent.title"
    //     defaultMessage="Delete Grid Content"
    //   />
    // </EuiContextMenuItem>,
  ];

  const button = (
    <EuiButtonIcon
      iconType="gear"
      title={intl.formatMessage({
        id: 'tableConfigToggle.button.toggleTableConfigs',
        defaultMessage: 'Toggle Table configs',
      })}
      aria-label="Toggle table configs"
      iconSize="l"
      onClick={onButtonClick}
    />
  );

  return (
    <EuiPopover
      style={{
        position: 'absolute',
        cursor: 'pointer',
        top: '-35px',
        left: '-25px',
        opacity: '90%',
        zIndex: VISIBILITY.POPOVER,
      }}
      id={smallContextMenuPopoverId}
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenuPanel size="s" items={items} />
    </EuiPopover>
  );
}
