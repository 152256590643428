import {
  EuiButtonIcon,
  EuiCollapsibleNav,
  EuiCollapsibleNavGroup,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiIconTip,
  EuiShowFor,
  EuiSwitch,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { isEmpty } from 'lodash';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { RoutePath } from 'routes';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { expandedSideNavItemsAtom, FoldersNavigation, useFoldersTree } from './Folders';
import { SideBarFolderSearch } from './SideBarFolderSearch';

const sidebarGroup = {
  MENU: 'Menu',
  FOLDERS: 'Folders',
  SIDEBAR: 'Sidebar',
};

const sidebarConfigAtom = atomWithStorage('sidebarConfig', {
  width: '250px',
  isOpenOutsideAnnotationPage: true,
  isDockedOutsideAnnotationPage: true,
  isOpenInAnnotationPage: false,
  isDockedInAnnotationPage: false,
  openGroups: [sidebarGroup.MENU, sidebarGroup.FOLDERS],
});

export function useSidebarConfig() {
  const [sidebarConfig, setSidebarConfig] = useAtom(sidebarConfigAtom);
  const location = useLocation();

  const isAnnotationPage = !!matchPath(
    { path: `${RoutePath.FOLDER}/${RoutePath.ANNOTATIONS}` },
    location.pathname
  );
  const isSidebarOpen = isAnnotationPage
    ? sidebarConfig.isOpenInAnnotationPage
    : sidebarConfig.isOpenOutsideAnnotationPage;
  const isSidebarDocked = isAnnotationPage
    ? sidebarConfig.isDockedInAnnotationPage
    : sidebarConfig.isDockedOutsideAnnotationPage;

  function toggleIsSidebarOpen() {
    if (isAnnotationPage) {
      setSidebarConfig({
        ...sidebarConfig,
        isOpenInAnnotationPage: !sidebarConfig.isOpenInAnnotationPage,
      });
    } else {
      setSidebarConfig({
        ...sidebarConfig,
        isOpenOutsideAnnotationPage: !sidebarConfig.isOpenOutsideAnnotationPage,
      });
    }
  }

  function toggleGroupAccordion(isOpen: boolean, group?: string) {
    if (!group) return;
    const itExists = sidebarConfig.openGroups.includes(group);
    if (isOpen) {
      if (itExists) return;
      sidebarConfig.openGroups.push(group);
    } else {
      const index = sidebarConfig.openGroups.indexOf(group);
      if (index > -1) {
        sidebarConfig.openGroups.splice(index, 1);
      }
    }
    setSidebarConfig({ ...sidebarConfig, openGroups: [...sidebarConfig.openGroups] });
  }

  return {
    sidebarConfig: sidebarConfig,
    setSidebarConfig: setSidebarConfig,
    isSidebarOpen: isSidebarOpen,
    isSidebarDocked: isSidebarDocked,
    toggleIsSidebarOpen: toggleIsSidebarOpen,
    toggleGroupAccordion: toggleGroupAccordion,
  };
}

export function Sidebar() {
  const intl = useTranslate();
  const {
    sidebarConfig,
    setSidebarConfig,
    isSidebarOpen,
    isSidebarDocked,
    toggleIsSidebarOpen,
    toggleGroupAccordion,
  } = useSidebarConfig();

  const collapsibleNavId = useGeneratedHtmlId({ prefix: 'collapsibleNav' });
  const { toggleCollapseTree } = useFoldersTree();
  const [expandedSideNavItems] = useAtom(expandedSideNavItemsAtom);

  const onCollapseClick = () => {
    toggleCollapseTree();
  };

  const sidebarConfigGroup = (
    <EuiCollapsibleNavGroup
      title={
        <a className="eui-textInheritColor">
          <Translate id="sidebar.sidebar" defaultMessage="Sidebar" />
        </a>
      }
      // To align the border with top border of bottom bar on annotation page
      style={{ paddingTop: '1px' }}
      iconProps={{ color: 'text' }}
      iconSize="m"
      titleSize="xxs"
      paddingSize="none"
      isCollapsible={true}
      initialIsOpen={sidebarConfig.openGroups.includes(sidebarGroup.SIDEBAR)}
      onToggle={(isOpen: boolean) => toggleGroupAccordion(isOpen, sidebarGroup.SIDEBAR)}
    >
      <EuiFlexGroup direction="column" gutterSize="m" style={{ margin: '0px 4px' }}>
        {/* Docking button only for larger screens that can support it*/}
        <EuiShowFor sizes={['l', 'xl']}>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiSwitch
                  label={intl.formatMessage({
                    id: 'sidebar.keepSidebarVisible',
                    defaultMessage: 'Keep sidebar visible outside annotation page',
                  })}
                  // compressed
                  checked={sidebarConfig.isDockedOutsideAnnotationPage}
                  onChange={(event) =>
                    setSidebarConfig({
                      ...sidebarConfig,
                      isDockedOutsideAnnotationPage: !sidebarConfig.isDockedOutsideAnnotationPage,
                      isOpenOutsideAnnotationPage: event.target.checked ? true : false,
                    })
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiIconTip
                  position="top"
                  content={
                    <p>
                      <Translate
                        id="sidebar.sidebarEnableDescription"
                        defaultMessage={`
                       If enabled, the sidebar is always visible. If disabled, the sidebar is only temporarily shown when clicking the menu icon. Disabling provides more screen space, e.g. on smaller screens or during annotation.
                        `}
                      />
                    </p>
                  }
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiSwitch
              label={intl.formatMessage({
                id: 'sidebar.visibleDescription',
                defaultMessage: 'Keep sidebar visible on annotation page',
              })}
              checked={sidebarConfig.isDockedInAnnotationPage}
              onChange={(event) => {
                setSidebarConfig({
                  ...sidebarConfig,
                  isDockedInAnnotationPage: !sidebarConfig.isDockedInAnnotationPage,
                  isOpenInAnnotationPage: event.target.checked ? true : false,
                });
              }}
            />
          </EuiFlexItem>
        </EuiShowFor>
        <EuiFlexItem style={{ maxWidth: '130px' }}>
          <EuiFormRow
            label={intl.formatMessage({ id: 'sidebar.width', defaultMessage: 'Width' })}
          >
            <EuiFieldNumber
              min={100}
              max={600}
              step={10}
              title={intl.formatMessage({
                id: 'sidebar.sidebarWidth',
                defaultMessage: 'Sidebar width',
              })}
              append="px"
              value={parseInt(sidebarConfig.width, 10)}
              onChange={(e) => {
                setSidebarConfig({
                  ...sidebarConfig,
                  width: `${e.target.value}px`,
                });
              }}
              aria-label="Sidebar width"
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiCollapsibleNavGroup>
  );

  const collapsibleNav = (
    <EuiCollapsibleNav
      id={collapsibleNavId}
      aria-label="Navigation menu"
      isOpen={isSidebarOpen}
      isDocked={isSidebarDocked && isSidebarOpen}
      showButtonIfDocked
      size={sidebarConfig.width}
      side="left"
      closeButtonPosition="outside"
      hideCloseButton={true}
      button={
        <EuiHeaderSectionItemButton
          aria-label="Toggle navigation menu"
          onClick={toggleIsSidebarOpen}
        >
          <EuiIcon
            type="menu"
            title={
              isSidebarOpen
                ? intl.formatMessage({
                    id: 'sidebar.hideSidebar',
                    defaultMessage: 'Hide sidebar',
                  })
                : intl.formatMessage({
                    id: 'sidebar.showSidebar',
                    defaultMessage: 'Show sidebar',
                  })
            }
            size="m"
            aria-hidden="true"
          />
        </EuiHeaderSectionItemButton>
      }
      onClose={toggleIsSidebarOpen}
    >
      <EuiFlexGroup
        gutterSize="xs"
        direction="column"
        style={{ height: '100%', padding: '5px' }}
      >
        <EuiFlexItem grow={false} style={{ paddingLeft: '8px' }}>
          <EuiFlexGroup gutterSize={'xs'}>
            <EuiFlexItem style={{ alignSelf: 'center' }}>
              <EuiTitle size="xs">
                <h3>
                  <Translate id="sidebar.folders" defaultMessage="Folders" />
                </h3>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType={isEmpty(expandedSideNavItems) ? 'arrowRight' : 'arrowDown'}
                color="text"
                aria-label="Options"
                size="s"
                iconSize="m"
                title={intl.formatMessage({
                  id: 'sidebar.button.toggleCollapse',
                  defaultMessage: 'Expand/collapse all folders',
                })}
                onClick={onCollapseClick}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <SideBarFolderSearch />
        </EuiFlexItem>
        <EuiFlexItem className="eui-yScroll">
          <FoldersNavigation />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {/* Span fakes the nav group into not being the first item and therefore adding a top border */}
          <span />
          {sidebarConfigGroup}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiCollapsibleNav>
  );

  return collapsibleNav;
}
