import { EuiButtonEmpty, EuiComboBox, EuiHighlight } from '@elastic/eui';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetSchemas } from '../../../services/documents';
import { VISIBILITY } from '../../../settings/constants';
import { InLineLoader } from '../../InlineLoader/InlineLoader';
import { useTranslate } from '../../Internationalisation/useTranslate';

export function SchemaSelector({
  defaultSchemaId,
  onChange,
  compressed = true,
  includeParentFolders = false,
  isDisabled = false,
  addNullOption = false,
  hasLink = false,
  folderDefaultSchemaId,
}) {
  const { data: schemas, isLoading } = useGetSchemas(includeParentFolders);

  const [options, setOptions] = useState([]);
  const [selectedSchemas, setSelectedSchemas] = useState([]);
  const intl = useTranslate();
  const { folderId: selectedFolderId } = useParams();

  useEffect(() => {
    if (selectedSchemas.length === 1) {
      onChange(selectedSchemas[0].value);
    }
  }, [selectedSchemas]);

  useEffect(() => {
    const parentPrefix = '↑ ';
    const defaultSKillPrefix = '★';
    if (schemas) {
      const defaultSchema =
        folderDefaultSchemaId &&
        schemas.results.find((schema) => schema.id === folderDefaultSchemaId);
      let defaultSchemaOption;
      let restSchemas = schemas.results;
      if (defaultSchema) {
        defaultSchemaOption = {
          label: `${defaultSKillPrefix}${defaultSchema.name} (${intl.asDateTime(
            defaultSchema.created_at
          )}, ${defaultSchema.created_by})`,
          value: defaultSchema.id,
          key: defaultSchema.id,
        };
        restSchemas = schemas.results.filter(
          (schema) => schema.id !== folderDefaultSchemaId
        );
      }

      const options = restSchemas.map((value) => ({
        label: `${value.folder_id !== selectedFolderId ? parentPrefix : ''}${
          value.name
        } (${intl.asDateTime(value.created_at)}, ${value.created_by})`,
        value: value.id,
        key: value.id,
        folderId: value.folder_id,
      }));
      options.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (
          nameA > nameB ||
          (nameA.startsWith(parentPrefix) && !nameB.startsWith(parentPrefix))
        ) {
          return 1;
        }
        if (
          nameA < nameB ||
          (!nameA.startsWith(parentPrefix) && nameB.startsWith(parentPrefix))
        ) {
          return -1;
        }
        return 0;
      });
      if (addNullOption) {
        options.unshift({ label: '-', value: null, key: '-' });
      }
      if (defaultSchemaOption) {
        options.unshift(defaultSchemaOption);
      }
      setOptions(options);
    }
  }, [schemas]);

  useEffect(() => {
    if (options) {
      setSelectedSchemas(options.filter((option) => option.value === defaultSchemaId));
    }
  }, [options, defaultSchemaId]);

  if (isLoading) return <InLineLoader />;

  const renderOption = (option, searchValue) => {
    const { label } = option;
    return (
      <>
        <span style={{ zIndex: VISIBILITY.SCHEMA_SELECTOR_OPTION }}>
          <EuiHighlight search={searchValue}>{label.toString()}</EuiHighlight>
        </span>
      </>
    );
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <EuiComboBox
        fullWidth
        placeholder={intl.formatMessage({
          id: 'schemaSelector.selectSkill',
          defaultMessage: 'Select skill',
        })}
        singleSelection={{ asPlainText: true }}
        sortMatchesBy="startsWith"
        options={options}
        compressed={compressed}
        isClearable={false}
        selectedOptions={selectedSchemas}
        renderOption={renderOption}
        onChange={setSelectedSchemas}
        isDisabled={isDisabled}
        style={{ width: hasLink ? '90%' : '100%', marginRight: '5px' }}
      />
      {hasLink && selectedSchemas.length > 0 && selectedSchemas[0].folderId && (
        <Link
          isActive={hasLink && selectedSchemas.length > 0}
          to={`/folders/${selectedSchemas[0].folderId}/manage/skills/${selectedSchemas[0].value}`}
          style={{ width: '10%' }}
        >
          <EuiButtonEmpty
            title={intl.formatMessage({
              id: 'schemaSelector.button.goToSkill',
              defaultMessage: 'Go to skill configuration',
            })}
            color="text"
            iconType="symlink"
          ></EuiButtonEmpty>
        </Link>
      )}
    </div>
  );
}
