import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHideFor,
} from '@elastic/eui';
// import { ReactComponent as LogoFull } from 'assets/images/logo-full.svg';
// import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { Sidebar } from 'components/Folders/Sidebar';
import Search from 'components/Search/Search';
import { useAtom } from 'jotai';
import React from 'react';
import { Link } from 'react-router-dom';
import { THEME, THEME_MODE } from 'settings/constants';
import { ThemeMode } from 'settings/ThemeMode';
import { putAnnotationStatus } from '../../services/annotations';
import { invalidateAnnotation, invalidateAnnotations } from '../../services/documents';
import {
  currentReviewAnnotation,
  reviewDocumentsNumberAtom,
  reviewDocumentsQueryStringAtom,
} from '../Annotation/AnnotationState';
import { Translate } from '../Internationalisation/translate';
import { useTranslate } from '../Internationalisation/useTranslate';
import { HeaderFoldersNavigation } from './HeaderFoldersNavigation';
import { HeaderUserMenu } from './HeaderUserMenu';

function HeaderLogo() {
  const color =
    THEME_MODE === ThemeMode.dark ? THEME.euiTextColor : THEME.euiColorPrimary;

  return (
    <Link to={'/'}>
      <EuiFlexGroup
        alignItems="center"
        responsive={false}
        justifyContent="center"
        gutterSize="none"
        style={{ margin: '0 16px 0 8px' }}
      >
        {/* <EuiFlexItem grow={false}>
          <EuiShowFor sizes={['xs', 's']}>
            <EuiIcon
              style={{
                color: color,
                marginLeft: '8px',
              }}
              type={Logo}
              title="IDP"
              size="m"
            />
          </EuiShowFor>
          <EuiHideFor sizes={['xs', 's']}>
            <EuiIcon
              style={{
                color: color,
                width: '70px',
                marginLeft: '8px',
              }}
              type={LogoFull}
              title="IDP"
              size="m"
            />
          </EuiHideFor>
        </EuiFlexItem> */}
        <EuiHideFor sizes={['xs', 's', 'm', 'l']}>
          <EuiFlexItem
            grow
            style={{
              padding: '0 0 0 6px',
            }}
          >
            <span
              style={{
                fontWeight: 600,
                color: color,
              }}
            >
              <Translate
                id="header.documentIntelligence"
                defaultMessage="Intelligent Document Processing"
              />
            </span>
          </EuiFlexItem>
        </EuiHideFor>
      </EuiFlexGroup>
    </Link>
  );
}

export default function Header() {
  const [reviewDocumentsNumber, setReviewDocumentsNumber] = useAtom(
    reviewDocumentsNumberAtom
  );
  const [, setReviewDocumentsQueryString] = useAtom(reviewDocumentsQueryStringAtom);
  const [reviewAnnotation, setCurrentReviewAnnotation] = useAtom(
    currentReviewAnnotation
  );
  const intl = useTranslate();

  const clearReviewDocuments = () => {
    setReviewDocumentsNumber(undefined);
    setReviewDocumentsQueryString(undefined);
    if (reviewAnnotation) {
      // @ts-ignore
      putAnnotationStatus(reviewAnnotation.annotationId, 'to_review')
        .then(() => invalidateAnnotations(reviewAnnotation.documentId))
        .then(() => invalidateAnnotation(reviewAnnotation.annotationId))
        .then(() => {
          setCurrentReviewAnnotation(undefined);
        });
    }
  };

  return (
    <>
      <EuiHeader
        position="fixed"
        style={{ padding: '0em 0.2em 0em 0.2em' }}
        sections={[
          {
            items: [
              // <EuiButtonIcon
              //   color="text"
              //   title="Go back"
              //   aria-label="Go back"
              //   size="s"
              //   iconType="arrowLeft"
              //   onClick={history.goBack}
              // />,
              // <HeaderAppMenu />,
              <Sidebar />,
              <HeaderLogo />,
              <EuiHideFor sizes={['xs', 's', 'm']}>
                <HeaderFoldersNavigation />
              </EuiHideFor>,
            ],
            borders: 'none',
          },
          {
            items: [],
            borders: 'none',
          },
          {
            items: [
              <>
                {reviewDocumentsNumber && (
                  <EuiBadge
                    iconType="cross"
                    iconSide="right"
                    // TODO: Exit review mode (ie. also revert annotation status)
                    iconOnClick={clearReviewDocuments}
                    iconOnClickAriaLabel={intl.formatMessage({
                      id: 'header.stopReviewMode',
                      defaultMessage: 'Exit review mode',
                    })}
                    color="primary"
                    style={{ marginRight: '16px' }}
                  >
                    <Translate
                      id="header.documentsToReview"
                      defaultMessage="Documents to review: {reviewDocumentsNumber}"
                      values={{
                        reviewDocumentsNumber,
                      }}
                    />
                  </EuiBadge>
                )}
              </>,
              <Search />,
              <HeaderUserMenu />,
            ],
            borders: 'none',
          },
        ]}
      />
    </>
  );
}
