import { EuiButtonIcon, EuiIcon, useEuiTheme } from '@elastic/eui';
import { useAtom } from 'jotai';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Rnd } from 'react-rnd';
import { updateGridContent } from 'services/tableGrid';
import { ulid } from 'ulid';
import { Can, PERMISSION } from 'utils/permissions';
import { invalidateAnnotationContent } from '../../../services/documents';
import { useTranslate } from '../../Internationalisation/useTranslate';
import { useToasts } from '../../Toasts/Toasts';
import { useAnnotationStore } from '../Annotation';
import { annotationItemIdAtom } from '../AnnotationState';
import { pageScaleAtom } from '../DocumentPanel/ImageBrowser';
import { ColumnConfigToggle } from './ColumnConfigToggle';
import { RowConfigToggle } from './RowConfigToggle';
import { TableConfigToggle } from './TableConfigToggle';
import { TableGrid } from './TableGrid';
import { ScaledTableGridContext } from './TableGridContainer';
import { getGridSetFromTableGrid, sortTableGridLines } from './utils';

export function MainTableGrid({
  pageWidth,
  pageHeight,
  columnSchemasOptions,
  detectGrid,
  annotationId,
  annotationItem,
}) {
  const { euiTheme } = useEuiTheme();
  const [scale] = useAtom(pageScaleAtom);
  const [annotationItemId] = useAtom(annotationItemIdAtom);
  const annotationStore = useAnnotationStore();
  const { addToast } = useToasts();
  const { scaledGrid, setScaledGrid, setShowMainTableAnnotation } = useContext(
    ScaledTableGridContext
  );

  const [rectSize, setRectSize] = useState({
    width: scaledGrid.table.width / scale,
    height: scaledGrid.table.height / scale,
    coordinates: {
      x: scaledGrid.table.coordinates.x / scale,
      y: scaledGrid.table.coordinates.y / scale,
    },
  });

  const [showColumnPanel, setShowColumnPanel] = useState(false);
  const [showRowPanel, setShowRowPanel] = useState(false);
  const [columnButtonXPosition, setColumnButtonXPosition] = useState(0);
  const [rowButtonYPosition, setRowButtonYPosition] = useState(0);
  const [highlightedLine, setHighlightedLine] = useState(undefined);
  const [deleteColumnButtonXPosition, setDeleteColumnButtonXPosition] = useState(
    undefined
  );
  const [deleteRowButtonYPosition, setDeleteRowButtonYPosition] = useState(undefined);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showColumnAddButton, setShowColumnAddButton] = useState(true);
  const [highlightedArea, setHighlightedArea] = useState(undefined);
  const [showRowConfigIconLine, setShowRowConfigIconLine] = useState(undefined);
  const [showProgressButtons, setShowProgressButtons] = useState([]);
  const [resizeStart, setResizeStart] = useState(false);

  const tableRef = useRef(null);
  const rndRef = useRef(null);
  const containerRef = useRef(null);
  const intl = useTranslate();

  const getUpdateOriginalRectSize = () => {
    return {
      width: scaledGrid.table.width / scale,
      height: scaledGrid.table.height / scale,
      coordinates: {
        x: scaledGrid.table.coordinates.x / scale,
        y: scaledGrid.table.coordinates.y / scale,
      },
    };
  };

  const getUpdateGridLines = () => {
    return scaledGrid.lines.map((gridLine) => {
      if (gridLine.type === 'row') {
        const newCoordinates = {
          to: {
            x: scaledGrid.table.width,
            y: (gridLine.to.y / gridLine.scale) * scale,
          },
          from: { x: 0, y: (gridLine.from.y / gridLine.scale) * scale },
        };
        return {
          ...gridLine,
          ...newCoordinates,
          scale,
        };
      } else {
        const newCoordinates = {
          to: {
            x: (gridLine.to.x / gridLine.scale) * scale,
            y: scaledGrid.table.height,
          },
          from: { x: (gridLine.from.x / gridLine.scale) * scale, y: 0 },
        };
        return { ...gridLine, ...newCoordinates, scale };
      }
    });
  };

  useEffect(() => {
    if (resizeStart) {
      setShowColumnPanel(false);
    } else {
      setShowColumnPanel(true);
    }
  }, [resizeStart]);

  useEffect(() => {
    const updatedGridLines = getUpdateGridLines();
    const updatedOriginalRectSize = getUpdateOriginalRectSize();

    setScaledGrid({ ...scaledGrid, lines: updatedGridLines, scale });
    setRectSize(updatedOriginalRectSize);
  }, [scaledGrid.table]);

  useEffect(() => {
    const updatedScaledRectSize = {
      width: rectSize.width * scale,
      height: rectSize.height * scale,
      coordinates: {
        x: rectSize.coordinates.x * scale,
        y: rectSize.coordinates.y * scale,
      },
    };

    if (rndRef) {
      rndRef.current.updatePosition({
        x: updatedScaledRectSize.coordinates.x,
        y: updatedScaledRectSize.coordinates.y,
      });
      rndRef.current.updateSize({
        width: updatedScaledRectSize.width,
        height: updatedScaledRectSize.height,
      });
    }

    setScaledGrid({
      ...scaledGrid,
      table: updatedScaledRectSize,
      scale,
      containerWidth: pageWidth,
      containerHeight: pageHeight,
    });
  }, [scale]);

  const renderColumnPositionIcons = () => {
    const onMouseEnter = (line) => {
      setShowColumnAddButton(false);
      setDeleteColumnButtonXPosition(line.from.x - 12);
      setHighlightedLine(line);
    };

    return scaledGrid.lines
      .filter((line) => line.type === 'column' && line.from.x <= scaledGrid.table.width)
      .map((line, index) => {
        return (
          <EuiIcon
            onMouseEnter={() => onMouseEnter(line)}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              top: '2',
              left: `${line.from.x - 5}px`,
            }}
            type="grabHorizontal"
            size="s"
            key={index}
          />
        );
      });
  };

  const deleteLine = () => {
    const filteredGridLines = scaledGrid.lines.filter(
      (gridLine) => gridLine.id !== highlightedLine.id
    );
    setScaledGrid({ ...scaledGrid, lines: filteredGridLines });
    hideDeleteButton();
  };

  const renderColumnConfigIcons = useCallback(() => {
    const onMouseEnter = (line) => {
      setShowColumnAddButton(false);
      const columnLines = scaledGrid.lines.filter(
        (line) => line.type === 'column' && line.from.x <= scaledGrid.table.width
      );
      const lineIndex = columnLines.findIndex(
        (columnLine) => columnLine.id === line.id
      );
      const nextLine = columnLines[lineIndex + 1];
      if (nextLine) {
        setHighlightedArea({
          width: nextLine.from.x - line.from.x,
          height: scaledGrid.table.height,
          x: line.from.x,
          y: line.from.y,
        });
      } else {
        setHighlightedArea({
          width: scaledGrid.table.width - line.from.x,
          height: scaledGrid.table.height,
          x: line.from.x,
          y: line.from.y,
        });
      }
    };

    const onMouseLeave = () => {
      setShowColumnAddButton(true);
      setHighlightedArea(undefined);
    };

    return scaledGrid.lines
      .filter((line) => line.type === 'column' && line.from.x <= scaledGrid.table.width)
      .map((line, index) => {
        return (
          <ColumnConfigToggle
            onEnter={onMouseEnter}
            onLeave={onMouseLeave}
            line={line}
            key={index}
            columnSchemasOptions={columnSchemasOptions}
          />
        );
      });
  }, [scaledGrid]);

  const renderRowConfigIcons = () => {
    const onMouseEnter = (line) => {
      setShowAddButton(false);
      const rowLines = scaledGrid.lines.filter((line) => line.type === 'row');
      const lineIndex = rowLines.findIndex((rowLine) => rowLine.id === line.id);
      const nextLine = rowLines[lineIndex + 1];
      if (nextLine) {
        setHighlightedArea({
          width: scaledGrid.table.width,
          height: nextLine.from.y - line.from.y,
          x: line.from.x,
          y: line.from.y,
        });
      } else {
        setHighlightedArea({
          height: scaledGrid.table.height - line.from.y,
          width: scaledGrid.table.width,
          x: line.from.x,
          y: line.from.y,
        });
      }
    };

    const onMouseLeave = (hideIcon) => {
      if (hideIcon) {
        setShowRowConfigIconLine(undefined);
      }
      setShowAddButton(true);
      setHighlightedArea(undefined);
    };

    return (
      <RowConfigToggle
        onEnter={onMouseEnter}
        onLeave={onMouseLeave}
        line={showRowConfigIconLine}
        columnSchemasOptions={columnSchemasOptions}
      />
    );
  };

  const renderRowPositionIcons = () => {
    const onMouseEnter = (line) => {
      setShowAddButton(false);
      setShowRowConfigIconLine(line);
      setDeleteRowButtonYPosition(line.from.y - 13);
      setHighlightedLine(line);
    };

    return scaledGrid.lines
      .filter((line) => line.type === 'row')
      .map((line, index) => {
        return (
          <EuiIcon
            onMouseEnter={() => onMouseEnter(line)}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              top: `${line.from.y - 5}px`,
              left: '2',
            }}
            type="grab"
            title={intl.formatMessage({
              id: 'mainTableGrid.button.grab',
              defaultMessage: 'Grab',
            })}
            size="s"
            key={index}
          />
        );
      });
  };

  const hideDeleteButton = (type) => {
    setDeleteColumnButtonXPosition(undefined);
    setDeleteRowButtonYPosition(undefined);
    if (type === 'column') {
      setShowColumnAddButton(true);
    } else {
      setShowAddButton(true);
    }
    setHighlightedLine(undefined);
  };

  const extractGrid = () => {
    const grid = getGridSetFromTableGrid(scaledGrid);
    setShowProgressButtons([...showProgressButtons, 'extract']);
    // TODO: filter by id  when multiple grid would be enabled on one page
    const restGrids =
      annotationItem.gridset.grids.filter(
        (annotationGrid) => annotationGrid.page_index !== grid.page_index
      ) || [];
    annotationStore
      .patchAnnotationWithoutContentUpdate(annotationItemId, {
        gridset: {
          grids: [...restGrids, grid],
        },
      })
      .then(() => {
        return updateGridContent(annotationId, annotationItemId, grid.id);
      })
      .then(() => invalidateAnnotationContent(annotationId))
      .then(() => {
        const filteredProgressButtons = showProgressButtons.filter(
          (button) => button === 'extract'
        );
        setShowProgressButtons(filteredProgressButtons);
        addToast({
          title: intl.formatMessage({
            id: 'mainTableGrid.message.tableSuccessfullyExtracted',
            defaultMessage: 'Table successfully extracted',
          }),
          color: 'success',
        });
      })
      .catch((err) => console.log(err));
  };

  const detectGridContent = () => {
    setShowProgressButtons([...showProgressButtons, 'detect']);
    detectGrid()
      .then(() => {
        const filteredProgressButtons = showProgressButtons.filter(
          (button) => button === 'detect'
        );
        setShowProgressButtons(filteredProgressButtons);
        addToast({
          title: intl.formatMessage({
            id: 'mainTableGrid.message.tableSuccessfullyDetected',
            defaultMessage: 'Table successfully detected',
          }),
          color: 'success',
        });
      })
      .catch((err) => console.log(err));
  };

  const onResize = (e, direction, ref) => {
    setScaledGrid({
      ...scaledGrid,
      scale,
      table: {
        coordinates: {
          x:
            tableRef.current.getBoundingClientRect().x -
            containerRef.current.getBoundingClientRect().left,
          y:
            tableRef.current.getBoundingClientRect().y -
            containerRef.current.getBoundingClientRect().top,
        },
        width: Number(ref.style.width.replace('px', '')),
        height: Number(ref.style.height.replace('px', '')),
      },
    });
  };

  const onDrag = () => {
    const table = {
      ...scaledGrid.table,
      coordinates: {
        x:
          tableRef.current.getBoundingClientRect().x -
          containerRef.current.getBoundingClientRect().left,
        y:
          tableRef.current.getBoundingClientRect().y -
          containerRef.current.getBoundingClientRect().top,
      },
    };
    setScaledGrid({ ...scaledGrid, table });
  };

  const onColumnPanelMouseOver = (event) => {
    let updatedXMousePosition;
    const mouseXPosition = event.pageX - tableRef.current.getBoundingClientRect().left;

    if (mouseXPosition > scaledGrid.table.width) {
      updatedXMousePosition = scaledGrid.table.width;
    } else if (mouseXPosition < 0) {
      updatedXMousePosition = 0;
    } else {
      updatedXMousePosition = mouseXPosition;
    }

    setColumnButtonXPosition(updatedXMousePosition - 15);
  };

  const onRowPanelMouseOver = (event) => {
    let updatedYMousePosition;
    const mouseYPosition = event.pageY - tableRef.current.getBoundingClientRect().top;

    if (mouseYPosition > scaledGrid.table.height) {
      updatedYMousePosition = scaledGrid.table.height;
    } else if (mouseYPosition < 0) {
      updatedYMousePosition = 0;
    } else {
      updatedYMousePosition = mouseYPosition;
    }

    setRowButtonYPosition(updatedYMousePosition - 15);
  };

  const createColumn = (columnXCoordinate) => {
    const from = { x: columnXCoordinate + 10, y: 0 };
    const to = { x: columnXCoordinate + 10, y: scaledGrid.table.height };
    setScaledGrid({
      ...scaledGrid,
      lines: sortTableGridLines([
        ...scaledGrid.lines,
        { from, to, id: ulid(), type: 'column', scale },
      ]),
    });
  };

  const createRow = (rowYCoordinate) => {
    const from = { x: 0, y: rowYCoordinate + 10 };
    const to = { x: scaledGrid.table.width, y: rowYCoordinate + 10 };

    setScaledGrid({
      ...scaledGrid,
      lines: sortTableGridLines([
        ...scaledGrid.lines,
        { from, to, id: ulid(), type: 'row', scale },
      ]),
    });
  };

  const hasSchemaSelected = useMemo(() => {
    return scaledGrid.lines.some((line) => {
      return line.schemaElementId;
    });
  }, [scaledGrid.lines]);

  const tableGridActionButtons = (
    <>
      <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
        <EuiButtonIcon
          display="fill"
          color="primary"
          iconType="check"
          size="s"
          title={
            hasSchemaSelected
              ? intl.formatMessage({
                  id: 'tableAnnotationConfig.mainTable.extractContent',
                  defaultMessage: 'Extract content',
                })
              : intl.formatMessage({
                  id: 'tableAnnotationConfig.mainTable.title.defineColumnSchema',
                  defaultMessage: 'Define column skill element first',
                })
          }
          style={{
            position: 'absolute',
            top: '40px',
            right: '-40px',
          }}
          isLoading={showProgressButtons.includes('extract')}
          disabled={!hasSchemaSelected}
          onClick={extractGrid}
        ></EuiButtonIcon>
        <EuiButtonIcon
          color="primary"
          iconType="grid"
          size="s"
          display="fill"
          title={intl.formatMessage({
            id: 'tableAnnotationConfig.mainTable.detectGrid',
            defaultMessage: 'Detect grid',
          })}
          style={{
            position: 'absolute',
            top: '80px',
            right: '-40px',
          }}
          isLoading={showProgressButtons.includes('detect')}
          onClick={detectGridContent}
        ></EuiButtonIcon>
      </Can>
      <EuiButtonIcon
        color="primary"
        iconType="cross"
        size="s"
        display="fill"
        title={intl.formatMessage({
          id: 'tableAnnotationConfig.mainTable.cancel',
          defaultMessage: 'Cancel',
        })}
        style={{ position: 'absolute', top: '120px', right: '-40px' }}
        onClick={() => {
          setScaledGrid(undefined);
          setShowMainTableAnnotation(false);
        }}
      ></EuiButtonIcon>
    </>
  );

  const tableGridColumnPanel = (
    <div
      onMouseEnter={() => setShowColumnAddButton(true)}
      onMouseMove={onColumnPanelMouseOver}
      onMouseLeave={() => {
        setShowColumnAddButton(false);
      }}
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        left: '-1px',
        top: '-30px',
        height: '30px',
        width: `${scaledGrid.table.width}px`,
      }}
    >
      <div
        style={{
          position: 'relative',
          height: '17px',
          borderRadius: '5px 5px 0 0',
          width: `${scaledGrid.table.width}px`,
          backgroundColor: euiTheme.colors.primary,
        }}
      >
        {showColumnAddButton && (
          <EuiIcon
            style={{
              position: 'absolute',
              cursor: 'pointer',
              top: '-3px',
              left: `${columnButtonXPosition}px`,
            }}
            type="plusInCircleFilled"
            size="l"
            onClick={() => createColumn(columnButtonXPosition)}
          />
        )}
        {renderColumnPositionIcons()}
        {renderColumnConfigIcons(scaledGrid.lines)}
        {deleteColumnButtonXPosition && (
          <EuiIcon
            onMouseLeave={() => hideDeleteButton('column')}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              top: '-3px',
              left: `${deleteColumnButtonXPosition + 2}px`,
            }}
            type="minusInCircleFilled"
            color="danger"
            size="l"
            onClick={deleteLine}
          />
        )}
      </div>
    </div>
  );

  const tableGridRowPanel = (
    <div
      onMouseOver={() => setShowRowPanel(true)}
      onMouseMove={onRowPanelMouseOver}
      onMouseLeave={() => setShowRowPanel(false)}
      style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'flex-end',
        top: '-1px',
        left: '-30px',
        width: '30px',
        height: `${scaledGrid.table.height}px`,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '17px',
          borderRadius: '5px 0 0 5px',
          height: `${scaledGrid.table.height}px`,
          backgroundColor: showRowPanel ? euiTheme.colors.primary : 'transparent',
        }}
      >
        {showRowPanel && showAddButton && (
          <EuiIcon
            style={{
              position: 'absolute',
              cursor: 'pointer',
              top: `${rowButtonYPosition}px`,
              left: '-2px',
            }}
            type="plusInCircleFilled"
            size="l"
            onClick={() => createRow(rowButtonYPosition)}
          />
        )}
        {showRowPanel && renderRowPositionIcons()}

        {showRowPanel && showRowConfigIconLine && renderRowConfigIcons()}
        {deleteRowButtonYPosition && (
          <EuiIcon
            onMouseLeave={() => hideDeleteButton('row')}
            style={{
              position: 'absolute',
              cursor: 'pointer',
              top: `${deleteRowButtonYPosition + 2}px`,
              left: '-3px',
            }}
            type="minusInCircleFilled"
            color="danger"
            size="l"
            onClick={deleteLine}
          />
        )}
      </div>
    </div>
  );

  return (
    <div
      ref={containerRef}
      style={{
        width: pageWidth,
        height: pageHeight,
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Rnd
        ref={rndRef}
        style={{
          border: `2px solid ${euiTheme.colors.primary}`,
          display: 'block',
          position: 'relative',
        }}
        bounds="parent"
        onResize={onResize}
        onResizeStart={() => setResizeStart(true)}
        onResizeStop={() => setResizeStart(false)}
        onDragStop={onDrag}
        dragHandleClassName={'dragHandleIcon'}
        onMouseEnter={() => !resizeStart && setShowColumnPanel(true)}
        onMouseLeave={() => !resizeStart && setShowColumnPanel(false)}
      >
        <div
          className="container"
          ref={tableRef}
          style={{
            width: `${scaledGrid.table.width}px`,
            height: `${scaledGrid.table.height}px`,
          }}
        >
          <TableGrid
            highlightedLine={highlightedLine}
            setHighlightedLine={setHighlightedLine}
            highlightedArea={highlightedArea}
          />
          {tableGridActionButtons}
          <Can I={PERMISSION.ANNOTATIONS_UPDATE}>
            {showColumnPanel && tableGridColumnPanel}
            {tableGridRowPanel}
            <EuiIcon
              style={{
                position: 'absolute',
                cursor: 'pointer',
                top: '-25px',
                right: '-25px',
                opacity: '90%',
              }}
              type="pinFilled"
              color="primary"
              size="l"
              className={'dragHandleIcon'}
            />
            <TableConfigToggle />
          </Can>
        </div>
      </Rnd>
    </div>
  );
}
