import { documentSplit } from 'components/Annotation/AnnotationState';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo } from 'react';
import { useActivateDatapoint } from '../../AnnotationPanel/useActivateDatapoint';
import { documentClassificationSchemaIds } from '../../DocumentPanel/ImageAnnotation/useDocumentClassification';
import { AnnotationBox } from './AnnotationBox';
export function AnnotationHighlight({ annotation, active, annotationDrawMode }) {
  const { geometry, data } = annotation;
  const red = '#ff6666';
  const blue = '#006bb4';
  const [isDocumentSplit] = useAtom(documentSplit);
  const { activeDatapoint, setActiveDatapointById } = useActivateDatapoint();

  const showMetacontentHighlight = useMemo(() => {
    if (isDocumentSplit) {
      return documentClassificationSchemaIds.includes(data.schema_element_id);
    } else {
      return !documentClassificationSchemaIds.includes(data.schema_element_id);
    }
  }, [isDocumentSplit, annotation]);

  useEffect(() => {
    if (active && activeDatapoint?.id !== annotation.data.id) {
      setActiveDatapointById(annotation.data.id, undefined, false);
    }
  }, [active]);

  const styles = {
    value: {
      borderColor: red,
      backgroundColor: red + '30',
      borderStyle: 'solid',
      activeBorderThickness: '1px',
      unactiveBorderThickness: '0px',
    },
    keyword: {
      borderColor: blue,
      backgroundColor: blue + '20',
      borderStyle: 'solid',
      activeBorderThickness: '1px',
      unactiveBorderThickness: '0px',
    },
    // TODO: Context will be depracated
    context: {
      borderColor: blue,
      backgroundColor: blue + '00',
      borderStyle: 'solid',
      activeBorderThickness: '1px',
      unactiveBorderThickness: '1px',
    },
    column_header: {
      borderColor: blue,
      backgroundColor: blue + '20',
      borderStyle: 'solid',
      activeBorderThickness: '1px',
      unactiveBorderThickness: '1px',
    },
  };
  const style = styles[data.annotation_class];

  return (
    geometry &&
    showMetacontentHighlight &&
    !annotationDrawMode && (
      <AnnotationBox
        key={annotation.data.id}
        geometry={geometry}
        style={{
          outline: active
            ? `${style.borderStyle} ${style.activeBorderThickness} ${style.borderColor}`
            : `${style.borderStyle} ${style.unactiveBorderThickness}  ${style.borderColor}`,
          background: style.backgroundColor,
        }}
      />
    )
  );
}
