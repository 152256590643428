import { EuiBasicTable, EuiLink } from '@elastic/eui';
import { Translate } from 'components/Internationalisation/translate';
import { asDateTime, useTranslate } from 'components/Internationalisation/useTranslate';
import { React } from 'react';
import { useDocument } from 'services/documents';
import { formatAsTitle } from 'utils/text';

function DocumentName({ documentId }) {
  const { data: document, isLoading: documentIsLoading } = useDocument(documentId);

  if (documentIsLoading) {
    return null;
  }

  return document.original_file_name;
}

function RelationType({ relationType }) {
  // i18n message IDs have to be static to be able to be translated,
  // so we have to declare them like this.
  switch (relationType) {
    case 'split_parent':
      return (
        <Translate
          id="relatedDocumentsTable.relations.split_parent"
          defaultMessage="Split parent"
        />
      );
    case 'split_child':
      return (
        <Translate
          id="relatedDocumentsTable.relations.split_child"
          defaultMessage="Split child"
        />
      );
    default:
      return formatAsTitle(relationType);
  }
}

function DocumentImportedAt({ documentId }) {
  const { data: document, isLoading: documentIsLoading } = useDocument(documentId);

  if (documentIsLoading) {
    return null;
  }

  return asDateTime(document.imported_at);
}

export function RelatedDocumentsTable({ document }) {
  const intl = useTranslate();

  const relatedDocumentColumns = [
    {
      field: 'related_document_id',
      name: (
        <Translate
          id="relatedDocumentsTable.column.relatedDocumentId.title"
          defaultMessage="Document"
        />
      ),
      truncateText: false,
      render: (relatedDocumentId) => (
        <EuiLink href={`/documents/${relatedDocumentId}`}>
          <DocumentName documentId={relatedDocumentId} />
        </EuiLink>
      ),
    },
    {
      field: 'relation_type',
      name: (
        <Translate
          id="relatedDocumentsTable.column.relationType.title"
          defaultMessage="Relation"
        />
      ),
      render: (relationType) => <RelationType relationType={relationType} />,
    },
    {
      // Duplicate field ID isn't pretty but it's the easiest way
      field: 'related_document_id',
      name: (
        <Translate
          id="relatedDocumentsTable.column.importedAt.title"
          defaultMessage="Imported at"
        />
      ),
      dataType: 'date',
      width: '10em',
      render: (relatedDocumentId) => (
        <DocumentImportedAt documentId={relatedDocumentId} />
      ),
    },
  ];

  const relatedDocuments = document.relations;

  return (
    <EuiBasicTable
      items={relatedDocuments}
      rowHeader="related_document_id"
      columns={relatedDocumentColumns}
      noItemsMessage={intl.formatMessage({
        id: 'error.noRelatedDocumentsFound',
        defaultMessage: 'No related documents found.',
      })}
    />
  );
}
